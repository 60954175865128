import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import CareTemplate from "../../components/templates/Care";
import { ActivityDataType } from "../../shared/type";
import { totalMetrics } from "./data";
import { CARE_METRICS_QUERY } from "./query";
import { ICareMetrics } from "./type";

const Care = () => {
  const [careMetrics, setCareMetrics] =
    useState<ActivityDataType[]>(totalMetrics);
  const { data, loading } = useQuery<ICareMetrics>(CARE_METRICS_QUERY);
  useEffect(() => {
    document.title = "SetuCare Metrics - SetuFarm";
  }, [])
  useEffect(() => {
    if (data && !loading) {
      setCareMetrics((metrics) =>
        metrics.map((metric) => ({
          ...metric,
          count: data[metric.name as keyof ICareMetrics].aggregate.count,
          countVariants: metric.countVariants?.map((countVariant) => ({...countVariant, count: data[countVariant.name as keyof ICareMetrics].aggregate.count}))
        }))
      );
    }
  }, [data, loading]);

  return <CareTemplate totalMetricData={careMetrics} isLoading={loading} />;
};

export default Care;
