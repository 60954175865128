import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { injectStyle } from "react-toastify/dist/inject-style";
import "./index.css";
import { config } from "./config";
import { ErrorBoundary } from "./components/atoms/Fallback/ErrorBoundary";

Sentry.init({
  environment: config.env,
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

//  inject toast styles only once in app
if (typeof window !== "undefined") {
  injectStyle();
}

ReactDOM.render(
  <ErrorBoundary>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ErrorBoundary>,
  document.getElementById("root")
);
