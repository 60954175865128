import { MouseEventHandler, useEffect, useState } from "react";

const useScrollToTop = (
  offset: number
): [boolean, MouseEventHandler<HTMLDivElement>] => {
  const [display, setDisplay] = useState(false);
  const [triggerOffset, setTriggerOffset] = useState(offset);

  const handleDisplay = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    setDisplay(top > triggerOffset ? true : false);
  };

  const scrollToTop: MouseEventHandler<HTMLDivElement> = (e) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleDisplay, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleDisplay);
    };
  }, []);

  return [display, scrollToTop];
};

export default useScrollToTop;
