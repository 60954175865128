import { DocumentNode } from "graphql";
import { DailyRatesForm } from "./DailyRatesForm";
import { CREATE_BASE_RATES } from "./query";

interface ITradeSetupModalType {
  name: string;
  Form: any;
  state: any;
  required: string[];
  create: DocumentNode;
}

export interface ITradeSetupModalData {
  "daily-rates": ITradeSetupModalType;
}

export const tradeSetupModalData: ITradeSetupModalData = {
  "daily-rates": {
    name: "Daily Commodity Rates",
    create: CREATE_BASE_RATES,
    state: {},
    required: ["rates"],
    Form: DailyRatesForm,
  },
};
