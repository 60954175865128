import React, { Suspense } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

const Login = React.lazy(() => import("../pages/Login"));
const Advisory = React.lazy(() => import("../pages/Advisory"));
const Articles = React.lazy(() => import("../pages/Articles"));
const CreateArticle = React.lazy(() => import("../pages/CreateArticle"));
const EditArticle = React.lazy(() => import("../pages/EditArticle"));
const AdvisorySetup = React.lazy(() => import("../pages/AdvisorySetup"));
const Farm = React.lazy(() => import("../pages/Farm"));
const FarmManage = React.lazy(() => import("../pages/FarmManage"));
const FarmManageDetail = React.lazy(() => import("../pages/FarmManageDetail"));
const FarmObservation = React.lazy(() => import("../pages/FarmObservation"));
const FarmObservationDetail = React.lazy(
  () => import("../pages/FarmObservationDetail")
);
const FarmOperation = React.lazy(() => import("../pages/FarmOperation"));
const FarmOperationDetail = React.lazy(
  () => import("../pages/FarmOperationDetail")
);
const Teams = React.lazy(() => import("../pages/Teams"));
const TeamManage = React.lazy(() => import("../pages/TeamManage"));
const TradeMetrics = React.lazy(() => import("../pages/Trade/Metrics"));
const TradeSetup = React.lazy(() => import("../pages/Trade/Setup"));
const TradeListing = React.lazy(() => import("../pages/Trade/Listing"));

import {
  LogoLoader as Loading,
  LogoLoader,
} from "../components/atoms/Loader/LogoLoader";
import { AuthRoute } from "../components/molecules/ProtectedRoutes/AuthRoute";
import { RestrictedContent } from "../components/molecules/ProtectedRoutes/RestrictedContent";

import { PageOutlet } from "../components/molecules/PageOutlet";
import TabsTableContent from "../components/organisms/ArticleListingSections/TabsTableContent";
import TeamUserTabsTableContent from "../components/organisms/TeamUserSections/TabsTableContent";
import NotificationTabsTableContent from "../components/organisms/NotificationsListingSections/TabsTableContent";

import SetupTabsTableContent from "../components/templates/AdvisorySetup/SetupTabsTableContent";
import { SetupCreateModal as CourseCreateModal } from "../components/organisms/CoursesSections/CourseCreateModal";
import { SetupEditModal as CourseEditModal } from "../components/organisms/CoursesSections/CourseEditModal";
import { SetupCreateModal } from "../components/organisms/AdvisorySetupSections/SetupCreateModal";
import { SetupEditModal } from "../components/organisms/AdvisorySetupSections/SetupEditModal";
import Notifications from "../pages/Notifications";
import CreateNotification from "../pages/CreateNotification";
import NotificationsListing from "../pages/NotificationsListing";
import TeamsTemplate from "../components/templates/Teams";
import FarmAssetsTemplate from "../components/templates/Teams/FarmAssets";
import TeamMapView from "../components/templates/TeamMapView";
import School from "../pages/School";
import SchoolCourses from "../pages/SchoolCourses";
import { CoursesDetailModal } from "../components/organisms/CoursesSections/CoursesDetailModal";
import Care from "../pages/Care";
import TeamUserTable from "../pages/TeamUserTable";
import UserManageTable from "../pages/TeamUserTable/UserTable";
import { CareListing } from "../pages/CareListing";
import TradeSetupTable from "../components/templates/Trade/Setup/TradeSetupTable";
import { TradeSetupCreateModal } from "../components/organisms/TradeSetupSections/TradeSetupCreateModal";
import { useUser } from "../lib/contexts/usercontext";
import { AuthorsTable } from "../components/templates/AdvisorySetup/AuthorsTable";
import { AuthorForm } from "../components/organisms/AdvisorySetupSections/AuthorForm";
import { OrgAccessForm } from "../components/organisms/AdvisorySetupSections/OrgAccessForm";

const AppRoutes: React.FunctionComponent = () => {
  const { state } = useUser();

  let firstViewRoute =
    state?.permissions?.find((item) => item.permissions.includes("view"))
      ?.route || "farm";

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Suspense fallback={<Loading width={150} margin="25% auto" />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/"
        element={
          <AuthRoute>
            <Suspense fallback={<Loading width={150} />}>
              {state?.permissions ? <PageOutlet /> : <LogoLoader width={150} />}
            </Suspense>
          </AuthRoute>
        }
      >
        <Route index element={<Navigate to={firstViewRoute} />} />
        <Route
          path="farm"
          element={
            <RestrictedContent
              keyName="farm_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route index element={<Farm />} />
          <Route path="map" element={<TeamManage />}>
            <Route index element={<TeamMapView />} />
          </Route>
          <Route path="plot-crops-table">
            <Route index element={<FarmManage />} />
            <Route path=":id" element={<FarmManageDetail />} />
          </Route>
          <Route path="observation">
            <Route index element={<FarmObservation />} />
            <Route path=":id" element={<FarmObservationDetail />} />
          </Route>
          <Route path="operation">
            <Route index element={<FarmOperation />} />
            <Route path=":id" element={<FarmOperationDetail />} />
          </Route>
        </Route>

        <Route
          path="advisory"
          element={
            <RestrictedContent
              keyName="advice_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route index element={<Advisory />} />
          <Route path="articles">
            <Route element={<Articles />}>
              <Route index element={<Navigate to="published" />} />
              <Route path=":type" element={<TabsTableContent />} />
            </Route>
            <Route
              path="create"
              element={
                <RestrictedContent
                  keyName="advice_management"
                  permission="create"
                  showFallback
                >
                  <CreateArticle />
                </RestrictedContent>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RestrictedContent
                  keyName="advice_management"
                  permission="edit"
                  showFallback
                >
                  <EditArticle />
                </RestrictedContent>
              }
            />
          </Route>
          <Route path="setup">
            <Route element={<AdvisorySetup />}>
              <Route index element={<Navigate to="categories" />} />
              <Route path=":type" element={<SetupTabsTableContent />}>
                <Route
                  path="create"
                  element={
                    <RestrictedContent
                      keyName="advice_management"
                      permission="create"
                    >
                      <SetupCreateModal />
                    </RestrictedContent>
                  }
                />
                <Route
                  path="edit/:id"
                  element={
                    <RestrictedContent
                      keyName="advice_management"
                      permission="edit"
                    >
                      <SetupEditModal />
                    </RestrictedContent>
                  }
                />
              </Route>
              {[
                { path: "authors" as const, Form: AuthorForm },
                { path: "org-access" as const, Form: OrgAccessForm },
              ].map((item) => (
                <Route
                  key={item.path}
                  path={item.path}
                  element={<AuthorsTable key={item.path} path={item.path} />}
                >
                  <Route
                    path="create"
                    element={
                      <RestrictedContent
                        keyName="advice_management"
                        permission="create"
                      >
                        <item.Form />
                      </RestrictedContent>
                    }
                  />
                  <Route
                    path="edit/:id"
                    element={
                      <RestrictedContent
                        keyName="advice_management"
                        permission="edit"
                      >
                        <item.Form />
                      </RestrictedContent>
                    }
                  />
                </Route>
              ))}
            </Route>
          </Route>
        </Route>

        <Route
          path="trade"
          element={
            <RestrictedContent
              keyName="trade_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route index element={<TradeMetrics />} />
          <Route path="listing" element={<TradeListing />} />
          <Route path="setup" element={<TradeSetup />}>
            <Route index element={<Navigate to="daily-rates" />} />
            <Route path=":type" element={<TradeSetupTable />}>
              <Route
                path="create"
                element={
                  <RestrictedContent
                    keyName="trade_management"
                    permission="create"
                  >
                    <TradeSetupCreateModal />
                  </RestrictedContent>
                }
              />
            </Route>
          </Route>
        </Route>

        <Route
          path="notifications"
          element={
            <RestrictedContent
              keyName="message_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route index element={<Notifications />} />
          <Route
            path="create"
            element={
              <RestrictedContent
                keyName="message_management"
                permission="create"
              >
                <CreateNotification />
              </RestrictedContent>
            }
          />
          <Route path="listing">
            <Route element={<NotificationsListing />}>
              <Route index element={<Navigate to="broadcasted" />} />
              <Route path=":type" element={<NotificationTabsTableContent />} />
            </Route>
          </Route>
        </Route>

        <Route
          path="network"
          element={
            <RestrictedContent
              keyName="network_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route element={<Teams />}>
            <Route index element={<Navigate to="metrics" />} />
            <Route path="metrics" element={<TeamsTemplate />} />
            <Route path="assets" element={<FarmAssetsTemplate />} />
          </Route>
          <Route path="teams" element={<TeamUserTable />}>
            <Route
              index
              element={<TeamUserTabsTableContent type="team" key="team" />}
            />
          </Route>
          <Route path="users" element={<UserManageTable />}>
            <Route
              index
              element={<TeamUserTabsTableContent type="user" key="user" />}
            />
          </Route>
        </Route>

        <Route
          path="school"
          element={
            <RestrictedContent
              keyName="school_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route index element={<School />} />
          <Route path="courses" element={<SchoolCourses />}>
            <Route path=":id" element={<CoursesDetailModal />} />
            <Route
              path="create"
              element={
                <RestrictedContent
                  keyName="school_management"
                  permission="create"
                >
                  <CourseCreateModal />
                </RestrictedContent>
              }
            />
            <Route
              path="edit/:id"
              element={
                <RestrictedContent
                  keyName="school_management"
                  permission="edit"
                >
                  <CourseEditModal />
                </RestrictedContent>
              }
            />
          </Route>
        </Route>

        <Route
          path="care"
          element={
            <RestrictedContent
              keyName="care_management"
              permission="view"
              showFallback
            >
              <Outlet />
            </RestrictedContent>
          }
        >
          <Route index element={<Care />} />
          <Route path="products" element={<CareListing />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
