import { Divider } from "../../atoms/Divider";
import { Group } from "../../atoms/Group";
import { Space } from "../../atoms/Space";
import Typography from "../../atoms/Typography";
import AttachmentSection from "../../organisms/GenericSections/PlotDetailCard/PlotDetailCardSections/AttachmentSection";
import { ProductSection } from "./ProductSections";
import { ProductTable } from "./ProductTable";

export function CareProductPreview({ data }: { data: any }) {
  const product = data?.product?.[0];
  if (!product) return <></>;
  return (
    <div>
      <div className="mb-6">
        <Typography variant="h3" className="mb-6">
          {product.name}
        </Typography>
        <Typography variant="body1">{product.description}</Typography>
      </div>
      <AttachmentSection
        attachments={
          product.product_attachments
            ?.filter((item: any) => item.photo.media_type === "image")
            ?.map((item: any, i: number) => ({
              image: item.photo.url,
              index: i + 1,
            })) || []
        }
        showImageIndex={true}
      />
      <Space h={24} />
      <ProductTable
        data={[
          {
            name: "Manufacturer",
            value: product.manufacturing_facility?.manufacturer?.name,
          },
          {
            name: "Marketed by",
            value: product.marketing_facility?.marketer?.name,
          },
        ]}
      />
      <Divider my={16} />
      <ProductSection section={data?.product_composition} level={0} />
      <ProductSection section={data?.nutrient_recommendation} level={0} />
      <ProductSection section={data?.protection_recommendation} level={0} />
      {product?.product_mainsections?.map((item: any, i: number) => (
        <ProductSection section={item} level={0} key={i} />
      ))}
    </div>
  );
}
