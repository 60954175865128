import { useEffect, useState } from "react";
import { Divider } from "../../atoms/Divider";
import Typography from "../../atoms/Typography";
import { ProductTable } from "./ProductTable";

export function ProductSection({
  section,
  level = 0,
  handleEmptyInner,
}: {
  section: any;
  level: number;
  handleEmptyInner?: (val: boolean) => void;
}) {
  if (!section) return <></>;

  let sectionKey = section?.product_sections
    ? "product_sections"
    : section?.product_subsections
    ? "product_subsections"
    : "";

  const sectionTitleMap: Record<number, string> = {
    0: "h5",
    1: "subtitle1",
    2: "subtitle2",
  };
  const [emptyInner, setEmptyInner] = useState<boolean[]>([]);

  const hideSection =
    !section?.value &&
    !section.product_attributes?.length &&
    emptyInner.every((i) => i);

  useEffect(() => {
    handleEmptyInner?.(hideSection);
  }, [hideSection]);

  return (
    <div>
      {!hideSection && (
        <Typography
          variant={sectionTitleMap[level || 0] as any}
          className="my-4"
        >
          {section?.title?.name}
        </Typography>
      )}
      <Typography variant="body1">{section?.value}</Typography>
      {section.product_attributes?.length ? (
        <ProductTable
          data={section?.product_attributes.map((attr: any) => ({
            name: attr?.attribute?.name,
            value: attr?.value,
          }))}
        />
      ) : null}

      {section[sectionKey]?.map((innerSection: any, i: number) => (
        <ProductSection
          key={i}
          section={innerSection}
          level={level + 1}
          handleEmptyInner={(val) => setEmptyInner((prev) => [...prev, val])}
        />
      ))}
      {level === 0 && !hideSection && <Divider my={16} />}
    </div>
  );
}
