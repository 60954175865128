import { DocumentNode, gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  Actions,
  NotificationActions,
  NotificationState,
} from "../../../../pages/CreateNotification/type";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import { MultiSelect } from "../../../atoms/MultiSelect";

export interface Props {
  state: NotificationState;
  dispatch: React.Dispatch<Actions>;
  query: DocumentNode;
}
export function NotificationType({ state, dispatch }: Props) {
  const handleChangeValue = (key: string, value: string | null) => {
    dispatch({
      type: NotificationActions.SET_VALUE,
      payload: {
        key,
        value,
      },
    });
  };
  const [getData, { data }] = useLazyQuery(
    state.selectedCategory.query || gql``
  );
  useEffect(() => {
    handleChangeValue("notificationType", null);
    getData();
  }, [state.selectedCategory]);

  /** Auto fill name and description. */
  useEffect(() => {
    if (state.notificationType) {
      dispatch({
        type: NotificationActions.SET_VALUES,
        payload: [
          ...[
            {
              key: "recordId",
              value: state.notificationType.codename,
            },
            {
              key: "title",
              value: state.notificationType.name,
            },
            {
              key: "description",
              value: state.notificationType.description || "",
            },
          ],
        ],
      });
    }
  }, [state.notificationType]);

  return (
    <Select
      data-test-id="notification-type"
      limit={100}
      label={`Select ${state.selectedCategory.type}`}
      values={state.notificationType || ""}
      listData={data ? data[state.selectedCategory.type || ""] : []}
      accessor={{ name: "name", value: "codename" }}
      placeholder={`Select ${state.selectedCategory.type}`}
      onSelect={(item) => handleChangeValue("notificationType", item)}
      searchable
      clearable
      size="lg"
      className="w-1/3 mb-8"
    />
  );
}
