import styled from "styled-components";

export const NoResultsContainer = styled.div<{
  width?: string;
  marginLeft?: string;
  marginRight?: string;
}>`
  font-size: 1.4rem;
  font-weight: bold;
  width: ${({ width }) => width || "100%"};
  margin-left: ${({ marginLeft }) => marginLeft || "auto"};
  margin-right: ${({ marginRight }) => marginRight || "auto"};
  padding: 1rem;
  color: ${({ theme }) => theme.colors.orange.main};
`;
