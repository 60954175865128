import { useQuery } from "@apollo/client";
import { useDebouncedValue } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { BsGrid, BsList } from "react-icons/bs";
import { HiSearch } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { Group } from "../../components/atoms/Group";
import { Input } from "../../components/atoms/MantineInput";
import { SegmentedControl } from "../../components/atoms/SegmentedControl";
import { Space } from "../../components/atoms/Space";
import FilterDrawer from "../../components/molecules/FilterDrawer";
import { ExpandingInput } from "../../components/molecules/FilterDrawer/ExpandingInput";
import PageTitle from "../../components/molecules/PageTitle";
import { CareListingTemplate } from "../../components/templates/CareListing";
import { config } from "../../config";
import { getLocalStorage, setLocalStorage } from "../../lib/utils/localStorage";
import { careListingBreadcrumbs } from "./data";
import { filterData, initialData, initialValues } from "./filterData";
import { CARE_LISTING_FILTERS } from "./query";

export function CareListing() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [delayedSearchText] = useDebouncedValue(
    searchText,
    config.debounceWait
  );
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) || 1
  );
  const [careListCount, setCareListCount] = useState(0);
  const [selectedList, setSelectedList] = useState<any>({});
  const [selectedListData, setSelectedListData] = useState(initialData);

  const [listType, setListType] = useState(
    getLocalStorage("careConfig")?.listType || "list"
  );
  const { loading, data: careListingFilters } = useQuery(CARE_LISTING_FILTERS);

  useEffect(() => {
    if (careListingFilters) {
      setSelectedListData((prev: any) => ({
        ...prev,
        crops: {
          ...prev.crops,
          listData: careListingFilters.crops?.map((item: any) => item.crop),
        },
        brands: {
          ...prev.brands,
          original: careListingFilters.brands,
          listData: careListingFilters.brands,
        },
        category: {
          ...prev.category,
          original: careListingFilters.category,
          listData: careListingFilters.category,
        },
        subCategory: {
          ...prev.subCategory,
          original: careListingFilters.subcategory,
          listData: careListingFilters.subcategory,
        },
      }));
    }
  }, [loading]);

  const handleQueryParams = ({
    page = currentPage,
    search = searchText,
  }: {
    page?: number;
    search?: string;
  }) => {
    if (page > 1) searchParams.set("page", page.toString());
    else searchParams.delete("page");
    if (search) searchParams.set("search", search);
    else searchParams.delete("search");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    handleQueryParams({ search: delayedSearchText });
  }, [delayedSearchText]);

  useEffect(() => {
    setSearchText(searchParams.get("search") || "");
    setCurrentPage(Number(searchParams.get("page")) || 1);
    document.title = "Products - SetuFarm";
  }, [searchParams]);

  const handleListType = (type: string) => {
    let localConfig = getLocalStorage("careConfig");
    setLocalStorage("careConfig", { ...localConfig, listType: type });
    setListType(type);
  };

  return (
    <>
      <PageTitle breadCrumbsList={careListingBreadcrumbs} fixed />
      <Group spacing={0} noWrap>
        <ExpandingInput>
          <Input
            data-cy="careList-search-input"
            value={searchText || ""}
            onChange={(e) => setSearchText(e.target.value)}
            rightSection={<HiSearch />}
            className="transition ease-in-out"
            placeholder="Search by product..."
            size={"md"}
          />
        </ExpandingInput>
        <FilterDrawer
          filterData={filterData}
          initialValues={initialValues}
          handleValues={setSelectedList}
          initialData={selectedListData}
          count={careListCount}
        />
        <SegmentedControl
          size="md"
          value={listType}
          onChange={(value) => handleListType(value)}
          data={[
            { label: <BsList className="text-primary-main" />, value: "list" },
            { label: <BsGrid className="text-primary-main" />, value: "grid" },
          ]}
          style={{ flexShrink: 0 }}
        />
      </Group>
      <Space h="lg" />
      <CareListingTemplate
        listType={listType}
        search={delayedSearchText}
        setCareListCount={setCareListCount}
        filterData={selectedList}
        currentPage={currentPage}
        setCurrentPage={(page: number) => handleQueryParams({ page })}
      />
    </>
  );
}
