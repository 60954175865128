import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useUser } from "../../../lib/contexts/usercontext";
import { Checkbox } from "../../atoms/Input/MantineCheckbox";
import { Input } from "../../atoms/MantineInput";
import { Select } from "../../atoms/MantineSelect";
import { GET_SERIES_CREATE_DATA } from "./query";
import { MultiSelect } from "../../atoms/MultiSelect";
import {
  FeaturedImageContainer,
  SelectedImageContainer,
  SelectItem,
} from "../ArticleCreationSections/styles";
import { Attachment } from "../../../lib/interfaces/Article";
import UploadButton from "../../atoms/UploadFile";
import { UPLOAD_FILE } from "../../../lib/queries/uploadFile";
import { Grid } from "../../atoms/Grid";
import { Textarea } from "../../atoms/Textarea";
import { Loader } from "../../atoms/Loader/Loader";
import { Divider } from "../../atoms/Divider";

export const CourseForm = ({ state, handleChange, type }: any) => {
  const user = useUser();
  const { loading, data } = useQuery(GET_SERIES_CREATE_DATA);
  const [selectedFeature, setSelectedFeature] = useState("");
  const [UploadFile] = useMutation(UPLOAD_FILE);

  useEffect(() => {
    handleChange({ orgId: user.state.me?.agent.organisation.id });
  }, [user]);

  const handleDropdownFeatureImage = (item: Attachment) => {
    if (item) setSelectedFeature("dropdown");
    else setSelectedFeature("");
    handleChange({ featuredImage: item, featuredImageId: item?.id || "" });
  };

  const uploadFeaturedImage = (response: any) => {
    UploadFile({
      variables: {
        url: response.data.url,
        mediaType: response.data.type,
        name: response.data.name,
        duration: 0,
      },
    }).then(({ data }) => {
      let item = {
        id: data.insert_fileupload_one.id,
        url: response.data.url,
        media_type: response.data.type,
        name: response.data.name,
      };
      handleChange({ featuredImage: item, featuredImageId: item?.id || "" });
    });
  };

  const handleFeatureImageUpload = (response: any) => {
    if (response.status === 200) {
      setSelectedFeature("upload");
      uploadFeaturedImage(response);
    } else {
      setSelectedFeature("");
      handleChange({ featuredImage: undefined, featuredImageId: "" });
    }
  };

  return (
    <>
      <Grid>
        <Grid.Col span={4}>
          <Input
            label="Order"
            placeholder="Enter order"
            type="number"
            min={0}
            max={9999}
            value={state.order}
            onChange={(e) => {
              if (e.target.value.length > 4) return;
              handleChange({ order: e.target.value });
            }}
            required
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Input
            label="Name"
            placeholder="Enter course name"
            value={state.name}
            onChange={(e) => handleChange({ name: e.target.value })}
            required
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            label="Course summary"
            placeholder="Enter course summary"
            value={state.courseSummary}
            onChange={(e) => handleChange({ courseSummary: e.target.value })}
            required
            autosize
            maxRows={5}
          />
        </Grid.Col>
        <Grid.Col span={2} mb={"sm"} className="self-end">
          <Checkbox
            label="Public"
            checked={state.isPublic}
            onChange={(e) => handleChange({ isPublic: e.target.checked })}
          />
        </Grid.Col>
        <Grid.Col span={3} mb={"sm"} className="self-end">
          <Checkbox
            label="Lectures Locked"
            checked={state.areLecturesLocked}
            onChange={(e) =>
              handleChange({ areLecturesLocked: e.target.checked })
            }
          />
        </Grid.Col>
        <Grid.Col span={2} mb={"sm"} className="self-end">
          {type === "edit" ? (
            <Checkbox
              label="Active"
              checked={state.isActive}
              onChange={(e) => handleChange({ isActive: e.target.checked })}
            />
          ) : null}
        </Grid.Col>
        <Grid.Col span={5}>
          <Input
            label="Number of lectures unlocked"
            type="number"
            placeholder="Enter lectures unlocked"
            value={state.noOfLecturesUnlocked}
            onChange={(e) =>
              handleChange({ noOfLecturesUnlocked: e.target.value })
            }
            required
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider my={8} />
        </Grid.Col>
        <Grid.Col span={6}>
          <Input
            label="Unit Price"
            type="number"
            placeholder="Enter unit price"
            value={state.unitPrice}
            onChange={(e) => handleChange({ unitPrice: e.target.value })}
            required
            icon={state.currency}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Input
            label="Discount Price"
            type="number"
            placeholder="Enter discount price"
            value={state.discountPrice}
            onChange={(e) => handleChange({ discountPrice: e.target.value })}
            required
            icon={state.currency}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Input
            label="Taxed Unit Price"
            type="number"
            placeholder="Enter taxed unit price"
            value={state.taxedUnitPrice}
            onChange={(e) => handleChange({ taxedUnitPrice: e.target.value })}
            required
            icon={state.currency}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Input
            label="Taxed Discount Price"
            type="number"
            placeholder="Enter taxed discount price"
            value={state.taxedDiscountPrice}
            onChange={(e) =>
              handleChange({ taxedDiscountPrice: e.target.value })
            }
            required
            icon={state.currency}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Divider my={8} />
        </Grid.Col>
        {!loading ? (
          <>
            <Grid.Col span={6}>
              <Select
                label="Packages"
                listData={data?.packages}
                placeholder="Select Package"
                values={state.packageId || ""}
                accessor={{ name: "name", value: "id" }}
                onSelect={(item) => handleChange({ packageId: item.id })}
                searchable
                clearable
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <MultiSelect
                label="Categories"
                listData={data?.categories}
                placeholder="Select Categories"
                values={state.categories}
                accessor={{ name: "name", value: "id" }}
                onSelect={(item) =>
                  handleChange({
                    categories: item.map((item: any) => item.id),
                  })
                }
                searchable
                clearable
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <MultiSelect
                label="Authors"
                listData={data.authors.map((item: any) => ({
                  name: item.shared_author.name,
                  seriesAuthorId: item.seriesAuthorId,
                }))}
                placeholder="Select Authors"
                values={state.authors}
                accessor={{ name: "name", value: "seriesAuthorId" }}
                onSelect={(item) =>
                  handleChange({
                    authors: item.map((item: any) => item.seriesAuthorId),
                  })
                }
                searchable
                clearable
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <MultiSelect
                label="Crops"
                listData={data.crops}
                placeholder="Select Crops"
                values={state.crops}
                accessor={{ name: "name", value: "crop_id" }}
                onSelect={(item) =>
                  handleChange({
                    crops: item.map((item: any) => item.crop_id),
                  })
                }
                searchable
                clearable
              />
            </Grid.Col>
            <Grid.Col span={12} mt={"md"}>
              <FeaturedImageContainer>
                <div className="flex items-end gap-4">
                  <Select
                    label="Featured Image"
                    data-test-id="feature-image"
                    listData={data.featuredImages}
                    values={state.featuredImage || ""}
                    accessor={{ name: "name", value: "id" }}
                    placeholder="Select existing images"
                    onSelect={handleDropdownFeatureImage}
                    disabled={selectedFeature === "upload"}
                    searchable
                    clearable
                    required
                    limit={100}
                    itemComponent={SelectItem}
                    className="w-full"
                  />
                  <UploadButton
                    accept={["image/*"]}
                    onfileUploadedCallback={handleFeatureImageUpload}
                    placeholder=""
                    disabled={selectedFeature === "dropdown"}
                    className="w-14 h-14"
                  />
                </div>
                {state.featuredImage && (
                  <SelectedImageContainer imageUrl={state.featuredImage?.url} />
                )}
              </FeaturedImageContainer>
            </Grid.Col>
          </>
        ) : (
          <Loader mx={"auto"} my={"lg"} />
        )}
      </Grid>
    </>
  );
};
