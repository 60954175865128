export function normalizeTeamManageFilter(filterData: Record<string, any>) {
  if (!filterData) filterData = {};
  filterData.regions = [
    ...(filterData.villages || []),
    ...(filterData.talukas || []),
  ];
  return {
    ...(filterData.isActive
      ? { is_active: { _eq: filterData.isActive === "active" } }
      : {}),
    ...(filterData.verification
      ? { is_verified: { _eq: filterData.verification === "verified" } }
      : {}),
    ...(filterData.farmsetuQC
      ? { quality_checked: { _eq: filterData.farmsetuQC === "verified" } }
      : {}),
    ...(filterData?.teams?.length || filterData?.regions?.length
      ? {
          farmer: {
            mutual_group_memberships: {
              mutual_group: {
                ...(filterData?.teams?.length
                  ? {
                      codename: {
                        _in: filterData.teams.map((item: any) => item.id),
                      },
                    }
                  : {}),
                ...(filterData?.regions?.length
                  ? {
                      region_id: {
                        _in: filterData.regions.map((item: any) => item.id),
                      },
                    }
                  : {}),
              },
            },
          },
        }
      : {}),
  };
}
