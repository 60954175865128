import {
  Checkbox as MantineCheckbox,
  MantineTheme,
  CheckboxProps,
} from "@mantine/core";

let styles = (theme: MantineTheme) => ({
  input: {
    border: "2px solid " + theme.other.colors.primary.main,
    ":checked": {
      border: "2px solid " + theme.other.colors.primary.main,
      background: theme.other.colors.white,
    },
  },
  icon: {
    color: theme.other.colors.primary.main,
  },
  label: {
    color: theme.other.colors.primary.main,
    cursor: "pointer",
  },
});

export const Checkbox = (props: CheckboxProps) => {
  return <MantineCheckbox styles={styles} {...props} />;
};
