import styled, { css } from "styled-components";
import { Group } from "../Group";

export const AssetLabel = styled.div<{ verified?: boolean; infoText?: number }>`
  display: none;
  z-index: 999;
  padding: 1rem;
  padding-bottom: 0.4rem;
  position: absolute;
  border-radius: 0.6rem;
  border: 3px solid
    ${({ theme, verified }) =>
      theme.colors[verified ? "positive" : "negative"].default};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary.main};

  & .popup-status {
    color: ${({ theme, verified }) =>
      theme.colors[verified ? "positive" : "negative"].default};
  }

  & > * {
    margin-bottom: 0.8rem;
  }

  ${({ theme, infoText }) =>
    infoText
      ? css`
          border: none;
          color: ${theme.colors.white};
          background-color: ${theme.colors.blue.light};
        `
      : ""}
`;

export const MapOverlay = styled.div`
  display: flex;
  position: absolute;
  z-index: 29;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000070;
  user-select: none;
`;

export const PopupCardCrop = styled(Group)<{ verified?: boolean }>`
  padding: 1rem;
  border-radius: 0.6rem;
  border: 2px solid
    ${({ theme, verified }) =>
      theme.colors[verified ? "positive" : "negative"].default};
`;
