import { MantineTheme } from "@mantine/core";
import {
  DatePicker as MantineDatePicker,
  DatePickerProps,
} from "@mantine/dates";
import { theme } from "../../../style/theme";

let styles = (theme: MantineTheme) => ({
  input: {
    color: theme.other.colors.primary.main,
  },
  disabled: {
    color: theme.other.colors.border.color + "!important",
    backgroundColor: theme.other.colors.disabled.backgroundColor + "!important",
  },
  label: {
    fontWeight: theme.other.fonts.subtitle2.fontWeight,
    color: theme.other.colors.primary.main,
    marginBottom: theme.other.gaps["4"],
  },
  day: {
    ":hover": {
      backgroundColor:
        theme.other.colors.disabled.backgroundColor + "!important",
    },
    "&[data-selected]": {
      backgroundColor: theme.other.colors.primary.main + "!important",
    },
  },
  monthPickerControlActive: {
    backgroundColor: theme.other.colors.primary.main + "!important",
  },
  yearPickerControlActive: {
    backgroundColor: theme.other.colors.primary.main + "!important",
  },
  required: {
    color: theme.other.colors.primary.main,
  },
});

const currentDateStyles = {
  height: "100%",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid " + theme.colors.orange.main,
};

const DatePickerInput = (props: DatePickerProps) => {
  return (
    <MantineDatePicker
      styles={styles}
      {...props}
      renderDay={(date) => {
        return (
          <div
            style={{
              ...(date.toLocaleDateString() ===
                new Date().toLocaleDateString() && currentDateStyles),
            }}
          >
            {date.getDate()}
          </div>
        );
      }}
    />
  );
};

export default DatePickerInput;
