import { useLazyQuery, useQuery } from "@apollo/client";
import { Dispatch, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "../../../../lib/contexts/usercontext";
import { getOrganizationPreferences } from "../../../../lib/utils/teamDetailApi";
import { theme } from "../../../../style/theme";
import { Divider } from "../../../atoms/Divider";
import { Grid } from "../../../atoms/Grid";
import AreaIcon from "../../../atoms/Icons/AreaIcon";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserIcon from "../../../atoms/Icons/UserIcon";
import { Radio, RadioGroup } from "../../../atoms/Input/Radio";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import { GET_USERS } from "../../../templates/TeamsDetailModal/query";
import {
  Actions,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";
import { GET_FIELDS } from "./query";

export interface PlotDetailFormProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
}

export function PlotDetailForm({ state, dispatch }: PlotDetailFormProps) {
  const user = useUser();
  const [plotSuffix, setPlotSuffix] = useState("");

  const [getUsers, { data: usersData }] = useLazyQuery<{
    users: { id: number; name: string }[];
  }>(GET_USERS);

  const [getFields, { data: fields }] = useLazyQuery(GET_FIELDS);

  useEffect(() => {
    if (state.plot.detail.teamId) {
      getUsers({ variables: { teamId: state.plot.detail.teamId } });
      getFields({ variables: { teamId: state.plot.detail.teamId } });
    }
  }, [state.plot.detail.teamId]);

  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: TeamActions.SET_PLOT_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.plot.detail,
          ...(!state.plot.detail.updatedFields.includes(name)
            ? { updatedFields: [...state.plot.detail.updatedFields, name] }
            : {}),
          [name]: value,
        },
      },
    });
  };

  const fetchData = async (userId: string) => {
    try {
      const organizationPreferences = await getOrganizationPreferences({
        userId,
      });

      setPlotSuffix(organizationPreferences.data.plot_suffix);
    } catch (error) {
      toast.error("Failed to fetch preferences");
    }
  };

  useEffect(() => {
    const userId = user?.state?.me?.id;
    if (userId) {
      fetchData(userId);
    }
  }, [user]);

  return (
    <section className="flex  flex-col  gap-8" data-cy="detail-form">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.plot.detail.owner}</Typography>
        </div>
        <div className="flex justify-start items-center gap-4">
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.plot.detail.teamName}</Typography>
        </div>
      </div>
      <Grid className="mt-2" gutter="xl">
        <Grid.Col lg={2} md={4} sm={6} xs={12}>
          <Input
            label="Plot tag"
            data-cy="plot-tag"
            required
            prefix=""
            icon={
              <div className=" font-bold text-primary-main">{plotSuffix} -</div>
            }
            placeholder="Enter tag"
            type="text"
            maxLength={8}
            value={state.plot.detail.tag.replace(`${plotSuffix}-`, "")}
            name="description"
            size="lg"
            onChange={(e) => {
              dispatchValue("tag", `${plotSuffix}-${e.target.value}`);
            }}
            autoComplete="off"
            error={!state.plot.detail.tag ? "Tag is required" : ""}
          />
        </Grid.Col>
        <Grid.Col  lg={5} md={4} sm={6} xs={12}>
          <Input
            label="Plot name"
            data-cy="plot-name"
            required
            placeholder="Enter plot name"
            type="text"
            value={state.plot.detail.name}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("name", e.target.value);
            }}
            autoComplete="off"
            error={!state.plot.detail.name ? "Name is required" : ""}
          />
        </Grid.Col>
        <Grid.Col  lg={5} md={4} sm={6} xs={12}>
          <Select
            label="Plot owner"
            data-cy="plot-owner"
            size="lg"
            listData={usersData?.users || []}
            placeholder="Select owner"
            values={{ id: state.plot.detail.ownerId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("ownerId", item?.id);
            }}
            clearable
            required
            error={!state.plot.detail.ownerId ? "Owner is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={2} md={4} sm={6} xs={12} data-cy="area-section">
          <Typography variant="h6"><span className="font-bold">Plot area</span></Typography>
          <div className="flex items-center gap-4 mt-6">
            <AreaIcon color={theme.colors.orange.main} className="w-12 h-12" />
            <Typography variant="h5">
              <span className="font-bold">
                {Number(state.plot.detail.area).toFixed(2)} {state.plot.detail.areaUnit}
              </span>
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col lg={5} md={4} sm={6} xs={12} data-cy="close-plot">
          <Typography variant="h6">
            <b>Close plot?*</b>
          </Typography>
          <RadioGroup
            size="md"
            value={state.plot.detail.isArchived ? "yes" : "no"}
            onChange={(value) =>
              dispatchValue("isArchived", value === "yes" ? true : false)
            }
            spacing={30}
            mt={8}
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </RadioGroup>
        </Grid.Col>
      
     
        <Grid.Col lg={5} md={4} sm={6} xs={12}>
          <Select
            label="Field"
            data-cy="field"
            size="lg"
            searchable
            listData={
              fields?.field?.map(
                (item: { id: number; tag: string; name: string }) => ({
                  ...item,
                  name: `${item.tag} ${item.name}`,
                })
              ) || []
            }
            placeholder="Select field"
            values={{ id: state.plot.detail.fieldId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("fieldId", item?.id);
            }}
            clearable
            required
            error={!state.plot.detail.fieldId ? "Field is required" : ""}
          />
        </Grid.Col>
      </Grid>
      <Divider size={1} mt={8} mb={8} />
      <Typography variant="h6"><span data-cy="crop-detail">Crop details</span></Typography>
      <section className="border border-border border-solid" data-cy="crop-detail-section">
        <Grid className="m-auto py-2  bg-disabled-background w-full border-b border-border border-solid">
          <Grid.Col span={1} className="text-center">
            <Typography variant="body1"><span className="font-bold">S.No.</span></Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1"><span className="font-bold">Crop name</span></Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1"><span className="font-bold">Variety</span></Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1"><span className="font-bold">Association</span></Typography>
          </Grid.Col>
          <Grid.Col span={2}>
            <Typography variant="body1"><span className="font-bold">Status</span></Typography>
          </Grid.Col>
        </Grid>
        {state.plot.detail.cropName[0] ? state.plot.detail.cropName.map((crop, index) => (
          <Grid key={index} className="m-auto w-full border-b border-border border-solid last:border-b-0">
            <Grid.Col span={1} className="text-center">
              <Typography variant="body1">{index + 1}</Typography>
            </Grid.Col>
            <Grid.Col span={3}>
              <Typography variant="body1">{crop}</Typography>
            </Grid.Col>
            <Grid.Col span={3}>
              <Typography variant="body1">
                {state.plot.detail.variety[index]}
              </Typography>
            </Grid.Col>
            <Grid.Col span={3}>
              <Typography variant="body1">
                {state.plot.detail.association[index]}
              </Typography>
            </Grid.Col>
            <Grid.Col span={2}>
              <Typography variant="body1">
                {state.plot.detail.status[index]}
              </Typography>
            </Grid.Col>
          </Grid>
        )): (
          <div className="p-8 flex items-center justify-center">
             <Typography variant="body1">No crop found. Ask user to add the crop.</Typography>
          </div>
        )}
      </section>
    </section>
  );
}
