import { HiChevronDown, HiX } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { theme } from "../../../../style/theme";
import { Group } from "../../../atoms/Group";
import Typography from "../../../atoms/Typography";
import { sidebarTypes } from "../../../templates/TeamMapView/data";
import { GetEntityIcon } from "./GetEntityIcon";
import { SidebarHeadLink } from "./SidebarHeadItem";
import { SidebarIconText } from "./styles";

export function SidebarHead({ data }: { data: any }) {
  const [params, setParams] = useSearchParams();
  const type = (params.get("type") || "team") as sidebarTypes;

  return (
    <Group position="apart" align="flex-start" py={12} noWrap>
      <Group spacing={8} direction="column">
        <SidebarIconText>
          <GetEntityIcon type={type} size={24} />
          <Typography variant="h5">
            {data.tag ? `${data.tag} ` : ""}
            {type === "crop" ? data?.crop?.name : data?.name}
          </Typography>
        </SidebarIconText>
        {type === "crop" ? (
          <SidebarIconText>
            <span style={{ width: "2.4rem" }} />
            <Typography variant="subtitle1">{data?.variety?.name}</Typography>
          </SidebarIconText>
        ) : null}
        {!(type === "team" || type === "user") ? (
          <SidebarHeadLink
            data={data.farmer?.memberships[0]?.group}
            type="team"
          />
        ) : (
          <Typography variant="small1">{data.id}</Typography>
        )}
        {type === "field" && <SidebarHeadLink data={data.farm} type="farm" />}
        {type === "plot" && (
          <>
            <SidebarHeadLink data={data.field.farm} type="farm" />
            <SidebarHeadLink data={data.field} type="field" />
          </>
        )}
        {type === "crop" && (
          <>
            <SidebarHeadLink data={data.plot.field.farm} type="farm" />
            <SidebarHeadLink data={data.plot.field} type="field" />
            <SidebarHeadLink data={data.plot} type="plot" />
          </>
        )}
        {type === "team" && (
          <Typography
            variant="body1"
            textColor={
              data.verified
                ? theme.colors.positive.default
                : theme.colors.negative.default
            }
          >
            <span className="font-bold">
              {data.verified ? "Verified" : "Unverified"}
            </span>
          </Typography>
        )}
      </Group>
      {params.get("id") ? (
        <HiX
          style={{ flex: "0 0 18px" }}
          className="mt-1 cursor-pointer shrink-0"
          data-cy="asset-sidebar-close"
          size={18}
          color={theme.colors.orange.main}
          onClick={() => setParams({})}
        />
      ) : (
        <HiChevronDown
          style={{ flex: "0 0 18px" }}
          className="mt-1 cursor-pointer shrink-0"
          size={18}
          color={theme.colors.orange.main}
          onClick={() => setParams({ id: data.id })}
        />
      )}
    </Group>
  );
}
