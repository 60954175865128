import { HiArrowsExpand, HiCheck, HiPencil, HiX } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { fullDate } from "../../../../lib/utils/dateTimeFormat";
import { capitalize } from "../../../../lib/utils/stringFormat";
import { theme } from "../../../../style/theme";
import { Grid } from "../../../atoms/Grid";
import { Group } from "../../../atoms/Group";
import CalendarIcon from "../../../atoms/Icons/Calendar";
import DegreeIcon from "../../../atoms/Icons/DegreeIcon";
import GenderIcon from "../../../atoms/Icons/GenderIcon";
import LocationIcon from "../../../atoms/Icons/LocationIcon";
import PhoneIcon from "../../../atoms/Icons/PhoneIcon";
import { AssociationIcon } from "../../../atoms/Icons/MapSidebarIcons/AssociationIcon";
import { EstimatedDateIcon } from "../../../atoms/Icons/MapSidebarIcons/EstimatedDateIcon";
import { P2PDistanceIcon } from "../../../atoms/Icons/MapSidebarIcons/P2PDistanceIcon";
import { R2RDistanceIcon } from "../../../atoms/Icons/MapSidebarIcons/R2RDistanceIcon";
import UserIcon from "../../../atoms/Icons/UserIcon";
import { MantineButton } from "../../../atoms/MantineButton";
import Typography from "../../../atoms/Typography";
import { SidebarIconText } from "./styles";
import { useContext } from "react";
import { AssetContext } from "../../../../lib/contexts/AssetContext";
import FieldIcon from "../../../atoms/Icons/FieldIcon";
import { Modal } from "../../../atoms/Modal";
import { Input } from "../../../atoms/MantineInput";
import { Checkbox } from "../../../atoms/Input/MantineCheckbox";
import { useState } from "react";
import { useEffect } from "react";
import { getSidebarListData } from "../../../templates/TeamMapView/data";
import { Divider } from "../../../atoms/Divider";
import { MapSidebarListContainer } from "../../../templates/TeamMapView/styles";
import { SidebarBottomItem } from "./SidebarBottomItem";
import { StatusBlock } from "../../../molecules/Cards/ActivityCard/style";
import { RestrictedContent } from "../../../molecules/ProtectedRoutes/RestrictedContent";

export function SidebarDetail({
  data,
  type,
}: {
  data: any;
  type: string;
  isListing?: boolean;
}) {
  const { handleAssetCoords, asset, setAsset } = useContext(AssetContext);
  const [oldValueCheck, setOldValueCheck] = useState(false);
  const [newAreaValue, setNewAreaValue] = useState(0);
  const sidebarListData = getSidebarListData(type, {
    ...data,
    [type]: data[type],
  });

  useEffect(() => {
    setNewAreaValue(Number(Number(asset?.area?.value).toFixed(4)));
  }, [asset?.area?.value]);

  const [params, setParams] = useSearchParams();

  const handleOpenDetail = () => {
    if (
      type === "farm" ||
      type === "team" ||
      type === "user" ||
      type === "field" ||
      type === "plot" ||
      type === "crop"
    ) {
      params.append("detail", data[type].id);
      params.append("detailType", type);
      setParams(params);
    }
  };

  return (
    <>
      {!(type === "team" || params.get("listType")) && (
        <StatusBlock success={data[type].verified} className="font-bold">
          <span>{data[type].verified ? "VERIFIED" : "UNVERIFIED"}</span>
        </StatusBlock>
      )}
      {data[type].areaUnit && (
        <>
          <Group spacing={8} align="" data-cy="area-unit">
            <Typography variant="body1">
              {capitalize(type, true)} Area -
            </Typography>
            <Typography variant="h5">
              {Number(data[type].area).toFixed(2)} {data[type].areaUnit?.name}
            </Typography>
          </Group>
        </>
      )}
      {type === "field" && (
        <>
          <Typography variant="body1">
            <span data-cy="soil">Soil Type - {data[type].soil.name}</span>
          </Typography>
          <Typography variant="body1">
            <span data-cy="gat-no">GAT No. - {data[type].gatNumber}</span>
          </Typography>
        </>
      )}

      {data[type].number && (
        <SidebarIconText data-cy="number">
          <PhoneIcon />
          <Typography variant="body1">{data[type].number}</Typography>
        </SidebarIconText>
      )}

      {data[type].farmer?.companies?.[0]?.company?.name && (
        <SidebarIconText data-cy="company-name">
          <AssociationIcon />
          <Typography variant="body1">
            {data[type].farmer.companies?.[0]?.company.name}
          </Typography>
        </SidebarIconText>
      )}
      {type === "crop" && (
        <>
          <Typography variant="body1">
            <span data-cy="crop-tag">Crop Tag - {data[type].tag}</span>
          </Typography>
          {data[type].sowing_date ? (
            <Group spacing={8} align="" data-cy="sowing-date">
              <EstimatedDateIcon />
              <Typography variant="body1">Sowing date {"(E)"} -</Typography>
              <Typography variant="h5">
                {fullDate(data[type].sowing_date)}
              </Typography>
            </Group>
          ) : null}
          {data[type].p2p ? (
            <Group spacing={8} align="" data-cy="p2p">
              <P2PDistanceIcon />
              <Typography variant="body1">PxP distance -</Typography>
              <Typography variant="h5">
                {data[type].p2p} {data[type].p2pUnit?.name}
              </Typography>
            </Group>
          ) : null}
          {data[type].r2r ? (
            <Group spacing={8} align="" data-cy="r2r">
              <R2RDistanceIcon />
              <Typography variant="body1">RxR distance -</Typography>
              <Typography variant="h5">
                {data[type].r2r} {data[type].r2rUnit?.name}
              </Typography>
            </Group>
          ) : null}
        </>
      )}
      {data[type].address?.raw && (
        <SidebarIconText data-cy="address">
          <LocationIcon />
          <Typography variant="body1">{data[type].address.raw}</Typography>
        </SidebarIconText>
      )}
      {data[type].education && (
        <SidebarIconText data-cy="education">
          <DegreeIcon />
          <Typography variant="body1">{data[type].education}</Typography>
        </SidebarIconText>
      )}
      {data[type].farmer?.name && type !== "crop" && (
        <SidebarIconText data-cy="farmer-name">
          <UserIcon />
          <Typography variant="body1">{data[type].farmer.name}</Typography>
        </SidebarIconText>
      )}

      {data[type].created_at && data[type].gender && (
        <Grid data-cy="created-at-gender-section">
          <Grid.Col span={6}>
            <SidebarIconText>
              <CalendarIcon />
              <Typography variant="body1">
                {fullDate(data[type].created_at)}
              </Typography>
            </SidebarIconText>
          </Grid.Col>
          <Grid.Col span={6}>
            <SidebarIconText>
              <GenderIcon />
              <Typography variant="body1">{data[type].gender}</Typography>
            </SidebarIconText>
          </Grid.Col>
        </Grid>
      )}
      <RestrictedContent keyName="farm_management" permission="edit">
        <>
          <Group spacing={8} grow>
            <MantineButton
              data-cy="sidebar-detail-button"
              variant="outline"
              size="md"
              disabled={
                !(
                  type === "farm" ||
                  type === "team" ||
                  type === "user" ||
                  type === "field" ||
                  type === "plot" ||
                  type === "crop"
                )
              }
              onClick={handleOpenDetail}
              leftIcon={<HiPencil size={18} color={theme.colors.orange.main} />}
            >
              DETAILS
            </MantineButton>
            {asset?.edit ? (
              <Group spacing={8} align="flex-end" grow>
                <MantineButton
                  variant="outline"
                  size="md"
                  disabled={
                    !Boolean(asset.id) ||
                    (type !== "farm" && !Boolean(asset?.area?.value))
                  }
                  onClick={() => {
                    type !== "farm"
                      ? setAsset((prev: any) => ({ ...prev, modalOpen: true }))
                      : handleAssetCoords();
                  }}
                >
                  <HiCheck color={theme.colors.orange.main} size={18} />
                </MantineButton>
                <MantineButton
                  variant="outline"
                  size="md"
                  onClick={() => {
                    setAsset((prev: any) => ({
                      ...prev,
                      edit: false,
                      handlePlotExpand: undefined,
                      area: undefined,
                      coords: undefined,
                    }));
                  }}
                >
                  <HiX color={theme.colors.orange.main} size={18} />
                </MantineButton>
              </Group>
            ) : (
              <MantineButton
                variant="outline"
                size="md"
                leftIcon={
                  ["plot", "field", "crop"].includes(type) ? (
                    <FieldIcon
                      width={18}
                      height={18}
                      color={theme.colors.orange.main}
                    />
                  ) : (
                    <LocationIcon
                      width={18}
                      height={18}
                      color={theme.colors.orange.main}
                    />
                  )
                }
                onClick={() => {
                  setAsset((prev: any) => ({
                    ...prev,
                    id: data[type].id,
                    type,
                    containerId: data[type].field?.id,
                    edit: true,
                  }));
                }}
                disabled={["team", "user", "crop"].includes(type)}
              >
                {["plot", "field", "crop"].includes(type)
                  ? "BOUNDS"
                  : "LOCATION"}
              </MantineButton>
            )}
          </Group>
          {type === "plot" ? (
            <MantineButton
              data-cy="sidebar-detail-button"
              variant="outline"
              size="md"
              onClick={() => {
                setAsset((prev: any) => ({
                  ...prev,
                  id: data[type].id,
                  type,
                  edit: true,
                  containerId: data[type].field?.id,
                  handlePlotExpand: true,
                }));
              }}
              leftIcon={
                <HiArrowsExpand size={18} color={theme.colors.orange.main} />
              }
            >
              USE FIELD BOUNDS
            </MantineButton>
          ) : null}
        </>
      </RestrictedContent>
      {sidebarListData?.[0] && (
        <>
          <Divider size={1} />
          <MapSidebarListContainer data-cy="asset-bottom-section">
            {sidebarListData.map((item, index) => (
              <SidebarBottomItem
                key={index}
                listType={type}
                type={item.name}
                name={`${data[type]?.tag || ""} ` + data[type].name}
                value={item.count}
                id={item.id}
              />
            ))}
          </MapSidebarListContainer>
        </>
      )}
      {asset?.modalOpen && (type === "field" || type === "plot") ? (
        <Modal
          isOpen={asset?.edit || false}
          handleModal={() =>
            setAsset((prev: any) => ({ ...prev, modalOpen: false }))
          }
          modalTitle="Confirm area"
          height="26rem"
          width="35rem"
          margin="auto"
        >
          <Group spacing={10} mb={20}>
            <Input
              value={newAreaValue || 0}
              type="number"
              onChange={(e) => setNewAreaValue(Number(e.target.value))}
              onBlur={(e) => {
                let valueFixed = Number(Number(e.target.value).toFixed(4));
                let areaFixed = Number(Number(asset.area?.value).toFixed(4));
                let maxValue = areaFixed + 1;
                let minValue = areaFixed - 1;
                if (valueFixed > maxValue) valueFixed = maxValue;
                if (valueFixed < minValue) valueFixed = minValue;
                setNewAreaValue(Number(Number(valueFixed).toFixed(4)));
              }}
              placeholder="Enter area"
              disabled={oldValueCheck}
            />
            <Typography variant="body1">Acres</Typography>
          </Group>
          <Checkbox
            size="md"
            label={`Keep Previous Value - ${Number(data[type].area).toFixed(
              2
            )} ${data[type].areaUnit?.name}`}
            checked={oldValueCheck}
            onChange={(e) => setOldValueCheck((prev) => !prev)}
          />
          <Group spacing={8} mt={30} align="flex-end" grow>
            <MantineButton
              variant="outline"
              size="md"
              disabled={!Boolean(asset.id)}
              onClick={() =>
                handleAssetCoords(
                  !oldValueCheck ? newAreaValue : data[type].area
                )
              }
            >
              <HiCheck color={theme.colors.orange.main} size={18} />
            </MantineButton>
            <MantineButton
              variant="outline"
              size="md"
              onClick={() => {
                setAsset((prev: any) => ({ ...prev, modalOpen: false }));
              }}
            >
              <HiX color={theme.colors.orange.main} size={18} />
            </MantineButton>
          </Group>
        </Modal>
      ) : null}
    </>
  );
}
