import { SVGProps } from "react";

const BellIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 2.80005C9.3608 2.80005 5.59999 6.56086 5.59999 11.2V16.2201L4.61004 17.2101C4.20965 17.6105 4.08987 18.2127 4.30656 18.7358C4.52325 19.2589 5.03374 19.6 5.59999 19.6H22.4C22.9662 19.6 23.4767 19.2589 23.6934 18.7358C23.9101 18.2127 23.7903 17.6105 23.3899 17.2101L22.4 16.2201V11.2C22.4 6.56086 18.6392 2.80005 14 2.80005Z"
      fill={props.color || "white"}
    />
    <path
      d="M14 25.2C11.6804 25.2 9.79995 23.3196 9.79995 21H18.1999C18.1999 23.3196 16.3195 25.2 14 25.2Z"
      fill={props.color || "white"}
    />
  </svg>
);

export default BellIcon;
