import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useUser } from "../../../lib/contexts/usercontext";
import { ALLOW_QC_CHECK_DOMAIN } from "../../../lib/utils/constants";
import { Group } from "../../atoms/Group";
import ArticlesIcon from "../../atoms/Icons/ArticlesIcon";
import GenderIcon from "../../atoms/Icons/GenderIcon";
import LocationIcon from "../../atoms/Icons/LocationIcon";
import UserIcon from "../../atoms/Icons/UserIcon";
import UsersIcon from "../../atoms/Icons/UsersIcon";
import { Space } from "../../atoms/Space";
import Typography from "../../atoms/Typography";
import ActivityCard from "../../molecules/Cards/ActivityCard";
import ActivitiesSection from "../../organisms/GenericSections/ActivitiesSection";
import { TEAMS_USERS_METRICS } from "./query";

function TeamsTemplate() {
  const [getTeamsUsers, { data, loading }] = useLazyQuery(TEAMS_USERS_METRICS);
  const { state: user } = useUser();

  useEffect(() => {
    document.title = "Teams & Users - SetuFarm";
    getTeamsUsers();
  }, []);
  return (
    <div>
      <Space h={16} />
      <ActivitiesSection
        legendTitle="Team and User metrics"
        legendIcon={UsersIcon}
        isLoading={loading}
        alignCenter={true}
      >
        <div
          className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          data-cy="team-metrics"
        >
          <ActivityCard
            icon={UsersIcon}
            displayName="Total teams"
            count={data?.totalTeams.aggregate.count}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedTeams.aggregate.count,
            }}
          />
          <ActivityCard
            icon={UserIcon}
            displayName="Total users"
            count={data?.totalUsers.aggregate.count}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedUsers.aggregate.count,
            }}
          />
          <ActivityCard
            icon={GenderIcon}
            displayName="Gender"
            count={0}
            loading={loading}
          >
            <Group direction="column">
              <Group position="apart" >
              </Group>
              <Group position="apart" >
              </Group>

              <Group position="apart">
                <Typography variant="h6">Male</Typography>
                <Typography variant="h5">
                  {data?.male.aggregate.count.toLocaleString()}
                </Typography>
              </Group>
              <Group position="apart">
                <Typography variant="h6">Female</Typography>
                <Typography variant="h5">
                  {data?.female.aggregate.count.toLocaleString()}
                </Typography>
              </Group>
              <Group position="apart">
                <Typography variant="h6">Not disclosed</Typography>
                <Typography variant="h5">
                  {data?.other.aggregate.count.toLocaleString()}
                </Typography>
              </Group>
            </Group>
          </ActivityCard>
        </div>
      </ActivitiesSection>
    </div>
  );
}

export default TeamsTemplate;
