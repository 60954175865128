import styled from "styled-components";

export const MapSidebarContainer = styled.div`
  flex: 1 0 36rem;
  padding: 0 1.2rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.elevation.elevation1};
  margin-right: 1.6rem;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
`;

export const MapSidebarListContainer = styled.div`
  & > a {
    display: block;
    padding: 1.4rem;
    background-color: ${({ theme }) => theme.colors.disabled.background};
    border-color: ${({ theme }) => theme.colors.border};
    border-style: solid;
    border-width: 0px 2px 1px 2px;
    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &:first-of-type {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-top-width: 2px;
    }
  }
`;
