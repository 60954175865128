import { useEffect, useState } from "react";
import { teamsUserTableBreadcrumbs } from "./data";
import { Outlet } from "react-router";
import PageTitle from "../../components/molecules/PageTitle";
import { useSearchParams } from "react-router-dom";
import { Group } from "../../components/atoms/Group";
import { ExpandingInput } from "../../components/molecules/FilterDrawer/ExpandingInput";
import { Input } from "../../components/atoms/MantineInput";
import FilterDrawer from "../../components/molecules/FilterDrawer";
import { HiSearch } from "react-icons/hi";
import { filterData, usersInitialData, initialValues } from "./teamFilter";
import { config } from "../../config";
import { useDebouncedValue } from "@mantine/hooks";
import { GET_MANAGE_FILTERS } from "./query";
import { useQuery } from "@apollo/client";
import { useUser } from "../../lib/contexts/usercontext";
import { MantineButton } from "../../components/atoms/MantineButton";
import { DownloadReportModal } from "../../components/templates/TeamsManage/DownloadReportModal";
import { BulkUserRegistration } from "../../components/templates/TeamsManage/BulkUserRegistration";
import { Space } from "../../components/atoms/Space";
import { RestrictedContent } from "../../components/molecules/ProtectedRoutes/RestrictedContent";
import { InviteUser } from "../../components/templates/TeamsManage/InviteUser";

const UserManageTable = () => {
  const { state: user } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) || 1
  );
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [selectedList, setSelectedList] = useState<any>({});
  const [selectedListData, setSelectedListData] = useState(usersInitialData);
  const [resultCount, setResultCount] = useState(0);
  const [delayedSearchText] = useDebouncedValue(
    searchText,
    config.debounceWait
  );

  const { loading, data: manageFilters } = useQuery(GET_MANAGE_FILTERS, {
    variables: {
      orgId: user.me?.agent.organisation.id,
    },
    skip: !user.me?.agent.organisation.id,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (manageFilters) {
      setSelectedListData((prev: any) => ({
        ...prev,
        network: {
          ...prev.network,
          listData: manageFilters.networks,
        },
      }));
    }
  }, [loading]);

  const handleQueryParams = ({
    page = currentPage,
    search = searchText,
  }: {
    page?: number;
    search?: string;
  }) => {
    if (page > 1) searchParams.set("page", page.toString());
    else searchParams.delete("page");
    if (search) searchParams.set("search", search);
    else searchParams.delete("search");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    handleQueryParams({ search: delayedSearchText });
  }, [delayedSearchText]);

  useEffect(() => {
    setSearchText(searchParams.get("search") || "");
    setCurrentPage(Number(searchParams.get("page")) || 1);
  }, [searchParams]);

  return (
    <>
      <PageTitle
        breadCrumbsList={teamsUserTableBreadcrumbs}
        fixed
        rightSection={
          <>
            <MantineButton
              className="h-16 px-14"
              onClick={() => setOpenReportModal(true)}
            >
              USER REPORT
            </MantineButton>
            <RestrictedContent keyName="network_management" permission="create">
              <Space w={16} />
              <MantineButton
                className="h-16 px-14"
                onClick={() => setOpenBulkModal(true)}
              >
                BULK UPLOAD
              </MantineButton>
            </RestrictedContent>
          </>
        }
      />
      <Group spacing={0} noWrap mb={10}>
        <ExpandingInput>
          <Input
            data-cy="manage-search-input"
            value={searchText || ""}
            onChange={(e) => setSearchText(e.target.value)}
            rightSection={<HiSearch />}
            className="transition ease-in-out"
            placeholder={`Search here...`}
            size="md"
          />
        </ExpandingInput>
        <FilterDrawer
          filterData={filterData}
          initialValues={initialValues}
          handleValues={setSelectedList}
          initialData={selectedListData}
          count={resultCount}
        />
      </Group>
      <DownloadReportModal
        type="user"
        open={openReportModal}
        setOpen={setOpenReportModal}
      />
      <RestrictedContent keyName="network_management" permission="create">
        <BulkUserRegistration
          open={openBulkModal}
          setOpen={setOpenBulkModal}
          networks={manageFilters?.networks || []}
        />
        <InviteUser />
      </RestrictedContent>
      <Outlet
        context={{
          filterData: selectedList,
          setResultCount: setResultCount,
          searchText: delayedSearchText,
          currentPage,
          setCurrentPage: (page: number) => handleQueryParams({ page }),
        }}
      />
    </>
  );
};

export default UserManageTable;
