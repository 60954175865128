import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { capitalize } from "./stringFormat";
dayjs.extend(relativeTime);

export const getDateTimeDistance = (
  startDate: Date | string = new Date(),
  endDate: Date | string = new Date(),
  doCapitalize = false,
  nestingLevel = 1
) => {
  const calculatedValue = (value: string) =>
    doCapitalize ? capitalize(value, false, nestingLevel) : value;
  return calculatedValue(dayjs(endDate).to(dayjs(startDate)));
};
