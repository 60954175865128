import { getDate } from "../../lib/utils/getDate";
import { ITabsTableContent } from "./types";
import { Cell } from "react-table";
import MultiThumbnailImages from "../../components/atoms/MultiThumbnailImages";
import { fullDateTime } from "../../lib/utils/dateTimeFormat";

export const getColumns = (tabName: "broadcasted" | "scheduled") => [
  {
    Header: "Broadcasted Date",
    accessor: "broadcastedDate",
    flex: 2,
    Cell: ({ row }: any) => {
      return fullDateTime(row.values.broadcastedDate) as any;
    },
  },
  {
    Header: "Title",
    accessor: "title",
    flex: 3,
    Cell: (cell: Cell) => {
      return <b>{cell.value}</b>;
    },
  },
  {
    Header: "Category",
    accessor: "notification_category",
    flex: 2,
    Cell: (cell: Cell) => {
      return <b>{cell.value}</b>;
    },
  },
  {
    Header: "Broadcasted by",
    accessor: "broadcastedBy",
    flex: 2,
    disableSortBy: true,
  },
  {
    Header: "Media",
    accessor: "media",
    flex: 1,
    Cell: (cell: Cell) => {
      return <MultiThumbnailImages imagesData={cell.row.values.media} />;
    },
    disableSortBy: true,
  },
  ...(tabName === "broadcasted"
    ? [
        {
          Header: "Delivered to",
          accessor: "deliveredTo",
          flex: 1,
          disableSortBy: true,
        },
        {
          Header: "Views",
          accessor: "views",
          flex: 1,
          disableSortBy: true,
        },
      ]
    : []),
];

export const TabsData = [
  {
    id: "broadcasted",
    head: "Broadcasted",
  },
  {
    id: "scheduled",
    head: "Scheduled",
  },
];

export const notificationsListBreadcrumbs = {
  notifications: "Notifications",
  broadcasted: "Broadcasted",
  listing: "Listing",
  scheduled: "Scheduled",
};

export const TabsContentMap: ITabsTableContent = {
  broadcasted: {
    status: "broadcasted",
    date: getDate(),
    columns: getColumns("broadcasted"),
  },
  scheduled: {
    status: "scheduled",
    date: getDate(),
    columns: getColumns("scheduled"),
  },
};
