import styled, { css } from "styled-components";

export const PageTitleContainer = styled.div<{ fixed?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  height: 6.6rem;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 0.8rem;
  z-index: 20;
  & + * {
    margin-top: 2rem !important;
  }
  ${({ fixed }) =>
    fixed &&
    css`
      position: sticky;
      top: 7rem; // Header height
      box-shadow: ${({ theme }) => theme.elevation.elevation2};
    `}
`;
