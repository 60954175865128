import { SVGProps } from "react";

const CropProtectionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0172 4.74248C15.0812 4.58411 13.2684 3.75691 11.8956 2.38411L11.5437 2.01445C11.4029 1.87363 11.2092 1.80328 11.0156 1.80328C10.822 1.80328 10.6284 1.87363 10.4876 2.01445L10.1356 2.38411C8.76284 3.75691 6.95004 4.58411 5.01404 4.74248C4.62684 4.77771 4.32764 5.09445 4.32764 5.48168V7.61134C4.32764 12.0817 6.75647 16.2353 10.646 18.4353C10.7516 18.5057 10.8924 18.5234 11.0157 18.5234C11.139 18.5234 11.2621 18.4881 11.3854 18.4353C15.275 16.2353 17.7038 12.0817 17.7038 7.61134V5.48168C17.7038 5.09448 17.4045 4.77774 17.0172 4.74248ZM14.6236 8.42088L10.7516 12.7857C10.6284 12.9265 10.4172 12.9265 10.2941 12.7857L7.79477 10.1633C7.67151 10.0401 7.67151 9.84661 7.79477 9.72334L8.41084 9.10728C8.5341 8.98402 8.71002 8.98402 8.83327 9.08972L10.2236 10.3042C10.3469 10.4097 10.5403 10.4097 10.6636 10.2866L13.4972 7.4002C13.6205 7.27694 13.8139 7.27694 13.9372 7.38264L14.5708 7.96344C14.7292 8.10414 14.7292 8.29774 14.6236 8.42088Z"
      fill={props.color || "#005777"}
    />
    <rect
      x="6.13135"
      y="5.40985"
      width="9.37705"
      height="6.4918"
      fill={props.color || "#005777"}
    />
    <path
      d="M9.48253 12.1026C9.4667 12.0921 9.45617 12.0762 9.44033 12.0604C9.18696 11.7648 8.89667 11.4904 8.37414 11.4904C7.59294 11.4904 7.21289 12.0921 7.21289 13.1002H14.8136C14.8136 12.0921 14.4388 11.4904 13.6523 11.4904C13.04 11.4904 12.7392 11.8493 12.565 12.0604L12.5386 12.0921V10.3027C12.5386 9.75903 12.7445 9.3368 13.399 9.00426C13.0612 9.34732 12.887 9.68517 12.8501 10.0757C14.043 10.0652 14.5391 9.21007 14.6025 8.22835C13.0876 8.31811 12.3117 8.85646 12.2272 9.85933C11.8367 9.32621 11.0924 9.09395 10.1634 9.09395C10.1898 10.0757 10.6491 10.9202 11.9052 10.9413C11.958 10.6669 11.8102 10.366 11.3669 9.90152C12.0636 10.1338 12.2219 10.4188 12.2219 11.068V12.1606C12.1744 12.1395 12.1322 12.1078 12.0953 12.0603C11.9105 11.8439 11.6044 11.4903 11.0027 11.4903C10.3904 11.4903 10.0843 11.8492 9.90482 12.0603C9.87315 12.0973 9.84149 12.129 9.80452 12.1448V8.72975C9.80452 7.93801 10.0156 7.58433 10.6596 7.29936C10.2162 7.76387 10.0684 8.06475 10.0156 8.33918C11.3774 8.31805 11.8367 7.47881 11.863 6.49179C10.4643 6.49179 9.48253 7.01962 9.48253 8.30751V8.8142C9.11833 8.3497 8.43218 8.0911 7.42401 8.033C7.48734 9.01472 7.98351 9.86986 9.17641 9.88569C9.13422 9.48982 8.9653 9.15198 8.62745 8.80891C9.28194 9.14146 9.48251 9.56368 9.48251 10.1073L9.48253 12.1026Z"
      fill="#EBF4F8"
    />
  </svg>
);

export default CropProtectionIcon;
