import { SVGProps } from "react";

const LinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.3272 3.12799C11.0302 2.42505 12.1699 2.42505 12.8728 3.12799C13.5758 3.83093 13.5758 4.97063 12.8728 5.67357L10.1728 8.37357C9.46988 9.07652 8.33018 9.07652 7.62724 8.37357C7.27577 8.0221 6.70592 8.0221 6.35445 8.37357C6.00298 8.72504 6.00298 9.29489 6.35445 9.64637C7.76034 11.0523 10.0397 11.0523 11.4456 9.64637L14.1456 6.94636C15.5515 5.54048 15.5515 3.26108 14.1456 1.8552C12.7397 0.449309 10.4603 0.449309 9.05445 1.8552L7.70445 3.2052C7.35298 3.55667 7.35298 4.12652 7.70445 4.47799C8.05592 4.82946 8.62577 4.82946 8.97724 4.47799L10.3272 3.12799Z"
      fill={props.color || "#F6821F"}
    />
    <path
      d="M5.82726 7.62799C6.5302 6.92504 7.6699 6.92504 8.37284 7.62799C8.72431 7.97946 9.29416 7.97946 9.64563 7.62799C9.99711 7.27652 9.99711 6.70667 9.64563 6.3552C8.23975 4.94931 5.96035 4.94931 4.55446 6.3552L1.85446 9.0552C0.448577 10.4611 0.448577 12.7405 1.85446 14.1464C3.26035 15.5523 5.53975 15.5523 6.94563 14.1464L8.29563 12.7964C8.6471 12.4449 8.6471 11.875 8.29563 11.5236C7.94416 11.1721 7.37431 11.1721 7.02284 11.5236L5.67284 12.8736C4.9699 13.5765 3.8302 13.5765 3.12726 12.8736C2.42431 12.1706 2.42431 11.0309 3.12726 10.328L5.82726 7.62799Z"
      fill={props.color || "#F6821F"}
    />
  </svg>
);

export default LinkIcon;
