export const notificationsDefaultSorting = [
  {
    id: "broadcastedDate",
    desc: true,
  }
];

export const notificationSortingMap: any = { notification_category: "name", broadcastedDate: "broadcast_datetime" };

export const datetime_mapping: { [index: string]: any } = {
  broadcasted: { _lte: new Date() },
  scheduled: { _gt: new Date() },
};
