import { categoryData } from "./data";
import {
  Actions,
  NotificationActions,
  NotificationState,
  Region,
} from "./type";

export const initialState: NotificationState = {
  categories: categoryData,
  selectedCategory: categoryData[0],
  notificationType: null,
  notificationTypeData: [],
  category: null,
  title: "",
  description: "",
  selectedFeaturedImage: null,
  featuredImages: [],
  regions: [],
  networks: [],
  teams: [],
  users: [],
  visibilityType: "",
  selectedRegions: [],
  selectedState: [],
  selectedDistrict: [],
  selectedTaluka: [],
  selectedVillage: [],
  selectedAllNetwork: { networks: [], teams: [], users: [] },
  selectedNetworks: [],
  selectedUsers: [],
  selectedTeams: [],
  content: "",
  dateTime: null,
  notificationBy: null,
  broadcastDateTime: null,
  broadcastBy: null,
  recordId: "",
};

const filterRegionSelectedValues = (
  state: NotificationState,
  value: Region[],
  key: keyof NotificationState
): NotificationState => {
  switch (key) {
    case "selectedState":
      const district = state.selectedDistrict.filter((t) =>
        value.map((v) => v.id).includes(t.parentregionid)
      );
      return {
        ...filterRegionSelectedValues(state, district, "selectedDistrict"),
        ...{ [key]: value },
        selectedDistrict: district,
      };
    case "selectedDistrict":
      const taluka = state.selectedTaluka.filter((t) =>
        value.map((v) => v.id).includes(t.parentregionid)
      );
      return {
        ...filterRegionSelectedValues(state, taluka, "selectedTaluka"),
        ...{ [key]: value },
        selectedTaluka: taluka,
      };
    case "selectedTaluka":
      const village = state.selectedVillage.filter((t) =>
        value.map((v) => v.id).includes(t.parentregionid)
      );
      return {
        ...filterRegionSelectedValues(state, village, "selectedVillage"),
        ...{ [key]: value },
        selectedVillage: village,
      };
    default:
      return state;
  }
};

export const getAllRegions = (state: NotificationState) => {
  const states = state.selectedState.filter(
    (selectedState) =>
      !state.selectedDistrict.some(
        (district) => district.parentregionid === selectedState.id
      )
  );
  const districts = state.selectedDistrict.filter(
    (selectedDistrict) =>
      !state.selectedTaluka.some(
        (taluka) => taluka.parentregionid === selectedDistrict.id
      )
  );
  const talukas = state.selectedTaluka.filter(
    (selectedTaluka) =>
      !state.selectedVillage.some(
        (villages) => villages.parentregionid === selectedTaluka.id
      )
  );
  return [...states, ...districts, ...talukas, ...state.selectedVillage];
};

export const getAllNetworks = (state: NotificationState) => {
  const networks = state.selectedNetworks.filter(
    (selectedNetwork) =>
      !state.selectedTeams.some((teamNetwork) =>
        teamNetwork?.networks
          ?.map((e: any) => e.network.codename)
          .includes(selectedNetwork.codename)
      )
  );
  const teams = state.selectedTeams.filter(
    (selectedTeam) =>
      !state.selectedUsers.some(
        (selectedUser) => selectedUser?.team?.codename === selectedTeam.codename
      )
  );
  return { networks, teams, users: state.selectedUsers };
};

export const notificationReducer = (
  state: NotificationState,
  action: Actions
): NotificationState => {
  switch (action.type) {
    case NotificationActions.SET_VALUE:
      const newState: NotificationState = filterRegionSelectedValues(
        state,
        action.payload.value,
        action.payload.key
      );
      if (action.payload.key === "selectedNetworks") {
        newState.selectedTeams = [];
        newState.selectedUsers = [];
      } else {
        let teams = newState.selectedTeams.concat(
          action.payload.key === "selectedTeams" ? action.payload.value : []
        );
        newState.selectedUsers = state.selectedUsers.filter((user) => {
          return teams.map((e) => e.codename).includes(user?.team?.codename);
        });
      }

      return {
        ...newState,
        [action.payload.key]: action.payload.value,
        ...(action.payload.key === "selectedCategory"
          ? {
              title: "",
              description: "",
              recordId: "",
              // notificationType: null,
            }
          : {}),
      };
    case NotificationActions.SET_VALUES:
      return {
        ...state,
        ...(action.payload[0]
          ? action.payload.reduce(
              (acc: object, cv: any) => ({ ...acc, [cv.key]: cv.value }),
              {}
            )
          : { [action.payload.key]: action.payload.value }),
      };
    case NotificationActions.SELECT_VALUE: {
      return {
        ...state,
        [action.payload.prop]: action.payload.data,
      };
    }
    case NotificationActions.SELECT_FEATURED_IMAGE: {
      let selectedFeaturedImage = action.payload.data;
      return {
        ...state,
        selectedFeaturedImage,
      };
    }
    default:
      return state;
  }
};
