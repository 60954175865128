import { forwardRef } from "react";
import { GetEntityIcon } from "../../organisms/TeamsMapSections/MapSidebar/GetEntityIcon";
import { Divider } from "../Divider";
import { Group } from "../Group";
import Typography from "../Typography";
import { AssetLabel, PopupCardCrop } from "./styles";
import { MapPopupData } from "./type";

export const MapPopup = forwardRef<
  HTMLDivElement,
  { data: MapPopupData | null }
>(({ data }, ref) => {
  if (data?.infoText?.length) {
    return (
      <AssetLabel
        verified={data?.verified}
        ref={ref}
        infoText={data?.infoText?.length}
      >
        {data.infoText.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </AssetLabel>
    );
  }

  return (
    <AssetLabel verified={data?.verified} ref={ref}>
      <Group spacing={8} align="center">
        <GetEntityIcon type={data?.type || ""} size={24} />
        <Typography variant="subtitle1">
          {data?.tag} {data?.name}
        </Typography>
      </Group>
      <Group spacing={32} align="center" justify="space-between">
        <Typography variant="subtitle1" className="popup-status">
          {data?.verified ? "Verified" : "Unverified"}
        </Typography>
        {data?.area?.value && data?.area?.unit ? (
          <Typography variant="body1">
            <span>Area: </span>
            <b>
              {data?.area.value.toFixed(2)} {data?.area.unit}
            </b>
          </Typography>
        ) : null}
      </Group>
      {data?.farmer?.name ? (
        <Group spacing={8} align="center">
          <GetEntityIcon type={"user"} size={24} />
          <Typography variant="body1">{data?.farmer?.name}</Typography>
        </Group>
      ) : null}
      {data?.farmer?.team ? (
        <Group spacing={8} align="center">
          <GetEntityIcon type={"team"} size={24} />
          <Typography variant="body1">{data?.farmer?.team}</Typography>
        </Group>
      ) : null}
      {data?.farm?.name ? (
        <Group spacing={8} align="center">
          <GetEntityIcon type={"farm"} size={24} />
          <Typography variant="body1">
            {data?.farm.tag} {data?.farm.name}
          </Typography>
        </Group>
      ) : null}
      {data?.field?.name ? (
        <Group spacing={8} align="center">
          <GetEntityIcon type={"field"} size={24} />
          <Typography variant="body1">
            {data?.field.tag} {data?.field.name}
          </Typography>
        </Group>
      ) : null}
      {data?.crops?.length ? (
        <>
          <Divider size={1} />
          <PopupCardCrop spacing={8} align="flex-start">
            <GetEntityIcon type={"crop"} size={20} />
            <div>
              <Typography variant="subtitle1">{data?.crops[0].name}</Typography>
              <Typography variant="body2">{data?.crops[0].variety}</Typography>
            </div>
          </PopupCardCrop>
          {data?.crops.length > 1 ? (
            <Typography variant="body1" className="text-center">
              +{data?.crops.length - 1} more crop
              {data.crops.length > 2 ? "s" : ""}
            </Typography>
          ) : null}
        </>
      ) : null}
    </AssetLabel>
  );
});
