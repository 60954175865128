import { SVGProps } from "react";

const DegreeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.8335 19.1017V20.168H20.1668V19.1017C20.1668 18.9298 20.0415 18.7578 19.8535 18.7578H2.14692C1.99023 18.7578 1.83354 18.9298 1.83354 19.1017H1.8335Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M2.12704 8.41288H19.8451C19.9706 8.41288 20.096 8.3158 20.1587 8.18657C20.19 8.0572 20.1273 7.89551 20.0332 7.83089L11.1585 1.88049C11.0644 1.81588 10.9076 1.81588 10.8136 1.88049L1.97025 7.83089C1.84479 7.89551 1.81346 8.0572 1.84479 8.18657C1.87612 8.31595 2.00158 8.41303 2.12704 8.41303L2.12704 8.41288Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M15.824 17.1633C15.6661 17.1633 15.5083 17.3245 15.5083 17.4857V18.1306H19.2651V17.4857C19.2651 17.3245 19.1388 17.1633 18.9494 17.1633H18.539V10.1665H18.9494C19.1388 10.1665 19.2651 10.0375 19.2651 9.84407V9.19922H15.5083V9.84407C15.5083 10.0375 15.6661 10.1665 15.824 10.1665H16.2344L16.2343 17.1633H15.824Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M3.05102 17.1633C2.89319 17.1633 2.73535 17.3245 2.73535 17.4857V18.1306H6.49204V17.4857C6.49204 17.3245 6.36574 17.1633 6.17637 17.1633H5.76595L5.76609 10.1665H6.17651C6.36588 10.1665 6.49218 10.0375 6.49218 9.84407V9.19922H2.7355V9.84407C2.7355 10.0375 2.89333 10.1665 3.05117 10.1665H3.46159V17.1633H3.05102Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M13.4043 10.918C13.6593 11.1753 13.5416 11.0722 13.4043 10.918V10.918Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M11.133 10.0053C11.0714 9.97218 10.9789 9.97218 10.9172 10.0053C8.82045 10.835 7.18594 11.4324 7.15505 11.4656C6.8467 11.5984 6.90831 11.9634 7.12424 12.063C7.30922 12.1627 10.8555 13.988 10.8864 14.0213C10.9172 14.0544 10.979 14.0544 11.0098 14.0544C11.0714 14.0544 11.1024 14.0544 11.1332 14.0213C14.9568 12.0299 14.8954 12.063 14.9568 12.0299C15.1418 11.8639 15.0493 11.5652 14.8642 11.4656C12.4589 10.5363 12.9216 10.7022 13.2916 10.8682C13.014 10.7355 12.3664 10.5031 11.133 10.0053L11.133 10.0053Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M10.7149 14.7002C10.6836 14.6677 9.33598 14.0161 8.17633 13.4297L8.14502 15.0587C8.14502 15.2216 8.2704 15.3844 8.4271 15.3844C9.24202 15.4822 10.0568 15.8731 10.8403 16.6224C10.9029 16.6875 10.9657 16.7202 11.0596 16.7202C11.1222 16.7202 11.2163 16.6876 11.2789 16.6551C12.0624 15.971 12.9087 15.5474 13.7549 15.4171C13.9116 15.3846 14.0057 15.2542 14.0057 15.0914V13.4624C13.1594 13.8859 11.4984 14.7329 11.4984 14.7329C11.2165 14.7978 10.997 14.8305 10.7149 14.7002L10.7149 14.7002Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default DegreeIcon;
