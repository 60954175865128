import { DocumentNode } from "graphql";
import {
  HiOutlineEye,
  HiOutlineHeart,
  HiOutlineThumbUp,
  HiPencil,
} from "react-icons/hi";
import { Cell } from "react-table";
import { theme } from "../../../style/theme";
import Typography from "../../atoms/Typography";
import { GET_COURSE, UPSERT_COURSE } from "./query";
import { CourseForm } from "./CourseForm";
import { Actions } from "../../molecules/Table/styles";
import { RestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";
import { Link } from "react-router-dom";

interface ISetupModalType {
  name: string;
  Form: any;
  state: any;
  create: DocumentNode;
  read: DocumentNode;
  update: DocumentNode;
  mapper: (data: any) => any;
  required: string[];
  validityCheck: (data: any, value: any) => any;
}

export interface ISetupModalData {
  course: ISetupModalType;
}

export const setupModalData: ISetupModalType = {
  name: "course",
  create: UPSERT_COURSE,
  read: GET_COURSE,
  update: UPSERT_COURSE,
  mapper: (data) => ({
    ...data.series,
    unitPrice: String(data.series.unitPrice),
    taxedUnitPrice: String(data.series.taxedUnitPrice),
    discountPrice: String(data.series.discountPrice),
    taxedDiscountPrice: String(data.series.taxedDiscountPrice),
    packageId: data.series.package?.id || "",
    featuredImageId: data.series.featuredImage?.id || "",
    categories: data.series.categories.map((item: any) => item.id),
    authors: data.series.authors.map((item: any) => item.seriesAuthorId),
    crops: data.series.crops.map((item: any) => item.crop_id),
  }),
  required: [
    "name",
    "discountPrice",
    "taxedDiscountPrice",
    "unitPrice",
    "taxedUnitPrice",
    "noOfLecturesUnlocked",
    "courseSummary",
    "packageId",
    "categories",
    "authors",
    "featuredImageId",
    "order",
  ],
  validityCheck: (data, value) => value?.includes(data.groupId),
  state: {
    name: "",
    discountPrice: "",
    unitPrice: "",
    noOfLecturesUnlocked: "",
    courseSummary: "",
    packageId: "",
    categories: [],
    authors: [],
    crops: [],
    featuredImageId: "",
    featuredImage: null,
    isPublic: false,
    areLecturesLocked: false,
    order: "",
  },
  Form: CourseForm,
};

export const coursesArticlesColumns: any[] = [
  {
    Header: "Title",
    accessor: "title",
    flex: 2,
    disableSortBy: true,
  },
  {
    Header: "Next Article",
    accessor: "next_title",
    flex: 2,
    disableSortBy: true,
  },
  {
    Header: "Assessment",
    accessor: "assessment_name",
    flex: 2,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return (
        <>
          {cell.value || (
            <Typography variant="body2" textColor={theme.colors.primary["500"]}>
              No Assessment
            </Typography>
          )}
        </>
      );
    },
  },
  {
    Header: (
      <>
        <HiOutlineEye size={18} />
        <span className="ml-2">Views</span>
      </>
    ),
    accessor: "views",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: (
      <>
        <HiOutlineThumbUp size={18} />
        <span className="ml-2">Likes</span>
      </>
    ),
    accessor: "likes",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: (
      <>
        <HiOutlineHeart size={18} />
        <span className="ml-2">Bookmarks</span>
      </>
    ),
    accessor: "favorites",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Actions",
    accessor: "article_id",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell<any>) => {
      return (
        <Actions>
          <RestrictedContent keyName="school_management" permission="edit">
            <Link to={"/advisory/articles/edit/" + cell.value} target="_blank">
              <HiPencil size={28} />
            </Link>
          </RestrictedContent>
        </Actions>
      );
    },
  },
];
