import { Tabs } from "@mantine/core";
import { StyledTabs } from "../../AdvisorySections/styles";
import FarmIcon from "../../../atoms/Icons/FarmIcon";
import FieldIcon from "../../../atoms/Icons/FieldIcon";
import PlotIcon from "../../../atoms/Icons/PlotIcon";
import { BulkAuditAsset } from "./BulkAuditAsset";
import { FarmIco } from "../../../atoms/Icons/NavIcons";
import { getLocalStorage } from "../../../../lib/utils/localStorage";
import { normalizeTeamManageFilter } from "../../../templates/TeamMapView/normalize";

export function BulkAudit({ edges }: { edges?: Record<string, number> }) {
  let filters = normalizeTeamManageFilter(getLocalStorage("teamFilter"));
  return edges ? (
    <Tabs defaultValue="farms" variant="pills">
      <StyledTabs grow>
        <Tabs.Tab value="farms" icon={<FarmIcon />}>
          Farms
        </Tabs.Tab>
        <Tabs.Tab value="fields" icon={<FieldIcon />}>
          Fields
        </Tabs.Tab>
        <Tabs.Tab value="plots" icon={<PlotIcon />}>
          Plots
        </Tabs.Tab>
        <Tabs.Tab value="plot_crops" icon={<FarmIco />}>
          Plot Crops
        </Tabs.Tab>
      </StyledTabs>
      <Tabs.Panel value="farms">
        <BulkAuditAsset type="farm" edges={edges} filters={filters} />
      </Tabs.Panel>
      <Tabs.Panel value="fields">
        <BulkAuditAsset type="field" edges={edges} filters={filters} />
      </Tabs.Panel>
      <Tabs.Panel value="plots">
        <BulkAuditAsset type="plot" edges={edges} filters={filters} />
      </Tabs.Panel>
      <Tabs.Panel value="plot_crops">
        <BulkAuditAsset type="plot_crop" edges={edges} filters={filters} />
      </Tabs.Panel>
    </Tabs>
  ) : null;
}
