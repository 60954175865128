import {
  createStyles,
  Chip,
  Input,
  ChipGroupProps,
  MantineSize,
} from "@mantine/core";
import { wrapperStyles } from "../../atoms/MantineInput";

const useStyles = createStyles((theme, _params, getRef) => ({
  iconWrapper: {
    ref: getRef("iconWrapper"),
  },
  label: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    color: theme.other.colors.primary.main,
    border: `1px solid ${theme.other.colors.primary.main} !important`,
    "&[data-checked]": {
      backgroundColor: `${theme.other.colors.primary.main} !important`,
      color: theme.other.colors.white,
      border: `1px solid ${theme.other.colors.primary.main} !important`,
      [`& .${getRef("iconWrapper")}`]: {
        display: "none",
      },
    },
  },
}));

interface IChipsGroup<T extends boolean = false> extends ChipGroupProps<T> {
  listData: { name: string; value: string }[];
  label?: string;
  size?: MantineSize;
  multiple?: T;
}

function ChipsGroup<T extends boolean>({
  listData,
  label,
  className,
  size,
  ...props
}: IChipsGroup<T>) {
  const { classes } = useStyles();

  return (
    <Input.Wrapper
      label={label}
      styles={wrapperStyles}
      className={className}
      size={size}
    >
      <Chip.Group {...props} className={className}>
        {listData.map((item, index) => (
          <Chip value={item.value} key={index} size={size} classNames={classes}>
            {item.name}
          </Chip>
        ))}
      </Chip.Group>
    </Input.Wrapper>
  );
}

export default ChipsGroup;
