import styled from "styled-components";

export const UploadBtn = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  min-width: 5rem;
  padding: 0 1rem;
  background: #ffffff;
  border: 1px solid #dbe2ea;
  box-shadow: ${(props) => props.theme.elevation.elevation2};
  border-radius: 6px;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled.background : theme.colors.white};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled.color : theme.colors.primary.main};

  & svg {
    width: 2.2rem;
    flex: 0 0 2.2rem;
    height: 2.2rem;
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.disabled.color : theme.colors.orange.main};

    &.cancel_upload {
      margin-right: 1rem;
    }
  }
  & > p {
    margin-right: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
