import Image from "../../atoms/Image";
import { MantineButton } from "../../atoms/MantineButton";
import Typography from "../../atoms/Typography";
import ApprovalImage from "../../../assets/approvalIllustration.png";
import { sidebarTypes } from "../../templates/TeamMapView/data";
import { capitalize } from "../../../lib/utils/stringFormat";

export interface ApprovalSectionProps {
  onContinue: () => void;
  type: "document" | "audit";
  entity: sidebarTypes | null;
}
export function ApprovalSection({ onContinue, type, entity }: ApprovalSectionProps) {
  return (
    <section className="w-full flex flex-col gap-20 items-center" data-cy="approval-section">
      <Image src={ApprovalImage} />
      <div className="flex flex-col gap-4  items-center">
        <Typography variant="h4">Approved successfully!</Typography>
        <Typography variant="body1">{type === "document" ? `${capitalize(entity || "")} documents has been approved` : `${capitalize(entity || "")} has been approved`}</Typography>
      </div>
      <div className="w-full flex items-center justify-center">
        <div>
          <MantineButton onClick={() => onContinue()}>CONTINUE</MantineButton>
        </div>
      </div>
    </section>
  );
}
