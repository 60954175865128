import { SVGProps } from "react";

const DraftsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.41331 16.3419V3.7627H3.66925C3.48493 3.7627 3.33412 3.9135 3.33412 4.09782L3.33398 18.0915C3.33398 18.2759 3.48478 18.4267 3.66911 18.4267H14.2408C14.4251 18.4267 14.5759 18.2759 14.5759 18.0915V17.3475H5.41916C4.86605 17.3475 4.41363 16.8949 4.41363 16.342L4.41331 16.3419Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M13.5137 3.95572V2.00162C13.5137 1.86751 13.5974 1.7435 13.7214 1.69328C13.8488 1.6397 13.9895 1.66986 14.0867 1.76707L16.5704 4.24735C16.6642 4.34456 16.6944 4.48863 16.6408 4.61265C16.5906 4.73665 16.4666 4.8204 16.3325 4.8204H14.3784C13.9025 4.8204 13.5137 4.43169 13.5137 3.95572H13.5137Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M14.3782 5.4913C13.5302 5.4913 12.8431 4.8042 12.8431 3.95622V1.66699H5.41911C5.23142 1.66699 5.08398 1.81779 5.08398 2.00212V16.341C5.08398 16.5253 5.23142 16.6761 5.41911 16.6761H16.3323C16.5167 16.6761 16.6675 16.5253 16.6675 16.341V5.4913H14.3782ZM7.47348 4.49253H11.7606C11.9449 4.49253 12.0957 4.64333 12.0957 4.82765C12.0957 5.01198 11.9449 5.16278 11.7606 5.16278H7.47348C7.28915 5.16278 7.13835 5.01198 7.13835 4.82765C7.13835 4.64333 7.28915 4.49253 7.47348 4.49253ZM14.2744 13.8508H7.47348C7.28915 13.8508 7.13835 13.7 7.13835 13.5156C7.13835 13.3313 7.28915 13.1805 7.47348 13.1805H14.2744C14.4588 13.1805 14.6096 13.3313 14.6096 13.5156C14.6096 13.7 14.4588 13.8508 14.2744 13.8508ZM14.2744 10.9548H7.47348C7.28915 10.9548 7.13835 10.804 7.13835 10.6197C7.13835 10.4353 7.28915 10.2845 7.47348 10.2845H14.2744C14.4588 10.2845 14.6096 10.4353 14.6096 10.6197C14.6096 10.804 14.4588 10.9548 14.2744 10.9548ZM14.2744 8.05885H7.47348C7.28915 8.05885 7.13835 7.90805 7.13835 7.72372C7.13835 7.53939 7.28915 7.38859 7.47348 7.38859H14.2744C14.4588 7.38859 14.6096 7.53939 14.6096 7.72372C14.6096 7.90805 14.4588 8.05885 14.2744 8.05885Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default DraftsIcon;
