import {
  SimpleGrid as MantineSimpleGrid,
  SimpleGridProps,
} from "@mantine/core";

interface ISimpleGridProps extends SimpleGridProps {
  justifyContent?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
}

export const SimpleGrid = ({
  justifyContent,
  alignItems,
  ...props
}: ISimpleGridProps) => {
  return (
    <MantineSimpleGrid {...props} style={{ justifyContent, alignItems }} />
  );
};
