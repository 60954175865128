import { DefaultTheme } from "styled-components";
import { fonts } from "./mantine/fonts";

export const theme: DefaultTheme = {
  colors: {
    primary: {
      main: "#005777",
      100: "rgba(0, 87, 119, 0.1)",
      200: "rgba(0, 87, 119, 0.2)",
      300: "rgba(0, 87, 119, 0.3)",
      400: "rgba(0, 87, 119, 0.4)",
      500: "rgba(0, 87, 119, 0.5)",
      600: "rgba(0, 87, 119, 0.6)",
      700: "rgba(0, 87, 119, 0.7)",
      800: "rgba(0, 87, 119, 0.8)",
      900: "rgba(0, 87, 119, 0.9)",
    },
    orange: {
      main: "#F6821F",
      100: "rgba(246, 130, 31, 0.05)",
      200: "rgba(246, 130, 31, 0.1)",
      300: "rgba(246, 130, 31, 0.15)",
      400: "rgba(246, 130, 31, 0.4)",
      500: "rgba(246, 130, 31, 0.6)",
      600: "rgba(246, 130, 31, 0.8)",
    },
    green: {
      main: "#008D97",
      100: "rgba(0, 141, 151, 0.1)",
      200: "rgba(0, 141, 151, 0.2)",
      300: "rgba(0, 141, 151, 0.3)",
      400: "rgba(0, 141, 151, 0.4)",
      500: "rgba(0, 141, 151, 0.5)",
      600: "rgba(0, 141, 151, 0.6)",
    },
    warning: {
      default: "#FEE81E",
      light: "#FFEC42",
      dark: "#EAD30A",
    },

    positive: {
      default: "#2AC769",
      light: "#40DD7F",
      dark: "#1AB759",
    },
    negative: {
      default: "#FF1515",
      dark: "#BA0000",
      light: "#FF4A4A",
    },
    blue: {
      default: "#3CB5FF",
      dark: "#0093ED",
      light: "#5AC1FF",
    },
    background: "#fafafa",
    border: "#DBE2EA",
    white: "#fff",
    disabled: {
      color: "#00577766",
      background: "#EBF4F8",
    },
    loader: {
      backgroundDefault: "#eee",
      backgroundLight: "#eee",
      backgroundDark: "#eee",
    },
  },
  loaderGradientOverlay: {
    default: "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)",
    light: "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)",
    dark: "linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)",
  },
  elevation: {
    elevation1: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    elevation2: "0px 4px 8px rgba(0, 0, 0, 0.05)",
    elevation3: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    elevation4: "0px -10px 20px rgba(0, 0, 0, 0.15)",
    elevation5: "10px 10px 20px rgba(0, 0, 0, 0.15)",
  },
  fonts: fonts,
};
