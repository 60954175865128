import { Card } from "../../atoms/Card";
import Image from "../../atoms/Image";
import { Space } from "../../atoms/Space";
import Typography from "../../atoms/Typography";

export function ProductCard({
  product,
  handleClick,
}: {
  product: any;
  handleClick?: (value: string) => void;
}) {
  return (
    <Card
      className="h-full cursor-pointer hover:shadow-lg transition-all"
      onClick={() => handleClick?.(product.id)}
    >
      <Image src={product.image} width={300} />
      <div className="p-4">
        <Typography variant="h6" className="!font-bold">
          {product.name}
        </Typography>
        <Space h={12} />
        <Typography variant="subtitle2">Manufacturer:</Typography>
        <Typography variant="body2">{product.manufacturer}</Typography>
        <Space h={8} />
        <Typography variant="subtitle2">Composition:</Typography>
        {product.composition.map((item: any, i: number) => (
          <Typography variant="body2" key={i}>
            {item.ingredient} - {item.content}
          </Typography>
        ))}
      </div>
    </Card>
  );
}
