import { gql } from "@apollo/client";

import { UserDetails } from "./usercontext";

export const GET_LOGGED_IN_USER = gql`
  query LoggedInUserDetails {
    me {
      username
      first_name
      last_name
      id
      email
      allowed_roles
      reference_id
      mutual_groups {
        id
        name
        codename
      }
      photo {
        id
        url
      }
      agent {
        id
        organisation {
          id
          name
        }
      }
    }
  }
`;

export interface responseData {
  me: UserDetails;
}
