import styled from "styled-components";
import { TextProps } from "./types";

export const Text = styled.p<TextProps>((props) => {
  const styles = {
    fontWeight: 400,
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    letterSpacing: "0.1px",
    color: props.textColor || props.theme.colors.primary.main,
  };
  switch (props.type) {
    case "h1":
      return {
        fontWeight: "bold",
        fontSize: "5.6rem",
        lineHeight: "6rem",
        letterSpacing: "0.4px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "h2":
      return {
        fontWeight: "bold",
        fontSize: "4rem",
        lineHeight: "4.6rem",
        letterSpacing: "0.2px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "h3":
      return {
        fontWeight: 400,
        fontSize: "2.8rem",
        lineHeight: "3.4rem",
        letterSpacing: "0.2px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "h4":
      return {
        fontWeight: "bold",
        fontSize: "2.6rem",
        lineHeight: "3.4rem",
        letterSpacing: "0.2px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "h5":
      return {
        fontWeight: "bold",
        fontSize: "2rem",
        lineHeight: "2.6rem",
        letterSpacing: "0.2px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "h6":
      return {
        fontWeight: 400,
        fontSize: "1.8rem",
        lineHeight: "2.4rem",
        letterSpacing: "0.1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "subtitle1":
      return {
        fontWeight: "bold",
        fontSize: "1.6rem",
        lineHeight: "2.4rem",
        letterSpacing: "0.1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "subtitle2":
      return {
        fontWeight: "bold",
        fontSize: "1.4rem",
        lineHeight: "2rem",
        letterSpacing: "0.1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "body1":
      return styles;

    case "body2":
      return {
        fontWeight: 400,
        fontSize: "1.4rem",
        lineHeight: "2rem",
        letterSpacing: "0.1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "small1":
      return {
        fontWeight: 400,
        fontSize: "1.3rem",
        lineHeight: "1.6rem",
        letterSpacing: "0.1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "small2":
      return {
        fontWeight: 400,
        fontSize: "1.2rem",
        lineHeight: "1.6rem",
        letterSpacing: "0.1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "buttonTxt":
      return {
        fontWeight: "bold",
        fontSize: "1.5rem",
        letterSpacing: "0.2px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    case "initials":
      return {
        fontWeight: "bold",
        fontSize: "1.3rem",
        lineHeight: "1.6rem",
        letterSpacing: "1px",
        color: props.textColor || props.theme.colors.primary.main,
      };

    default:
      return styles;
  }
});
