import { Actions, TeamActions, TeamState } from "./type";

const commonStateFields = {
  id: 0,
  verification_remark: "",
  verified: false,
  address: {
    id: 0,
    houseStreet: "", // local_area
    village: "",
    taluka: "",
    name: "",
    addressKind: "",
    pincode: "",
    state: "",
    district: "",
    location: {
      coordinates: [],
      type: "",
    },
    country: "",
    raw: "",
    updatedFields: [],
  },
};

export const initialState: TeamState = {
  farm: {
    detail: {
      tag: "",
      name: "",
      owner: "",
      ownerId: null,
      teamName: null,
      teamId: null,
      updatedFields: [],
    },
    ...commonStateFields,
  },
  team: {
    detail: {
      name: "",
      owner: "",
      ownerId: null,
      teamName: null,
      teamId: null,
      signedUrl: null,
      updatedFields: [],
    },
    ...commonStateFields,
  },
  user: {
    detail: {
      firstName: "",
      middleName: "",
      lastName: "",
      username: "",
      email: "",
      owner: "",
      ownerId: null,
      teamName: null,
      teamId: null,
      number: null,
      secondaryNumber: null,
      dateOfBirth: null,
      gender: null,
      education: null,
      updatedFields: [],
    },
    ...commonStateFields,
  },
  field: {
    detail: {
      tag: "",
      name: "",
      gatNumber: "",
      soilName: "",
      soilId: null,
      farmName: "",
      farmId: null,
      owner: "",
      areaCatered: {},
      area: null,
      areaUnit: "",
      areaUnitId: null,
      ownerId: null,
      teamName: null,
      teamId: null,
      updatedFields: [],
    },
    ...commonStateFields,
  },
  plot: {
    detail: {
      tag: "",
      name: "",
      fieldName: "",
      fieldId: null,
      owner: "",
      areaCatered: {},
      isArchived: null,
      cropName: [],
      variety: [],
      association: [],
      status: [],
      area: null,
      areaUnit: "",
      areaUnitId: null,
      ownerId: null,
      teamName: null,
      teamId: null,
      updatedFields: [],
    },
    ...commonStateFields,
  },
  crop: {
    detail: {
      tag: "",
      name: "",
      owner: "",
      areaCatered: {},
      isArchived: null,
      association: "",
      associationId: null,
      associationLinkId: null,
      networkId: null,
      agronomistId: null,
      agronomistLinkId: null,
      agronomist: "",
      area: null,
      areaUnit: "",
      areaUnitId: null,
      cropName: "",
      cropId: null,
      cropYear: "",
      harvestQuantity: null,
      ownerId: null,
      teamName: null,
      teamId: null,
      updatedFields: [],
      variety: "",
      varietyId: null,
      noOfPlant: null,
      growingCycle: null,
      estimateHarvest: null,
      p2p: null,
      p2pUnit: "",
      r2r: null,
      r2rUnit: "",
      isSeed: null,
      status: "",
      statusId: null,
      plotName: "",
      plotId: null,
    },
    ...commonStateFields,
  },
};

export const teamDetailReducer = (state: TeamState, action: Actions) => {
  switch (action.type) {
    case TeamActions.SET_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case TeamActions.SET_FARM_VALUE:
      return {
        ...state,
        farm: {
          ...state.farm,
          [action.payload.key]: action.payload.value,
        },
      };
    case TeamActions.SET_TEAM_VALUE:
      return {
        ...state,
        team: {
          ...state.team,
          [action.payload.key]: action.payload.value,
        },
      };

    case TeamActions.SET_USER_VALUE:
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.value,
        },
      };
    case TeamActions.SET_FIELD_VALUE:
      return {
        ...state,
        field: {
          ...state.field,
          [action.payload.key]: action.payload.value,
        },
      };
    case TeamActions.SET_PLOT_VALUE:
      return {
        ...state,
        plot: {
          ...state.plot,
          [action.payload.key]: action.payload.value,
        },
      };
    case TeamActions.SET_CROP_VALUE:
      return {
        ...state,
        crop: {
          ...state.crop,
          [action.payload.key]: action.payload.value,
        },
      };
    case TeamActions.SET_VALUES:
      return {
        ...state,
        ...(action.payload[0]
          ? action.payload.reduce(
              (acc: object, cv: any) => ({ ...acc, [cv.key]: cv.value }),
              {}
            )
          : { [action.payload.key]: action.payload.value }),
      };
    default:
      return state;
  }
};
