import { useQuery } from "@apollo/client";
import { IconType } from "react-icons";
import LegendDivider from "../../atoms/LegendDivider";
import Typography from "../../atoms/Typography";
import { LegendTitleContainer } from "../../molecules/LegendTitle/style";
import { UserEngagementCard } from "../UserEngagementCard";
import { GET_SCHOOL_USER_METRICS } from "./query";
import { ISchoolUserEngagement } from "./type";

export interface ISchoolUserEngagementMetricsProps {
  legendTitle: string;
  legendIcon: IconType;
}

export function SchoolUserEngagementMetrics({
  legendIcon,
  legendTitle,
}: ISchoolUserEngagementMetricsProps) {
  const { data, loading } = useQuery<ISchoolUserEngagement>(
    GET_SCHOOL_USER_METRICS
  );

  const LegendIcon = legendIcon;
  const LegendTitle = (
    <LegendTitleContainer>
      <LegendIcon />
      <Typography variant="h6">{legendTitle}</Typography>
    </LegendTitleContainer>
  );
  return (
    <div className="mt-14" data-cy="school-user-engagement-metrics-section">
      <LegendDivider title={LegendTitle} />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-11">
        <UserEngagementCard
          label="Views"
          count={data?.usersViews
            .filter((e) => e.name && e.count > 0)
            .reduce((acc, cv) => acc + cv.count, 0)}
          loading={loading}
          data={data?.usersViews
            .filter((e) => e.name && e.count > 0)
            .map((e) => ({
              item: { ...e },
              count: e.count,
            }))}
        />
        <UserEngagementCard
          label="Likes"
          count={data?.usersLikes
            .filter((e) => e.name && e.count > 0)
            .reduce((acc, cv) => acc + cv.count, 0)}
          loading={loading}
          data={data?.usersLikes
            .filter((e) => e.name && e.count > 0)
            .map((e) => ({
              item: { ...e },
              count: e.count,
            }))}
        />
        <UserEngagementCard
          label="Bookmarks"
          count={data?.usersFavorites
            .filter((e) => e.name && e.count > 0)
            .reduce((acc, cv) => acc + cv.count, 0)}
          loading={loading}
          data={data?.usersFavorites
            .filter((e) => e.name && e.count > 0)
            .map((e) => ({
              item: { ...e },
              count: e.count,
            }))}
        />
      </div>
    </div>
  );
}
