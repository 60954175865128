import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { toast } from "react-toastify";
import { MantineButton } from "../../atoms/MantineButton";
import { Modal } from "../../atoms/Modal";
import { setupModalData } from "./data";

export const SetupCreateModal = () => {
  const { refetch } = useOutletContext<{
    refetch: any;
  }>();
  const type = setupModalData;
  const navigate = useNavigate();
  const Form = type.Form;
  const [state, setState] = useState(type.state);
  const [Create] = useMutation(type.create);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast
      .promise(Create({ variables: state }), {
        pending: "Creating " + type.name,
        success: "Created " + type.name,
        error: "Couldn't create " + type.name,
      })
      .then(() => {
        refetch();
        navigate("/school/courses");
      });
  };

  const checkRequired = () => {
    return type.required.some((item) => {
      if (Array.isArray(state[item])) return !Boolean(state[item].length);
      else return !Boolean(state[item]);
    });
  };

  useEffect(() => {
    document.title = `Create ${type.name} - SetuFarm`;
    return () => {
      document.title = "School Courses - SetuFarm";
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      handleModal={(e) => navigate("/school/courses")}
      modalTitle={`Create course`}
      width={"min(100rem, 95vw)"}
      height="auto"
    >
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <Form
          state={state}
          handleChange={(value: any) =>
            setState((prev: any) => ({ ...prev, ...value }))
          }
        />
        <MantineButton
          className="mt-8 self-end"
          type="submit"
          disabled={checkRequired()}
        >
          Create
        </MantineButton>
      </form>
    </Modal>
  );
};
