import { useQuery } from "@apollo/client";
import { IconType } from "react-icons";
import LegendDivider from "../../atoms/LegendDivider";
import Typography from "../../atoms/Typography";
import { LegendTitleContainer } from "../../molecules/LegendTitle/style";
import { TrendMetricsCard } from "../TrendMetricsCard";
import { UserEngagementCard } from "../UserEngagementCard";
import { GET_PRODUCT_VIEW_BY_USER } from "./query";
import { ICareUserEngagement } from "./type";

export interface ICareUserEngagementMetricsProps {
  legendTitle: string;
  legendIcon: IconType;
}

export function CareUserEngagementMetrics({
  legendIcon,
  legendTitle,
}: ICareUserEngagementMetricsProps) {
  const { data, loading } = useQuery<ICareUserEngagement>(
    GET_PRODUCT_VIEW_BY_USER
  );

  const LegendIcon = legendIcon;
  const LegendTitle = (
    <LegendTitleContainer>
      <LegendIcon />
      <Typography variant="h6">{legendTitle}</Typography>
    </LegendTitleContainer>
  );
  return (
    <div className="mt-14" data-cy="care-user-engagement-metrics-section">
      <LegendDivider title={LegendTitle} />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-11">
        <UserEngagementCard
          label="Views"
          count={data?.productViewByUser
            .filter((e) => e.name && e.count > 0)
            .reduce((acc, cv) => acc + cv.count, 0)}
          loading={loading}
          data={data?.productViewByUser
            .filter((e) => e.name && e.count > 0)
            .map((e) => ({
              item: { ...e },
              count: e.count,
            }))}
        />
      </div>
    </div>
  );
}
