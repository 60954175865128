import CalendarIcon from "../../components/atoms/Icons/Calendar";
import DatePickerInput from "../../components/atoms/Input/DatePicker";
import { MultiSelect } from "../../components/atoms/MultiSelect";
import ChipsGroup from "../../components/molecules/ChipsGroup";
import { theme } from "../../style/theme";

export const filterData = [
  {
    name: "category",
    component: ChipsGroup,
    props: {
      label: "Category",
      listData: [],
      size: "md",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onChange: (value: any) => func("category", value),
    }),
  },

  {
    name: "fromDate",
    component: DatePickerInput,
    props: {
      label: "Broadcasted Date",
      placeholder: "From date",
      icon: <CalendarIcon color={theme.colors.orange.main} />,
      size: "md",
      clearable: false,
      className: "grid-col-span-1",
    },
    handler: (func: any) => ({
      onChange: (value: any) => func("fromDate", value),
    }),
  },
  {
    name: "toDate",
    component: DatePickerInput,
    props: {
      placeholder: "To date",
      icon: <CalendarIcon color={theme.colors.orange.main} />,
      size: "md",
      clearable: false,
      className: "grid-col-span-1",
    },
    handler: (func: any) => ({
      onChange: (value: any) => func("toDate", value),
    }),
  },
];

export const initialValues = {
  category: {
    valueprop: "value",
    value: "",
  },
  fromDate: {
    valueprop: "value",
    value: null,
    dependency: {
      dependent: ["toDate"],
      mapper: (data: any, value: any) =>
        new Date(value).getTime() <= new Date(data).getTime() ? data : null,
    },
  },
  toDate: {
    valueprop: "value",
    value: null,
    dependency: {
      dependent: ["fromDate"],
      mapper: (data: any, value: any) =>
        new Date(value).getTime() >= new Date(data).getTime() ? data : null,
    },
  },
};

export const initialData = {
  localStorageKey: "notificationFilters",
};
