import { getLocalStorage } from "../../../lib/utils/localStorage";
import { getGeocodeAddress } from "./utils";
import { MapPopupData } from "./type";
import { displayAssetLabel } from "./addToMap";

interface IMarker extends google.maps.MarkerOptions {
  data: MapPopupData;
  onClick?: (data: IMarker["data"]) => void;
  setAsset?: Function;
  overlay: google.maps.MapCanvasProjection;
  assetLabel: HTMLDivElement | null;
  setPopupData?: Function;
}

export const makeMarker = (options: IMarker) => {
  let marker = new google.maps.Marker();
  let map = options.map as google.maps.Map;
  marker.setOptions(options);
  if (options.onClick) {
    marker.addListener("click", () => {
      map?.setZoom(17);
      map?.setCenter(options.position as google.maps.LatLng);
      options.onClick && options.onClick(options.data);
    });
  }
  marker.setDraggable(options.draggable || false);
  marker.setAnimation(options.draggable ? google.maps.Animation.BOUNCE : null);

  marker.addListener("mouseover", (e: any) => {
    if (getLocalStorage("mapConfig")?.hidePopovers || !options.assetLabel)
      return;
    const point = options.overlay.fromLatLngToDivPixel(e.latLng);
    displayAssetLabel(map, point, options.assetLabel, options.data.type);
    options.setPopupData && options.setPopupData(options.data);
  });
  marker.addListener("mouseout", () => {
    if (options.assetLabel) options.assetLabel.style.display = "none";
  });

  if (options.draggable && options.setAsset) {
    marker.addListener("dragend", (e: any) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: e.latLng.toJSON() }).then((response) => {
        options.setAsset &&
          options.setAsset((prev: any) => ({
            ...prev,
            ...options.data,
            coords: [e.latLng.lng(), e.latLng.lat()],
            address: getGeocodeAddress(response),
          }));
      });
    });
  }
  return marker;
};
