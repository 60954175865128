import { Badge } from "@mantine/core";
import { HiChevronDown } from "react-icons/hi";
import { theme } from "../../../../style/theme";
import { Group } from "../../../atoms/Group";
import Typography from "../../../atoms/Typography";
import { sidebarTypes } from "../../../templates/TeamMapView/data";
import { GetEntityIcon } from "./GetEntityIcon";
import { SidebarIconText } from "./styles";

export function ListCardHeader({
  listType,
  name,
  variety,
  verified,
  tag,
  isExpanded,
  area,
}: {
  listType: sidebarTypes;
  name: string;
  variety?: string;
  verified: boolean;
  tag?: string;
  isExpanded: boolean;
  area?: string;
}) {
  return (
    <Group spacing={8} direction="column" className="w-full">
      <div className="flex justify-between items-center">
        <div>
          <SidebarIconText>
            <GetEntityIcon type={listType} size={24} />
            <Typography variant="body1">
              {listType !== "crop" && tag ? `${tag} ` : ""}
              {name}
            </Typography>
          </SidebarIconText>
          {variety ? (
            <SidebarIconText>
              <span style={{ width: "2.4rem" }} />
              <Typography variant="body2">{variety}</Typography>
            </SidebarIconText>
          ) : null}
        </div>
        <HiChevronDown
          className={`icon-thumbnails text-orange-main ${
            isExpanded ? "rotate-180" : ""
          } transform transition-all`}
        />
      </div>
      <div className="flex items-end justify-between">
        <Typography
          variant="body1"
          textColor={
            verified
              ? theme.colors.positive.default
              : theme.colors.negative.default
          }
        >
          <span className="font-bold">
            {verified ? "Verified" : "Unverified"}
          </span>
        </Typography>
        {area && (
          <section className="flex items-end flex-col">
            <Typography variant="small2">Area</Typography>
            <Typography variant="h5">{area}</Typography>
          </section>
        )}
      </div>
    </Group>
  );
}
