import { createContext, useState } from "react";
import {
  ADD_NEW_LOCATION,
  SET_FARM_LOCATION,
  SET_FIELD_LOCATION,
  SET_PLOT_LOCATION,
} from "../../components/templates/TeamMapView/query";
import { gqlFetchRequest } from "../utils/gqlFetchRequest";

export interface IAsset {
  asset?: {
    id: number;
    type: "farm" | "field" | "plot";
    coords: any;
    area?: {
      value: number;
      unitId: number;
    };
    address?: {
      pinCode: string;
      country: string;
      state: string;
      district: string;
      locality: string;
      subLocality: string;
      rawAddress: string;
    };
    containerId?: number;
    edit: boolean;
    focus: boolean;
    handlePlotExpand?: boolean;
    modalOpen?: boolean;
  };
  setAsset: Function;
  handleAssetCoords: Function;
}

export const AssetContext = createContext<IAsset>({
  setAsset() {},
  handleAssetCoords() {},
});

export function AssetProvider(props: any) {
  const [asset, setAsset] = useState<IAsset["asset"]>();

  async function handleAssetCoords(newArea: number | undefined = undefined) {
    if (!asset?.id || !asset?.type || !asset?.edit) return { error: true };
    const query = {
      farm: SET_FARM_LOCATION,
      field: SET_FIELD_LOCATION,
      plot: SET_PLOT_LOCATION,
    };
    const address = {
      ...asset.address,
      location: {
        type: asset?.type === "farm" ? "Point" : "Polygon",
        crs: {
          type: "name",
          properties: { name: "urn:ogc:def:crs:EPSG::4326" },
        },
        coordinates: asset.coords,
      },
    };

    try {
      if (asset.type === "farm") {
        const newAddress = await gqlFetchRequest(
          ADD_NEW_LOCATION.loc?.source.body,
          { address }
        );

        let addressId = newAddress?.data?.insert_address_one?.id;
        if (addressId) {
          const updated = await gqlFetchRequest(
            query[asset?.type].loc?.source.body,
            {
              farmId: Number(asset?.id),
              addressId,
            }
          );
          if (!updated.error) {
            setAsset((prev: any) => ({
              ...prev,
              edit: false,
              handlePlotExpand: undefined,
              coords: undefined,
              modalOpen: false,
              area: undefined,
            }));
          }
        }
      } else {
        const updated = await gqlFetchRequest(
          query[asset?.type].loc?.source.body,
          {
            [`${asset.type}Id`]: Number(asset?.id),
            areaCatered: address.location,
            area: newArea || asset.area?.value,
            areaUnitId: asset.area?.unitId,
          }
        );
        if (!updated.error) {
          setAsset((prev: any) => ({
            ...prev,
            edit: false,
            handlePlotExpand: undefined,
            coords: undefined,
            modalOpen: false,
            area: undefined,
          }));
        }
      }
    } catch (error) {
      return { error: error };
    }
  }

  return (
    <AssetContext.Provider value={{ asset, setAsset, handleAssetCoords }}>
      {props.children}
    </AssetContext.Provider>
  );
}
