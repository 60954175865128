import { SVGProps } from "react";
import { MultipleUsersIcon, FarmIco } from "../../atoms/Icons/NavIcons";
import fms from "../../../assets/dashboardIcons/fms.svg";
import quality from "../../../assets/dashboardIcons/quality.svg";
import registration from "../../../assets/dashboardIcons/registration.svg";
import SetuAdviceIcon from "../../atoms/Icons/SetuAdvice";
import BellIcon from "../../atoms/Icons/BellIcon";
import SchoolIcon from "../../atoms/Icons/SchoolIcon";
import CareIcon from "../../atoms/Icons/CareIcon";
import SetuTradeIcon from "../../atoms/Icons/SetuTrade";
import {
  PermissionKey,
  PermissionType,
} from "../../../lib/contexts/usercontext";

export interface MainMenu {
  title: string;
  keyName: PermissionKey;
  route: string;
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  subMenu?: SubMenu[];
  feature?: boolean;
}

export interface SubMenu {
  title: string;
  route: string;
  feature?: boolean;
  permission?: PermissionType;
  selectby: string[];
}

export const NavItems: MainMenu[] = [
  {
    keyName: "network_management",
    title: "Network",
    route: "/network/metrics",
    Icon: MultipleUsersIcon,
    subMenu: [
      {
        title: "Teams",
        route: "/network/teams",
        selectby: ["/network/teams"],
      },
      {
        title: "Users",
        route: "/network/users",
        selectby: ["/network/users"],
      },
    ],
  },
  {
    keyName: "farm_management",
    title: "Farm",
    route: "/farm",
    Icon: FarmIco,
    subMenu: [
      {
        title: "Map",
        route: "/farm/map",
        selectby: ["/farm/map"],
      },
      {
        title: "Plot Crops",
        route: "/farm/plot-crops-table",
        selectby: ["/farm/plot-crops-table"],
      },
      {
        title: "Observation",
        route: "/farm/observation",
        selectby: ["/farm/observation"],
      },
      {
        title: "Operation",
        route: "/farm/operation",
        selectby: ["/farm/operation"],
      },
    ],
  },
  {
    keyName: "advice_management",
    title: "Advisory",
    route: "/advisory",
    Icon: SetuAdviceIcon,
    subMenu: [
      {
        title: "Articles",
        route: "/advisory/articles",
        selectby: [
          "/advisory/articles/published",
          "/advisory/articles/archived",
          "/advisory/articles/scheduled",
          "/advisory/articles/promoted",
          "/advisory/articles/drafts",
        ],
      },
      {
        title: "Create",
        route: "/advisory/articles/create",
        selectby: ["/advisory/articles/create"],
        permission: "create",
      },
      {
        title: "Setup",
        route: "/advisory/setup",
        selectby: [
          "/advisory/setup/categories",
          "/advisory/setup/packages",
          "/advisory/setup/authors",
          "/advisory/setup/org-access",
        ],
      },
    ],
  },
  {
    keyName: "trade_management",
    title: "Trade",
    route: "/trade",
    Icon: SetuTradeIcon,
    subMenu: [
      {
        title: "Listing",
        route: "/trade/listing",
        selectby: ["/trade/listing"],
      },
      {
        title: "Setup",
        route: "/trade/setup",
        selectby: ["/trade/setup/daily-rates"],
      },
    ],
  },
  {
    keyName: "school_management",
    title: "School",
    route: "/school",
    Icon: SchoolIcon,
    subMenu: [
      {
        title: "Courses",
        route: "/school/courses",
        selectby: ["/school/courses"],
      },
    ],
  },
  {
    keyName: "care_management",
    title: "Care",
    route: "/care",
    Icon: CareIcon,
    subMenu: [
      {
        title: "Products",
        route: "/care/products",
        selectby: ["/care/products"],
      },
    ],
  },
  {
    keyName: "message_management",
    title: "Message Center",
    route: "/notifications",
    Icon: BellIcon,
    subMenu: [
      {
        title: "Listing",
        route: "/notifications/listing",
        selectby: [
          "/notifications/listing/broadcasted",
          "/notifications/listing/scheduled",
        ],
      },
      {
        title: "Create",
        route: "/notifications/create",
        selectby: ["/notifications/create"],
        permission: "create",
      },
    ],
  },
];

export const supplyChainModules = {
  dev: [
    // { title: "FMS", url: "https://sahyadrifarms.vesatogo.com", image: fms },
    // {
    //   title: "Quality",
    //   url: "https://sahyadrifarmsqc.vesatogo.in",
    //   image: quality,
    // },
    // {
    //   title: "Registration",
    //   url: "https://sahyadrifarms.vesatogo.in",
    //   image: registration,
    // },
  ],
  qa: [
    // { title: "FMS", url: "https://sahyadrifarms.vesatogo.com", image: fms },
    // {
    //   title: "Quality",
    //   url: "https://sahyadrifarmsqc.vesatogo.in",
    //   image: quality,
    // },
    // {
    //   title: "Registration",
    //   url: "https://sahyadrifarms.vesatogo.in",
    //   image: registration,
    // },
  ],
  prod: [
    // { title: "FMS", url: "https://sahyadrifarms.vesatogo.com", image: fms },
    // {
    //   title: "Quality",
    //   url: "https://sahyadrifarmsqc.vesatogo.in",
    //   image: quality,
    // },
    // {
    //   title: "Registration",
    //   url: "https://sahyadrifarms.vesatogo.in",
    //   image: registration,
    // },
  ],
};
