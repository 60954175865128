import { SVGProps } from "react";

const SetuAdviceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.2736 1.09106V3.67516H7.63672V10.7817H14.8883V1.09106H10.2736ZM13.3227 9.21629H9.20235C8.99233 9.21629 8.82203 9.0494 8.82203 8.84357C8.82203 8.63774 8.99233 8.47085 9.20235 8.47085H13.3227C13.5327 8.47085 13.703 8.63774 13.703 8.84357C13.703 9.0494 13.5327 9.21629 13.3227 9.21629ZM13.3227 7.27825H9.20235C8.99233 7.27825 8.82203 7.11136 8.82203 6.90553C8.82203 6.6997 8.99233 6.53281 9.20235 6.53281H13.3227C13.5327 6.53281 13.703 6.6997 13.703 6.90553C13.703 7.11144 13.5327 7.27825 13.3227 7.27825ZM13.3227 5.34012H9.20235C8.99233 5.34012 8.82203 5.17323 8.82203 4.9674C8.82203 4.76158 8.99233 4.59469 9.20235 4.59469H13.3227C13.5327 4.59469 13.703 4.76158 13.703 4.9674C13.703 5.17323 13.5327 5.34012 13.3227 5.34012Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M15.2684 2.58197V11.1545H9.5127V12.2726H16.7643V2.58197H15.2684Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M10.139 1.09097L7.63672 3.54323V3.80671H7.90566L10.408 1.35445V1.09088L10.139 1.09097Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M13.0406 16.4977C11.9828 18.0418 12.5303 20.6041 12.9367 21.6611C13.1242 20.872 14.2636 19.0034 15.9229 18.5078C18.3457 17.7841 18.8815 17.772 20.3889 16.5758C21.8963 15.3795 22.8026 12.0605 22.8988 11.7633C22.9951 11.4662 23.1078 10.6265 22.9191 9.6722C22.7681 8.9088 22.7016 8.1345 22.6873 7.84278C22.7754 7.13277 22.7069 6.71684 22.6617 6.59763C22.0317 6.39097 21.8147 7.34257 21.7849 7.84421L21.7024 10.3219C21.937 10.4302 22.3436 10.7984 21.8905 11.5735C21.5281 12.1935 20.9162 13.2211 20.6556 13.6575C19.9384 14.3928 19.6211 14.5235 19.5247 14.5858C19.4475 14.6357 19.356 14.6453 19.3199 14.6439C19.967 14.1517 20.3036 13.7612 20.4693 13.5181C20.635 13.275 20.8142 12.9737 21.0753 12.4958C21.291 12.1012 21.8945 11.2609 21.8358 10.8667C21.8234 10.7835 21.7593 10.6537 21.673 10.6254C21.1777 10.4629 20.8783 10.6934 20.2355 11.3436C19.5927 11.9938 19.329 12.4239 19.1226 12.6066C18.9162 12.7894 18.3691 13.4396 17.8182 13.6346C17.2673 13.8296 16.8907 13.8626 16.4329 14.0099C15.9751 14.1571 14.3629 14.5675 13.0406 16.4977Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M21.5643 9.14947L21.5813 10.3135C21.0254 10.3065 20.6874 10.5734 20.5879 10.7078L20.626 9.71835C20.643 8.18345 20.7752 7.60166 20.8392 7.50262C20.9205 7.28927 21.1444 6.87009 21.3888 6.9002C21.6945 6.93784 21.6042 7.26828 21.5591 8.05191C21.5231 8.67882 21.5475 9.04483 21.5643 9.14947Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M20.4093 9.70987L20.4272 10.8116C19.9569 11.1896 19.5575 11.6743 19.458 11.8087L19.511 10.4014C19.5279 8.86653 19.7083 8.21356 19.7722 8.11452C19.8536 7.90116 20.0775 7.48199 20.3219 7.5121C20.6275 7.54973 20.4653 7.81831 20.4202 8.60194C20.3842 9.22885 20.3926 9.60523 20.4093 9.70987Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M19.2724 11.6069L19.2736 12.0143C18.8466 12.5004 18.4513 12.9082 18.3518 13.0426L18.4419 12.4773C18.5689 11.517 18.4989 11.7602 18.478 11.1428C18.4521 10.3745 18.6417 9.78896 18.6842 9.72317C18.7656 9.50982 18.9534 9.05511 19.1979 9.08522C19.5035 9.12286 19.3298 9.35571 19.2847 10.1393C19.2487 10.7663 19.2557 11.5023 19.2724 11.6069Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M10.9594 16.4977C12.0172 18.0418 11.4697 20.6041 11.0633 21.6611C10.8758 20.872 9.7364 19.0034 8.07713 18.5078C5.6543 17.7841 5.1185 17.772 3.6111 16.5758C2.10371 15.3795 1.19741 12.0605 1.10117 11.7633C1.00492 11.4662 0.892204 10.6265 1.08093 9.6722C1.23191 8.9088 1.29836 8.1345 1.31272 7.84278C1.22461 7.13277 1.29307 6.71684 1.33832 6.59763C1.96827 6.39097 2.18529 7.34257 2.21505 7.84421L2.2976 10.3219C2.06304 10.4302 1.65642 10.7984 2.10947 11.5735C2.47191 12.1935 3.08377 13.2211 3.3444 13.6575C4.06158 14.3928 4.37889 14.5235 4.47534 14.5858C4.55249 14.6357 4.64402 14.6453 4.68013 14.6439C4.033 14.1517 3.69644 13.7612 3.53074 13.5181C3.36504 13.275 3.18584 12.9737 2.92469 12.4958C2.70904 12.1012 2.10548 11.2609 2.16424 10.8667C2.17664 10.7835 2.24066 10.6537 2.32697 10.6254C2.82229 10.4629 3.12175 10.6934 3.76451 11.3436C4.40728 11.9938 4.67101 12.4239 4.87739 12.6066C5.08378 12.7894 5.63089 13.4396 6.18178 13.6346C6.73267 13.8296 7.10932 13.8626 7.56713 14.0099C8.02495 14.1571 9.63712 14.5675 10.9594 16.4977Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M2.43572 9.14947L2.41865 10.3135C2.97462 10.3065 3.31259 10.5734 3.41207 10.7078L3.37397 9.71835C3.35699 8.18345 3.22481 7.60166 3.16085 7.50262C3.07949 7.28927 2.85564 6.87009 2.61115 6.9002C2.30555 6.93784 2.39584 7.26828 2.44088 8.05191C2.47692 8.67882 2.45246 9.04483 2.43572 9.14947Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M3.59068 9.70987L3.57281 10.8116C4.04313 11.1896 4.44254 11.6743 4.54202 11.8087L4.48904 10.4014C4.47206 8.86653 4.29172 8.21356 4.22776 8.11452C4.1464 7.90116 3.92255 7.48199 3.67806 7.5121C3.37246 7.54973 3.53472 7.81831 3.57977 8.60194C3.6158 9.22885 3.60741 9.60523 3.59068 9.70987Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M4.72757 11.6069L4.72638 12.0143C5.15343 12.5004 5.54867 12.9082 5.64816 13.0426L5.55807 12.4773C5.43106 11.517 5.50111 11.7602 5.52195 11.1428C5.54788 10.3745 5.35826 9.78896 5.31577 9.72317C5.23441 9.50982 5.0466 9.05511 4.80212 9.08522C4.49651 9.12286 4.67023 9.35571 4.71527 10.1393C4.75131 10.7663 4.74431 11.5023 4.72757 11.6069Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default SetuAdviceIcon;
