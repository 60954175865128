export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || "");
  } catch (error) {
    return null;
  }
};
