import { MantineThemeOverride } from "@mantine/core";
import { colors, primaryList } from "./colors";
import { fonts } from "./fonts";
import { gaps } from "./gaps";

export const mantineTheme: MantineThemeOverride = {
  fontFamily: "'Open Sans', sans-serif",
  cursorType: "pointer",
  colors: {
    primary: primaryList,
  },
  primaryColor: "primary",
  other: {
    colors,
    fonts,
    gaps,
  },
};
