import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation createFileRecord(
    $url: String!
    $mediaType: String
    $name: String
    $duration: Int
  ) {
    insert_fileupload_one(
      object: {
        url: $url
        media_type: $mediaType # image, file, video, archive
        name: $name
        duration: $duration
        is_encrypted: false
        access: "protected" # private, public, protected
      }
    ) {
      id
    }
  }
`;
