import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { GOOGLE_MAP_API_KEY } from "../../../lib/utils/constants";
import SkeletonLoader from "../Loader/SkeletonLoader";
import MapError from "./MapError";
import MapWrapper from "./MapWrapper";
import { MapProps } from "./type";

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return <SkeletonLoader />;
    case Status.FAILURE:
      return <MapError />;
    case Status.SUCCESS:
      return <MapWrapper />;
  }
};

function Map({ markers, ...wrapperProps }: MapProps) {
  return (
    <Wrapper
      apiKey={GOOGLE_MAP_API_KEY}
      render={render}
      libraries={["drawing"]}
    >
      <MapWrapper {...wrapperProps} />
    </Wrapper>
  );
}

export default Map;
