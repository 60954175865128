import { MantineTheme } from "@mantine/core";
import {
  RichTextEditor as MantineRichTextEditor,
  RichTextEditorProps,
} from "@mantine/rte";
import { Space } from "../Space";
import Typography from "../Typography";
import { RichTextEditorWrapper } from "./styles";

const styles = (theme: MantineTheme) => ({
  toolbarControl: {
    backgroundColor: theme.other.colors.white + "!important",
    ":checked": {
      color: "red !important",
    },
  },
});

interface IRichTextEditorProps extends RichTextEditorProps {
  minHeight?: string;
  label?: string;
}

export const RichTextEditor = ({
  label,
  minHeight,
  ...props
}: IRichTextEditorProps) => {
  return (
    <RichTextEditorWrapper minHeight={minHeight}>
      {label && (
        <>
          <Typography variant="h6">
            <b>{label}</b>
          </Typography>
          <Space h="xs" />
        </>
      )}
      <MantineRichTextEditor {...props} styles={styles} />
    </RichTextEditorWrapper>
  );
};
