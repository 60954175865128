import { useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AssetContext } from "../../../lib/contexts/AssetContext";
import { Divider } from "../../atoms/Divider";
import { Group } from "../../atoms/Group";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { ScrollArea } from "../../atoms/ScrollArea";
import { ListCard } from "../../organisms/TeamsMapSections/MapSidebar/ListCard";
import { SidebarDetail } from "../../organisms/TeamsMapSections/MapSidebar/SidebarDetail";
import { SidebarHead } from "../../organisms/TeamsMapSections/MapSidebar/SidebarHead";
import { SidebarListHead } from "../../organisms/TeamsMapSections/MapSidebar/SidebarListHead";
import { sidebarQueries, sidebarListingQueries, sidebarTypes } from "./data";
import { MapSidebarContainer } from "./styles";

export function MapSidebar() {
  const [params, setParams] = useSearchParams();
  const id = params.get("id");
  const expandedId = params.get("expandedId");
  const type = (params.get("type") || "team") as sidebarTypes;
  const listType = params.get("listType") as sidebarTypes;
  const [activeIndex, setActiveIndex] = useState<number>(expandedId ? -1 : 0);
  const { asset } = useContext(AssetContext);

  let listing = sidebarListingQueries[type];
  let query = listType ? listing.query : sidebarQueries[type];
  let listCondition = listType && listing.condition[listType](id);

  const { data, error, refetch } = useQuery(query, {
    variables: listCondition ? { where: listCondition } : { id },
    skip: !id,
    fetchPolicy: "network-only",
  });

  if (error) setParams({});

  useEffect(() => {
    if (!expandedId && data?.[type]?.[0]?.id) {
      params.append("expandedId", data?.[type]?.[0]?.id);
      setParams(params);
    }
  }, [expandedId, data]);

  useEffect(() => {
    refetch();
  }, [asset?.edit]);

  return (
    <MapSidebarContainer>
      {data?.[type] ? (
        <>
          {listType ? (
            <SidebarListHead data={data[type]} />
          ) : (
            <SidebarHead data={data[type]} />
          )}
          <Divider size={1} />
          <ScrollArea offsetScrollbars pr={8} className="flex-1">
            {listType ? (
              (data?.[type] as any[])?.map((item, i) => (
                <ListCard
                  key={item.id}
                  type={type}
                  item={item}
                  isActive={Number(expandedId) === item.id || activeIndex === i}
                  onSelect={() => setActiveIndex(activeIndex === i ? -1 : i)}
                />
              ))
            ) : (
              <Group direction="column" spacing={16} className="py-5">
                <SidebarDetail data={data} type={type} />
              </Group>
            )}
          </ScrollArea>
        </>
      ) : (
        <LogoLoader width={50} margin="50% auto" />
      )}
    </MapSidebarContainer>
  );
}
