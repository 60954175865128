import { useQuery } from "@apollo/client";
import { IconType } from "react-icons";
import LegendDivider from "../../atoms/LegendDivider";
import Typography from "../../atoms/Typography";
import { LegendTitleContainer } from "../../molecules/LegendTitle/style";
import { TrendMetricsCard } from "../TrendMetricsCard";
import { GET_SCHOOL_TREND_METRICS } from "./query";
import { ISchoolTrendingMetrics } from "./type";
interface IActivitiesSectionProps {
  legendTitle: string;
  legendIcon: IconType;
}
export const SchoolTrendMetrics = ({
  legendTitle,
  legendIcon,
}: IActivitiesSectionProps) => {
  const { data, loading } = useQuery<ISchoolTrendingMetrics>(
    GET_SCHOOL_TREND_METRICS
  );
  const LegendIcon = legendIcon;
  const LegendTitle = (
    <LegendTitleContainer>
      <LegendIcon />
      <Typography variant="h6">{legendTitle}</Typography>
    </LegendTitleContainer>
  );

  return (
    <div className="mt-14" data-cy="school-trend-metrics-section">
      <LegendDivider title={LegendTitle} />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-11">
        <TrendMetricsCard
          label="Courses"
          loading={loading}
          tabsNames={["views", "likes", "usersSubscribers"]}
          defaultValue="views"
          data={{
            usersSubscribers: data?.courseFavorites
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e, type: "video" },
                count: e.count,
              })),
            views: data?.courseViews
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e, type: "video" },
                count: e.count,
              })),
            likes: data?.courseLikes
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e, type: "video" },
                count: e.count,
              })),
          }}
        />
        <TrendMetricsCard
          label="Authors"
          loading={loading}
          tabsNames={["views", "likes", "usersSubscribers"]}
          defaultValue="views"
          data={{
            usersSubscribers: data?.authorFavorites
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
            views: data?.authorViews
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
            likes: data?.authorLikes
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
          }}
        />
        <TrendMetricsCard
          label="Categories"
          loading={loading}
          tabsNames={["views", "likes", "usersSubscribers"]}
          defaultValue="views"
          data={{
            usersSubscribers: data?.categoryFavorites
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
            views: data?.categoryViews
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
            likes: data?.categoryLikes
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
          }}
        />
      </div>
    </div>
  );
};
