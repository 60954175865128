import { SVGProps } from "react";

const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 5.49935V3.66602M11 5.49935C9.98744 5.49935 9.16663 6.32016 9.16663 7.33268C9.16663 8.3452 9.98744 9.16602 11 9.16602M11 5.49935C12.0125 5.49935 12.8333 6.32016 12.8333 7.33268C12.8333 8.3452 12.0125 9.16602 11 9.16602M5.49996 16.4993C6.51248 16.4993 7.33329 15.6785 7.33329 14.666C7.33329 13.6535 6.51248 12.8327 5.49996 12.8327M5.49996 16.4993C4.48744 16.4993 3.66663 15.6785 3.66663 14.666C3.66663 13.6535 4.48744 12.8327 5.49996 12.8327M5.49996 16.4993V18.3327M5.49996 12.8327V3.66602M11 9.16602V18.3327M16.5 16.4993C17.5125 16.4993 18.3333 15.6785 18.3333 14.666C18.3333 13.6535 17.5125 12.8327 16.5 12.8327M16.5 16.4993C15.4874 16.4993 14.6666 15.6785 14.6666 14.666C14.6666 13.6535 15.4874 12.8327 16.5 12.8327M16.5 16.4993V18.3327M16.5 12.8327V3.66602"
      stroke="#F6821F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterIcon;
