import { useLazyQuery } from "@apollo/client";
import { Dispatch, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "../../../../lib/contexts/usercontext";
import { getOrganizationPreferences } from "../../../../lib/utils/teamDetailApi";
import { theme } from "../../../../style/theme";
import { Grid } from "../../../atoms/Grid";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserIcon from "../../../atoms/Icons/UserIcon";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import { GET_USERS } from "../../../templates/TeamsDetailModal/query";
import {
  Actions,
  DetailTypes,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";

export interface FarmDetailFormProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
  type: DetailTypes;
}

export function FarmDetailForm({ state, dispatch }: FarmDetailFormProps) {
  const user = useUser();
  const [farmSuffix, setFarmSuffix] = useState("");
  const [getUsers, { data: usersData }] = useLazyQuery<{
    users: { id: number; name: string }[];
  }>(GET_USERS);

  useEffect(() => {
    if (state.farm.detail.teamId) {
      getUsers({ variables: { teamId: state.farm.detail.teamId } });
    }
  }, [state.farm.detail.teamId]);

  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: TeamActions.SET_FARM_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.farm.detail,
          ...(!state.farm.detail.updatedFields.includes(name)
            ? { updatedFields: [...state.farm.detail.updatedFields, name] }
            : {}),
          [name]: value,
        },
      },
    });
  };

  const fetchData = async (userId: string) => {
    try {
      const organizationPreferences = await getOrganizationPreferences({
        userId,
      });

      setFarmSuffix(organizationPreferences.data.farm_suffix);
    } catch (error) {
      toast.error("Failed to fetch preferences");
    }
  };

  useEffect(() => {
    const userId = user?.state?.me?.id;
    if (userId) {
      fetchData(userId);
    }
  }, [user]);

  return (
    <section className="flex  flex-col  gap-8" data-cy="detail-form">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.farm.detail.owner}</Typography>
        </div>
        <div className="flex justify-start items-center gap-4">
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.farm.detail.teamName}</Typography>
        </div>
      </div>
      <Grid className="mt-2">
        <Grid.Col span={4}>
          <Input
            label="Farm tag"
            data-cy="farm-tag"
            required
            prefix=""
            icon={
              <div className=" font-bold text-primary-main">{farmSuffix} -</div>
            }
            placeholder="Enter tag"
            type="text"
            maxLength={8}
            value={state.farm.detail.tag.replace(`${farmSuffix}-`, "")}
            name="description"
            size="lg"
            onChange={(e) => {
              dispatchValue("tag", `${farmSuffix}-${e.target.value}`);
            }}
            autoComplete="off"
            error={!state.farm.detail.tag ? "Tag is required" : ""}
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Input
            label="Farm name"
            data-cy="farm-name"
            required
            placeholder="Enter farm name"
            type="text"
            value={state.farm.detail.name}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("name", e.target.value);
            }}
            autoComplete="off"
            error={!state.farm.detail.name ? "Name is required" : ""}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Farm owner"
            data-cy="farm-owner"
            size="lg"
            listData={usersData?.users || []}
            placeholder="Select owner"
            values={{ id: state.farm.detail.ownerId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("ownerId", item?.id);
            }}
            clearable
            required
            error={!state.farm.detail.ownerId ? "Owner is required" : ""}
          />
        </Grid.Col>
      </Grid>
    </section>
  );
}
