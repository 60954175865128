import { useMutation } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router";
import { toast } from "react-toastify";
import { MantineButton } from "../../atoms/MantineButton";
import { Modal } from "../../atoms/Modal";
import { tradeSetupModalData, ITradeSetupModalData } from "./data";

export const TradeSetupCreateModal = () => {
  const { setupType, refetch, tableData } = useOutletContext<{
    setupType: keyof ITradeSetupModalData;
    refetch: any;
    tableData: any;
  }>();
  const type = tradeSetupModalData[setupType];
  const navigate = useNavigate();
  const Form = type.Form;
  const [state, setState] = useState(type.state);
  const [Create] = useMutation(type.create);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    let filterInvalidArrayItems = type.required.reduce((prev, item) => {
      return {
        ...prev,
        ...(Array.isArray(state[item]) && {
          [item]: state[item].filter(Boolean),
        }),
      };
    }, {});

    toast
      .promise(
        Create({
          variables: { ...state, ...filterInvalidArrayItems },
          context: { clientName: "setuTrade" },
        }),
        {
          pending: "Creating " + type.name,
          success: "Created " + type.name,
          error: "Couldn't create " + type.name,
        }
      )
      .then(() => {
        refetch();
        navigate("/trade/setup/" + setupType);
      });
  };

  const checkRequired = () => {
    return type.required.some((item) => {
      if (Array.isArray(state[item]))
        return !Boolean(state[item].filter(Boolean).length);
      else return !Boolean(state[item]);
    });
  };

  useEffect(() => {
    document.title = `Create ${type.name} - SetuFarm`;
    return () => {
      document.title = "Rate Setup: Trade - SetuFarm";
    };
  }, []);

  return (
    <Modal
      isOpen={true}
      handleModal={(e) => navigate("/trade/setup/" + setupType)}
      modalTitle={`Create ${type.name}`}
      width={"min(60rem, 95vw)"}
      height="auto"
    >
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <Form
          state={state}
          handleChange={(value: any) =>
            setState((prev: any) => ({ ...prev, ...value }))
          }
          tableData={tableData}
        />
        <MantineButton
          className="mt-8 self-end"
          type="submit"
          disabled={checkRequired()}
        >
          Create
        </MantineButton>
      </form>
    </Modal>
  );
};
