import { useQuery } from "@apollo/client";
import { Space } from "../../../atoms/Space";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router";
import { config } from "../../../../config";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../../lib/utils/localStorage";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import { Pagination } from "../../../atoms/Pagination";
import Table from "../../../molecules/Table";
import { TableFooter } from "../../../molecules/Table/styles";
import { ISetupData, setupData, setupOrder, setupSortingMap } from "./data";
import { sortingMapper } from "../../../../lib/utils/table";
import { SortingRule } from "react-table";
import { gqlQueryDate } from "../../../../lib/utils/dateTimeFormat";

const TradeSetupTable = () => {
  let params: any = useParams();
  let setupType: keyof ISetupData =
    params.type in setupData ? params.type : "daily-rates";
  const { filterData, search, currentPage, setCurrentPage, setResultCount } =
    useOutletContext<any>();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("tradeSetupConfig")?.[setupType]?.itemsPerPage ||
      config.itemsPerPage
  );
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("tradeSetupConfig")?.[setupType]?.sortBy ||
      setupOrder[setupType]
  );

  const { loading, data, error, refetch } = useQuery(
    setupData[setupType].query,
    {
      variables: {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        search,
        startDate: gqlQueryDate(filterData.fromDate),
        endDate: gqlQueryDate(filterData.toDate),
      },
      context: { clientName: "setuTrade" },
    }
  );

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("tradeSetupConfig");
    setLocalStorage("tradeSetupConfig", {
      ...localConfig,
      [setupType]: {
        ...localConfig?.[setupType],
        itemsPerPage: Number(item),
      },
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("tradeSetupConfig");
    setLocalStorage("tradeSetupConfig", {
      ...localConfig,
      [setupType]: { ...localConfig?.[setupType], sortBy: sorting },
    });
    setSortby(sorting);
  };

  useEffect(() => {
    setResultCount(data?.baseRates?.total_count);
  }, [data]);

  return (
    <div className="flex flex-col relative">
      <Table
        tableColumns={setupData[setupType].columns}
        tableData={!error && data ? setupData[setupType].mapper(data) : []}
        pageSize={itemsPerPage}
        loading={loading}
        getRowProps={(row: any) => ({
          onClick: (e: MouseEvent) =>
            setupData[setupType].rowClick && navigate(`${row.original.id}`),
        })}
        fixedHead
        sortByData={sortBy}
        handleSortBy={handleSorting}
      />
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w={20} />
        <Select
          listData={config.itemsPerPageList.map((item) => String(item))}
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && !error && (
            <Pagination
              page={currentPage}
              onChange={setCurrentPage}
              total={Math.ceil(
                (data?.baseRates?.total_count || itemsPerPage) / itemsPerPage
              )}
              boundaries={0}
              siblings={config.paginationWindow / 2 - 1}
              withEdges
              position="right"
            />
          )}
        </div>
      </TableFooter>
      <Outlet context={{ setupType, refetch, tableData: data }} />
    </div>
  );
};
export default TradeSetupTable;
