import React, { FC, useState } from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { getRangedArray } from "../../../lib/utils/getRangedArray";
import Typography from "../../atoms/Typography";
import { PaginationContainer, PaginationNumbers } from "./styles";
import { IPaginationProps } from "./types";

export const Pagination: FC<IPaginationProps> = ({
  paginationWindow,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  const [windowStart, setWindowStart] = useState(1);
  const window = getRangedArray(
    windowStart,
    windowStart + paginationWindow,
    totalPages
  );
  const canLeft = windowStart > 1;
  const canRight = window[window.length - 1] < totalPages;

  const handleLeft = () => {
    setWindowStart(windowStart - paginationWindow);
  };

  const handleRight = () => {
    setWindowStart(windowStart + paginationWindow);
  };

  const handlePage = (page: number) => {
    setCurrentPage(page);
    setWindowStart(windowStart);
  };

  return (
    <PaginationContainer data-cy="pagination">
      <button onClick={handleLeft} disabled={!canLeft}>
        <HiChevronLeft />
      </button>
      {canLeft && (
        <Typography variant="small1" className="disabled">
          ...
        </Typography>
      )}
      <PaginationNumbers>
        {window.map((item, i) => (
          <div
            key={i}
            onClick={() => handlePage(item)}
            className={currentPage === item ? "current" : ""}
          >
            {item}
          </div>
        ))}
      </PaginationNumbers>
      {canRight && (
        <Typography variant="small1" className="disabled">
          ...
        </Typography>
      )}
      <button onClick={handleRight} disabled={!canRight}>
        <HiChevronRight />
      </button>
    </PaginationContainer>
  );
};
