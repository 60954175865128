import { useState } from "react";
import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ width?: number; hidePlaceholder: boolean }>`
  ${({ width }) => `width:${width}px; flex: 0 0 ${width}px`};
  ${({ hidePlaceholder }) =>
    hidePlaceholder &&
    css`
      & input::placeholder {
        color: transparent;
      }
    `};
  margin-right: 1.5rem;
  transition: 0.3s ease all;
`;

export const ExpandingInput: React.FC = ({ children }) => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const initialWidth = 150;
  const expandedWidth = 300;
  return (
    <Wrapper
      width={isSearchFocused ? expandedWidth : initialWidth}
      onFocus={() => setIsSearchFocused(true)}
      onBlur={() => setIsSearchFocused(false)}
      hidePlaceholder={!isSearchFocused}
    >
      {children}
    </Wrapper>
  );
};
