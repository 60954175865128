import { Group } from "../../atoms/Group";
import { FaBookmark, FaThumbsUp } from "react-icons/fa";
import { HiEye } from "react-icons/hi";
import { Authors } from "../../../lib/interfaces/Article";
import { fullDate } from "../../../lib/utils/dateTimeFormat";
import { Chip } from "../../atoms/Chip";
import Typography from "../../atoms/Typography";
import { BulletList, TopListRow } from "./styles";

interface IArticlePreviewProps {
  publishDate: string;
  authors: Authors[];
  metrics: any;
  categories: any[];
  subscription: any;
}

export const TopListSection = ({
  publishDate,
  authors,
  metrics,
  categories,
  subscription,
}: IArticlePreviewProps) => {
  return (
    <Group mt={32} direction="column" spacing={16} align="center">
      <TopListRow data-cy="publish-section">
        <Typography variant="body2">Published on :</Typography>
        <Typography variant="body2">
          <b>{publishDate && fullDate(publishDate)}</b>
        </Typography>
        <Group spacing={32} data-cy="metrics-count">
          <Group spacing={8}>
            <HiEye />
            <b>{metrics?.views?.count || 0}</b>
          </Group>
          <Group spacing={8}>
            <FaThumbsUp />
            <b>{metrics?.likes?.count || 0}</b>
          </Group>
          <Group spacing={8}>
            <FaBookmark />
            <b>{metrics?.favorites?.count || 0}</b>
          </Group>
        </Group>
      </TopListRow>
      <TopListRow data-cy="authors-row">
        <Typography variant="body2">Author(s) :</Typography>
        <BulletList>
          {authors.map((item: Authors) => (
            <div key={item.reference_id}>
              <Typography variant="body2" key={item.reference_id}>
                <b>{item.full_name}</b>
              </Typography>
            </div>
          ))}
        </BulletList>
      </TopListRow>
      <TopListRow align="center" data-cy="category-row">
        <Typography variant="body2">
          {categories?.length ? "Category" : "Subscription Package"} :
        </Typography>
        <Group spacing={96}>
          <Group spacing={16}>
            {categories?.map((category, i) => (
              <Chip key={i} fill chip={category} size="small" />
            ))}
          </Group>
          {subscription ? (
            <Group>
              {categories?.length && (
                <Typography variant="body2">Subscription Package :</Typography>
              )}
              <Chip fill chip={subscription} size="small" />
            </Group>
          ) : null}
        </Group>
      </TopListRow>
    </Group>
  );
};
