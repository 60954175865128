import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { useEffect } from "react";
import {
  Actions,
  NotificationActions,
  NotificationState,
} from "../../../../pages/CreateNotification/type";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import { MultiSelect } from "../../../atoms/MultiSelect";
import { NotificationType } from "./NotificationType";

export interface Props {
  state: NotificationState;
  dispatch: React.Dispatch<Actions>;
}
export function TitleDescriptionSection({ state, dispatch }: Props) {
  const handleChangeValue = (key: string, value: string | null) => {
    dispatch({
      type: NotificationActions.SET_VALUE,
      payload: {
        key,
        value,
      },
    });
  };

  return (
    <section data-cy="notification-title-description-section">
      {state.selectedCategory.type && state.selectedCategory.query && (
        <NotificationType state={state} dispatch={dispatch} query={state.selectedCategory.query} />
      )}
      <Input
        data-test-id="notification-title"
        label="Title*"
        type="text"
        placeholder="Enter title"
        value={state.title || ""}
        name="title"
        size="lg"
        onChange={(e) => handleChangeValue("title", e.target.value)}
        autoComplete="off"
        className="w-1/2 mb-8"
      />
      <Input
        data-test-id="notification-description"
        label="Description*"
        type="text"
        placeholder="Enter short description"
        value={state.description || ""}
        name="description"
        size="lg"
        onChange={(e) => handleChangeValue("description", e.target.value)}
        autoComplete="off"
        className="w-1/2"
      />
    </section>
  );
}
