export const config = {
  trendMetricsLimit: 10,
  itemsPerPageList: [25, 50, 75, 100],
  itemsPerPage: 25,
  paginationWindow: 10,
  columnWidth: 12,
  dateFormats: {
    fullDateTime: "Do MMM, YYYY | hh:mm A",
    fullDate: "Do MMM, YYYY",
    mantineDate: "MMMM D, YYYY",
    time12Hours: "hh:mm a",
    gqlQueryDate: "YYYY-MM-DD",
  },
  debounceWait: 800,
  env: process.env.REACT_APP_ENV || "dev",
  zoomLimit: {
    field: 16,
    plot: 17,
    crop: 18,
  },
  teamsDropdownSearchDataLimit: 5,
  SIUnitCode: {
    acre: 1,
  },
};
