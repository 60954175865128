type font = {
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  letterSpacing?: string;
};

type fontNames =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "small1"
  | "small2"
  | "small3"
  | "button"
  | "initials";

export type IFonts = {
  [key in fontNames]?: font;
};

export const fonts: IFonts = {
  h1: {
    fontSize: "56px",
    lineHeight: "60px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  h2: {
    fontSize: "40px",
    lineHeight: "46px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  h3: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: "regular",
    letterSpacing: "0.1px",
  },
  h4: {
    fontSize: "26px",
    lineHeight: "34px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  h5: {
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  h6: {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "regular",
    letterSpacing: "0.1px",
  },
  subtitle1: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  subtitle2: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  body1: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "regular",
    letterSpacing: "0.1px",
  },
  body2: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "regular",
    letterSpacing: "0.1px",
  },
  small1: {
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: "regular",
    letterSpacing: "0.1px",
  },
  small2: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  small3: {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  button: {
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
  initials: {
    fontSize: "13px",
    lineHeight: "16px",
    fontWeight: "bold",
    letterSpacing: "0.1px",
  },
};
