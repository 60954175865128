import styled from "styled-components";

export const NotificationCreateInput = styled.div<{ flex?: boolean }>`
  ${({ flex }) => flex && `display:flex;flex:1;`}
  margin: 1.25rem 0;
  line-height: 1.1;
  & input {
    margin-top: 0.8rem;
  }
`;

export const TimePickerInputContainer = styled.div<{ flex?: boolean }>`
  ${({ flex }) => flex && `display:flex;flex:1;`}
  margin: 1.25rem 0;
  line-height: 1.1;
  & .mantine-InputWrapper-root {
    margin-top: 0.8rem;
  }
`;
