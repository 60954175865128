import { gql } from "@apollo/client";

export const GET_NOTIFICATION_TREND_METRICS = gql`
  query NotificationTrendingQueries {
    notificationMetrics: notification_metrics {
      name: title
      count
      category: category__name
    }
    notificationMetricsSeen: notification_metrics_seen {
      name: title
      count
      category: category__name
    }
    notificationRegionMetrics: notification_metrics_by_region {
      name
      count
    }
    notificationRegionMetricsSeen: notification_metrics_by_region_seen {
      name
      count
    }
  }
`;
