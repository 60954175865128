import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useUser } from "../../../lib/contexts/usercontext";
import { ALLOW_QC_CHECK_DOMAIN } from "../../../lib/utils/constants";
import { Group } from "../../atoms/Group";
import AreaIcon from "../../atoms/Icons/AreaIcon";
import ArticlesIcon from "../../atoms/Icons/ArticlesIcon";
import FarmIcon from "../../atoms/Icons/FarmIcon";
import FieldIcon from "../../atoms/Icons/FieldIcon";
import LocationIcon from "../../atoms/Icons/LocationIcon";
import { FarmIco } from "../../atoms/Icons/NavIcons";
import PlotIcon from "../../atoms/Icons/PlotIcon";
import UniqueCropIcon from "../../atoms/Icons/UniqueCropIcon";
import { Space } from "../../atoms/Space";
import ActivityCard from "../../molecules/Cards/ActivityCard";
import ActivitiesSection from "../../organisms/GenericSections/ActivitiesSection";
import { FARM_ASSETS_METRICS } from "./assetsQuery";

function FarmAssetsTemplate() {
  const { state: user } = useUser();
  const [getAssetsMetrics, { data, loading }] =
    useLazyQuery(FARM_ASSETS_METRICS);

  useEffect(() => {
    document.title = "Farm Assets - SetuFarm";
    getAssetsMetrics();
  }, []);
  return (
    <div>
      <Space h={16} />
      <ActivitiesSection
        legendTitle="Farm and Field metrics"
        legendIcon={FarmIcon}
        isLoading={loading}
        alignCenter={true}
      >
        <div
          className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          data-cy="farm-metrics"
        >
          <ActivityCard
            icon={FarmIcon}
            displayName="Total farms"
            count={data?.totalFarms.aggregate.count}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedFarms.aggregate.count,
            }}
          />
          <ActivityCard
            icon={FieldIcon}
            displayName="Total fields"
            count={data?.totalFields.aggregate.count}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedFields.aggregate.count,
            }}
          />
          <ActivityCard
            icon={AreaIcon}
            displayName="Area (acre)"
            count={data?.totalFieldArea.aggregate.sum.area}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedFieldArea.aggregate.sum.area,
            }}
          />
        </div>
      </ActivitiesSection>
      <Space h={48} />
      <ActivitiesSection
        legendTitle="Plot/Crop metrics"
        legendIcon={FarmIco}
        isLoading={loading}
        alignCenter={true}
      >
        <div
          className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          data-cy="plot-crop-metrics"
        >
          <ActivityCard
            icon={FarmIco}
            displayName="Total crops"
            count={data?.totalCrops.aggregate.count}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedCrops.aggregate.count,
            }}
          />
          <ActivityCard
            icon={AreaIcon}
            displayName="Area (acre)"
            count={data?.totalCropArea.aggregate.sum.area}
            loading={loading}
            alignCenter={true}
            secondary={{
              count: data?.unverifiedCropArea.aggregate.sum.area,
            }}
          />
          <Group direction="column" justify="space-between">
            <ActivityCard
              icon={UniqueCropIcon}
              displayName="Unique Crops"
              count={data?.uniqueCrops.aggregate.count}
              loading={loading}
              alignCenter={true}
              variant="small"
            />
            <ActivityCard
              icon={FarmIco}
              displayName="Variety"
              count={data?.uniqueVarieties.aggregate.count}
              loading={loading}
              alignCenter={true}
              variant="small"
            />
          </Group>
        </div>
      </ActivitiesSection>
    </div>
  );
}

export default FarmAssetsTemplate;
