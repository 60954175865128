import { useEffect, useState } from "react";
import Table from "../../molecules/Table";
import { authorSortingMapper, restSetupData, setupOrder } from "./data";
import axios from "axios";
import { SetupTableContainer } from "./styles";
import { TableFooter } from "../../molecules/Table/styles";
import Typography from "../../atoms/Typography";
import { Space } from "../../atoms/Space";
import { Select } from "../../atoms/MantineSelect";
import { config } from "../../../config";
import { Pagination } from "../../atoms/Pagination";
import { Outlet, useOutletContext, useParams } from "react-router";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import { SortingRule } from "react-table";
import { sortingMapper } from "../../../lib/utils/table";

export function AuthorsTable({ path }: { path?: keyof typeof restSetupData }) {
  const [data, setData] = useState({ results: [], count: 0 });
  const [loading, setLoading] = useState(false);

  const setupType = path || "authors";
  const { search, currentPage, setCurrentPage } = useOutletContext<any>();
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("setupConfig")?.[setupType]?.itemsPerPage ||
      config.itemsPerPage
  );
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("setupConfig")?.[setupType]?.sortBy || setupOrder[setupType]
  );

  function fetchData() {
    setLoading(true);
    const url = new URL(
      `${process.env.REACT_APP_ONFARM_REST_API_URL}${restSetupData[setupType].urlPath}`
    );
    if (search) url.searchParams.set("search", search);
    const order = JSON.stringify([
      sortingMapper(sortBy, authorSortingMapper[setupType]),
    ]);
    if (sortBy.length) url.searchParams.set("order_by", order);
    url.searchParams.set("page", currentPage);
    url.searchParams.set("page_size", `${itemsPerPage}`);

    axios
      .get(url.toString())
      .then(({ data }) => setData(data?.data || {}))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData();
  }, [setupType, search, JSON.stringify(sortBy), currentPage, itemsPerPage]);

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("setupConfig");
    setLocalStorage("setupConfig", {
      ...localConfig,
      [setupType]: {
        ...localConfig?.[setupType],
        itemsPerPage: Number(item),
      },
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("setupConfig");
    setLocalStorage("setupConfig", {
      ...localConfig,
      [setupType]: { ...localConfig?.[setupType], sortBy: sorting },
    });
    setSortby(sorting);
  };

  return (
    <SetupTableContainer>
      <Table
        tableColumns={restSetupData[setupType].columns}
        tableData={data?.results || []}
        pageSize={itemsPerPage}
        loading={loading}
        fixedHead
        sortByData={sortBy}
        handleSortBy={handleSorting}
      />
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w={20} />
        <Select
          listData={config.itemsPerPageList.map((item) => String(item))}
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && data && (
            <Pagination
              page={currentPage}
              onChange={setCurrentPage}
              total={Math.ceil((data.count || itemsPerPage) / itemsPerPage)}
              boundaries={0}
              siblings={config.paginationWindow / 2 - 1}
              withEdges
              position="right"
            />
          )}
        </div>
      </TableFooter>
      <Outlet context={{ setupType, refetch: fetchData }} />
    </SetupTableContainer>
  );
}
