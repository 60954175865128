import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { toast } from "react-toastify";
import { useUser } from "../../../../lib/contexts/usercontext";
import { ALLOW_QC_CHECK_DOMAIN } from "../../../../lib/utils/constants";
import { capitalize } from "../../../../lib/utils/stringFormat";
import { Group } from "../../../atoms/Group";
import { Checkbox } from "../../../atoms/Input/MantineCheckbox";
import { MantineButton } from "../../../atoms/MantineButton";
import Table from "../../../molecules/Table";
import { fetchAssets } from "../../../templates/TeamMapView/utils";
import { UPDATE_BULK_QC } from "./queries";

interface IProps {
  type: "farm" | "field" | "plot" | "plot_crop";
  edges?: Record<string, number>;
  filters?: any;
}
export function BulkAuditAsset({ type, edges, filters }: IProps) {
  const [data, setData] = useState<any[]>([]);
  const [qcChanges, setQcChanges] = useState<Record<string, boolean>>({});
  const [activeChanges, setActiveChanges] = useState<Record<string, boolean>>(
    {}
  );
  const [UpdateBulkAudit] = useMutation(UPDATE_BULK_QC(type));
  const user = useUser();

  const columns: any[] = [
    {
      Header: "Team",
      accessor: "farmer",
      flex: 1,
      disableSortBy: true,
      Cell: (cell: Cell<any>) =>
        `${cell.value?.memberships?.[0]?.group?.name || ""}`,
    },
    {
      Header: "Tag",
      accessor: "tag",
      flex: 0.25,
      disableSortBy: true,
      Cell: (cell: Cell<any>) => cell.value || "",
    },
    ...Object.values({
      owner: {
        Header: "Owner",
        accessor: "farmer.name",
        flex: 1,
        disableSortBy: true,
        Cell: (cell: Cell<any>) => cell.value || "",
      },
      ...(type === "field" || type === "plot"
        ? {
            area: {
              Header: "Area",
              accessor: "area",
              flex: 0.5,
              disableSortBy: true,
              Cell: (cell: Cell<any>): string =>
                `${cell.value} ${cell.row.original?.areaUnit?.name}`,
            },
          }
        : {}),
      ...(type === "plot_crop"
        ? {
            crop: {
              Header: "Crop (Variety)",
              accessor: "crop",
              flex: 2,
              disableSortBy: true,
              Cell: (cell: Cell<any>): string =>
                `${cell.value.name} (${
                  cell.row.original?.variety?.name || ""
                })`,
            },
          }
        : {}),
    }),
    {
      Header: (
        <Checkbox
          label="Active"
          checked={checkAllCheck(activeChanges)}
          onChange={(e) => handleAllChange(e.target.checked, setActiveChanges)}
        />
      ),
      accessor: "active",
      flex: 0.25,
      disableSortBy: true,
      Cell: (cell: Cell<any>) => {
        let id = cell.row.original.id;
        let value = activeChanges[id];
        return (
          <Checkbox
            checked={value !== undefined ? value : cell.value}
            onChange={({ target }) => {
              handleChange(id, target.checked, setActiveChanges);
            }}
          />
        );
      },
    },
    ...Object.values({
      ...(user.state.me?.email.includes(ALLOW_QC_CHECK_DOMAIN)
        ? {
            fsQc: {
              Header: (
                <Checkbox
                  label="FS QC"
                  checked={checkAllCheck(qcChanges)}
                  onChange={(e) =>
                    handleAllChange(e.target.checked, setQcChanges)
                  }
                />
              ),
              accessor: "farmSetuQC",
              flex: 0.5,
              disableSortBy: true,
              Cell: (cell: Cell<any>) => {
                let id = cell.row.original.id;
                let value = qcChanges[id];
                return (
                  <Checkbox
                    checked={value !== undefined ? value : cell.value}
                    onChange={({ target }) => {
                      handleChange(id, target.checked, setQcChanges);
                    }}
                  />
                );
              },
            },
          }
        : {}),
    }),
  ];

  function checkAllCheck(dataValues: Record<string, boolean>) {
    let values = Object.values(dataValues);
    return values.length === data.length && values.every((item) => item);
  }

  function handleChange(id: number, value: boolean, setState: Function) {
    setState((prev: any) => {
      let tempList = Object.assign({}, prev);
      tempList[id] = value;
      return tempList;
    });
  }

  function handleAllChange(value: boolean, setState: Function) {
    setState((prev: any) => {
      let tempList = Object.assign({}, prev);
      data.forEach((item) => {
        tempList[item.id] = value;
      });
      return tempList;
    });
  }

  function updateValues() {
    let lists: Record<string, number[]> = {
      isActiveTrue: [],
      isActiveFalse: [],
      FSQCTrue: [],
      FSQCFalse: [],
    };
    Object.entries(activeChanges).forEach(([id, value]) => {
      if (value) lists["isActiveTrue"].push(Number(id));
      else lists["isActiveFalse"].push(Number(id));
    });
    Object.entries(qcChanges).forEach(([id, value]) => {
      if (value) lists["FSQCTrue"].push(Number(id));
      else lists["FSQCFalse"].push(Number(id));
    });
    toast.promise(UpdateBulkAudit({ variables: lists }), {
      pending: `Updating ${type}s`,
      success: `Updated ${type}s`,
      error: `Failed to update ${type}s`,
    });
  }

  useEffect(() => {
    fetchAssets({ edges, filters, type }).then(({ data }) => {
      setData(() => data?.[type] || []);
    });
    return () => {};
  }, [edges, filters]);

  return data ? (
    <Group direction="column" spacing={8}>
      <Table
        tableColumns={columns}
        tableData={data}
        loading={false}
        getRowProps={(row: any) => {}}
        pageSize={data.length}
        manualSorting={false}
      />
      <Group position="right" mt={16}>
        <MantineButton onClick={updateValues}>
          {"Save " + capitalize(type.replaceAll("_", " "), true) + "s"}
        </MantineButton>
      </Group>
    </Group>
  ) : null;
}
