import { gql } from "@apollo/client";

export function careListingCondition(
  filterData: any,
  search: string,
  storeId?: string
) {
  return {
    is_featured: { _eq: true },
    ...(storeId && { store_id: { _eq: storeId } }),
    sellable_item: {
      product: {
        _or: [
          {
            manufacturing_facility: {
              manufacturer: { name: { _ilike: `%${search}%` } },
            },
          },
          { name: { _ilike: `%${search}%` } },
        ],
        ...(filterData.crops?.length && {
          product_crop_associations: {
            crop: {
              reference_id: {
                _in: filterData.crops?.map((item: any) => item.value),
              },
            },
          },
        }),
        ...(filterData.brands?.length && {
          manufacturing_facility: {
            manufacturer_id: {
              _in: filterData.brands?.map((item: any) => item.value),
            },
          },
        }),
        ...(filterData.subCategory?.length && {
          subcategory_id: {
            _in: filterData.subCategory?.map((item: any) => item.value),
          },
        }),
        ...(filterData.category?.length && {
          subcategory: {
            category_id: {
              _in: filterData.category?.map((item: any) => item.value),
            },
          },
        }),
      },
    },
  };
}

export const CARE_LISTING = gql`
  query CareListing(
    $limit: Int
    $offset: Int
    $where: sellable_item_stock_bool_exp
    $order: [sellable_item_stock_order_by!]
  ) {
    sellable_item_stock(
      offset: $offset
      limit: $limit
      order_by: $order
      where: $where
    ) {
      id
      sellable_item {
        id
        product {
          id
          name
          manufacturing_facility {
            manufacturer {
              name
            }
          }
          product_compositions {
            ingredient {
              name
            }
            content
            content_unit {
              name
            }
          }
          featured_image {
            url
          }
        }
        packaging: product_sku {
          name
          short_name
        }
      }
      purchases: product_purchased_aggregate {
        aggregate {
          sum {
            item_quantity
          }
        }
      }
      views: product_views_aggregate {
        aggregate {
          count
        }
      }
      unit_rate: taxed_rate
      discounted_rate: discounted_taxed_rate
    }
    sellable_item_stock_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;
