interface IProps {
  components: {
    name: string;
    component: any;
    props: any;
    handler: (value: any) => object;
  }[];
  data: any;
  values: any;
  handler: any;
}

export const ComponentList = ({
  components,
  data,
  values,
  handler,
}: IProps) => {
  return (
    <>
      {components.map((item, i) => {
        const Component = item.component;
        return (
          <Component
            key={i}
            {...item.props}
            {...data[item.name]}
            {...values[item.name]}
            {...item.handler(handler)}
          />
        );
      })}
    </>
  );
};
