import { useState } from "react";
import { toast } from "react-toastify";
import { Group } from "../../atoms/Group";
import { Modal } from "../../atoms/Modal";
import { MultiSelect } from "../../atoms/MultiSelect";
import SwitchInput from "../../atoms/Switch";
import Typography from "../../atoms/Typography";
import UploadButton from "../../atoms/UploadFile";

export function BulkUserRegistration({
  open,
  setOpen,
  networks,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  networks: any[];
}) {
  const [selectedNetworks, setSelectedNetworks] = useState([]);
  const [setPassword, setSetPassword] = useState(false);
  return (
    <Modal
      isOpen={open}
      handleModal={() => setOpen(false)}
      modalTitle={`Bulk User Registration`}
      height="auto"
      margin="auto"
      width={"min(60rem, 95vw)"}
    >
      <Group mb={16} className="flex-wrap items-start" spacing={16}>
        <MultiSelect
          label={"Networks"}
          size={"md"}
          values={selectedNetworks}
          listData={networks}
          accessor={{ name: "name", value: "id" }}
          searchable={true}
          clearable={true}
          placeholder={"Select networks"}
          className="flex-1"
          onSelect={(values) => setSelectedNetworks(values)}
        />
        <Group direction="column" className="flex-2" spacing={6}>
          <Typography variant="body1" className="!font-bold">
            Set Password
          </Typography>
          <SwitchInput
            onLabel="Yes"
            offLabel="No"
            size="xl"
            checked={setPassword}
            className="flex-1"
            onChange={(item) => setSetPassword(item.currentTarget.checked)}
          />
        </Group>
      </Group>
      <UploadButton
        placeholder="Upload a spreadsheet file"
        accept={[
          ".csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ]}
        uploadPath={"/services/account/bulk-upload/"}
        method="post"
        formData={{
          set_password: setPassword,
          network_ids: String(selectedNetworks.map((item: any) => item.id)),
        }}
        onfileUploadedCallback={({ status }) => {
          if (status === 200) {
            toast.success("Bulk upload successful!");
            setOpen(false);
          }
        }}
      />
    </Modal>
  );
}
