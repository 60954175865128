import { useEffect, useState } from "react";
import { isYoutubeUrl } from "../../../lib/utils/mediaUrlChecks";

export const YouTubeEmbed = ({
  url,
  className,
}: {
  url: string;
  className?: string;
}) => {
  const [youtubeId, setYoutubeId] = useState("");
  useEffect(() => {
    let youtubeUrl = isYoutubeUrl(url);
    if (youtubeUrl) {
      setYoutubeId(youtubeUrl.id);
    } else {
      setYoutubeId("");
    }
  }, [url]);

  return youtubeId ? (
    <iframe
      className={className}
      src={`https://www.youtube.com/embed/${youtubeId}`}
      allow="autoplay; encrypted-media; picture-in-picture"
      allowFullScreen={true}
      frameBorder={0}
    />
  ) : null;
};
