import { ReactNode, Suspense } from "react";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import {
  PermissionKey,
  PermissionType,
  useUser,
} from "../../../lib/contexts/usercontext";
import { NoResults } from "../../atoms/NoResults";

export function useRestrictedContent({
  keyName,
  permission,
}: {
  keyName: PermissionKey;
  permission: PermissionType;
}) {
  const { state } = useUser();
  const isAllowed = state.permissions?.find(
    (item) => item.key === keyName && item.permissions.includes(permission)
  );
  return isAllowed;
}

export function RestrictedContent({
  children,
  keyName,
  permission,
  showFallback,
}: {
  children: ReactNode;
  keyName: PermissionKey;
  permission: PermissionType;
  showFallback?: boolean;
}) {
  const { state } = useUser();
  const isAllowed = state.permissions?.find(
    (item) => item.key === keyName && item.permissions.includes(permission)
  );

  if (!isAllowed && showFallback)
    document.title = "No Access Permission - SetuFarm";

  return state.permissions ? (
    isAllowed ? (
      <Suspense fallback={<LogoLoader width={150} />}>{children}</Suspense>
    ) : showFallback ? (
      <NoResults
        width="40%"
        notFoundText="You do not have the permission to access this page. Please get in touch with FarmSetu Support Team."
      />
    ) : null
  ) : (
    <></>
  );
}
