import { Divider } from "../../atoms/Divider";
import Typography from "../../atoms/Typography";
import BreadCrumbs from "../BreadCrumb";
import { PageTitleContainer } from "./styles";
import { PageTitleProps } from "./type";
import useScrollFromTop from "../../../lib/hooks/useScrollFromTop";
import { useMediaQuery } from "@mantine/hooks";

function PageTitle({
  title,
  breadCrumbsList,
  rightSection,
  className,
  fixed,
}: PageTitleProps) {
  const [applyShadow] = useScrollFromTop(0);
  const largeScreen = useMediaQuery("(min-width: 48em)");
  return (
    <PageTitleContainer className={className} fixed={fixed && applyShadow}>
      {!largeScreen && rightSection ? null : (
        <div className="flex items-center">
          <Typography variant="h3">{title}</Typography>
          {title && breadCrumbsList ? <Divider mx={15} /> : null}
          {breadCrumbsList ? (
            <BreadCrumbs breadCrumbsList={breadCrumbsList || {}} />
          ) : null}
        </div>
      )}
      {rightSection && (
        <div className="flex items-center ml-auto">{rightSection} </div>
      )}
    </PageTitleContainer>
  );
}
export default PageTitle;
