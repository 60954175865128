import { SVGProps } from "react";

export const P2PDistanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.97914 12.314C6.9479 12.2945 6.92712 12.2651 6.89587 12.2357C6.39583 11.6868 5.82295 11.1771 4.79174 11.1771C3.25004 11.1771 2.5 12.2946 2.5 14.1667H17.5C17.5 12.2945 16.7605 11.1771 15.2083 11.1771C13.9999 11.1771 13.4061 11.8436 13.0624 12.2357L13.0104 12.2945V8.97154C13.0104 7.96194 13.4167 7.17785 14.7084 6.5603C14.0416 7.19739 13.6979 7.82478 13.625 8.55009C15.9792 8.53054 16.9584 6.9425 17.0834 5.11941C14.0938 5.2861 12.5625 6.28584 12.3959 8.14821C11.6251 7.15818 10.1563 6.72686 8.32294 6.72686C8.37496 8.54995 9.28135 10.1183 11.7604 10.1575C11.8646 9.64788 11.5729 9.08913 10.6979 8.22656C12.0729 8.65785 12.3854 9.18719 12.3854 10.3928V12.4218C12.2916 12.3826 12.2082 12.3238 12.1354 12.2356C11.7708 11.8337 11.1667 11.177 9.97912 11.177C8.77079 11.177 8.16666 11.8435 7.81253 12.2356C7.75004 12.3042 7.68755 12.363 7.6146 12.3924V6.05053C7.6146 4.58024 8.03123 3.92343 9.30206 3.39423C8.42706 4.25684 8.13544 4.81559 8.03123 5.32522C10.7187 5.28597 11.6251 3.72746 11.6771 1.89453C8.91669 1.89453 6.97915 2.87473 6.97915 5.26641V6.20736C6.2604 5.34475 4.90627 4.86453 2.91665 4.75664C3.04163 6.57973 4.02082 8.16775 6.37502 8.19716C6.29175 7.46201 5.95839 6.83462 5.29165 6.19752C6.58328 6.81508 6.97911 7.59917 6.97911 8.60877L6.97914 12.314Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M6.27323 18.3333L4.16675 16.6667M4.16675 16.6667L6.27323 15M4.16675 16.6667H15.0001M12.8936 18.3333L15.0001 16.6667M15.0001 16.6667L12.8936 15"
      stroke={props.color || "#005777"}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
