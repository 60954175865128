import { gql } from "@apollo/client";

export const SERIES_QUERY = gql`
  query Series {
    series(order_by: { name: asc }) {
      codename: id
      name
      featuredImage: featured_image {
        id
        url
      }
    }
  }
`;

export const ARTICLE_QUERY = gql`
  query ArticleList {
    article(
      order_by: { title: asc }
      where: { status: { _eq: "publish" }, is_series: { _eq: false } }
    ) {
      codename: id
      name: title
      description
      featuredImage: feature_image {
        id
        url
      }
    }
  }
`;

export const NOTIFICATION_DROPDOWNS_DATA_QUERY = gql`
  query NotificationAttributesQuery($orgId: Int) {
    networks: network(
      order_by: { name: asc }
      where: { company: { organisation_id: { _eq: $orgId } } }
    ) {
      codename: id
      name
    }
    fileupload(
      where: { media_type: { _eq: "image" } }
      order_by: { name: asc }
    ) {
      id
      name
      url
      media_type
    }
  }
`;

export const PRODUCT_QUERY = gql`
  query getProductList {
    product(order_by: { name: asc }) {
      codename: id
      name
      featuredImage: featured_image {
        id
        url
      }
    }
  }
`;

export const NOTIFICATION_CATEGORIES = gql`
  query getNotificationCategories {
    notification_category(order_by: { name: asc }) {
      codename: id
      name
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotificationTemplate(
    $title: String!
    $description: String!
    $categoryId: String!
    $featureImage: String
    $body: String
    $broadcastDateTime: DateTime!
    $teams: [String]
    $users: [String]
    $regions: [String]
    $networks: [String]
    $recordId: String
  ) {
    create_notification(
      title: $title
      description: $description
      category_id: $categoryId
      image_url: $featureImage
      body: $body
      broadcast_datetime: $broadcastDateTime
      team_ids: $teams
      user_ids: $users
      region_ids: $regions
      network_ids: $networks
      record_id: $recordId
    ) {
      id
    }
  }
`;
