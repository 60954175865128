import {
  Textarea as MantineTextarea,
  MantineTheme,
  TextareaProps,
} from "@mantine/core";

const styles = (theme: MantineTheme) => ({
  label: {
    fontWeight: 700,
    color: theme.other.colors.primary.main,
    marginBottom: theme.other.gaps["8"],
  },
  input: {
    heigth: "48px",
    border: "1px solid " + theme.other.colors.border,
    color: theme.other.colors.primary["700"],
    "::placeholder": {
      color: theme.other.colors.primary["700"],
    },
  },
  required: {
    color: theme.other.colors.primary.main,
  },
});

export const Textarea = (props: TextareaProps) => {
  return <MantineTextarea styles={styles} {...props} />;
};
