import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { toast } from "react-toastify";
import { MantineButton } from "../../atoms/MantineButton";
import { Modal } from "../../atoms/Modal";
import { ISetupModalData } from "./data";
import { Input } from "../../atoms/MantineInput";
import { Space } from "@mantine/core";
import { Textarea } from "../../atoms/Textarea";
import SwitchInput from "../../atoms/Switch";
import {
  FeaturedImageContainer,
  SelectedImageContainer,
} from "../ArticleCreationSections/styles";
import Typography from "../../atoms/Typography";
import UploadButton from "../../atoms/UploadFile";
import { Group } from "../../atoms/Group";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { GET_ALL_ORGANISATIONS } from "./query";
import { GET_NETWORK_USERS } from "../NotificationsListingSections/query";
import { Select } from "../../atoms/MantineSelect";
import { Loader } from "../../atoms/Loader/Loader";

export const AuthorForm = () => {
  const { setupType, refetch } = useOutletContext<{
    setupType: keyof ISetupModalData;
    refetch: any;
  }>();
  const navigate = useNavigate();
  const [state, setState] = useState({
    organisation_id: "",
    author_id: "",
    title: "",
    about: "",
    photo: "",
    is_school_mentor: false,
  });
  const params = useParams();

  const { data: orgList, loading: orgListLoading } = useQuery(
    GET_ALL_ORGANISATIONS
  );

  const { data: userList, loading: userListLoading } = useQuery(
    GET_NETWORK_USERS,
    {
      variables: {
        orgId: state.organisation_id,
      },
      skip: !state.organisation_id,
      fetchPolicy: "network-only",
    }
  );

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast
      .promise(
        axios({
          url: `${process.env.REACT_APP_ONFARM_REST_API_URL}/articles/authors/${
            params.id ? `${params.id}/` : ""
          }`,
          method: params.id ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
          },
          data: state,
        }),
        {
          pending: (params.id ? "Editing" : "Creating") + " author",
          success: (params.id ? "Edited" : "Created") + " author",
          error: "Couldn't " + (params.id ? "edit" : "create") + " author",
        }
      )
      .then(() => {
        refetch();
        navigate("/advisory/setup/" + setupType);
      });
  };

  const disableSubmit = () => {
    return !(state.organisation_id && state.author_id && state.title);
  };

  function handleChange(value: Partial<typeof state>) {
    setState((prev: any) => ({ ...prev, ...value }));
  }

  useEffect(() => {
    if (params.id) {
      axios({
        url: `${process.env.REACT_APP_ONFARM_REST_API_URL}/articles/authors/${params.id}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
        },
      }).then(({ data }) => {
        if (!data.data) {
          navigate("/advisory/setup/" + setupType);
        } else {
          const author = data.data as typeof state;
          setState((prev) => ({
            author_id: author.author_id,
            title: author.title,
            about: author.about,
            is_school_mentor: author.is_school_mentor,
            organisation_id: author.organisation_id,
            photo: author.photo,
          }));
        }
      });
    }
  }, [params.id]);

  return (
    <Modal
      isOpen={true}
      handleModal={(e) => navigate("/advisory/setup/" + setupType)}
      modalTitle={`${params.id ? "Edit" : "Create"} Authors`}
      width={"min(60rem, 95vw)"}
      height="auto"
    >
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <>
          <Group spacing="lg">
            <Select
              label="Organization"
              listData={orgList?.organisation || []}
              placeholder="Select an organization"
              values={{ id: state.organisation_id }}
              accessor={{ name: "name", value: "id" }}
              className="flex-1"
              onSelect={(item) =>
                handleChange({ organisation_id: item.id, author_id: "" })
              }
              rightSection={orgListLoading ? <Loader size="sm" /> : <></>}
              searchable
              required
            />
            <Select
              label="Users"
              listData={
                userList?.base_user_network?.map(({ base_user }: any) => ({
                  id: base_user?.reference_id,
                  name: base_user?.full_name,
                })) || []
              }
              rightSection={userListLoading ? <Loader size="sm" /> : <></>}
              placeholder="Select a user"
              values={{ id: state.author_id }}
              className="flex-1"
              accessor={{ name: "name", value: "id" }}
              onSelect={(item) => handleChange({ author_id: item.id })}
              disabled={
                !state.organisation_id || !userList?.base_user_network?.length
              }
              searchable
              required
            />
          </Group>
          <Space h="lg" />
          <Input
            label="Title"
            placeholder="Enter author title"
            value={state.title}
            onChange={(e) => handleChange({ title: e.target.value })}
            required
          />
          <Space h="lg" />
          <Textarea
            label="About"
            placeholder="Enter author description"
            minRows={10}
            value={state.about}
            onChange={(e) => handleChange({ about: e.target.value })}
          />
          <Space h="lg" />
          <Group position="apart" align="flex-start">
            <FeaturedImageContainer className="flex-1">
              <div className="flex-[0_0_50%]">
                <UploadButton
                  accept={["image/*"]}
                  onfileUploadedCallback={({ data }) =>
                    handleChange({ photo: encodeURI(data?.url) })
                  }
                  label={
                    <Typography variant="subtitle2">User photo</Typography>
                  }
                  placeholder="Upload"
                  className={`h-24 ${state.photo ? "w-full" : "w-1/2"}`}
                />
                <Space h="xl" />
                <SwitchInput
                  onLabel="Yes"
                  offLabel="No"
                  label="Is school mentor?"
                  size="lg"
                  checked={state.is_school_mentor}
                  onChange={(item) => {
                    handleChange({
                      is_school_mentor: item.currentTarget.checked,
                    });
                  }}
                />
              </div>
              {state.photo && (
                <SelectedImageContainer
                  className="flex-1"
                  imageUrl={state.photo}
                />
              )}
            </FeaturedImageContainer>
          </Group>
        </>
        <MantineButton
          className="mt-8 self-end"
          type="submit"
          disabled={disableSubmit()}
        >
          {params.id ? "Edit" : "Create"}
        </MantineButton>
      </form>
    </Modal>
  );
};
