import { SVGProps } from "react";

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0264 2.22241C13.0806 2.22241 15.5556 4.68411 15.5556 7.77436C15.5556 10.3932 11.7116 15.8402 10.0264 17.778C8.28865 15.84 4.44446 10.4455 4.44446 7.77436C4.44446 4.68408 6.91946 2.22241 10.0264 2.22241ZM10.0264 4.73641C11.6589 4.73641 13.0281 6.09816 13.0281 7.77419C13.0281 9.39788 11.659 10.7597 10.0264 10.7597C8.34135 10.7597 6.97221 9.39799 6.97221 7.77419C6.97221 6.09819 8.34132 4.73641 10.0264 4.73641Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default LocationIcon;
