import styled, { css } from "styled-components";
import SendMessageIcon from "../../atoms/Icons/SendMessageIcon";

export const TableContainer = styled.table`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;
`;

export const TableHead = styled.thead<{ fixedHead?: boolean }>`
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  overflow: hidden;
  ${({ fixedHead }) =>
    fixedHead &&
    css`
      position: sticky;
      z-index: 20;
      top: 13.6rem; // this value is the combined height of fixed header and page title
    `}
  & th {
    min-width: 5rem;
    padding: 1.4rem 1rem;
    cursor: pointer;
    background-color: #ebf4f8;
    border-right: 1px solid transparent;
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    & svg {
      flex: none;
    }
  }
`;

export const TableBody = styled.tbody`
  padding: 0.1rem;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 1rem;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.disabled.background};
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.border};
  }

  & > tr {
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 1.4rem;
  }
`;

export const TableRow = styled.tr`
  display: inline-flex;
  min-width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-bottom: 1rem;

  & td {
    min-width: 5rem;
    padding: 0.8rem 1rem;
    display: flex;
    background-color: ${({ theme }) => theme.colors.white};
    flex-direction: column;
    justify-content: center;
  }
`;

export const IconBox = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  & svg {
    width: 2.8rem;
    height: 2.8rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.orange.main};

  & svg {
    margin-right: 1.5rem;
    flex: 0 0 3rem;
    height: 3rem;
    padding: 0.4rem;
    &:hover {
      background-color: ${({ theme }) => theme.colors.disabled.background};
    }
  }
`;

export const TableLoading = styled.td`
  display: flex;
  justify-content: center;
  padding: 1rem;
`;

export const SendMessageIconWrapper = styled(SendMessageIcon)`
  width: 2rem !important;
  height: 2rem !important;
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0.8rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  z-index: 10;
`;
