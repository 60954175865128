import { MouseEventHandler, useEffect } from "react";
import { HiX } from "react-icons/hi";
import { theme } from "../../../style/theme";
import Typography from "../Typography";
import Portal from "./Portal";
import { ModalBox, ModalContainer, ModalContent, ModalHead } from "./styles";

interface IModalProps {
  children: React.ReactNode;
  handleModal: MouseEventHandler;
  isOpen: boolean;
  width?: string;
  height?: string;
  modalTitle?: string | JSX.Element;
  closeable?: boolean;
  closeIconColor?: string;
  headerSeparator?: boolean;
  padding?: string;
  margin?: string;
}

export const Modal = ({
  isOpen,
  handleModal,
  children,
  width,
  height,
  modalTitle,
  closeable = true,
  closeIconColor,
  headerSeparator = false,
  padding,
  margin,
}: IModalProps) => {
  const handleContentClick: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "initial";
    return () => {
      document.body.style.overflow = "initial";
    };
  }, [isOpen]);

  return isOpen ? (
    <Portal>
      <ModalContainer onClick={handleModal} data-cy="app-modal">
        <ModalBox
          onClick={handleContentClick}
          width={width}
          height={height}
          padding={padding}
          margin={margin}
        >
          <ModalHead borderBottom={headerSeparator}>
            <Typography variant="h6">{modalTitle}</Typography>
            {closeable && (
              <HiX
                size={24}
                onClick={handleModal}
                color={closeIconColor || theme.colors.orange.main}
              />
            )}
          </ModalHead>
          <ModalContent>{children}</ModalContent>
        </ModalBox>
      </ModalContainer>
    </Portal>
  ) : null;
};
