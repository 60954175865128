export const deviceSize = {
  desktopHd: 1440,
  desktop: 1024,
  tabletPro: 850,
  tablet: 600,
  mobile: 500,
};

export const device = {
  desktopHds: `(max-width: ${deviceSize.desktopHd}px)`,
  desktops: `(max-width: ${deviceSize.desktop}px)`,
  tabletsPro: `(max-width: ${deviceSize.tabletPro}px)`,
  tablets: `(max-width: ${deviceSize.tablet}px)`,
  mobiles: `(max-width: ${deviceSize.mobile}px)`,
};
