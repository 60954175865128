import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { TabsContainer, TabsHead, TabsHeadItem, TabsContent } from "./styles";
import { ITabs, TabsProps } from "./types";

const Tabs: FC<TabsProps> = ({
  children,
  tabs,
  headPostfix,
  resetParams,
  onTabChange,
  hideBorder,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let tab = tabs.find((item) => pathname.split("/").includes(item.id));
    setActiveTab(`${tab?.id}`);
  }, [tabs, pathname]);

  const handleTabClick = (item: ITabs) => {
    setActiveTab(item.id);
    onTabChange && onTabChange(item.id);
    navigate({
      pathname: item.id,
      search: !resetParams ? "?" + searchParams.toString() : "",
    });
  };
  return (
    <TabsContainer hideBorder={hideBorder}>
      <section className="flex justify-between items-center">
        <TabsHead data-cy="tab-items">
          {tabs.map((item) => (
            <TabsHeadItem
              className={activeTab === item.id ? "active" : ""}
              onClick={(e) => activeTab !== item.id && handleTabClick(item)}
              key={item.id}
            >
              {item.head}
            </TabsHeadItem>
          ))}
        </TabsHead>
        {headPostfix && <div>{headPostfix}</div>}
      </section>
      <TabsContent>{children}</TabsContent>
    </TabsContainer>
  );
};

export default Tabs;
