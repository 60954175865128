import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  DetailTypes,
  TeamState,
} from "../../components/templates/TeamsDetailModal/type";
import { useUser } from "../contexts/usercontext";
import { capitalize } from "../utils/stringFormat";
import {
  getDocumentByContentType,
  getOrganizationPreferences,
} from "../utils/teamDetailApi";

export default function useDocumentPreferences({
  type,
  entityId, 
  teamId,
}: {
  type: DetailTypes;
  entityId: number;
  teamId: number;
}) {
  const user = useUser();
  const [documentKinds, setDocumentKinds] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (
    entity_id: string | number,
    userId: string,
    teamId: string | number
  ) => {
    try {
      setLoading(true);
      const organizationPreferences = await getOrganizationPreferences({
        userId,
      });
      const contentType = organizationPreferences.data?.document_config?.find(
        (e: any) => e?.name === capitalize(type)
      );
      if (contentType) {
        const documents = await getDocumentByContentType({
          content_type_id: contentType?.id,
          mutual_group_id: teamId,
          entity_id,
        });

        setLoading(false);

        const data = contentType?.document_kinds?.map((docKind: any) => {
            let doc = documents?.data?.[0]?.documents?.filter((document: any) => document.document_kind.id === docKind.id);
            return {
                document_id: doc?.[0]?.id,
                id_number: doc?.[0]?.id_number,
                valid_from: doc?.[0]?.valid_from,
                attachments: doc?.map((d: any) => d?.attachments)?.flat(),
                verification_remark: doc?.[0]?.verification_remark,
                is_verified: doc?.[0]?.is_verified,
                ...docKind,
            }
        })
        setDocumentKinds(data || []);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Failed to fetch documents");
    }
  };

  const fetch = () => {
    const userId = user?.state?.me?.id;
    if (entityId && userId && teamId) {
      fetchData(entityId, userId, teamId);
    }
    return () => {
      setDocumentKinds([]);
    };
  }

  useEffect(() => {
    fetch();
  }, [entityId, teamId, user]);

  return {
    documentKinds,
    loading,
    refetch: fetch,
  };
}
