import styled from "styled-components";
import Typography from "../Typography";

export const NavListItem = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  grid-template-columns: 24px 1fr;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  border-radius: 5px;
  ${(props) => props.isSelected && "background-color:#EBF4F8"};
  cursor: pointer;
  margin-bottom: 0.8rem;
  &:hover {
    background-color: #ebf4f8;
  }
`;

export const Title = styled(Typography)`
  transition: opacity 500ms;
  margin-left: 0.8rem;
`;

export const SubMenuList = styled.ul`
  list-style-type: none;
`;
