import React, { useEffect, useRef } from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import { useMemo } from "react";
import {
  TableBody,
  TableContainer,
  TableHead,
  TableLoading,
  TableRow,
} from "./styles";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { ITableProps } from "./types";
import { BsChevronExpand } from "react-icons/bs";
import { NoResults } from "../../atoms/NoResults";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { config } from "../../../config";
import { Checkbox } from "../../atoms/Input/MantineCheckbox";

const checkboxColumn = {
  accessor: "select",
  flex: 0.25,
  disableSortBy: true,
  Header: ({ getToggleAllRowsSelectedProps }: any) => {
    let props = getToggleAllRowsSelectedProps();
    return (
      <Checkbox
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          return props.onClick;
        }}
      />
    );
  },
  Cell: ({ row }: any) => {
    let props = row.getToggleRowSelectedProps();
    return (
      <Checkbox
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          return props.onClick;
        }}
      />
    );
  },
};

const Table: React.FC<ITableProps> = ({
  tableColumns,
  tableData,
  pageSize,
  loading,
  getRowProps,
  fixedHead,
  sortByData = [],
  handleSortBy,
  manualSorting = true,
  getSelectedRows,
}) => {
  const columns = useMemo(
    () => [...(getSelectedRows ? [checkboxColumn] : []), ...tableColumns],
    [tableColumns]
  );
  const data = useMemo(() => tableData, [tableData]);
  const headRef = useRef<HTMLTableSectionElement>(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    selectedFlatRows,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: manualSorting,
      initialState: { sortBy: sortByData },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    getSelectedRows?.(selectedFlatRows);
  }, [selectedFlatRows.length]);

  useEffect(() => {
    if (handleSortBy) handleSortBy(sortBy);
  }, [sortBy]);

  useEffect(() => {
    if (pageSize) setPageSize(pageSize);
  }, [pageSize]);

  return (
    <TableContainer {...getTableProps()}>
      <TableHead fixedHead={fixedHead} ref={headRef}>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i, arr) => (
              <th
                {...column.getHeaderProps({
                  ...column.getSortByToggleProps(),
                })}
                style={{
                  flex: `1 0 ${
                    config.columnWidth * Number((column as any)?.flex)
                  }rem`,
                }}
              >
                {column.render("Header")}

                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <HiChevronDown size={18} style={{ marginLeft: "0.5rem" }} />
                  ) : (
                    <HiChevronUp size={18} style={{ marginLeft: "0.5rem" }} />
                  )
                ) : !column.disableSortBy ? (
                  <BsChevronExpand size={18} style={{ marginLeft: "0.5rem" }} />
                ) : null}
              </th>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody
        {...getTableBodyProps()}
        onScroll={(e) => {
          headRef.current?.scrollTo({
            top: e.currentTarget.scrollTop,
            left: e.currentTarget.scrollLeft,
          });
        }}
      >
        {!loading ? (
          page.map((row) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps(getRowProps && getRowProps(row))}>
                {row.cells.map((cell, i, arr) => (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      flex: `1 0 ${
                        config.columnWidth * Number((cell.column as any)?.flex)
                      }rem`,
                      justifyContent:
                        (cell.column as any).alignTop && "flex-start",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </TableRow>
            );
          })
        ) : (
          <tr className=" flex justify-center mb-4">
            <TableLoading>
              <LogoLoader width={100} margin="5% auto" />
            </TableLoading>
          </tr>
        )}
        {!loading && !data.length ? (
          <tr className=" flex justify-center mb-4">
            <td>
              <NoResults width="50%" />
            </td>
          </tr>
        ) : undefined}
      </TableBody>
    </TableContainer>
  );
};

export default Table;
