import { DocumentNode } from "graphql";
import { HiCheck, HiPencil, HiX } from "react-icons/hi";
import { useNavigate } from "react-router";
import { Cell } from "react-table";
import { GET_SETUP_CATEGORIES, GET_SETUP_PACKAGES } from "./query";
import { Actions } from "../../molecules/Table/styles";
import { theme } from "../../../style/theme";
import { useUser } from "../../../lib/contexts/usercontext";
import { RestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";
import Image from "../../atoms/Image";
import { Text } from "@mantine/core";

const actionsColumn = ({ userCheck }: { userCheck: boolean }) => ({
  Header: "Actions",
  accessor: "",
  flex: 1,
  disableSortBy: true,
  Cell: (cell: Cell<any>) => {
    const navigate = useNavigate();
    const user = useUser();
    const canEdit = userCheck
      ? cell.row.original.group_id ===
        user.state.me?.mutual_groups?.[0]?.codename
      : true;

    return canEdit ? (
      <RestrictedContent keyName="advice_management" permission="edit">
        <Actions>
          <HiPencil
            onClick={(e) => {
              e.stopPropagation();
              navigate("edit/" + cell.row.original.id);
            }}
          />
        </Actions>
      </RestrictedContent>
    ) : null;
  },
});

const booleanColumn = (header: string, accessor: string) => ({
  Header: header,
  accessor: accessor,
  flex: 0.5,
  disableSortBy: true,
  Cell: (cell: Cell) => {
    return (
      <div className="ml-4">
        {cell.value ? (
          <HiCheck color={theme.colors.positive.default} />
        ) : (
          <HiX color={theme.colors.negative.default} />
        )}
      </div>
    );
  },
});

const categoriesColumns = [
  {
    Header: "Name",
    accessor: "name",
    flex: 2,
  },
  {
    Header: "Team",
    accessor: "mutual_group",
    flex: 2,
    Cell: (cell: Cell) => {
      return <>{cell.value?.[0]?.name || ""}</>;
    },
  },
  {
    Header: "Description",
    accessor: "description",
    flex: 3,
    disableSortBy: true,
  },
  booleanColumn("Active", "is_active"),
  actionsColumn({ userCheck: true }),
];

const packagesColumns = [
  {
    Header: "Name",
    accessor: "name",
    flex: 2,
  },
  {
    Header: "Description",
    accessor: "description",
    flex: 3,
    disableSortBy: true,
  },
  {
    Header: "Package Type",
    accessor: "package_type",
    flex: 1,
    disableSortBy: true,
    Cell(cell: Cell) {
      return <>{packageType.find((item) => item.id === cell.value)?.name}</>;
    },
  },
  {
    Header: "Articles",
    accessor: "article_count",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Unit Price",
    accessor: "unit_price",
    flex: 1,
    disableSortBy: true,
    Cell(cell: Cell) {
      return String(cell.value) ? <>₹{cell.value}</> : <></>;
    },
  },
  {
    Header: "Discount Price",
    accessor: "discount_price",
    flex: 1,
    disableSortBy: true,
    Cell(cell: Cell) {
      return String(cell.value) ? <>₹{cell.value}</> : <></>;
    },
  },
  {
    Header: "Views",
    accessor: "view_count",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Likes",
    accessor: "like_count",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Bookmarks",
    accessor: "bookmark_count",
    flex: 1,
    disableSortBy: true,
  },
  booleanColumn("Active", "is_active"),
  actionsColumn({ userCheck: false }),
];

const authorsColumns: any[] = [
  {
    Header: "Photo",
    accessor: "photo",
    Cell(cell: any) {
      return <Image width={40} height={40} src={cell.value} />;
    },
    disableSortBy: true,
    flex: 1,
  },
  {
    Header: "Name",
    accessor: "name",
    flex: 1,
  },
  {
    Header: "Title",
    accessor: "title",
    flex: 1,
  },
  {
    Header: "Organisation Name",
    accessor: "organisation_name",
    flex: 1,
  },
  {
    Header: "Followers",
    accessor: "followers_count",
    flex: 1,
  },
  {
    Header: "About",
    accessor: "about",
    flex: 2,
    Cell(cell: any) {
      return <Text lineClamp={3}>{cell.value}</Text>;
    },
    disableSortBy: true,
  },
  actionsColumn({ userCheck: false }),
];

const orgAccessColumns: any[] = [
  {
    Header: "Subscription Package Name",
    accessor: "subscription_package_name",
    flex: 4,
  },
  {
    Header: "Organisation Name",
    accessor: "organisation_name",
    flex: 4,
  },
  actionsColumn({ userCheck: false }),
];

interface ISetupType {
  columns: any[];
  query: DocumentNode;
  mapper: (data: any) => any;
  order?: any;
  where?: (data: any) => any;
  rowClick?: boolean;
}
export interface ISetupData {
  categories: ISetupType;
  packages: ISetupType;
}

export const setupData: ISetupData = {
  categories: {
    columns: categoriesColumns,
    query: GET_SETUP_CATEGORIES,
    mapper: ({ categories }) => categories,
    where: (data) => ({ group_id: { _in: data.groupIds } }),
    rowClick: false,
  },
  packages: {
    columns: packagesColumns,
    query: GET_SETUP_PACKAGES,
    mapper: (data) => data.packages.records,
    rowClick: false,
  },
};

export const restSetupData = {
  authors: {
    columns: authorsColumns,
    urlPath: "/articles/authors/",
  },
  "org-access": {
    columns: orgAccessColumns,
    urlPath: "/articles/org-access-package/",
  },
};

export const setupSortingMap: any = {
  mutual_group: "group_id",
  article_subscription_package: { value: "name" },
};

export const authorSortingMapper = {
  authors: {
    name: "shared_author__name",
    organisation_name: "shared_organisation__name",
  },
  "org-access": {
    organisation_name: "shared_organisation",
    subscription_package_name: "subscription_package",
  },
};

export const setupOrder: any = {
  categories: [
    {
      id: "name",
      desc: false,
    },
  ],
  packages: [
    {
      id: "name",
      desc: false,
    },
  ],
  authors: [
    {
      id: "name",
      desc: false,
    },
  ],
  "org-access": [
    {
      id: "subscription_package_name",
      desc: false,
    },
  ],
};

export const packageType = [
  { id: "global", name: "Global" },
  { id: "org-public", name: "Organization Public" },
  { id: "team-public", name: "Team Public" },
  { id: "user-restricted", name: "User Restricted" },
];
