import { theme } from "../../../style/theme";
import Typography from "../Typography";
import { FooterContainer } from "./styles";

export const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant="small1" textColor={theme.colors.primary[700]}>
        Copyright @FarmSetu 2021-22
      </Typography>
    </FooterContainer>
  );
};
