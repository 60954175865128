import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GET_LOGGED_IN_USER } from "./api";
import axios from "axios";

enum UserActions {
  ADD_USER,
  REMOVE_USER,
  ADD_LOGGED_IN_USER_DETAILS,
}

interface Action {
  type: UserActions;
  payload?: any;
}

export interface UserDetails {
  username: string;
  first_name: string;
  last_name: string;
  id: string;
  email: string;
  allowed_roles: string[];
  reference_id: string;
  mutual_groups: {
    id: string;
    name: string;
    codename: string;
  }[];
  photo: {
    id: string;
    url: string;
  } | null;
  agent: {
    id: string;
    organisation: { id: string; name: string };
  };
}

export type PermissionKey =
  | "network_management"
  | "farm_management"
  | "advice_management"
  | "school_management"
  | "trade_management"
  | "care_management"
  | "message_management";

export type PermissionType = "view" | "create" | "edit" | "delete";

export interface User {
  token: string;
  me?: UserDetails;
  permissions?: {
    key: PermissionKey;
    permissions: PermissionType[];
    route: string;
  }[];
}

interface Props {
  children: React.ReactNode;
}

type Dispatch = (action: Action) => void;

const UserContext = React.createContext<
  | {
      state: User;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

function userReducer(state: User | undefined, action: Action) {
  switch (action.type) {
    case UserActions.ADD_USER:
      return action.payload;

    case UserActions.REMOVE_USER:
      localStorage.removeItem("farmsetu_token");
      localStorage.removeItem("agentId");
      return undefined;

    case UserActions.ADD_LOGGED_IN_USER_DETAILS:
      return { ...state, ...action.payload };

    default:
      throw new Error("No such Action defined");
  }
}

export const permissionRouteMap: Record<PermissionKey, string> = {
  network_management: "network",
  farm_management: "farm",
  advice_management: "advisory",
  care_management: "care",
  message_management: "notifications",
  school_management: "school",
  trade_management: "trade",
};

export async function getRoutePermissions() {
  return await axios({
    url: `${process.env.REACT_APP_KITE_URL}/services/organisation/my-apps/`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
    },
  });
}

export function UserProvider({ children }: Props) {
  const { data, loading } = useQuery(GET_LOGGED_IN_USER);
  const [state, dispatch] = React.useReducer(
    userReducer,
    undefined,
    (initialValue) => {
      const token = localStorage.getItem("farmsetu_token");
      if (token) return { token };
      return initialValue;
    }
  );

  useEffect(() => {
    if (!loading) {
      if (data) {
        getRoutePermissions().then(({ data: permissions }) => {
          addLoggedInUserDetails(dispatch, {
            me: data.me,
            permissions: permissions.map((item: any) => ({
              ...item,
              route: permissionRouteMap[item.key as PermissionKey],
            })),
          });
        });
      } else removeUser(dispatch);
    }
  }, [loading, data]);

  const value = { state, dispatch };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a CountProvider");
  }
  return context;
}

export const addUser = (dispatch: Dispatch, user: User) => {
  dispatch({ type: UserActions.ADD_USER, payload: user });
};

export const addLoggedInUserDetails = (
  dispatch: Dispatch,
  userData: Omit<User, "token">
) => {
  dispatch({ type: UserActions.ADD_LOGGED_IN_USER_DETAILS, payload: userData });
};

export const removeUser = (dispatch: Dispatch) => {
  dispatch({ type: UserActions.REMOVE_USER });
};
