import { Image } from "@mantine/core";
import Typography from "../Typography";
import { NoResultsContainer } from "./styles";
import NoResultsIllustration from "../../../assets/no_results.png";

export const NoResults = ({
  width,
  marginLeft,
  marginRight,
  notFoundText
}: {
  width?: string;
  marginLeft?: string;
  marginRight?: string;
  notFoundText?: string
}) => {
  return (
    <NoResultsContainer
      marginLeft={marginLeft}
      marginRight={marginRight}
      width={width}
    >
      <Image src={NoResultsIllustration} width="100%" />
      <Typography
        variant="subtitle1"
        textColor="text-orange-main"
        className="text-center"
      >
        {notFoundText || "No results found"}
      </Typography>
    </NoResultsContainer>
  );
};
