import { config } from "../../../config";
import { getLocalStorage } from "../../../lib/utils/localStorage";
import { createMapDiv, displayAssetLabel } from "./addToMap";
import { MapPopupData } from "./type";
import {
  getVertices,
  meterSquareToAcre,
  polygonActions,
  snapPolygon,
} from "./utils";

interface IPolygonProps extends google.maps.PolygonOptions {
  data: MapPopupData;
  onClick?: (data: IPolygonProps["data"]) => void;
  setAsset?: Function;
  container?: google.maps.Polygon;
  polygonList: Record<string, google.maps.Polygon>;
  expand?: boolean;
  overlay: google.maps.MapCanvasProjection;
  assetLabel: HTMLDivElement | null;
  setPopupData?: Function;
}

export const makePolygon = (options: IPolygonProps) => {
  const polygon = new google.maps.Polygon();
  let map = options.map as google.maps.Map;
  options.polygonList[options.data.id] = polygon;
  const bounds = new google.maps.LatLngBounds();
  const areaContainer = createMapDiv("polygonArea", "", "Area of Polygon");

  if (polygon) {
    polygon.setOptions(options);
    polygon.setEditable(options.editable || false);

    if (options.onClick) {
      polygon.addListener("click", () => {
        (options.paths as any[])?.map((latLng) => bounds?.extend(latLng));
        if (bounds) map.fitBounds(bounds);
        options.onClick && options.onClick(options.data);
      });
    }

    if (options.expand && options.container && options.setAsset) {
      let isInvalid = polygonActions(
        options.polygonList,
        polygon,
        "plot",
        options.data.id,
        options.container
      );
      let area = meterSquareToAcre(
        google.maps.geometry.spherical.computeArea(options.container.getPath())
      );
      options.setAsset((prev: any) => ({
        ...prev,
        id: Number(options.data.id),
        type: options.data.type,
        handlePlotExpand: false,
        coords: options.container
          ? [getVertices(options.container, true, true)]
          : undefined,
        area: !isInvalid
          ? { value: area, unitId: config.SIUnitCode.acre }
          : undefined,
        modalOpen: !isInvalid,
      }));
    }

    ["mouseover", "mousemove"].forEach((event) => {
      polygon.addListener(event, (e: any) => {
        if (getLocalStorage("mapConfig")?.hidePopovers || !options.assetLabel)
          return;
        const point = options.overlay.fromLatLngToDivPixel(e.latLng);
        displayAssetLabel(map, point, options.assetLabel, options.data.type);
        options.setPopupData && options.setPopupData(options.data);
      });
    });

    polygon.addListener("mouseout", () => {
      if (options.assetLabel) options.assetLabel.style.display = "none";
    });

    if (options.editable) {
      polygon.addListener("contextmenu", (e: any) => {
        let path = polygon.getPath();
        if (e.vertex != null && path.getLength() >= 4) path.removeAt(e.vertex);
      });

      options.map?.controls[google.maps.ControlPosition.TOP_CENTER].push(
        areaContainer
      );
      let area = meterSquareToAcre(
        google.maps.geometry.spherical.computeArea(polygon.getPath())
      );
      let areaText = areaContainer.querySelector("button");
      if (areaText !== null)
        areaText.innerText = "Area: " + area.toFixed(2) + " acres";
      let sameSnappedIndex = -1;

      ["set_at", "insert_at", "remove_at"].forEach((item) => {
        polygon.getPath().addListener(item, (index: any) => {
          if (options.data.type === "field" || options.data.type === "plot") {
            let area = meterSquareToAcre(
              google.maps.geometry.spherical.computeArea(polygon.getPath())
            );
            if (areaText !== null)
              areaText.innerText = "Area: " + area.toFixed(2) + " acres";

            let isInvalid = polygonActions(
              options.polygonList,
              polygon,
              options.data.type,
              options.data.id,
              options.container
            );
            if (!isInvalid && sameSnappedIndex === -1) {
              let snapped = snapPolygon(polygon, index, options.container);
              if (snapped) {
                let latLng = new google.maps.LatLng(snapped[0], snapped[1]);
                sameSnappedIndex = index;
                polygon.getPath().setAt(index, latLng);
              }
            }
            sameSnappedIndex = -1;

            options.setAsset &&
              options.setAsset((prev: any) => ({
                ...prev,
                id: options.data.id,
                type: options.data.type,
                coords: [getVertices(polygon, true, true)],
                area: !isInvalid
                  ? { value: area, unitId: config.SIUnitCode.acre }
                  : undefined,
              }));
          }
        });
      });
    }
  }
  return polygon;
};
