import styled from "styled-components";

export const AuthorsContainer = styled.div<{ dir?: "row" | "column" }>`
  display: flex;
  ${({ dir = "column" }) => `flex-direction: ${dir};`};
  flex-wrap: wrap;
`;

export const AuthorItem = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  margin: 0.25rem 0;
`;
