import { gql } from "@apollo/client";
const teamsContentTypeId = 15;
const usersContentTypeId = 9;

export const TEAMS_USERS_METRICS = gql`
  query TeamMetrics {
    totalTeams: mutual_group_aggregate(
      where: {is_active: {_eq: true}}
      ){
      aggregate {
        count
      }
    }
    unverifiedTeams: mutual_group_aggregate(
      where: { 
        _or: [
          {is_verified: {_is_null: true}}, 
          {is_verified: {_eq: false}}
        ]
        _and: {is_active: {_eq: true}}
    }) {
      aggregate {
        count
      }
    }
    regions: address_aggregate(
      where: {
      mutual_groups: {
        _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
        ]}
      }, 
      distinct_on: village) {
      aggregate {
        count
      }
    }
    teamDocuments: document_aggregate(
      where: { content_type_id: { _eq: ${teamsContentTypeId} } }) {
      aggregate {
        count
      }
    }

    totalUsers: base_user_aggregate (
      where: {is_active: {_eq: true}}
    ){
      aggregate {
        count
      }
    }
    unverifiedUsers: base_user_aggregate(
      where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] 
      _and: {is_active: {_eq: true}}
    }) {
      aggregate {
        count
      }
    }
    userDocuments: document_aggregate(where: { 
      content_type_id: { _eq: ${usersContentTypeId} } 
    }) {
      aggregate {
        count
      }
    }
    male: base_user_aggregate(
      where: { 
      gender: { _in: [ "Male", "male", "M", "m" ] } 
      _and: {is_active: {_eq: true}}
    }) {
      aggregate {
        count
      }
    }
    female: base_user_aggregate(
      where: { 
      gender: { _in: [ "Female", "female", "F", "f" ] } 
      _and: {is_active: {_eq: true}}
    }) {
      aggregate {
        count
      }
    }
    other: base_user_aggregate(
      where: { 
      gender: { _nin: [ "Male", "male", "M", "m", "Female", "female", "F", "f" ] } 
      _and: {is_active: {_eq: true}}
    }) {
      aggregate {
        count
      }
    }
  }
`;
