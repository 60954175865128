import LegendDivider from "../../../atoms/LegendDivider";
import Typography from "../../../atoms/Typography";
import ActivityCard from "../../../molecules/Cards/ActivityCard";
import { ActivitiesSectionProps } from "./type";
import { HorizontalSectionWrapper } from "./style";
import { LegendTitleContainer } from "../../../molecules/LegendTitle/style";

function ActivitiesSection({
  data,
  isLoading = false,
  legendTitle,
  legendIcon,
  alignCenter,
  children,
  ...props
}: ActivitiesSectionProps) {
  const LegendIcon = legendIcon;
  const LegendTitle = (
    <LegendTitleContainer>
      <LegendIcon />
      <Typography variant="h6">{legendTitle}</Typography>
    </LegendTitleContainer>
  );

  const renderElements = data?.map((item, index) => (
    <ActivityCard
      key={item.name}
      icon={item.icon}
      displayName={item.displayName}
      unit={item.unit}
      count={item.count}
      loading={isLoading}
      alignCenter={alignCenter}
      countVariants={item.countVariants || []}
      secondary={item.secondary}
    />
  ));

  return (
    <div {...props}>
      <LegendDivider title={LegendTitle} />
      {children || (
        <HorizontalSectionWrapper
          data-cy="activities-section"
          className="grid grid-cols-1 gap-11 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
        >
          {renderElements}
        </HorizontalSectionWrapper>
      )}
    </div>
  );
}

export default ActivitiesSection;
