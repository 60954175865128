import { SortingRule } from "react-table";

export const sortingMapper = (sorting: SortingRule<{}>[], map?: any) => {
  return sorting?.map((item: any) => {
    let key = map && map[item.id] ? map[item.id] : item.id;
    let sortBy = item.desc ? "desc" : "asc";
    if (typeof key === "string") return { [key]: sortBy };
    else if (typeof key === "function") return map[item.id]?.(sortBy);
    else return { [item.id]: { [map[item.id].value]: sortBy } };
  })[0];
};

export const parseSortBy = (params: URLSearchParams, keys: string[]) => {
  let result: any = [];
  keys?.forEach((key) => {
    let value = params.get(key);
    if (value) {
      result.push({
        id: key,
        desc: value === "desc",
      });
    }
  });
  return result;
};

export const sortValueToURL = (
  params: URLSearchParams,
  sortBy: any[],
  map: string[]
) => {
  map?.forEach((key) => {
    let item = sortBy.find((item) => item.id === key);
    if (item) {
      params.set(key, item.desc ? "desc" : "asc");
    } else params.delete(key);
  });
};
