import { gql } from "@apollo/client";

export const GET_STATES = gql`
  query getStates {
    region(
      where: { region_group: { name: { _eq: "State" } } }
      order_by: { name: asc }
    ) {
      id
      name
      codename
      parentregionid: parent_region_id
    }
  }
`;

export const GET_DISTRICTS = gql`
  query getDistricts($id: Int_comparison_exp) {
    region(
      where: {
        parent_region_id: $id
        region_group: { name: { _eq: "District" } }
      }
      order_by: { name: asc }
    ) {
      id
      name
      codename
      parentregionid: parent_region_id
    }
  }
`;

export const GET_TALUKAS = gql`
  query getTalukas($id: Int_comparison_exp, $limit: Int) {
    region(
      where: {
        parent_region_id: $id
        region_group: { name: { _eq: "Taluka" } }
      }
      order_by: { name: asc }
      limit: $limit
    ) {
      id
      name
      codename
      parentregionid: parent_region_id
    }
  }
`;

export const GET_VILLAGES = gql`
  query getVillages($id: Int_comparison_exp, $limit: Int) {
    region(
      where: {
        parent_region_id: $id
        region_group: { name: { _eq: "Village" } }
      }
      order_by: { name: asc }
      limit: $limit
    ) {
      id
      name
      codename
      parentregionid: parent_region_id
    }
  }
`;

export const GET_TEAMS = gql`
  query getTeams($networks: [Int], $limit: Int, $search: String) {
    teams: registration_mutual_group_list_with_total(
      network_ids: $networks
      limit: $limit
      query: $search
      search_attributes: ["name"]
    ) {
      total
      records {
        teams: mutual_group {
          name
          codename
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers(
    $networks: [Int]
    $teams: [String!]
    $limit: Int
    $search: String
  ) {
    users: my_network_users_with_total(
      network_ids: $networks
      mutual_group_codenames: $teams
      limit: $limit
      query: $search
      search_attributes: ["first_name", "last_name"]
    ) {
      total
      records {
        name: fullname
        codename: reference_id
        team: mutual_group {
          codename
          name
        }
      }
    }
  }
`;
