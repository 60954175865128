import { Cell } from "react-table";
import { ISetupType } from "../../../../pages/SchoolCourses/type";
import { TRADE_BASE_RATES } from "../../../organisms/TradeSetupSections/query";
import { fullDateTime } from "../../../../lib/utils/dateTimeFormat";

const dailyRatesColumns = [
  {
    Header: "Commodity Name",
    accessor: "commodity.name",
    flex: 2,
    disableSortBy: true,
  },
  {
    Header: "Variety Name",
    accessor: "commodity_variety.name",
    flex: 2,
    disableSortBy: true,
  },
  {
    Header: "Min Rate (per KG)",
    accessor: "min_rate",
    flex: 1,
    Cell: (cell: Cell) => {
      if (!cell.value) return <></>;
      let currency = (cell.row.original as any)?.base_rate_unit?.name;
      return <div>{`${cell.value} ${currency}`}</div>;
    },
    disableSortBy: true,
  },
  {
    Header: "Max Rate (per KG)",
    accessor: "max_rate",
    flex: 1,
    Cell: (cell: Cell) => {
      if (!cell.value) return <></>;
      let currency = (cell.row.original as any)?.base_rate_unit?.name;
      return <div>{`${cell.value} ${currency}`}</div>;
    },
    disableSortBy: true,
  },
  {
    Header: "Trade Start",
    accessor: "start_datetime",
    flex: 1,
    Cell(cell: Cell) {
      return <>{fullDateTime(cell.value)}</>;
    },
    disableSortBy: true,
  },
  {
    Header: "Trade End",
    accessor: "end_datetime",
    flex: 1,
    Cell(cell: Cell) {
      return <>{fullDateTime(cell.value)}</>;
    },
    disableSortBy: true,
  },
];

export interface ISetupData {
  "daily-rates": ISetupType;
}

export const setupData: ISetupData = {
  "daily-rates": {
    columns: dailyRatesColumns,
    query: TRADE_BASE_RATES,
    mapper: ({ baseRates }) => baseRates.records,
    where: () => {},
    rowClick: false,
  },
};

export const setupSortingMap: any = {
  mutual_group: "group_id",
  base_rate: "rate",
  date: "start_datetime",
  article_subscription_package: { value: "name" },
};

export const setupOrder: any = {
  "daily-rates": [{ id: "name", desc: false }],
};
