import {
  intersect,
  kinks,
  polygon as turfPolygon,
  point as turfPoint,
  booleanContains,
  nearestPointOnLine,
  lineString,
  distance,
} from "@turf/turf";
import { MAP_CONSTANTS } from "../../../lib/utils/constants";
import { assetsColor } from "./addToMap";

export function getVertices(
  polygon: google.maps.Polygon,
  appendLast?: boolean,
  opposite?: boolean
) {
  let vertices = polygon
    .getPath()
    .getArray()
    .map((item: any) =>
      opposite ? [item.lng(), item.lat()] : [item.lat(), item.lng()]
    );
  if (appendLast) vertices.push(vertices[0]);
  return vertices;
}

export function farthestPoint(
  map: google.maps.Map,
  polygon: google.maps.Polygon
) {
  let vertices = getVertices(polygon);
  let distances = vertices.map((item) => {
    let center = turfPoint(Object.values(map.getCenter()?.toJSON() || {}));
    let to = turfPoint(item);
    return distance(center, to, { units: "meters" });
  });
  let index = distances.indexOf(Math.max(...distances));
  return vertices[index];
}

export function checkSelfIntersect(polygon: any) {
  const vertices = getVertices(polygon, true);
  const poly = turfPolygon([vertices]);
  const selfIntersects = kinks(poly);
  return selfIntersects.features.length !== 0;
}

export function checkIntersection(
  polygonList: google.maps.Polygon[],
  polygon: google.maps.Polygon
) {
  const vertices = getVertices(polygon, true);
  const poly = turfPolygon([vertices]);
  let hasIntersection = false;
  let polyListCount = 0;

  while (polyListCount < polygonList.length) {
    let itemVertices = getVertices(polygonList[polyListCount], true);
    const itemPoly = turfPolygon([itemVertices]);
    if (intersect(itemPoly, poly)) return true;
    polyListCount++;
  }

  return hasIntersection;
}

export function checkContained(
  polygon: google.maps.Polygon,
  container?: google.maps.Polygon
) {
  if (!container) return false;
  const polygonVertices = turfPolygon([getVertices(polygon, true)]);
  const containerVertices = turfPolygon([getVertices(container, true)]);
  return !booleanContains(containerVertices, polygonVertices);
}

export function getGeocodeAddress(response: google.maps.GeocoderResponse) {
  const address = response.results[0]?.address_components;
  const locality =
    address.find((item) => item.types.includes("locality"))?.long_name || "";
  return {
    name: response.results[0]?.plus_code?.compound_code?.slice(0, 120) || "",
    address_kind: "",
    raw_address: response.results[0]?.formatted_address || "",
    pincode:
      address.find((item) => item.types.includes("postal_code"))?.long_name ||
      "",
    country:
      address.find((item) => item.types.includes("country"))?.long_name || "",
    state:
      address.find((item) => item.types.includes("administrative_area_level_1"))
        ?.long_name || "",
    district:
      address.find((item) => item.types.includes("administrative_area_level_2"))
        ?.long_name || "",
    local_area:
      address.find((item) => item.types.includes("sublocality_level_1"))
        ?.long_name || locality,
    taluka: locality,
    village: locality,
  };
}

export function storeEdges(
  map: google.maps.Map | undefined,
  boundTimes: number,
  storageKey?: string
) {
  if (!map) return;
  const bounds = map.getBounds();
  if (!bounds) return;
  const mapCenter = bounds.getCenter().toJSON();
  const northEast = bounds.getNorthEast().toJSON();
  const southWest = bounds.getSouthWest().toJSON();

  let leftMax = mapCenter.lng + (mapCenter.lng - southWest.lng);
  let rightMax = mapCenter.lng + (mapCenter.lng - northEast.lng);
  let topMax = mapCenter.lat + (mapCenter.lat - northEast.lat);
  let bottomMax = mapCenter.lat + (mapCenter.lat - southWest.lat);

  let returnValue = {
    topMax: mapCenter.lat + (mapCenter.lat - northEast.lat) * boundTimes,
    bottomMax: mapCenter.lat + (mapCenter.lat - southWest.lat) * boundTimes,
    leftMax: mapCenter.lng + (mapCenter.lng - southWest.lng) * boundTimes,
    rightMax: mapCenter.lng + (mapCenter.lng - northEast.lng) * boundTimes,
  };

  if (!storageKey) return returnValue;
  let localData = JSON.parse(localStorage.getItem(storageKey) || "{}");

  if (Object.keys(localData).length) {
    if (
      mapCenter.lng > localData.leftMax ||
      mapCenter.lng < localData.rightMax ||
      mapCenter.lat < localData.topMax ||
      mapCenter.lat > localData.bottomMax
    ) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({ topMax, bottomMax, leftMax, rightMax })
      );
      return returnValue;
    }
  } else {
    localStorage.setItem(
      storageKey,
      JSON.stringify({ leftMax, rightMax, topMax, bottomMax })
    );
    return returnValue;
  }
}

export function snapPolygon(
  polygon: google.maps.Polygon,
  vertex: number,
  container?: google.maps.Polygon
) {
  if (container) {
    let polygonVertices = getVertices(polygon);
    let containerVertices = getVertices(container, true);

    let vertexDistance = containerVertices.map((item) =>
      distance(polygonVertices[vertex], item, { units: "meters" })
    );
    let minDistance = Math.min(...vertexDistance);
    if (minDistance < MAP_CONSTANTS.vertexSnapDistance)
      return containerVertices[vertexDistance.indexOf(minDistance)];

    let nearest = nearestPointOnLine(
      lineString(containerVertices),
      turfPoint(polygonVertices[vertex]),
      { units: "meters" }
    ).geometry.coordinates;
    let dist = distance(polygonVertices[vertex], nearest, { units: "meters" });
    return dist < MAP_CONSTANTS.edgeSnapDistance ? nearest : null;
  }
  return null;
}

export function polygonActions(
  polygonList: any,
  polygon: google.maps.Polygon,
  type: "plot" | "field",
  exclude: string,
  container?: google.maps.Polygon
) {
  const { [exclude]: _, ...restPolygons } = polygonList;
  let invalidPolygon =
    checkIntersection(Object.values(restPolygons), polygon) ||
    checkSelfIntersect(polygon) ||
    checkContained(polygon, container);
  let color = invalidPolygon ? assetsColor[type].unverified : "transparent";
  polygon.setOptions({ fillColor: color });
  return invalidPolygon;
}

export function meterSquareToAcre(meterSquare: number) {
  return meterSquare * MAP_CONSTANTS.meterSqToAcre;
}
