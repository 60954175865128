import {
  MantineTheme,
  ScrollArea as MantineScrollArea,
  ScrollAreaProps,
} from "@mantine/core";

const styles = (theme: MantineTheme) => ({
  scrollbar: {
    backgroundColor: theme.other.colors.disabled.backgroundColor,
    borderRadius: "1rem",
  },
  thumb: {
    backgroundColor: theme.other.colors.border + "!important",
  },
});

export const ScrollArea = (props: ScrollAreaProps) => {
  return <MantineScrollArea styles={styles} {...props} />;
};
