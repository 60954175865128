import React, { useState } from "react";
import SectionDivider from "../../atoms/SectionDivider";
import VisibilityDetails from "../../organisms/NotificationCreationSections/VisibilityDetails";
import MediaSection from "../../organisms/NotificationCreationSections/MediaSection";
import { RichTextEditor } from "../../atoms/RichTextEditor";
import {
  NotificationState,
  Actions,
  NotificationActions,
} from "../../../pages/CreateNotification/type";
import { TitleDescriptionSection } from "../../organisms/NotificationCreationSections/TitleDescriptionSection";
import { CategorySection } from "../../organisms/NotificationCreationSections/CategorySection";
import { BroadcastSection } from "../../organisms/NotificationCreationSections/BroadcastSection";
import NetworkVisibilityDetails from "../../organisms/NotificationCreationSections/NetworkVisibilityDetails";
import { Radio, RadioGroup } from "../../atoms/Input/Radio";
import Typography from "../../atoms/Typography";
import { MultiSelect } from "../../atoms/MultiSelect";
import { Group } from "../../atoms/Group";

interface Props {
  state: NotificationState;
  dispatch: React.Dispatch<Actions>;
  onFeaturedImageUploaded: (response: any) => void;
}

const NotificationCreationForm: React.FunctionComponent<Props> = ({
  state,
  dispatch,
  onFeaturedImageUploaded,
}) => {
  const handleChangeValue = (
    key: keyof typeof state,
    value: string | object | undefined | null
  ) => {
    dispatch({
      type: NotificationActions.SET_VALUE,
      payload: {
        key,
        value,
      },
    });
  };

  return (
    <div
      className="flex flex-col w-full mt-2"
      data-cy="notification-creation-form"
    >
      <CategorySection state={state} handleChangeValue={handleChangeValue} />
      <SectionDivider value="Title and description" />
      <TitleDescriptionSection state={state} dispatch={dispatch} />
      <SectionDivider value="Media details" />

      <MediaSection
        state={state}
        dispatch={dispatch}
        onFeaturedImageUploaded={onFeaturedImageUploaded}
      />
      <SectionDivider value="Visibility Details" />
      <div className="flex-1 w-1/2 mb-8">
        <Typography variant="h6">
          <b>Networks(s)*</b>
        </Typography>
        <MultiSelect
          data-test-id="networks"
          limit={100}
          mt={8}
          values={state.selectedNetworks}
          listData={state.networks}
          accessor={{ name: "name", value: "codename" }}
          placeholder="Select networks"
          onSelect={(item) => handleChangeValue("selectedNetworks", item)}
          searchable
          clearable
          size="lg"
        />
      </div>
      <div
        className="flex-1"
        style={{ marginBottom: state.visibilityType ? 30 : 0 }}
      >
        <Typography variant="h6">
          <b>Visibility Type</b>
        </Typography>
        <RadioGroup
          size="md"
          value={state.visibilityType}
          onChange={(value) =>
            dispatch({
              type: NotificationActions.SELECT_VALUE,
              payload: {
                prop: "visibilityType",
                data: value,
              },
            })
          }
          spacing={30}
        >
          <Radio value={"network"} label={"Teams and Users"} />
          <Radio value={"geographical"} label={"Geographical"} />
        </RadioGroup>
      </div>
      {state.visibilityType === "network" ? (
        <NetworkVisibilityDetails
          state={state}
          handleChangeValue={handleChangeValue}
        />
      ) : state.visibilityType === "geographical" ? (
        <VisibilityDetails
          state={state}
          handleChangeValue={handleChangeValue}
        />
      ) : null}
      <SectionDivider value="Content details" />
      <RichTextEditor
        label="Notification content"
        minHeight="200px"
        sticky
        stickyOffset={136}
        placeholder="Enter content here..."
        value={state.content || ""}
        onChange={(editorData) => handleChangeValue("content", editorData)}
      />
      <SectionDivider value="Broadcast details" />
      <BroadcastSection
        state={state}
        dispatch={dispatch}
        handleChangeValue={handleChangeValue}
      />
    </div>
  );
};

export default NotificationCreationForm;
