import styled from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary.main};

  & button {
    border: none;
    background: transparent;
    padding: 0.25rem 0.5rem;
    margin: 0 0.5rem;
    border-radius: 0.25rem;

    &:not(:disabled) svg {
      fill: ${({ theme }) => theme.colors.primary.main};
    }

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.white};
      & svg {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }

  & svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const PaginationNumbers = styled.div`
  display: flex;
  align-items: center;

  & > * {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border-radius: 0.7rem;
    &:hover,
    &.current {
      background-color: ${({ theme }) => theme.colors.primary.main};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
