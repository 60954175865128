import { useLocation } from "react-router";
import { Navigate } from "react-router";

import { useUser } from "../../../lib/contexts/usercontext";

export function AuthRoute({ children }: { children: JSX.Element }) {
  const { state: user } = useUser();
  let location = useLocation();

  if (!user?.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
