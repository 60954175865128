import { gql } from "@apollo/client";

export const IN_APP_NOTIFICATION_METRICS = gql`
  query getNotificationMetrics {
    advice: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "Advise" } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    adviceSeen: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "Advise" } }
        }
        is_seen: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    school: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "School" } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    schoolSeen: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "School" } }
        }
        is_seen: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    care: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "Care" } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    careSeen: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "Care" } }
        }
        is_seen: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    weather: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "Weather" } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    weatherSeen: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "Weather" } }
        }
        is_seen: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    general: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "General" } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    generalSeen: notification_aggregate(
      where: {
        notification_template: {
          notification_category: { name: { _eq: "General" } }
        }
        is_seen: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
