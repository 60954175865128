import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import SchoolTemplate from "../../components/templates/School";
import { ActivityDataType } from "../../shared/type";
import { totalMetrics } from "./data";
import { SCHOOL_METRICS_QUERY } from "./query";

const School = () => {
  const [schoolMetrics, setSchoolMetrics] =
    useState<ActivityDataType[]>(totalMetrics);
  const { data, loading } = useQuery(SCHOOL_METRICS_QUERY);

  useEffect(() => {
    if (data && !loading) {
      setSchoolMetrics((metrics) =>
        metrics.map((metric) => ({
          ...metric,
          count: data[metric.name].total,
        }))
      );
    }
  }, [data, loading]);

  return <SchoolTemplate totalMetricData={schoolMetrics} isLoading={loading} />;
};

export default School;
