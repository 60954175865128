import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import NotificationsTemplate from "../../components/templates/Notifications";
import { ActivityDataType } from "../../shared/type";
import { totalMetrics } from "./data";
import { IN_APP_NOTIFICATION_METRICS } from "./query";
import { INotificationMetrics } from "./type";

const Notifications = () => {
  const [notificationMetrics, setNotificationMetrics] =
    useState<ActivityDataType[]>(totalMetrics);
  const { data, loading } = useQuery<INotificationMetrics>(
    IN_APP_NOTIFICATION_METRICS
  );
  useEffect(() => {
    if (data && !loading) {
      setNotificationMetrics((metrics) =>
        metrics.map((metric) => ({
          ...metric,
          count:
            data[metric.name as keyof INotificationMetrics].aggregate.count,
          secondary: {
            count:
              data[(metric.name + "Seen") as keyof INotificationMetrics]
                .aggregate.count || 0,
            successColor: true,
            label: "Seen",
            showPercentage: true,
          },
        }))
      );
    }
  }, [data, loading]);

  return (
    <NotificationsTemplate
      totalMetricData={notificationMetrics}
      isLoading={loading}
    />
  );
};

export default Notifications;
