import { gql } from "@apollo/client";

const whereClauseCategories = (
  categoryName: "Crop Protection" | "Crop Nutrition" | "Seeds" | "all"
) => `{
  subcategory: {
    category: {
      name:
        ${
          categoryName === "all"
            ? `{ _in: ["Crop Protection", "Crop Nutrition", "Seeds"] }`
            : `{ _eq: "${categoryName}" }`
        },
    },
  },
}`;

export const CARE_METRICS_QUERY = gql`
  query getCareMetrics {
    allProducts: product_aggregate(
      where: ${whereClauseCategories("all")}
    ) {
      aggregate {
        count
      }
    }
    cropProtectionProducts: product_aggregate(
      where: ${whereClauseCategories("Crop Protection")}
    ) {
      aggregate {
        count
      }
    }
    cropNutritionProducts: product_aggregate(
      where: ${whereClauseCategories("Crop Nutrition")}
    ) {
      aggregate {
        count
      }
    }
    seedsProducts: product_aggregate(
      where: ${whereClauseCategories("Seeds")}
    ) {
      aggregate {
        count
      }
    }

    totalView: product_view_aggregate(
      where: {
        product: ${whereClauseCategories("all")}
      }
    ) {
      aggregate {
        count
      }
    }

    cropProtectionView: product_view_aggregate(
      where: {
        product: ${whereClauseCategories("Crop Protection")}
      }
    ) {
      aggregate {
        count
      }
    }

    cropNutritionView: product_view_aggregate(
      where: {
        product: ${whereClauseCategories("Crop Nutrition")}
      }
    ) {
      aggregate {
        count
      }
    }

    seedsView: product_view_aggregate(
      where: {
        product: ${whereClauseCategories("Seeds")}
      }
    ) {
      aggregate {
        count
      }
    }

    totalPurchase: product_purchased_aggregate(
      where: {
        product: ${whereClauseCategories("all")}
      }
    ) {
      aggregate {
        count
      }
    }

    cropProtectionPurchase: product_purchased_aggregate(
      where: {
        product: ${whereClauseCategories("Crop Protection")}
      }
    ) {
      aggregate {
        count
      }
    }

    cropNutritionPurchase: product_purchased_aggregate(
      where: {
        product: ${whereClauseCategories("Crop Nutrition")}
      }
    ) {
      aggregate {
        count
      }
    }

    seedsPurchase: product_purchased_aggregate(
      where: {
        product: ${whereClauseCategories("Seeds")}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
