import { NumberInput } from "../../atoms/MantineInput";
import Typography from "../../atoms/Typography";
import { Group } from "../../atoms/Group";
import { useQuery } from "@apollo/client";
import { TODAYS_BASE_RATES } from "./query";
import { Loader } from "../../atoms/Loader/Loader";

export const DailyRatesForm = ({ state, handleChange, tableData }: any) => {
  const { data, loading } = useQuery(TODAYS_BASE_RATES, {
    context: { clientName: "setuTrade" },
  });

  function updateValues(value: any, index: number) {
    let item = data?.todaysBaseRates?.[index];
    let tempRates = [...(state.rates || [])];

    let minRate =
      "min_rate" in value
        ? value.min_rate
        : state.rates?.[index]?.min_rate || item?.min_rate;
    let maxRate =
      "max_rate" in value
        ? value.max_rate
        : state.rates?.[index]?.max_rate || item?.max_rate;
    let isRateSame = minRate == item?.min_rate && maxRate == item?.max_rate;

    tempRates[index] = !isRateSame && {
      existing_base_rate_id: item.id,
      commodity_variety_id: item.commodity_variety?.id,
      min_rate: state.rates?.[index]?.min_rate,
      max_rate: state.rates?.[index]?.max_rate,
      rate_unit_id: item.base_rate_unit?.id,
      weight_unit_id: item.base_rate_weight_unit?.id,
      start_datetime: item.start_datetime ? new Date().toJSON() : null,
      end_datetime: item.end_datetime,
      ...value,
    };

    handleChange({ rates: tempRates });
  }

  return !loading ? (
    <>
      <Group mb={"xs"} spacing={"xs"}>
        {["Commodity Variety", "Min Rate (per Kg)", "Max Rate (per Kg)"].map(
          (item, i) => (
            <Typography variant="subtitle2" key={i} className="flex-1">
              {item}
            </Typography>
          )
        )}
      </Group>
      {data?.todaysBaseRates?.map((item: any, index: number) => (
        <Group mb={"lg"} spacing={"xs"} key={index} align="center">
          <div style={{ flex: 1 }}>
            <Typography variant="subtitle2">
              {item.commodity_variety?.name}
            </Typography>
          </div>
          <div className="flex-1">
            <NumberInput
              precision={2}
              placeholder="Enter min rate"
              defaultValue={item.min_rate}
              value={state.rates?.[index]?.min_rate || item.min_rate}
              rightSection="₹"
              min={0}
              onChange={(value) => updateValues({ min_rate: value }, index)}
            />
          </div>
          <div className="flex-1">
            <NumberInput
              precision={2}
              placeholder="Enter max rate"
              defaultValue={item.max_rate}
              value={state.rates?.[index]?.max_rate || item.max_rate}
              rightSection="₹"
              min={state.rates?.[index]?.min_rate || 0}
              onChange={(value) => updateValues({ max_rate: value }, index)}
              required={state.rates?.[index]?.min_rate}
            />
          </div>
        </Group>
      ))}
    </>
  ) : (
    <Loader mx={"auto"} />
  );
};
