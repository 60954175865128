import { MantineTheme } from "@mantine/core";
import { TimeInput, TimeInputProps } from "@mantine/dates";

let styles = (theme: MantineTheme) => ({
  input: {
    color: theme.other.colors.primary.main,
  },
  disabled: {
    color: theme.other.colors.border.color + "!important",
    backgroundColor: theme.other.colors.disabled.backgroundColor + "!important",
  },
  label: {
    fontWeight: theme.other.fonts.subtitle2.fontWeight,
    color: theme.other.colors.primary.main,
    marginBottom: theme.other.gaps["4"],
  },
  day: {
    ":hover": {
      backgroundColor:
        theme.other.colors.disabled.backgroundColor + "!important",
    },
    "&[data-selected]": {
      backgroundColor: theme.other.colors.primary.main + "!important",
    },
  },
  required: {
    color: theme.other.colors.primary.main,
  },
});

const TimePickerInput = (props: TimeInputProps) => {
  return <TimeInput styles={styles} {...props} />;
};

export default TimePickerInput;
