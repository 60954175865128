import styled from "styled-components";
import { HiBell, HiChatAlt2 } from "react-icons/hi";
import { IoCreate } from "react-icons/io5";
import { BsFillCalendar2Fill } from "react-icons/bs";

export const AppbarContainer = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  height: 7.01rem;
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  gap: 1.6rem;
  z-index: 30;
  padding: 0 1.6rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const UserImage = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  cursor: pointer;
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1.6rem;
`;

export const NotificationsIcon = styled(HiBell)`
  width: 2.4rem;
  height: 2.4rem;
  fill: ${(props) => props.theme.colors.orange.main};
  margin-right: 2.4rem;
`;

export const ChatIcon = styled(HiChatAlt2)`
  width: 2.4rem;
  height: 2.4rem;
  fill: ${(props) => props.theme.colors.orange.main};
  margin-left: 1.6rem;
`;

export const CreateArticle = styled(IoCreate)`
  width: 2.4rem;
  height: 2.4rem;
  fill: ${(props) => props.theme.colors.orange.main};
  margin-left: 1.6rem;
`;

export const Calender = styled(BsFillCalendar2Fill)`
  width: 2.2rem;
  height: 2.2rem;
  fill: ${(props) => props.theme.colors.orange.main};
  margin-left: 1.6rem;
`;

export const UserDropdown = styled.div<{ width: number | undefined }>`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.elevation.elevation2};
  width: ${(props) => props.width}px;
  border-radius: 4px;
  padding: 0.8rem 0.2rem;
  z-index: 998;
`;

export const AppBarDropDownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary.main};
  & > span {
    margin-left: 1rem;
  }
  &:hover {
    background-color: #ebf4f8;
  }
`;
