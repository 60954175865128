import { HiTrendingUp } from "react-icons/hi";
import { MultipleUsersIcon } from "../../atoms/Icons/NavIcons";
import PageTitle from "../../molecules/PageTitle";
import { CareTrendMetrics } from "../../organisms/CareSections/CareTrendMetrics";
import { CareUserEngagementMetrics } from "../../organisms/CareSections/CareUserEngagementMetrics";
import TotalMetricSection from "../../organisms/GenericSections/TotalMetricsSection";
import { ICareTemplateProps } from "./type";

function CareTemplate({ totalMetricData, isLoading }: ICareTemplateProps) {
  return (
    <>
      <PageTitle title="SetuCare Metrics" fixed />
      <TotalMetricSection
        totalMetricData={totalMetricData}
        isLoading={isLoading}
      />
      <CareTrendMetrics legendTitle="Trending" legendIcon={HiTrendingUp} />
      <CareUserEngagementMetrics legendTitle="User Engagement" legendIcon={MultipleUsersIcon} />
    </>
  );
}

export default CareTemplate;
