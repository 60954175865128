import { TotalMetricSectionProps } from "./type";
import ActivitiesSection from "../ActivitiesSection";
import MetricsIcon from "../../../atoms/Icons/MetricsIcon";

function TotalMetricSection({
  title,
  totalMetricData,
  isLoading = false,
}: TotalMetricSectionProps) {
  return (
    <ActivitiesSection
      data={totalMetricData}
      legendTitle={title || "Total metrics"}
      legendIcon={MetricsIcon}
      isLoading={isLoading}
      alignCenter={true}
    />
  );
}

export default TotalMetricSection;
