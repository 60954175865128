import CartCheckIcon from "../../components/atoms/Icons/CartCheckIcon";
import CropNutritionIcon from "../../components/atoms/Icons/CropNutritionIcon";
import CropProtectionIcon from "../../components/atoms/Icons/CropProtectionIcon";
import EyeIcon from "../../components/atoms/Icons/EyeIcon";
import ProductIcon from "../../components/atoms/Icons/ProductIcon";
import SeedsIcon from "../../components/atoms/Icons/SeedsIcon";
import { ActivityDataType } from "../../shared/type";

const commonCountVariants = ({nameView, namePurchase}: {nameView: string, namePurchase: string}) => [
  {
    name: nameView,
    icon: EyeIcon,
    count: null,
  },
  {
    name: namePurchase,
    icon: CartCheckIcon,
    count: null,
  },
];

export const totalMetrics: ActivityDataType[] = [
  {
    name: "allProducts",
    displayName: "All Products",
    icon: ProductIcon,
    count: null,
    countVariants: commonCountVariants({nameView: "totalView", namePurchase: "totalPurchase"}),
  },
  {
    name: "seedsProducts",
    displayName: "Seeds",
    icon: SeedsIcon,
    count: null,
    countVariants: commonCountVariants({nameView: "seedsView", namePurchase: "seedsPurchase"}),
  },
  {
    name: "cropNutritionProducts",
    displayName: "Crop Nutrition",
    icon: CropNutritionIcon,
    count: null,
    countVariants: commonCountVariants({nameView: "cropNutritionView", namePurchase: "cropNutritionPurchase"}),
  },
  {
    name: "cropProtectionProducts",
    displayName: "Crop Protection",
    icon: CropProtectionIcon,
    count: null,
    countVariants: commonCountVariants({nameView: "cropProtectionView", namePurchase: "cropProtectionPurchase"}),
  },
];
