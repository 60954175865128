import { gql } from "@apollo/client";

export function manageCondition(data: any) {
  return {
    teams: data?.teams?.map((item: any) => item?.id),
    networks: data?.network?.map((item: any) => item?.id),
  };
}

export const GET_TEAM_LIST = gql`
  query TeamsListing(
    $limit: Int
    $offset: Int
    $query: String
    $networks: [Int]
    $teams: [Int]
  ) {
    team: registration_mutual_group_list_with_total(
      limit: $limit
      offset: $offset
      query: $query
      search_attributes: ["name", "address__village"]
      network_ids: $networks
      mutual_group_ids: $teams
    ) {
      total
      records {
        team: mutual_group {
          id
          name
          address {
            id
            name
            village
          }
          region {
            name
          }
        }
        user: base_user_fullname
        farmCount: farm_count
        fieldCount: field_count
        plotCount: plot_count
        plotCropCount: plot_crop_count
        userCount: user_count
      }
    }
  }
`;

export const GET_USER_LIST = gql`
  query UserList(
    $limit: Int
    $offset: Int
    $query: String
    $networks: [Int]
    $teams: [Int]
  ) {
    user: my_network_users_with_total(
      limit: $limit
      offset: $offset
      query: $query
      network_ids: $networks
      mutual_group_ids: $teams
      search_attributes: [
        "first_name"
        "last_name"
        "number"
        "mutual_groups__name"
      ]
    ) {
      total
      records {
        id
        fullName: fullname
        farmCount: farm_count
        fieldCount: field_count
        fieldAcreage: field_acreage
        plotCount: plot_count
        plotAcreage: plot_acreage
        plotCropCount: plot_crop_count
        isVerified: is_verified
        contact {
          primaryNumber: primary_number
          secondaryNumber: secondary_number
        }
        docsCount: user_documents_count
        team: teams {
          name
          region
        }
        address
        orgUserTag: organisation_user_tags
        last_active_on_web
        last_active_on_mobile
      }
    }
  }
`;
