import { HiTrendingUp } from "react-icons/hi";
import MetricsIcon from "../../atoms/Icons/MetricsIcon";
import ActivityCard from "../../molecules/Cards/ActivityCard";
import PageTitle from "../../molecules/PageTitle";
import ActivitiesSection from "../../organisms/GenericSections/ActivitiesSection";
import { NotificationTrendMetrics } from "../../organisms/NotificationSections/NotificationTrendMetrics";
import { INotificationsTemplateProps } from "./type";

function NotificationsTemplate({
  totalMetricData,
  isLoading,
}: INotificationsTemplateProps) {
  return (
    <>
      <PageTitle title="Notification Metrics" fixed />
      <ActivitiesSection
        data={totalMetricData}
        legendTitle="Total metrics"
        legendIcon={MetricsIcon}
        isLoading={isLoading}
        alignCenter={true}
      >
        <div
          className="grid grid-cols-1 gap-11 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5"
          data-cy="notification-metrics"
        >
          {totalMetricData.map((item, i) => (
            <ActivityCard
              key={i}
              icon={item.icon}
              displayName={item.displayName}
              count={item.count}
              loading={false}
              alignCenter={true}
              secondary={item.secondary}
            />
          ))}
        </div>
      </ActivitiesSection>
      <NotificationTrendMetrics
        legendTitle="Trending"
        legendIcon={HiTrendingUp}
      />
    </>
  );
}

export default NotificationsTemplate;
