import { SVGProps } from "react";

const CartCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 2.5L4.16667 2.5L4.5 4.16667M5.83333 10.8333L14.1667 10.8333L17.5 4.16667L4.5 4.16667M5.83333 10.8333L4.5 4.16667M5.83333 10.8333L3.92259 12.7441C3.39762 13.269 3.76942 14.1667 4.51184 14.1667L14.1667 14.1667M14.1667 14.1667C13.2462 14.1667 12.5 14.9129 12.5 15.8333C12.5 16.7538 13.2462 17.5 14.1667 17.5C15.0871 17.5 15.8333 16.7538 15.8333 15.8333C15.8333 14.9129 15.0871 14.1667 14.1667 14.1667ZM7.5 15.8333C7.5 16.7538 6.75381 17.5 5.83333 17.5C4.91286 17.5 4.16667 16.7538 4.16667 15.8333C4.16667 14.9129 4.91286 14.1667 5.83333 14.1667C6.75381 14.1667 7.5 14.9129 7.5 15.8333Z"
      stroke="#F6821F"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0214 6.71967L13.375 7.07322L13.7286 6.71967L15.7866 4.66161C15.8354 4.6128 15.9146 4.6128 15.9634 4.66161L16.3169 4.30806L15.9634 4.66161C16.0122 4.71043 16.0122 4.78957 15.9634 4.83839L13.4634 7.33839C13.4146 7.3872 13.3354 7.3872 13.2866 7.33839L12.0366 6.08839C11.9878 6.03957 11.9878 5.96043 12.0366 5.91161C12.0854 5.8628 12.1646 5.8628 12.2134 5.91161L13.0214 6.71967ZM14 11.5C17.0376 11.5 19.5 9.03757 19.5 6C19.5 2.96243 17.0376 0.500001 14 0.5C10.9624 0.5 8.5 2.96243 8.5 6C8.5 9.03757 10.9624 11.5 14 11.5Z"
      fill="#1AB759"
      stroke="#EBF4F8"
    />
  </svg>
);

export default CartCheckIcon;
