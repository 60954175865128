import { SVGProps } from "react";

const UserEditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 10C13.1302 10 14.8571 8.20914 14.8571 6C14.8571 3.79086 13.1302 2 11 2C8.86976 2 7.14286 3.79086 7.14286 6C7.14286 8.20914 8.86976 10 11 10Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M2 22C2 16.8453 6.02944 12.6667 11 12.6667C15.9706 12.6667 20 16.8453 20 22H2Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M20.2578 5.27088C20.6563 4.85762 21.3024 4.85762 21.7009 5.27088C22.0994 5.68413 22.0994 6.35415 21.7009 6.7674L21.2964 7.18692L19.8533 5.6904L20.2578 5.27088Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M19.1318 6.43866L14.8569 10.8718V12.3683H16.3L20.5748 7.93518L19.1318 6.43866Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default UserEditIcon;
