import { useLocalStorage } from "@mantine/hooks";
import { useEffect } from "react";
import { Group } from "../Group";
import { BiChevronDown } from "react-icons/bi";
import Typography from "../Typography";
import { Card } from "../Card";
import { Checkbox } from "../Input/MantineCheckbox";
import { MantineButton } from "../MantineButton";
import { HiCheck } from "react-icons/hi";

export function MapTools({
  map,
  show,
  moveToSelected,
  openBulkAudit,
}: {
  map?: google.maps.Map;
  show?: {
    assetToggle?: boolean;
    moveToSelected?: boolean;
    bulkFSQC?: boolean;
  };
  moveToSelected: () => void;
  openBulkAudit: () => void;
}) {
  const [mapConfig, setMapConfig] = useLocalStorage({
    key: "mapConfig",
    defaultValue: { hidePopovers: false, hidePlots: false, hideTools: false },
  });

  useEffect(() => {
    if (show?.moveToSelected) moveToSelected();
  }, [show?.moveToSelected]);

  if (!show) return <></>;

  return (
    <Card style={{ position: "absolute", top: 70, right: 10, padding: 8 }}>
      <Group
        align="center"
        position="apart"
        style={{ cursor: "pointer" }}
        onClick={() =>
          setMapConfig((prev) => ({ ...prev, hideTools: !prev.hideTools }))
        }
      >
        <Typography variant="subtitle1">Map Tools</Typography>
        <BiChevronDown size={28} />
      </Group>
      {!mapConfig.hideTools ? (
        <Group direction="column" spacing={8} mt={8}>
          {show?.assetToggle && (
            <>
              <Checkbox
                label="Show Popovers"
                checked={!mapConfig.hidePopovers}
                onChange={(e) =>
                  setMapConfig((prev) => ({
                    ...prev,
                    hidePopovers: !e.target.checked,
                  }))
                }
              />
              <Checkbox
                label="Show Plots"
                checked={!mapConfig.hidePlots}
                onChange={(e) => {
                  setMapConfig((prev) => ({
                    ...prev,
                    hidePlots: !e.target.checked,
                  }));
                  if (map) google.maps.event.trigger(map, "zoom_changed");
                }}
              />
            </>
          )}
          {show?.bulkFSQC && (
            <MantineButton
              onClick={openBulkAudit}
              variant="outline"
              leftIcon={<HiCheck size={20} />}
            >
              Bulk Check
            </MantineButton>
          )}
          {show?.moveToSelected && (
            <MantineButton onClick={moveToSelected} variant="outline">
              Move to selected
            </MantineButton>
          )}
        </Group>
      ) : (
        <></>
      )}
    </Card>
  );
}
