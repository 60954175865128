import { gql } from "@apollo/client";

export const GET_SETUP_SERIES = gql`
  query Series(
    $limit: Int!
    $offset: Int!
    $order: [JSONScalar]
    $search: String
  ) {
    series: series_list(
      limit: $limit
      offset: $offset
      order: $order
      search: $search
    ) {
      count
      records {
        id
        name
        order
        is_active
        created_at
        favorites: total_favorites
        likes: total_likes
        views: total_views
        articles: lecture_count
        subscription_package: package {
          id
          name
        }
      }
    }
  }
`;
