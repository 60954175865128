import { IDuration } from "../interfaces/IDuration";

export const durationToTime = ({ minutes, seconds }: IDuration) => {
  let result = Number(minutes) * 60 + Number(seconds);
  return result;
};

export const timeToDuration = (time: number): IDuration => {
  let result = {
    hours: Math.floor(time / 3600),
    minutes: Math.floor((time % 3600) / 60),
    seconds: Math.floor((time % 3600) % 60),
  };
  return result;
};

export const appendPlural = (num: number) => (num > 1 ? "s " : " ");

export const durationDisplay = ({ hours, minutes, seconds }: IDuration) => {
  let result = "";
  if (hours) result = hours + " hour" + appendPlural(hours);
  if (minutes) result += minutes + " minute" + appendPlural(minutes);
  if (seconds) result += seconds + " second" + appendPlural(seconds);
  return result;
};
