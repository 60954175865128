import { useMutation, useQuery } from "@apollo/client";
import { Dispatch, useContext, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AssetContext } from "../../../lib/contexts/AssetContext";
import { useUser } from "../../../lib/contexts/usercontext";
import useDocumentPreferences from "../../../lib/hooks/useDocumentPreferences";
import { ALLOW_QC_CHECK_DOMAIN } from "../../../lib/utils/constants";
import { capitalize } from "../../../lib/utils/stringFormat";
import { Divider } from "../../atoms/Divider";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { MantineButton } from "../../atoms/MantineButton";
import { Modal } from "../../atoms/Modal";
import { Stepper } from "../../atoms/Stepper";
import Typography from "../../atoms/Typography";
import { StatusBlock } from "../../molecules/Cards/ActivityCard/style";
import { ApprovalSection } from "../../organisms/TeamDetailModalSections/ApprovalSection";
import { RemarkSection } from "../../organisms/TeamDetailModalSections/RemarkSection";
import { sidebarTypes } from "../TeamMapView/data";
import {
  normalizeAndStoreData,
  sidebarDetailQueries,
  sidebarDetailSteps,
  STEPS,
  verifyAuditMutation,
} from "./data";
import {
  UPDATE_ADDRESS,
  UPDATE_AGRONOMIST,
  UPDATE_ASSOCIATION,
  UPDATE_PLOT_ARCHIVE,
  UPDATE_PLOT_CROP_ARCHIVE,
  UPDATE_TEAM_OWNER,
  VERIFY_AUDIT,
  VERIFY_DOCUMENT,
} from "./query";
import { initialState, teamDetailReducer } from "./teamDetailReducer";
import { Actions, DetailTypes, TeamState } from "./type";
import { RestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";

export function TeamsDetailModal({
  detail,
  detailType,
}: {
  detail?: string | null;
  detailType?: DetailTypes | null;
}) {
  if (!detail || !detailType) return <></>;

  const [showLoader, setShowLoader] = useState(false);
  const { asset, setAsset } = useContext(AssetContext);

  const user = useUser();

  const [state, dispatch]: [TeamState, Dispatch<Actions>] = useReducer(
    teamDetailReducer,
    initialState
  );

  const [remarkModal, setRemarkModal] = useState<{
    show: boolean;
    type: "document" | "audit";
    id: number | null;
  }>({
    show: false,
    type: "document",
    id: null,
  });

  const [approvalModal, setApprovalModal] = useState<{
    show: boolean;
    type: "document" | "audit";
    id: number | null;
  }>({
    show: false,
    type: "document",
    id: null,
  });

  const [params, setParams] = useSearchParams();

  const { data, loading, error, refetch } = useQuery(
    sidebarDetailQueries[detailType],
    {
      variables: {
        id: detail,
      },
      skip: !detail,
      fetchPolicy: "network-only",
    }
  );

  const [update, { data: updatedData }] = useMutation(
    sidebarDetailSteps[detailType].updateQuery
  );

  const [verifyDocument] = useMutation(VERIFY_DOCUMENT);

  const [verifyAudit] = useMutation(verifyAuditMutation[detailType]);

  const [updateAddress] = useMutation(UPDATE_ADDRESS);

  const [updateTeamOwner] = useMutation(UPDATE_TEAM_OWNER);

  const [updateArchivedValuePlot] = useMutation(UPDATE_PLOT_ARCHIVE);

  const [updateArchivedValuePlotCrop] = useMutation(UPDATE_PLOT_CROP_ARCHIVE);

  const [updateAssociation] = useMutation(
    UPDATE_ASSOCIATION({
      isUpdate: state.crop.detail.associationLinkId !== (null || undefined),
    })
  );

  const [updateAgronomist] = useMutation(
    UPDATE_AGRONOMIST({
      isUpdate: state.crop.detail.agronomistLinkId !== (null || undefined),
    })
  );

  const [active, setActive] = useState(0);

  const {
    documentKinds,
    loading: preferencesLoading,
    refetch: refetchDocumentPreferences,
  } = useDocumentPreferences({
    entityId: (state as any)[detailType].id,
    teamId: (state as any)[detailType].detail.teamId,
    type: detailType,
  });

  const updateArchiveValue: { [key: string]: any } = {
    plot: updateArchivedValuePlot,
    crop: updateArchivedValuePlotCrop,
  };

  const allowQCPassCheck =
    !["team", "user"].includes(detailType) &&
    user.state.me?.email.includes(ALLOW_QC_CHECK_DOMAIN);

  useEffect(() => {
    if (updatedData) {
      refetch();
    }
  }, [updatedData]);

  if (error) return <></>;

  useEffect(() => {
    if (!loading && data) {
      normalizeAndStoreData(dispatch, data, detailType);
    }
  }, [data, loading]);

  const isApproveRejectVisible = () => approvalModal.show || remarkModal.show;

  const handleCloseModal = () => {
    if (isApproveRejectVisible()) return;
    params.delete("detail");
    params.delete("detailType");
    setParams(params);
    let coords =
      detailType === "farm"
        ? data[detailType]?.address?.location?.coordinates
        : detailType === "crop"
        ? data[detailType]?.plot.area_catered?.coordinates
        : data[detailType]?.area_catered?.coordinates;

    setAsset(() => ({
      id: data[detailType]?.id,
      type: detailType,
      coords,
      focus: true,
    }));
  };

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));

  const handleClickDocApprove = (id: number) => {
    setShowLoader(true);
    handleVerifyDocument(true, "", id)
      .then(() => {
        setShowLoader(false);
        setApprovalModal({
          show: true,
          type: "document",
          id,
        });
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleClickAuditApprove = (id: number) => {
    setShowLoader(true);
    handleVerifyAudit(true, "", id)
      .then(() => {
        setShowLoader(false);
        refetch();
        setApprovalModal({
          show: true,
          type: "audit",
          id,
        });
      })
      .catch(() => {
        setShowLoader(false);
      });
  };

  const handleClickDocReject = (id: number) => {
    setRemarkModal({
      show: true,
      type: "document",
      id,
    });
  };
  const handleClickAuditReject = (id: number) => {
    setRemarkModal({
      show: true,
      type: "audit",
      id,
    });
  };

  const handleUpdateAddress = () =>
    updateAddress({
      variables: {
        address:
          sidebarDetailSteps[detailType].address.addAddressVariables(state),
      },
    });

  const handleUpdateData = async () => {
    const updateData = (variables: any) => {
      toast
        .promise(update({ variables }), {
          pending: `Saving ${detailType}`,
          success: `${detailType} Saved`,
          error: `Couldn't Save ${detailType}`,
        })
        .then(() => {
          sidebarDetailSteps[detailType].resetUpdatedFields({
            state,
            dispatch,
            stepName: STEPS[active].toLowerCase(),
            type: detailType,
          });
          refetch();
        });
    };
    if (STEPS[active] === "Address") {
      toast
        .promise(handleUpdateAddress(), {
          pending: `Saving ${detailType}`,
          success: `${detailType} Saved`,
          error: `Couldn't Save ${detailType}`,
        })
        .then(() => {
          sidebarDetailSteps[detailType].resetUpdatedFields({
            state,
            dispatch,
            stepName: STEPS[active].toLowerCase(),
            type: detailType,
          });
          nextStep();
        });
      refetch();
    } else {
      const variables = sidebarDetailSteps[detailType].updateVariables(state);
      try {
        if (
          detailType === "team" &&
          variables.updatedFields.includes("ownerId")
        ) {
          await updateTeamOwner({
            variables: {
              mutualGroupId: variables.teamId,
              oldOwnerId: data?.team?.farmer?.find(
                (farmer: any) => farmer?.mutual_group_role?.codename === "ROOT"
              )?.user?.id,
              newOwnerId: variables.ownerId,
            },
          });
        }
        if (
          ["plot", "crop"].includes(detailType) &&
          variables.updatedFields.includes("isArchived")
        ) {
          await updateArchiveValue[detailType]({
            variables: {
              id: variables.id,
              isArchived: variables.isArchived,
            },
          });
        }
        if (detailType === "crop") {
          if (variables.updatedFields.includes("associationId")) {
            await updateAssociation({
              variables: {
                id: variables.associationLinkId,
                networkId: variables.networkId,
                plotCropId: variables.id,
              },
            });
          }
          if (variables.updatedFields.includes("agronomistId")) {
            await updateAgronomist({
              variables: {
                id: variables.agronomistLinkId,
                agentId: variables.agronomistId,
                plotCropId: variables.id,
              },
            });
          }
        }
        updateData({
          ...variables,
          ...(!["team", "user"].includes(detailType)
            ? { qualityChecked: data?.[detailType]?.quality_checked }
            : {}),
        });
      } catch (error) {
        toast.error(`Couldn't Save ${detailType}`);
      }
    }
  };

  const getModalTitle = () => {
    if (remarkModal.show || approvalModal.show) {
      if (remarkModal.show) return "Remark";
      if (approvalModal.show) return "";
    }
    return (
      <span className="flex items-center gap-8" data-cy="detail-title">
        <span>{capitalize(detailType)}</span>
        <span className="w-2 h-2 bg-gray-300 rounded-full" />
        {data?.[detailType]?.tag && (
          <Typography variant="h6">
            <span className="font-bold">{data?.[detailType]?.tag}</span>
          </Typography>
        )}
        <Typography variant="h6">
          {data?.[detailType]?.name || data?.[detailType]?.full_name}
        </Typography>
        <span className="w-2 h-2 bg-gray-300 rounded-full" />
        {data && (
          <StatusBlock
            success={data?.[detailType]?.verified}
            className="font-bold"
            padding="0.6rem 1rem"
          >
            <span>
              {data[detailType]?.verified ? "VERIFIED" : "UNVERIFIED"}
            </span>
          </StatusBlock>
        )}
        {allowQCPassCheck && (
          <>
            <span className="w-2 h-2 bg-gray-300 rounded-full" />
            <StatusBlock
              success={data?.[detailType]?.quality_checked}
              className={`font-bold ${
                data?.[detailType]?.quality_checked
                  ? "!text-green-main !border-green-main  !bg-green-200"
                  : ""
              }`}
              padding="0.6rem 1rem"
            >
              <span>
                {!data?.[detailType]?.quality_checked && "Not "}FarmSetu QC
                Passed
              </span>
            </StatusBlock>
          </>
        )}
      </span>
    );
  };

  const handleVerifyDocument = (
    isVerified: boolean,
    remark: string,
    id: number | null
  ) => {
    return toast
      .promise(
        verifyDocument({
          variables: {
            documentId: id,
            remarks: remark,
            verifiedById: user.state.me?.id,
            verifiedOn: new Date(),
            isVerified: isVerified,
          },
        }),
        {
          ...(isVerified ? {} : { pending: `Document Rejecting` }),
          ...(isVerified ? {} : { success: `Document Rejected` }),
          error: `Couldn't ${!isVerified ? "reject" : "approve"} document`,
        }
      )
      .then(() => {
        refetchDocumentPreferences();
      });
  };

  const handleVerifyAudit = (
    isVerified: boolean,
    remark: string,
    id: number | null
  ) => {
    return toast.promise(
      verifyAudit({
        variables: {
          id: id,
          remarks: remark,
          verifiedById: user.state.me?.id,
          verifiedOn: new Date(),
          isVerified: isVerified,
        },
      }),
      {
        ...(isVerified ? {} : { pending: `Audit Rejecting` }),
        ...(isVerified ? {} : { success: `Audit Rejected` }),
        error: `Couldn't ${!isVerified ? "reject" : "approve"} audit`,
      }
    );
  };

  const onSubmitRemark = (remark: string) => {
    switch (remarkModal.type) {
      case "document": {
        setShowLoader(true);
        handleVerifyDocument(false, remark, remarkModal.id)
          .then(() => {
            setShowLoader(false);
            setRemarkModal({
              ...remarkModal,
              id: null,
              show: false,
            });
          })
          .catch(() => {
            refetchDocumentPreferences();
            setShowLoader(false);
          });
        break;
      }
      case "audit": {
        setShowLoader(true);
        handleVerifyAudit(false, remark, remarkModal.id)
          .then(() => {
            setShowLoader(false);
            setRemarkModal({
              ...remarkModal,
              id: null,
              show: false,
            });
            refetch();
          })
          .catch(() => {
            setShowLoader(false);
          });
        break;
      }
      default: {
        setRemarkModal({
          ...remarkModal,
          id: null,
          show: false,
        });
        break;
      }
    }
  };

  const onContinue = () => {
    setApprovalModal({
      ...approvalModal,
      show: false,
      id: null,
    });
  };

  const SaveDetail = ({ onSave }: { onSave: () => void }) => (
    <RestrictedContent keyName="network_management" permission="edit">
      <div data-cy="save-detail-section">
        <Divider size={1} mt={16} mb={24} />
        <MantineButton
          mr="xs"
          className="float-right"
          size="md"
          data-cy="datetime-picker-submit"
          onClick={onSave}
          disabled={
            !(sidebarDetailSteps[detailType] as any)[
              STEPS[active].toLowerCase()
            ].showSaveDetail(state)
          }
        >
          SAVE DETAILS
        </MantineButton>
      </div>
    </RestrictedContent>
  );

  return (
    <Modal
      isOpen={!!detail}
      handleModal={handleCloseModal}
      modalTitle={getModalTitle()}
      headerSeparator={!approvalModal.show}
      padding="0 0 2rem 0"
      closeable={!isApproveRejectVisible()}
      width={`min(${isApproveRejectVisible() ? "80" : "150"}rem, 95vw)`}
      height={`${isApproveRejectVisible() ? "auto" : "95vh"}`}
    >
      {!loading && !showLoader && !preferencesLoading ? (
        <>
          {!remarkModal.show && !approvalModal.show && (
            <Stepper
              active={active}
              onStepClick={setActive}
              breakpoint="sm"
              className="flex flex-col h-full pt-8"
              flex
              data-cy="stepper"
            >
              <Stepper.Step label="Step 1" description={STEPS[0]}>
                <section className="flex-1 flex flex-col">
                  <Divider size={1} mt={14} mb={24} />
                  <div className="flex-grow">
                    {sidebarDetailSteps[detailType].details.component({
                      state,
                      dispatch,
                      type: detailType,
                    })}
                  </div>
                  <SaveDetail onSave={handleUpdateData} />
                </section>
              </Stepper.Step>
              <Stepper.Step label="Step 2" description={STEPS[1]}>
                <Divider size={1} mt={14} mb={24} />
                <div className="flex-grow">
                  {sidebarDetailSteps[detailType].address.component({
                    data: data?.[detailType],
                    addData: data,
                    state,
                    dispatch,
                    type: detailType,
                  })}
                </div>
                {!["field", "plot", "crop"].includes(detailType) && (
                  <SaveDetail onSave={handleUpdateData} />
                )}
              </Stepper.Step>
              {documentKinds[0] && (
                <Stepper.Step label="Step 3" description={STEPS[2]}>
                  <Divider size={1} mt={14} mb={24} />
                  <div className="flex-grow">
                    {sidebarDetailSteps[detailType].documents({
                      state,
                      dispatch,
                      handleClickApprove: handleClickDocApprove,
                      handleClickReject: handleClickDocReject,
                      type: detailType,
                      loading: preferencesLoading,
                      documentKinds,
                    })}
                  </div>
                </Stepper.Step>
              )}
              <Stepper.Step
                label={documentKinds[0] ? "Step 4" : "Step 3"}
                description={STEPS[3]}
              >
                <Divider size={1} mt={14} mb={24} />
                <div className="flex-grow">
                  {sidebarDetailSteps[detailType].audit({
                    state,
                    dispatch,
                    data: data?.[detailType],
                    handleClickApprove: handleClickAuditApprove,
                    handleClickReject: handleClickAuditReject,
                    type: detailType,
                    loading: preferencesLoading,
                    documentKinds,
                    updateData: update,
                    refetch,
                    allowQCPassCheck,
                  })}
                </div>
              </Stepper.Step>
              <Stepper.Completed>
                Completed, click back button to get to previous step
              </Stepper.Completed>
            </Stepper>
          )}
          <RestrictedContent keyName="network_management" permission="edit">
            {remarkModal.show && (
              <RemarkSection
                onSubmit={onSubmitRemark}
                onCancel={() => {
                  setRemarkModal({
                    ...remarkModal,
                    id: null,
                    show: false,
                  });
                }}
              />
            )}

            {approvalModal.show && (
              <ApprovalSection
                entity={detailType}
                type={approvalModal.type}
                onContinue={onContinue}
              />
            )}
          </RestrictedContent>
        </>
      ) : (
        <LogoLoader width={100} margin="10% auto" />
      )}
    </Modal>
  );
}
