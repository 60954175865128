import styled, { css } from "styled-components";

export const RichTextEditorWrapper = styled.div<{ minHeight?: string }>`
  .ql-editor {
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
    img {
      display: inline-block;
    }
    ul,
    ol {
      list-style: revert;
    }
  }
  & .ql-tooltip {
    z-index: 40;
  }
`;
