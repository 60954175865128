export function normalizeProductListing(data: any): any[] {
  return (
    data?.map((item: any) => {
      let product = item.sellable_item?.product;
      return {
        id: item.id,
        name: product?.name,
        image: product?.featured_image.url,
        manufacturer: product?.manufacturing_facility?.manufacturer?.name,
        composition: product?.product_compositions.map((item: any) => ({
          ingredient: item.ingredient?.name,
          content: `${item.content}${item.content_unit.name}`,
        })),
      };
    }) || []
  );
}
