import React, { useState } from "react";
import { MultiSelect } from "../../atoms/MultiSelect";
import {
  NotificationState,
  Region,
} from "../../../pages/CreateNotification/type";
import { useQuery } from "@apollo/client";
import { GET_DISTRICTS, GET_STATES, GET_TALUKAS, GET_VILLAGES } from "./query";

interface Props {
  state: NotificationState;
  handleChangeValue: (key: keyof NotificationState, value: string) => void;
}

const VisibilityDetails: React.FunctionComponent<Props> = ({
  state,
  handleChangeValue,
}) => {
  const requiredValue = () => {
    return state.selectedState[0] ||
      state.selectedDistrict[0] ||
      state.selectedTaluka[0] ||
      state.selectedVillage[0]
      ? ""
      : "*";
  };
  const { data: states } = useQuery(GET_STATES);

  const [regions, setRegions] = useState({
    talukas: [],
    villages: [],
  });

  const getStateVariable = (selectedRegion: Region[], region: Region[]) => {
    if (selectedRegion[0]) {
      return { _in: selectedRegion.map((region) => region.id) };
    } else if (region && region[0]) {
      return { _in: region.map((region) => region.id) };
    } else {
      return {};
    }
  };

  /** Get district here */
  const { data: districts } = useQuery(GET_DISTRICTS, {
    variables: {
      id: getStateVariable(state.selectedState, []),
    },
  });
  return (
    <section
      className="grid grid-cols-1 md:grid-cols-2 gap-6"
      data-cy="notification-visibility-details-section"
    >
      <MultiSelect
        data-test-id="state"
        limit={100}
        label={`State(s)${requiredValue()}`}
        values={state.selectedState}
        listData={states?.region || []}
        accessor={{ name: "name", value: "codename" }}
        placeholder="Select states"
        onSelect={(item) => handleChangeValue("selectedState", item)}
        searchable
        clearable
        size="lg"
        className="flex-1"
      />
      <MultiSelect
        data-test-id="district"
        limit={100}
        label={`District(s)${requiredValue()}`}
        values={state.selectedDistrict}
        listData={districts?.region || []}
        accessor={{ name: "name", value: "codename" }}
        placeholder="Select districts"
        onSelect={(item) => handleChangeValue("selectedDistrict", item)}
        searchable
        clearable
        size="lg"
        className="flex-1"
      />
      <MultiSelect
        data-test-id="taluka"
        limit={100}
        label={`Taluka(s)${requiredValue()}`}
        values={state.selectedTaluka}
        accessor={{ name: "name", value: "codename" }}
        placeholder="Select talukas"
        onSelect={(item) => handleChangeValue("selectedTaluka", item)}
        listData={regions.talukas}
        query={GET_TALUKAS}
        fetchedData={(data: any) => {
          setRegions((prev) => ({ ...prev, talukas: data?.region || [] }));
        }}
        variables={{
          id: getStateVariable(state.selectedDistrict, districts?.region),
        }}
        searchable
        clearable
        size="lg"
        className="flex-1"
      />
      <MultiSelect
        data-test-id="villages"
        limit={100}
        label={`Villages(s)${requiredValue()}`}
        values={state.selectedVillage}
        accessor={{ name: "name", value: "codename" }}
        placeholder="Select villages"
        onSelect={(item) => handleChangeValue("selectedVillage", item)}
        listData={regions.villages}
        query={GET_VILLAGES}
        fetchedData={(data: any) => {
          setRegions((prev) => ({ ...prev, villages: data?.region || [] }));
        }}
        variables={{
          id: getStateVariable(state.selectedTaluka, regions.talukas),
        }}
        searchable
        clearable
        size="lg"
        className="flex-1"
      />
    </section>
  );
};

export default VisibilityDetails;
