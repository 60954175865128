import axios from "axios";

export async function getOrganizationPreferences({
  userId,
}: {
  userId: string | number;
}) {
  return axios.get(
    `${
      process.env.REACT_APP_KITE_URL || ""
    }/rest/user/${userId}/organisation/preferences`
  );
}

export async function getDocumentByContentType({
  entity_id,
  mutual_group_id,
  content_type_id,
}: {
  entity_id: string | number;
  mutual_group_id: string | number;
  content_type_id: string | number;
}) {
  var config = {
    method: "get",
    url: `${
      process.env.REACT_APP_KITE_URL || ""
    }/rest/document-by-content-type?mutual_group_id=${mutual_group_id}&content_type_id=${content_type_id}&entity_id=${entity_id}`,
    headers: {
      Authorization: process.env.REACT_APP_KITE_AUTH_TOKEN || "",
    },
  };
  return axios(config);
}
