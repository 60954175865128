import styled from "styled-components";

export const TopListRow = styled.div<{ align?: string }>`
  display: flex;
  align-items: ${({ align }) => align || "flex-start"};
  width: 100%;
  padding-right: 1.2rem;
  color: ${({ theme }) => theme.colors.primary.main};
  & > :first-child {
    flex: 0 0 11rem;
  }
  & > :nth-child(2) {
    flex: 1;
  }
`;

export const BulletList = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.8rem;
  font-size: 1.4rem;

  & > div {
    position: relative;
    padding-right: 2rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;

    &::after {
      content: " ";
      position: absolute;
      background-color: ${({ theme }) => theme.colors.border};
      padding: 3px;
      border-radius: 100%;
      right: 0;
    }

    &:last-of-type::after {
      display: none;
    }
  }
`;

export const ArticleMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  max-height: 60rem;
`;
