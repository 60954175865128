import { SVGProps } from "react";

export const EstimatedDateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8181 4.12109H15.303V5.53991C15.303 5.65182 15.2182 5.7426 15.1136 5.7426H13.2197C13.1151 5.7426 13.0303 5.65182 13.0303 5.53991V4.12109H6.96973V5.53991C6.96973 5.65182 6.88491 5.7426 6.78034 5.7426H4.8864C4.78183 5.7426 4.69701 5.65182 4.69701 5.53991V4.12109H3.18188C2.34639 4.12109 1.66675 4.84846 1.66675 5.7426V6.95872H18.3332V5.7426C18.3332 4.84846 17.6536 4.12109 16.8181 4.12109H16.8181Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M3.41797 14.945C3.64443 15.3883 3.98087 15.6909 4.37806 15.8046C4.77442 15.9183 5.20977 15.8353 5.61112 15.5737C5.38466 15.1304 5.04822 14.8277 4.65117 14.7137C4.25505 14.6001 3.81945 14.6832 3.41797 14.945V14.945Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M6.5913 3.91881V3.31076C6.5913 2.8637 6.25148 2.5 5.83374 2.5C5.41599 2.5 5.07617 2.86368 5.07617 3.31076V5.33766H6.5913V3.91881Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M3.63706 9.65251C3.78127 9.75183 3.94807 9.80207 4.13285 9.80207C4.20495 9.80207 4.27868 9.79439 4.35335 9.7789C4.28936 9.49716 4.14895 9.27173 3.94858 9.13408C3.80423 9.03476 3.63743 8.98438 3.45279 8.98438C3.38082 8.98438 3.3071 8.99205 3.23242 9.00754C3.29641 9.28928 3.43683 9.51471 3.63706 9.65251L3.63706 9.65251Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M11.7046 7.36719H7.34863V17.5017H16.793C17.6424 17.5017 18.3334 16.7622 18.3334 15.8532V7.36719H11.7046ZM17.1968 8.786V16.083C17.1968 16.1949 17.112 16.2857 17.0074 16.2857H8.67437C8.5698 16.2857 8.48498 16.1949 8.48498 16.083V8.786C8.48498 8.67409 8.5698 8.58331 8.67437 8.58331H17.0074C17.112 8.58331 17.1968 8.67408 17.1968 8.786Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M14.9246 3.91881V3.31076C14.9246 2.8637 14.5847 2.5 14.167 2.5C13.7492 2.5 13.4094 2.86368 13.4094 3.31076V5.33766H14.9246V3.91881Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M1.66675 15.8802C1.66675 16.7744 2.34639 17.5017 3.18188 17.5017H6.96988V7.36719H1.66709L1.66675 15.8802ZM6.02756 15.5605C6.06503 15.6487 6.03892 15.753 5.96533 15.8093C5.57898 16.1049 5.15419 16.2611 4.73698 16.2611C4.58222 16.2611 4.42841 16.2394 4.27988 16.1968C3.72671 16.0384 3.27272 15.5989 3.00107 14.9594C2.9636 14.8712 2.98971 14.767 3.06344 14.7107C3.44979 14.4149 3.87472 14.2587 4.29206 14.2587C4.44668 14.2587 4.6005 14.2803 4.74903 14.323C5.30192 14.4815 5.75604 14.9211 6.02755 15.5605H6.02756ZM2.95764 8.66977C3.12417 8.61244 3.2907 8.58348 3.45277 8.58348C3.71129 8.58348 3.94707 8.65558 4.1535 8.79761C4.49035 9.02925 4.70519 9.42405 4.75834 9.90906C4.7689 10.0052 4.71438 10.096 4.62821 10.1256C4.46168 10.183 4.29501 10.2119 4.1328 10.2119C3.87414 10.2119 3.6385 10.14 3.4322 9.99781C3.09549 9.76602 2.88066 9.37122 2.8275 8.88636C2.81695 8.79023 2.87147 8.69945 2.95764 8.66977ZM2.80572 12.7908C2.88053 12.309 3.11267 11.9256 3.45953 11.7114C3.65258 11.5922 3.86822 11.5317 4.10063 11.5317C4.28421 11.5317 4.47237 11.5698 4.65976 11.645C4.74444 11.6791 4.7949 11.7726 4.78002 11.868C4.70521 12.3498 4.47307 12.7331 4.12621 12.9472C3.93331 13.0665 3.71753 13.127 3.48484 13.127C3.30126 13.127 3.11324 13.0889 2.92599 13.0136C2.8413 12.9799 2.79084 12.8864 2.80572 12.7908V12.7908Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M3.93662 12.5967C4.14292 12.4693 4.29322 12.2512 4.36979 11.9731C4.27848 11.9494 4.18838 11.9375 4.10045 11.9375C3.93527 11.9375 3.78335 11.9796 3.64861 12.0629C3.44231 12.1903 3.29201 12.4083 3.21558 12.6866C3.30675 12.7103 3.39685 12.7222 3.48465 12.7222C3.64996 12.7222 3.80201 12.6799 3.93662 12.5967L3.93662 12.5967Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M15.6423 8.125H9.71387C8.88544 8.125 8.21387 8.79657 8.21387 9.625V15.0621C8.21387 15.8906 8.88544 16.5621 9.71387 16.5621H15.6423C16.4708 16.5621 17.1423 15.8906 17.1423 15.0621V9.625C17.1423 8.79657 16.4708 8.125 15.6423 8.125Z"
      fill={props.color || "#005777"}
      stroke={props.color || "#005777"}
      strokeWidth="1.3"
    />
    <path
      d="M12.3772 9.18511C12.3772 9.00705 12.2285 8.85135 12.0588 8.85135C11.6555 8.82915 10.9338 8.8959 10.4879 9.36318C10.0421 9.83061 9.99976 10.5872 9.99976 11.0101C9.99976 11.1882 10.1484 11.3439 10.3181 11.3439C10.7215 11.3661 11.4432 11.2993 11.889 10.832C12.3347 10.3646 12.3983 9.60796 12.3772 9.18511Z"
      fill="white"
    />
    <path
      d="M15.3943 9.29171C15.1281 9.16543 14.6169 9.03944 14.1613 9.36154C13.7055 9.68378 13.4284 10.383 13.2896 10.7799C13.2311 10.9471 13.2808 11.1321 13.3958 11.1764C13.662 11.3027 14.1733 11.4287 14.6289 11.1066C15.0847 10.7844 15.3618 10.0852 15.5006 9.68824C15.5447 9.51555 15.5022 9.35695 15.3943 9.29171Z"
      fill="white"
    />
    <path
      d="M13.252 12.6013C12.8481 12.5791 12.1253 12.6459 11.6789 13.1132C11.2323 13.5806 11.1899 14.3372 11.1899 14.7601C11.1899 14.9382 11.3388 15.0939 11.5088 15.0939C11.9127 15.1161 12.6355 15.0493 13.082 14.582C13.5285 14.1146 13.5709 13.358 13.5709 12.9351C13.5709 12.7348 13.422 12.6013 13.252 12.6013Z"
      fill="white"
    />
    <rect
      x="1.66675"
      y="7.5"
      width="4.76185"
      height="8.74963"
      fill={props.color || "#005777"}
    />
  </svg>
);
