interface IProps {
  date?: Date | string;
  addDays?: number;
}
export const getDate = ({ date, addDays }: IProps = {}) => {
  if (date === "") return undefined;
  let newDate = date ? new Date(date) : new Date();
  addDays && newDate.setDate(newDate.getDate() + addDays);
  return new Intl.DateTimeFormat("en-CA").format(newDate);
};
