import { SVGProps } from "react";

const AreaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.10813 3.18188V10.124V13.8265C4.02339 14.5207 4.20981 15.9092 5.63334 15.9092C7.05688 15.9092 9.10741 15.9092 9.95474 15.9092H16.8182"
      stroke={props.color || "#005777"}
    />
    <path
      d="M16.8072 10.9092L16.8072 6.44637V4.06621C16.8617 3.61992 16.7418 2.72736 15.8267 2.72736C14.9116 2.72736 13.5934 2.72736 13.0487 2.72736L8.63642 2.72736"
      stroke={props.color || "#005777"}
      strokeLinecap="round"
    />
    <path
      d="M1.70457 5.11352L4.09093 2.72716L6.47729 5.11353"
      stroke={props.color || "#005777"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8864 13.5227L17.2727 15.909L14.8864 18.2954"
      stroke={props.color || "#005777"}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.10641 11.4863C7.90641 11.4863 7.80641 11.3843 7.80641 11.1803V8.78034C7.80641 8.57634 7.90441 8.47434 8.10041 8.47434C8.29641 8.47434 8.39441 8.57634 8.39441 8.78034V8.99034C8.48241 8.82234 8.60441 8.69234 8.76041 8.60034C8.91641 8.50834 9.09841 8.46234 9.30641 8.46234C9.75441 8.46234 10.0444 8.65834 10.1764 9.05034C10.2684 8.86634 10.4004 8.72234 10.5724 8.61834C10.7444 8.51434 10.9404 8.46234 11.1604 8.46234C11.8164 8.46234 12.1444 8.85434 12.1444 9.63834V11.1803C12.1444 11.3843 12.0424 11.4863 11.8384 11.4863C11.6384 11.4863 11.5384 11.3843 11.5384 11.1803V9.66834C11.5384 9.42034 11.4944 9.23834 11.4064 9.12234C11.3224 9.00634 11.1824 8.94834 10.9864 8.94834C10.7664 8.94834 10.5924 9.02634 10.4644 9.18234C10.3364 9.33434 10.2724 9.54234 10.2724 9.80634V11.1803C10.2724 11.3843 10.1724 11.4863 9.97241 11.4863C9.76841 11.4863 9.66641 11.3843 9.66641 11.1803V9.66834C9.66641 9.42034 9.62441 9.23834 9.54041 9.12234C9.45641 9.00634 9.31641 8.94834 9.12041 8.94834C8.90041 8.94834 8.72641 9.02634 8.59841 9.18234C8.47441 9.33434 8.41241 9.54234 8.41241 9.80634V11.1803C8.41241 11.3843 8.31041 11.4863 8.10641 11.4863Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M13.1636 8.22217C13.0783 8.22217 13.0156 8.2035 12.9756 8.16617C12.9383 8.12617 12.9196 8.06883 12.9196 7.99417C12.9196 7.94883 12.9316 7.90483 12.9556 7.86217C12.9796 7.8195 13.0143 7.77417 13.0596 7.72617L13.8636 6.87017C13.9863 6.74217 14.0743 6.62617 14.1276 6.52217C14.1809 6.4155 14.2076 6.30883 14.2076 6.20217C14.2076 5.93017 14.0436 5.79417 13.7156 5.79417C13.6249 5.79417 13.5343 5.8075 13.4436 5.83417C13.3529 5.85817 13.2609 5.90083 13.1676 5.96217C13.1089 6.00217 13.0543 6.01417 13.0036 5.99817C12.9529 5.98217 12.9143 5.95017 12.8876 5.90217C12.8609 5.8515 12.8516 5.79817 12.8596 5.74217C12.8703 5.68617 12.9049 5.63817 12.9636 5.59817C13.0783 5.52083 13.2063 5.46217 13.3476 5.42217C13.4916 5.38217 13.6356 5.36217 13.7796 5.36217C14.0809 5.36217 14.3116 5.43017 14.4716 5.56617C14.6343 5.70217 14.7156 5.89683 14.7156 6.15017C14.7156 6.32083 14.6743 6.48483 14.5916 6.64217C14.5116 6.7995 14.3849 6.96883 14.2116 7.15017L13.5916 7.79817H14.6356C14.7823 7.79817 14.8556 7.8675 14.8556 8.00617C14.8556 8.15017 14.7823 8.22217 14.6356 8.22217H13.1636Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default AreaIcon;
