import { Dispatch } from "react";
import { HiShieldCheck } from "react-icons/hi";
import { fullDate } from "../../../../lib/utils/dateTimeFormat";
import { colors } from "../../../../style/mantine/colors";
import { theme } from "../../../../style/theme";
import { Card, CardBody } from "../../../atoms/Card";
import { Grid } from "../../../atoms/Grid";
import CalendarIcon from "../../../atoms/Icons/Calendar";
import ContentIcon from "../../../atoms/Icons/ContentIcon";
import UserIcon from "../../../atoms/Icons/UserIcon";
import WarningIcon from "../../../atoms/Icons/WarningIcon";
import Image from "../../../atoms/Image";
import SkeletonLoader from "../../../atoms/Loader/SkeletonLoader";
import { MantineButton } from "../../../atoms/MantineButton";
import Typography from "../../../atoms/Typography";
import { sidebarTypes } from "../../../templates/TeamMapView/data";
import {
  Actions,
  DetailTypes,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";
import NoDocument from "../../../../assets/No_docs.png";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import { ImageViewer } from "../../../molecules/ImageViewer";
import useDocumentPreferences from "../../../../lib/hooks/useDocumentPreferences";
import { RestrictedContent } from "../../../molecules/ProtectedRoutes/RestrictedContent";

export interface DocSectionProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
  type: DetailTypes;
  handleClickApprove: (documentId: string) => void;
  handleClickReject: (documentId: string) => void;
  loading?: boolean;
  documentKinds: any[];
}

export function DocSection({
  state,
  dispatch,
  type,
  handleClickApprove,
  handleClickReject,
  loading,
  documentKinds = [],
}: DocSectionProps) {
  return (
    <section className="flex  flex-col  gap-8" data-cy="doc-section">
      <div className="flex justify-between items-center">
        <div
          className="flex justify-start items-center gap-4"
          data-cy="owner-name-section"
        >
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state[type].detail.owner}</Typography>
        </div>
        <div
          className="flex justify-start items-center gap-4"
          data-cy="team-name-section"
        >
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state[type].detail.teamName}</Typography>
        </div>
      </div>
      {loading ? (
        <SkeletonLoader height={300} />
      ) : (
        <>
          {documentKinds[0] ? (
            documentKinds?.map((docKind, index) => (
              <Card
                key={index}
                shadow="none"
                border="solid"
                borderWidth={1}
                borderColor={theme.colors.border}
                data-cy="document-card"
              >
                <CardBody>
                  <Grid className=" p-6">
                    <Grid.Col
                      span={5}
                      className="flex flex-col justify-between items-start  h-60"
                      data-cy="document-info"
                    >
                      <Typography
                        variant="h6"
                        className="flex items-center gap-4"
                      >
                        {docKind?.is_verified ? (
                          <HiShieldCheck
                            color={colors.positive.default}
                            size="1.3em"
                          />
                        ) : (
                          <WarningIcon color={colors.warning.dark} />
                        )}
                        <span className="font-bold">{docKind?.name}</span>
                      </Typography>
                      <Typography
                        variant="body1"
                        className="flex items-center gap-4"
                      >
                        <span className="w-9 flex justify-center">
                          <ContentIcon />
                        </span>
                        <span>{docKind?.id_number}</span>
                      </Typography>
                      <Typography
                        variant="body1"
                        className="flex items-center gap-4"
                      >
                        <span className="w-9 flex justify-center">
                          <CalendarIcon />
                        </span>
                        <span>
                          {docKind.valid_from
                            ? fullDate(docKind.valid_from)
                            : "-"}
                        </span>
                      </Typography>
                    </Grid.Col>
                    <Grid.Col span={7} data-cy="document-media">
                      <section>
                        <Typography variant="body1">Media</Typography>
                        <div className="flex  flex-row flex-wrap justify-start items-center gap-4">
                          {docKind?.attachments?.[0] ? (
                            <ImageViewer
                              images={docKind?.attachments.map((img: any) => ({
                                ...img,
                                src: img.url,
                                alt: "image-" + img.id,
                                index: img.id,
                              }))}
                              showImageIndex={false}
                              containerStyle="flex items-center gap-4"
                              width={150}
                              height={100}
                              imageStyle="h-60 w-80 rounded-md"
                            />
                          ) : (
                            <div>
                              <Typography variant="body2">
                                <span className="font-bold">
                                  No attachment found.
                                </span>
                              </Typography>
                            </div>
                          )}
                        </div>
                      </section>
                    </Grid.Col>
                    {!docKind?.is_verified && docKind.document_id && (
                      <RestrictedContent
                        keyName="network_management"
                        permission="edit"
                      >
                        <div
                          className="flex justify-end gap-4 w-full mt-3"
                          data-cy="action-buttons"
                        >
                          <MantineButton
                            variant="outline"
                            onClick={() =>
                              handleClickReject(docKind.document_id)
                            }
                            size="md"
                          >
                            REJECT
                          </MantineButton>
                          <MantineButton
                            variant="filled"
                            onClick={() =>
                              handleClickApprove(docKind.document_id)
                            }
                            size="md"
                          >
                            APPROVE
                          </MantineButton>
                        </div>
                      </RestrictedContent>
                    )}
                  </Grid>
                </CardBody>
              </Card>
            ))
          ) : (
            <div
              className="flex  flex-col  h-[400px] justify-center items-center gap-3"
              data-cy="no-doc-available"
            >
              <Image src={NoDocument} />
              <Typography variant="body1">
                <span className="font-bold">No documents available!</span>
              </Typography>
              <Typography variant="body1">
                <span className="font-bold">
                  Ask users to add the documents
                </span>
              </Typography>
            </div>
          )}
        </>
      )}
    </section>
  );
}
