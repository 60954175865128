import { SVGProps } from "react";

export default function SchoolIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5145 21.5641C12.0412 21.9391 11.8778 21.8646 11.4857 21.5641V9.9631C11.4857 8.46498 10.8449 7.30149 8.75395 6.38511C9.83331 7.33047 10.373 8.26145 10.5078 9.35232C6.69666 9.30868 5.1115 6.95225 4.90918 4.24701C8.13008 4.4071 10.3222 5.1197 11.4858 6.3997V5.00345C11.4858 1.45449 14.6224 0 19.091 0C19.0068 2.71984 17.5395 5.03248 13.1889 5.09071C13.3576 4.33448 13.8297 3.50537 15.2462 2.22537C13.1889 3.01064 12.5145 3.98525 12.5145 6.16699V21.5641Z"
        fill={props.color || "#005777"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5361 11.2344V21.7358C21.5361 21.7358 18.3667 20.8959 16.0119 21.3227C13.9027 21.7048 12.3505 22.7834 12.0887 22.974V23.0058C12.0887 23.0058 12.0809 23 12.0667 22.9896C12.0529 23 12.0451 23.0058 12.0451 23.0058V22.974C11.7835 22.7834 10.2313 21.7051 8.12264 21.3227C5.76716 20.8956 2.28754 21.7358 2.28754 21.7358V11.2034C2.08406 11.2321 1.86816 11.2628 1.63623 11.2955V22.6087C1.63623 22.6087 5.42852 21.6888 8.00812 22.1567C10.5448 22.6166 12.0775 24.0002 12.0775 24.0002C12.0775 24.0002 13.6101 22.6168 16.1467 22.1567C18.7268 21.6886 22.3635 22.6087 22.3635 22.6087L22.3633 11.3442C22.3633 11.3442 22.0469 11.2938 21.5361 11.2344ZM7.93118 20.2597C9.99402 20.6333 10.835 21.2197 10.835 21.2197V11.0207C10.835 11.0207 9.98555 10.3002 7.91795 9.92557C5.82508 9.54615 3.09132 10.2957 3.09132 10.2957V20.6276C3.09132 20.6276 5.83371 19.8795 7.93118 20.2597ZM20.7311 20.6653V10.3335C20.7311 10.3335 18.2251 9.58377 16.1332 9.96334C14.0656 10.3378 13.1743 11.0207 13.1743 11.0207V21.2197C13.1743 21.2197 14.0565 20.6712 16.1197 20.297C18.2168 19.9174 20.7311 20.6653 20.7311 20.6653Z"
        fill={props.color || "#005777"}
      />
    </svg>
  );
}
