import {
  Button,
  ButtonProps,
  ButtonStylesParams,
  MantineTheme,
} from "@mantine/core";

const styles = (theme: MantineTheme, params: ButtonStylesParams) => ({
  root: {
    ...theme.other.fonts.subtitle2,
    backgroundColor: theme.other.colors.primary.main + "!important",
    ":disabled": {
      color: theme.other.colors.disabled.color + "!important",
      backgroundColor:
        theme.other.colors.disabled.backgroundColor + "!important",
      svg: {
        stroke: theme.other.colors.disabled.color + "!important",
      },
    },
    ...(params.variant === "outline" && {
      color: theme.other.colors.primary.main,
      backgroundColor: theme.other.colors.white,
      border: "1px solid " + theme.other.colors.primary.main,
      ":hover": {
        backgroundColor: "transparent",
      },
    }),
  },
});
type IButtonProps = ButtonProps & React.ComponentPropsWithoutRef<"button">;

export const MantineButton = ({ children, ...props }: IButtonProps) => {
  return (
    <Button styles={styles} {...props}>
      {children}
    </Button>
  );
};
