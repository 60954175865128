import {
  MultiSelect as MantineMultiSelect,
  MantineTheme,
  MultiSelectProps,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { DocumentNode } from "graphql";
import { useEffect, useState } from "react";
import { config } from "../../../config";
import { Loader } from "../Loader/Loader";
import { useLazyQuery } from "@apollo/client";

const styles = (theme: MantineTheme) => ({
  input: {
    border: "1px solid " + theme.other.colors.border,
  },
  searchInput: {
    "::placeholder": {
      color: theme.other.colors.primary["700"],
    },
  },
  label: {
    fontWeight: 700,
    color: theme.other.colors.primary.main,
    marginBottom: theme.other.gaps["4"],
  },
  value: {
    ...theme.other.fonts.body2,
    color: theme.other.colors.primary.main,
    border: "1px solid " + theme.other.colors.primary.main,
    backgroundColor: theme.other.colors.white,
    borderRadius: "50px",
    paddingRight: theme.other.gaps["4"],
    height: "28px",
  },
  defaultValueRemove: {
    color: theme.other.colors.primary.main,
  },
  rightSection: {
    "& svg": {
      color: theme.other.colors.primary.main + "!important",
    },
  },
  item: {
    color: theme.other.colors.primary["700"],
  },
  required: {
    color: theme.other.colors.primary.main,
  },
});
export interface IMultiSelectProps extends Omit<MultiSelectProps, "data"> {
  listData: (string | Record<string, any>)[];
  values?: (string | Record<string, any>)[];
  accessor?: { name: string; value: string };
  onSelect: (value: any) => void;
  query?: DocumentNode;
  variables?: Record<string, any>;
  fetchedData?: (data: any[], values?: any[], key?: string) => void;
}

export const MultiSelect = ({
  listData,
  values,
  accessor,
  onSelect,
  nothingFound,
  rightSection,
  query,
  variables = {},
  fetchedData,
  ...props
}: IMultiSelectProps) => {
  const [search, setSearch] = useState("");
  const [delayedSearch] = useDebouncedValue(search, config.debounceWait);
  const [getResults, results] = query ? useLazyQuery(query) : [() => {}, {}];

  useEffect(() => {
    getResults({
      variables: {
        limit: props.limit || 100,
        search: `%${delayedSearch}%`,
        ...variables,
      },
    });
    if (results.data && fetchedData)
      fetchedData(results.data, values, accessor?.value);
  }, [delayedSearch, results.data, JSON.stringify(variables)]);

  return (
    <MantineMultiSelect
      data-cy="mantine-multiselect"
      styles={styles}
      data={listData.map((item) =>
        typeof item === "object"
          ? {
              label: item[`${accessor?.name}`],
              value: item[`${accessor?.value}`],
              ...item,
            }
          : item
      )}
      value={
        values
          ? Array.isArray(values)
            ? values?.map((item: any) =>
                typeof item === "object" ? item[`${accessor?.value}`] : item
              )
            : [values]
          : []
      }
      onChange={(item) => {
        onSelect(
          listData.filter((elem) =>
            item.includes(
              typeof elem === "object" ? elem[`${accessor?.value}`] : elem
            )
          )
        );
      }}
      onSearchChange={(item) => setSearch(item)}
      nothingFound={nothingFound || "No results found"}
      rightSection={results.loading ? <Loader mx="auto" py={5} /> : null}
      {...props}
    />
  );
};
