import AdviceIcon from "../../components/atoms/Icons/AdviceIcon";
import CareIcon from "../../components/atoms/Icons/CareIcon";
import DraftsIcon from "../../components/atoms/Icons/DraftsIcon";
import SchoolIcon from "../../components/atoms/Icons/SchoolIcon";
import WeatherIcon from "../../components/atoms/Icons/WeatherIcon";
import { ActivityDataType } from "../../shared/type";

export const totalMetrics: ActivityDataType[] = [
  {
    name: "advice",
    displayName: "Advice",
    icon: AdviceIcon,
    count: null,
  },
  {
    name: "school",
    displayName: "School",
    icon: SchoolIcon,
    count: null,
  },
  {
    name: "care",
    displayName: "Care",
    icon: CareIcon,
    count: null,
  },
  {
    name: "weather",
    displayName: "Weather",
    icon: WeatherIcon,
    count: null,
  },
  {
    name: "general",
    displayName: "General",
    icon: DraftsIcon,
    count: null,
  },
];
