import styled from "styled-components";
import Typography from "../Typography";

export const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin: 2.4rem 0;
`;

export const SectionLabel = styled(Typography)`
  margin: 0 1.4rem;
  color: ${({ theme }) => theme.colors.primary["700"]};
`;

export const Line = styled.div`
  border-top: 1px solid #dbe2ea;
  flex: 1;
`;
