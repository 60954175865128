import axios from "axios";

export async function uploadFile({
  token,
  method,
  signal,
  uploadPath,
  formData,
}: {
  token: string;
  method: "post" | "put";
  signal?: any;
  uploadPath?: string;
  formData?: Record<string, any>;
}) {
  const uploadPathName = uploadPath || "/services/uploader";
  const response = await axios[method](
    `${process.env.REACT_APP_KITE_URL}${uploadPathName}` || "",
    formData,
    {
      ...(signal && { signal: signal }),
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
}
