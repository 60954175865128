import React, { useState } from "react";
import { MultiSelect } from "../../atoms/MultiSelect";
import { NotificationState } from "../../../pages/CreateNotification/type";
import { GET_TEAMS, GET_USERS } from "./query";
import { useDebouncedState } from "@mantine/hooks";
import { config } from "../../../config";

interface Props {
  state: NotificationState;
  handleChangeValue: (key: keyof NotificationState, value: string) => void;
}

const NetworkVisibilityDetails: React.FunctionComponent<Props> = ({
  state,
  handleChangeValue,
}) => {
  const [list, setList] = useState({ teams: [], users: [] });
  const { debounceWait } = config;
  const [teamSearch, setTeamSearch] = useDebouncedState("", debounceWait);
  const [userSearch, setUserSearch] = useDebouncedState("", debounceWait);

  const requiredValue = () => {
    return state.selectedUsers[0] || state.selectedTeams[0] ? "" : "*";
  };

  return (
    <section
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      data-cy="notification-visibility-details-section"
    >
      <MultiSelect
        data-test-id="teams"
        limit={100}
        label={`Team(s)${requiredValue()}`}
        onSearchChange={setTeamSearch}
        values={state.selectedTeams}
        listData={list.teams}
        query={GET_TEAMS}
        fetchedData={(data: any) => {
          setList((prev) => ({
            ...prev,
            teams: data?.teams?.records?.map((item: any) => item?.teams) || [],
          }));
        }}
        variables={{
          networks: state.selectedNetworks.map((item) => item.codename),
          search: teamSearch,
        }}
        accessor={{ name: "name", value: "codename" }}
        placeholder="Select teams"
        onSelect={(item) => handleChangeValue("selectedTeams", item)}
        searchable
        clearable
        size="lg"
        className="flex-1"
      />
      <MultiSelect
        data-test-id="users"
        limit={100}
        label={`User(s)${requiredValue()}`}
        onSearchChange={setUserSearch}
        values={state.selectedUsers}
        listData={list.users}
        query={GET_USERS}
        fetchedData={(data: any) => {
          setList((prev) => ({ ...prev, users: data?.users?.records || [] }));
        }}
        variables={{
          networks: state.selectedNetworks.map((item) => item.codename),
          teams: state.selectedTeams.map((item) => item.codename),
          search: userSearch,
        }}
        accessor={{ name: "name", value: "codename" }}
        placeholder="Select users"
        onSelect={(item) => handleChangeValue("selectedUsers", item)}
        searchable
        clearable
        size="lg"
        className="flex-1"
      />
    </section>
  );
};

export default NetworkVisibilityDetails;
