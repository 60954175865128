import { IArticlePreview } from "./types";

export const articleNormalizer = (data: IArticlePreview | undefined) => {
  if (!data) return data;
  return {
    ...data,
    attachment: data.attachments[0],
    subscriptions: data.subscriptions[0],
  };
};
