import { theme } from "../../style/theme";

export const RISK_TYPES: {
  [key: string]: {
    textColor: string;
  };
} = {
  high: {
    textColor: theme.colors.negative.dark,
  },
  low: {
    textColor: theme.colors.positive.dark,
  },
  medium: {
    textColor: theme.colors.warning.dark,
  },
};

export const ALLOW_QC_CHECK_DOMAIN = "farmsetu.co";
export const SIDEBAR_EXPANDED_ITEMS = "sidebarExpandedItems";

export const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_MAP_API_KEY || "";
export const DEFAULT_LOCATION: google.maps.LatLngLiteral = {
  lat: 19.986957473466166,
  lng: 73.78409431140557,
};

export const MAP_CONSTANTS = {
  meterSqToAcre: 0.000247105,
  vertexSnapDistance: 7, // meters
  edgeSnapDistance: 3, // meters
};
