import styled from "styled-components";

export const PageOutletContainer = styled.div`
  display: flex;
  position: relative;
`;

export const RouteContainer = styled.div`
  min-width: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 0 1.6rem;
`;
