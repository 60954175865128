import { INotificationData, INotificationDetailData } from "./type";

export function normalizeNotificationData(data: INotificationData) {
  return data.notification_template.map((notification) => ({
    id: notification.id,
    title: notification.title,
    broadcastedBy:
      notification?.shared_broadcaster?.name || notification.broadcaster_id,
    notification_category: notification.notification_category.name,
    broadcastedDate: notification.broadcast_datetime,
    media: [
      {
        image:
          notification.feature_image_url || notification.feature_image?.url,
        alt: "Image 1",
      },
    ],
    deliveredTo: notification.delivered_to.aggregate.count,
    views: notification.viewed.aggregate.count,
  }));
}

export function normalizeNotificationDetailData(
  data: INotificationDetailData
): any {
  return {
    title: data.notification_template_by_pk.title,
    description: data.notification_template_by_pk.description,
    selectedFeaturedImage: data.notification_template_by_pk.feature_image_url
      ? { url: data.notification_template_by_pk.feature_image_url }
      : data.notification_template_by_pk.feature_image,
    selectedRegions: data.notification_template_by_pk.regions.map((item) => ({
      codename: item.region[0].id,
      name: `${item.region[0]?.regionGroup?.name} - ${item.region[0].name}`,
    })),
    selectedAllNetwork: {
      networks: data.notification_template_by_pk.networks
        .map((item) => item.network[0])
        .filter(Boolean),
      teams: data.notification_template_by_pk.teams
        .map((item) => ({
          codename: item.team[0]?.id,
          name: item.team[0]?.name,
        }))
        .filter(Boolean),
      users: data.notification_template_by_pk.users.map((item) => ({
        codename: item.id,
        name: item?.user?.name || item.id,
      })),
    },
    content: data.notification_template_by_pk.body,
    broadcastDateTime: data.notification_template_by_pk.broadcast_datetime,
    broadcastBy: {
      name: data.notification_template_by_pk?.shared_broadcaster?.name,
    },
  };
}
