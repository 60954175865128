import { numberFormatter } from "../../../lib/utils/numberFormat";
import { colors } from "../../../style/mantine/colors";
import { NoResults } from "../../atoms/NoResults";
import Typography from "../../atoms/Typography";
import { iconCategoriesMap, iconMap } from "./data";
import {
  TrendImageIcon,
  TrendInfo,
  TrendListItemContainer,
  TrendListContainer,
} from "./styles";

interface IProps {
  data: any;
  className?: string;
}
export const TrendList = ({ data, className }: IProps) => {
  return (
    <TrendListContainer className={`${className || ""} px-4`}>
      {data.length ? (
        data.map((data: any, idx: number) => {
          let item = Array.isArray(data.item) ? data.item[0] : data.item;
          let Icon = iconMap[item?.type] || iconCategoriesMap[item?.type];
          return (
            item && (
              <TrendListItemContainer key={item.id || idx} data-cy="trend-list-item">
                {Icon && (
                  <TrendImageIcon>
                    <Icon />
                  </TrendImageIcon>
                )}
                <TrendInfo>
                  <Typography variant="subtitle2">{item.name}</Typography>
                  {!(data.count === undefined) && !(data.count === null) && (
                    <Typography variant="small1">{numberFormatter(data.count, 2)}</Typography>
                  )}
                </TrendInfo>
              </TrendListItemContainer>
            )
          );
        })
      ) : (
        <Typography variant="subtitle2" textColor={colors.orange.main}>No Data to Display</Typography>
      )}
    </TrendListContainer>
  );
};
