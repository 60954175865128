import { Tabs } from "@mantine/core";
import styled from "styled-components";

export const TrendContainer = styled.div`
  flex: 0 0 30%;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 1.2rem;
`;

export const TrendListContainer = styled.div`
  max-height: 40rem;
  height: 100%;
  overflow-y: auto;
  & > p {
    padding: 1rem;
    text-align: center;
  }
`;

export const TrendListItemContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 1rem;
  &:last-child {
    border-bottom: none;
  }
`;

export const TrendImageIcon = styled.div`
  flex: 0 0 3rem;
  color: ${({ theme }) => theme.colors.primary.main};
  & > * {
    width: 100%;
    height: auto;
  }
`;

export const TrendInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

export const StyledTabs = styled(Tabs.List)`
  margin-top: 1rem;

  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  & > button {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.orange.main};
    &[data-active],
    &[data-active]:hover,
    &:hover {
      color: ${({ theme }) => theme.colors.orange.main};
      background-color: ${({ theme }) => theme.colors.disabled.background};
    }
  }
`;
