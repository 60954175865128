import { useMutation, useQuery } from "@apollo/client";
import { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useOutletContext } from "react-router";
import { toast } from "react-toastify";
import { useUser } from "../../../lib/contexts/usercontext";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { MantineButton } from "../../atoms/MantineButton";
import { Modal } from "../../atoms/Modal";
import { setupModalData } from "./data";

export const SetupEditModal = () => {
  const { refetch } = useOutletContext<{
    refetch: any;
  }>();
  const type = setupModalData;
  const navigate = useNavigate();
  const params = useParams();
  const Form = type.Form;
  const [state, setState] = useState(type.state);
  const [Update] = useMutation(type.update);
  const user = useUser();

  const {
    loading,
    data,
    error,
    refetch: readRefetch,
  } = useQuery(type.read, {
    variables: params,
  });

  useEffect(() => {
    if (error) {
      navigate("/school/courses");
    } else if (!loading && data) {
      const mappedData = type.mapper(data);
      let userMutualGroup = user.state.me?.mutual_groups.map(
        (item) => item.codename
      );
      setState(mappedData);
    }

    document.title = `Edit Course - SetuFarm`;
    return () => {
      document.title = "School Courses - SetuFarm";
    };
  }, [loading]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast
      .promise(Update({ variables: state }), {
        pending: "Updating " + type.name,
        success: "Updated " + type.name,
        error: "Couldn't update " + type.name,
      })
      .then(() => {
        refetch();
        readRefetch();
        navigate("/school/courses");
      });
  };

  const checkRequired = () => {
    return type.required.some((item) => {
      if (Array.isArray(state[item])) return !Boolean(state[item].length);
      else return !Boolean(state[item]);
    });
  };

  return (
    <Modal
      isOpen={true}
      handleModal={(e) => navigate("/school/courses")}
      modalTitle={`Edit course`}
      width={"min(100rem, 95vw)"}
      height="auto"
    >
      {!loading ? (
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <Form
            state={state}
            handleChange={(value: any) =>
              setState((prev: any) => ({ ...prev, ...value }))
            }
            type="edit"
          />
          <MantineButton
            className="mt-8 self-end"
            type="submit"
            disabled={checkRequired()}
          >
            Save
          </MantineButton>
        </form>
      ) : (
        <LogoLoader width={75} margin="10% auto" />
      )}
    </Modal>
  );
};
