import { useQuery } from "@apollo/client";
import { Space } from "../../components/atoms/Space";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { config } from "../../config";
import { getLocalStorage, setLocalStorage } from "../../lib/utils/localStorage";
import { Select } from "../../components/atoms/MantineSelect";
import Typography from "../../components/atoms/Typography";
import { Pagination } from "../../components/atoms/Pagination";
import Table from "../../components/molecules/Table";
import { TableFooter } from "../../components/molecules/Table/styles";
import {
  courseOrder,
  coursesData,
  coursesSortingMap,
  schoolCoursesBreadcrumbs,
} from "./data";
import { sortingMapper } from "../../lib/utils/table";
import { SortingRule } from "react-table";
import { useSearchParams } from "react-router-dom";
import PageTitle from "../../components/molecules/PageTitle";
import { MantineButton } from "../../components/atoms/MantineButton";
import { Group } from "../../components/atoms/Group";
import { ExpandingInput } from "../../components/molecules/FilterDrawer/ExpandingInput";
import { Input } from "../../components/atoms/MantineInput";
import { HiSearch } from "react-icons/hi";
import { useDebouncedValue } from "@mantine/hooks";
import { useUser } from "../../lib/contexts/usercontext";
import { RestrictedContent } from "../../components/molecules/ProtectedRoutes/RestrictedContent";

function SchoolCourses() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const navigate = useNavigate();
  const { state: user } = useUser();
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) || 1
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("courseConfig")?.itemsPerPage || config.itemsPerPage
  );
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("courseConfig")?.sortBy || courseOrder
  );
  const [delayedSearchText] = useDebouncedValue(
    searchText,
    config.debounceWait
  );

  const { loading, data, error, refetch } = useQuery(coursesData.query, {
    variables: {
      offset: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
      order: [sortingMapper(sortBy, coursesSortingMap) || {}],
      search: delayedSearchText,
    },
  });

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("courseConfig");
    setLocalStorage("courseConfig", {
      ...localConfig,
      itemsPerPage: Number(item),
    });

    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("courseConfig");
    setLocalStorage("courseConfig", { ...localConfig, sortBy: sorting });
    setSortby(sorting);
  };

  const handleQueryParams = ({
    page = currentPage,
    search = searchText,
  }: {
    page?: number;
    search?: string;
  }) => {
    if (page > 1) searchParams.set("page", page.toString());
    else searchParams.delete("page");
    if (search) searchParams.set("search", search);
    else searchParams.delete("search");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    handleQueryParams({ search: delayedSearchText });
  }, [delayedSearchText]);

  useEffect(() => {
    setSearchText(searchParams.get("search") || "");
    setCurrentPage(Number(searchParams.get("page")) || 1);
    document.title = "School Courses - SetuFarm";
  }, [searchParams]);

  return (
    <>
      <PageTitle
        breadCrumbsList={schoolCoursesBreadcrumbs}
        fixed
        rightSection={
          <RestrictedContent keyName="school_management" permission="create">
            <MantineButton
              className="h-16 px-14"
              data-cy="create-course-action"
              onClick={() => navigate("create")}
            >
              CREATE COURSE
            </MantineButton>
          </RestrictedContent>
        }
      />
      <Group spacing={0} noWrap>
        <ExpandingInput>
          <Input
            value={searchText || ""}
            onChange={(e) => setSearchText(e.target.value)}
            data-cy="courses-search-input"
            rightSection={<HiSearch />}
            className="transition ease-in-out"
            placeholder="Type here to search..."
            size="md"
          />
        </ExpandingInput>
      </Group>
      <Space h="lg" />
      <span data-cy="school-courses-table">
        <Table
          tableColumns={coursesData.columns}
          tableData={
            !error && data?.series?.records
              ? coursesData.mapper(data.series.records)
              : []
          }
          pageSize={itemsPerPage}
          loading={loading}
          getRowProps={(row: any) => ({
            onClick: (e: MouseEvent) =>
              coursesData.rowClick && navigate(`${row.original.id}`),
          })}
          fixedHead
          sortByData={sortBy}
          handleSortBy={handleSorting}
        />
        <TableFooter>
          <Typography variant="body2">Entries per page</Typography>
          <Space w={20} />
          <Select
            listData={config.itemsPerPageList.map((item) => String(item))}
            values={String(itemsPerPage)}
            data-cy="select-entries-per-page"
            onSelect={(item) => {
              handleLimit(item);
            }}
            className="w-40"
          />
          <div className="ml-auto">
            {!loading && !error && (
              <Pagination
                page={currentPage}
                onChange={setCurrentPage}
                total={Math.ceil(
                  (data?.series?.count || itemsPerPage) / itemsPerPage
                )}
                boundaries={0}
                siblings={config.paginationWindow / 2 - 1}
                withEdges
                position="right"
              />
            )}
          </div>
        </TableFooter>
      </span>
      <Outlet context={{ refetch }} />
    </>
  );
}
export default SchoolCourses;
