import { SVGProps } from "react";

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.200195 1.29922C0.200195 0.691705 0.692682 0.199219 1.3002 0.199219H3.66836C4.20608 0.199219 4.66499 0.587973 4.75339 1.11838L5.56663 5.99784C5.64602 6.47418 5.40546 6.94659 4.97353 7.16255L3.27058 8.01403C4.49851 11.0654 6.93404 13.5009 9.98539 14.7288L10.8369 13.0259C11.0528 12.594 11.5252 12.3534 12.0016 12.4328L16.881 13.246C17.4114 13.3344 17.8002 13.7933 17.8002 14.3311V16.6992C17.8002 17.3067 17.3077 17.7992 16.7002 17.7992H14.5002C6.60252 17.7992 0.200195 11.3969 0.200195 3.49922V1.29922Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default PhoneIcon;
