import { Tooltip } from "@mantine/core";
import { HiShieldCheck } from "react-icons/hi";
import { nFormatter, percentage } from "../../../../lib/utils/numberFormat";
import { colors } from "../../../../style/mantine/colors";
import { Card } from "../../../atoms/Card";
import SkeletonLoader from "../../../atoms/Loader/SkeletonLoader";
import { Title } from "../../../atoms/Title";
import Typography from "../../../atoms/Typography";
import {
  ActivityCardContainer,
  ActivityCardHeader,
  ActivityCardIconWrapper,
  StatusBlock,
} from "./style";
import { ActivityCardProps } from "./type";

export default function ActivityCard({
  displayName,
  icon,
  count,
  unit,
  loading = false,
  borderTop,
  alignCenter,
  variant = "regular",
  secondary,
  children,
  countVariants = [],
}: ActivityCardProps) {
  /**
   * As Icon is optional so Tag will be render with
   * availability of icon.
   */
  const Icon = icon || undefined;

  return (
    <Card className="hover:shadow-elevation5 transition-all !h-fit">
      {loading ? (
        <SkeletonLoader height={variant === "small" ? 90 : 210} />
      ) : (
        <ActivityCardContainer
          borderTop={borderTop}
          alignCenter={alignCenter}
          variant={variant}
        >
          <ActivityCardHeader>
            {Icon && (
              <ActivityCardIconWrapper>
                <Icon width={30} height={30} />
              </ActivityCardIconWrapper>
            )}
            {typeof displayName === "string" ? (
              <Typography variant="h6">
                <p style={{ fontSize: "20px", textAlign: "left" }}>
                  {displayName} {unit}
                </p>
              </Typography>
            ) : (
              <>
                {displayName} {unit}
              </>
            )}
          </ActivityCardHeader>
          {children || (
            <Title
              order={2}
              py={variant !== "small" ? 16 : 0}
              style={{ fontSize: "max(3.6rem, min(2.4vw, 4rem))" }}
            >
              <Tooltip
                label={(count || 0).toLocaleString()}
                color={colors.primary.main}
                position="bottom"
              >
                <div>{(count || 0).toLocaleString() }</div>
              </Tooltip>
            </Title>
          )}
          {countVariants[0] && (
            <section
              className="flex items-center gap-2"
              data-cy="activity-card-count-variant"
            >
              {countVariants.map((countVariant, index) => (
                <Tooltip
                  key={index}
                  label={(countVariant.count || 0).toLocaleString()}
                  color={colors.primary.main}
                  position="bottom"
                >
                  <div className="flex-1 flex items-center flex-col gap-1 hover:bg-primary-100 rounded-lg p-4">
                    <countVariant.icon className=" w-12 h-12" />
                    <Typography variant="h6">
                      <span className="font-bold">
                        {nFormatter(countVariant.count || 0)}
                      </span>
                    </Typography>
                  </div>
                </Tooltip>
              ))}
            </section>
          )}
          {secondary ? (
            <StatusBlock
              success={secondary.successColor || !secondary.count}
              data-cy="secondary-count"
            >
              {!secondary.successColor && !secondary.count ? (
                <HiShieldCheck size={18} />
              ) : null}
              <span>
                {secondary.label ||
                  (secondary.count ? "Unverified" : "Verified")}
              </span>
              {secondary.count ? (
                <Tooltip
                  label={(secondary.count || 0).toLocaleString()}
                  color={colors.primary.main}
                  position="bottom"
                >
                    <b>{(secondary.count || 0).toLocaleString() }</b>
                </Tooltip>
              ) : null}
              {secondary.count && count && secondary.showPercentage ? (
                <b>({percentage(secondary.count, count, 0)}%)</b>
              ) : null}
            </StatusBlock>
          ) : null}
        </ActivityCardContainer>
      )}
    </Card>
  );
}
