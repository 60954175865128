import { DocumentNode } from "graphql";
import {
  GET_CROP_LISTING,
  GET_FARM_LISTING,
  GET_FARM_SIDEBAR,
  GET_FIELD_LISTING,
  GET_FIELD_SIDEBAR,
  GET_PLOT_CROP_SIDEBAR,
  GET_PLOT_LISTING,
  GET_PLOT_SIDEBAR,
  GET_TEAM_SIDEBAR,
  GET_USER_LISTING,
  GET_USER_SIDEBAR,
} from "./query";

export type sidebarTypes = "farm" | "field" | "plot" | "crop" | "team" | "user";

export const sidebarQueries: Record<sidebarTypes, DocumentNode> = {
  farm: GET_FARM_SIDEBAR,
  field: GET_FIELD_SIDEBAR,
  plot: GET_PLOT_SIDEBAR,
  crop: GET_PLOT_CROP_SIDEBAR,
  team: GET_TEAM_SIDEBAR,
  user: GET_USER_SIDEBAR,
};

export const teamAggregateCondition = (id: number) => ({
  farmer: {
    mutual_group_memberships: {
      mutual_group: {
        mutual_group_memberships: { mutual_group_id: { _eq: id } },
      },
    },
  },
});

export const sidebarListingQueries: Record<any, any> = {
  user: {
    query: GET_USER_LISTING,
    condition: {
      team: (id: number) => ({
        mutual_group_memberships: { mutual_group_id: { _eq: id } },
      }),
    },
  },
  farm: {
    query: GET_FARM_LISTING,
    condition: {
      user: (id: number) => ({
        farmer: { id: { _eq: id } },
      }),
      team: teamAggregateCondition,
    },
  },
  field: {
    query: GET_FIELD_LISTING,
    condition: {
      farm: (id: number) => ({
        farm_id: { _eq: id },
      }),
      team: teamAggregateCondition,
    },
  },
  plot: {
    query: GET_PLOT_LISTING,
    condition: {
      farm: (id: number) => ({
        field: { farm_id: { _eq: id } },
      }),
      field: (id: number) => ({
        field_id: { _eq: id },
      }),
      team: teamAggregateCondition,
    },
  },
  crop: {
    query: GET_CROP_LISTING,
    condition: {
      farm: (id: number) => ({
        plot: { field: { farm_id: { _eq: id } } },
      }),
      field: (id: number) => ({
        plot: { field_id: { _eq: id } },
      }),
      plot: (id: number) => ({
        plot_id: { _eq: id },
      }),
      team: teamAggregateCondition,
    },
  },
  team: "",
};

export const getSidebarListItem = (
  name: string,
  count: number,
  id: number | string
) => ({
  name,
  count,
  id,
});

export const getSidebarListData = (type: any, queryData: any) => {
  switch (type) {
    case "team":
      return [
        getSidebarListItem(
          "user",
          queryData?.registration_mutual_group_list?.[0]?.user_count,
          queryData[type].id
        ),
        getSidebarListItem(
          "farm",
          queryData?.registration_mutual_group_list?.[0]?.farm_count,
          queryData[type].id
        ),
        getSidebarListItem(
          "field",
          queryData?.registration_mutual_group_list?.[0]?.field_count,
          queryData[type].id
        ),
        getSidebarListItem(
          "plot",
          queryData?.registration_mutual_group_list?.[0]?.plot_count,
          queryData[type].id
        ),
        getSidebarListItem(
          "crop",
          queryData?.registration_mutual_group_list?.[0]?.plot_crop_count,
          queryData[type].id
        ),
      ];
    case "farm":
    case "field":
    case "plot":
    case "crop":
      return [
        getSidebarListItem(
          "field",
          queryData?.fieldsCount?.aggregate.count,
          queryData[type].id
        ),
        getSidebarListItem(
          "plot",
          queryData?.plotsCount?.aggregate.count,
          queryData[type].id
        ),
        getSidebarListItem(
          "crop",
          queryData?.cropsCount?.aggregate.count,
          queryData[type].id
        ),
      ];
    case "user":
      return [
        getSidebarListItem(
          "farm",
          queryData?.farmsCount?.aggregate.count,
          queryData[type].id
        ),
      ].filter((item) => item.count);
    default:
      return [];
  }
};
