import { IBreadCrumbProps } from "../../components/molecules/BreadCrumb/types";
import { ARTICLE_QUERY, PRODUCT_QUERY, SERIES_QUERY } from "./query";
import { ICategoryData } from "./type";

export const createNotificationBreadcrumbs: IBreadCrumbProps = {
  notifications: "Notifications",
  create: "Create",
};

export const categoryData: ICategoryData[] = [
  {
    name: "advise",
    display: "Advise",
    type: "article",
    query: ARTICLE_QUERY,
  },
  {
    name: "school",
    display: "School",
    type: "series",
    query: SERIES_QUERY,
  },
  {
    name: "care",
    display: "Care",
    type: "product",
    query: PRODUCT_QUERY,
  },
  {
    name: "weather",
    display: "Weather",
    type: null,
    query: null,
  },
  {
    name: "general",
    display: "General",
    type: null,
    query: null,
  }
];
