import { HiTrendingUp } from "react-icons/hi";
import { MultipleUsersIcon } from "../../atoms/Icons/NavIcons";
import PageTitle from "../../molecules/PageTitle";
import TotalMetricSection from "../../organisms/GenericSections/TotalMetricsSection";
import { SchoolTrendMetrics } from "../../organisms/SchoolSections/SchoolTrendMetrics";
import { SchoolUserEngagementMetrics } from "../../organisms/SchoolSections/SchoolUserEngagementMetrics";
import { ISchoolTemplateProps } from "./type";

function SchoolTemplate({ totalMetricData, isLoading }: ISchoolTemplateProps) {
  return (
    <>
      <PageTitle title="SetuSchool Metrics" fixed />
      <TotalMetricSection
        totalMetricData={totalMetricData}
        isLoading={isLoading}
      />
      <SchoolTrendMetrics legendTitle="Trending" legendIcon={HiTrendingUp} />
      <SchoolUserEngagementMetrics
        legendTitle="User Engagement"
        legendIcon={MultipleUsersIcon}
      />
    </>
  );
}

export default SchoolTemplate;
