import { BsPlayCircle } from "react-icons/bs";
import { HiOutlineDocumentText, HiPencil, HiTrash } from "react-icons/hi";
import { IoMicCircleOutline } from "react-icons/io5";
import { Actions, IconBox } from "../../components/molecules/Table/styles";
import { getDate } from "../../lib/utils/getDate";
import { ITabsTableContent } from "./types";
import { Authors } from "../../components/molecules/Authors";
import { Cell } from "react-table";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { durationDisplay, timeToDuration } from "../../lib/utils/duration";
import { RestrictedContent } from "../../components/molecules/ProtectedRoutes/RestrictedContent";

export const Columns = [
  {
    Header: "",
    accessor: "article_type",
    flex: 0.4,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return (
        <IconBox>
          {cell.value === "video" ? (
            <BsPlayCircle />
          ) : cell.value === "audio" ? (
            <IoMicCircleOutline />
          ) : (
            <HiOutlineDocumentText />
          )}
        </IconBox>
      );
    },
  },
  {
    Header: "Title",
    accessor: "title",
    flex: 3,
    Cell: (cell: Cell) => {
      return <b>{cell.value}</b>;
    },
  },
  {
    Header: "Authors",
    accessor: "authors",
    flex: 2,
    Cell: (cell: Cell) => {
      return cell.value.length ? (
        <Authors
          authors={cell.value?.map((item: string) => ({ full_name: item }))}
        />
      ) : (
        <span>No Authors</span>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "Category",
    accessor: "categories",
    flex: 2,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return cell.value?.length
        ? cell.value?.map((category: any) => <div>{category}</div>)
        : "-";
    },
  },
  {
    Header: "Published Date",
    accessor: "publish_date",
    flex: 1.5,
  },
  {
    Header: "Duration",
    accessor: "duration",
    flex: 1.5,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const duration = durationDisplay(
        timeToDuration((cell.row.original as any)?.duration)
      );
      return duration || "-";
    },
  },
  {
    Header: "Views",
    accessor: "views",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Likes",
    accessor: "likes",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Bookmarks",
    accessor: "favorites",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Actions",
    accessor: "",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell<any>) => {
      const navigate = useNavigate();
      const [searchParams] = useSearchParams();
      return (
        <Actions>
          <RestrictedContent keyName="advice_management" permission="edit">
            <HiPencil
              onClick={(e) => {
                e.stopPropagation();
                navigate("../edit/" + cell.row.original.id);
              }}
            />
          </RestrictedContent>
          <RestrictedContent keyName="advice_management" permission="delete">
            <HiTrash
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `?${searchParams.toString()}&deleteId=${cell.row.original.id}`
                );
              }}
            />
          </RestrictedContent>
        </Actions>
      );
    },
  },
];

export const TabsData = [
  {
    id: "published",
    head: "Published",
  },
  {
    id: "scheduled",
    head: "Scheduled",
  },
  {
    id: "drafts",
    head: "Drafts",
  },
  {
    id: "promoted",
    head: "Promoted",
  },
  {
    id: "archived",
    head: "Archived",
  },
];

export const articlesListBreadcrumbs = {
  advisory: "Advisory",
  articles: "Articles",
  published: "Published",
  drafts: "Drafts",
  promoted: "Promoted",
  archived: "Archived",
  scheduled: "Scheduled",
};

export const TabsContentMap: ITabsTableContent = {
  published: {
    status: "publish",
    date: getDate(),
    columns: Columns,
  },
  scheduled: {
    status: "scheduled",
    date: getDate(),
    columns: Columns,
  },
  drafts: {
    status: "draft",
    columns: Columns,
  },
  promoted: {
    status: "promoted",
    date: getDate(),
    columns: Columns,
  },
  archived: {
    status: "archive",
    columns: Columns,
  },
};
