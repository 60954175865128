import * as React from "react";
import { SVGProps } from "react";

// add document for how to export icons as svg components

export const FarmIco = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.766 21.177c-.05-.038-.083-.096-.133-.154-.8-1.073-1.717-2.07-3.367-2.07C1.8 18.953.6 21.14.6 24.8h24c0-3.662-1.184-5.847-3.667-5.847-1.933 0-2.884 1.304-3.433 2.07l-.084.116V14.64c0-1.975.65-3.508 2.717-4.716-1.067 1.246-1.617 2.473-1.733 3.892 3.766-.039 5.333-3.144 5.533-6.71-4.783.327-7.233 2.282-7.5 5.924-1.233-1.936-3.583-2.78-6.517-2.78.084 3.566 1.534 6.633 5.5 6.71.167-.997-.3-2.09-1.7-3.777 2.2.844 2.7 1.879 2.7 4.236v3.968a1.06 1.06 0 0 1-.4-.364c-.583-.786-1.55-2.07-3.45-2.07-1.933 0-2.9 1.303-3.466 2.07-.1.134-.2.25-.317.307V8.927c0-2.875.667-4.16 2.7-5.194-1.4 1.687-1.867 2.78-2.033 3.776 4.3-.077 5.75-3.125 5.833-6.71-4.417 0-7.517 1.918-7.517 6.595v1.84c-1.15-1.687-3.316-2.626-6.5-2.837.2 3.565 1.767 6.671 5.534 6.729-.134-1.438-.667-2.665-1.734-3.911 2.067 1.208 2.7 2.741 2.7 4.716v7.246Z"
      fill="#005777"
    />
  </svg>
);

export const MultipleUsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.6 7.2a3.6 3.6 0 1 1-7.2 0 3.6 3.6 0 0 1 7.2 0ZM21.6 9.6a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0ZM16.8 18a4.8 4.8 0 1 0-9.6 0v3.6h9.6V18ZM7.2 9.6a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0ZM19.2 21.6V18a7.168 7.168 0 0 0-.9-3.487A3.6 3.6 0 0 1 22.8 18v3.6h-3.6ZM5.7 14.513A7.168 7.168 0 0 0 4.8 18v3.6H1.2V18a3.6 3.6 0 0 1 4.5-3.487Z"
      fill={props.color || "#005777"}
    />
  </svg>
);
