import React, { FC } from "react";
import Typography from "../../atoms/Typography";
import { AuthorItem, AuthorsContainer } from "./styles";
import { AuthorName } from "./types";

export const Authors: FC<{ dir?: "row" | "column"; authors: AuthorName[] }> = ({
  authors,
  dir,
}) => {
  return (
    <AuthorsContainer dir={dir}>
      {authors.length ? (
        authors.map((item, i) => (
          <AuthorItem key={i}>{item.full_name}</AuthorItem>
        ))
      ) : (
        <Typography variant="small1">No Authors</Typography>
      )}
    </AuthorsContainer>
  );
};
