import { Group } from "../../../components/atoms/Group";
import { forwardRef } from "react";
import styled from "styled-components";
import Image from "../../atoms/Image";
import Typography from "../../atoms/Typography";

export const ArticleCreateInput = styled.div<{ flex?: boolean }>`
  ${({ flex }) => flex && `display:flex;flex:1;`}
  margin: 1.25rem 0;
`;

export const AuthorsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

export const PromotionInputSection = styled.div`
  flex: 2;
  & > * {
    margin: 0;
  }
  & input:first-of-type {
    margin-right: 1rem;
  }
`;

export const DurationContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 0.4rem;
  & > * {
    margin: 0;
    width: 8rem;
  }
`;

export const InputInnerLabel = styled(Typography)<{ light?: boolean }>`
  display: flex;
  margin-left: 1rem;
  margin-right: 2rem;
  margin-top: 1.25rem;
  ${({ light }) => !light && `font-weight: bold;`}
  & label {
    cursor: pointer;
    margin-left: 3rem;
  }
`;

export const MultiInputSection = styled.div``;

export const DropdownsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const ArticleFileSection = styled.div`
  display: flex;
  align-items: center;
  margin: 0 3rem;
`;

export const ArticleTypeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 0.4rem;
  margin-bottom: 3rem;
`;

export const MediaPreviewContainer = styled.div<{
  width?: string;
  height?: string;
}>`
  border-radius: 1rem;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  overflow: hidden;
`;

export const FeaturedImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 2rem;
`;

export const SelectedImageContainer = styled.div<{ imageUrl: string }>`
  height: 20rem;
  border-radius: 1rem;
  overflow: hidden;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  width: 50%;
`;

export const SelectItem = forwardRef<HTMLDivElement, any>(
  ({ label, url, ...others }: any, ref) => (
    <div ref={ref} {...others}>
      <Group className="h-20 py-2" noWrap>
        <Image src={url} className="w-20 max-h-full" />
        <Typography
          variant="body2"
          className="text-ellipsis overflow-hidden max-h-16"
        >
          {label}
        </Typography>
      </Group>
    </div>
  )
);
