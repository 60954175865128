import styled from "styled-components";
import { Switch, MantineSize } from "@mantine/core";
import { IFonts } from "../../../style/mantine/fonts";

const sizeToText: Partial<Record<MantineSize, keyof IFonts>> = {
  xl: "body2",
  lg: "small1",
};

export const SwitchWrapper = styled(Switch)`
  input {
    background-color: ${({ theme }) => theme.colors.disabled.background};
  }
  input:checked {
    background-color: ${({ theme }) => theme.colors.primary[700]};
  }
  input:before {
    border: none;
    background-color: ${({ theme }) => theme.colors.border};
  }
  input:checked:before {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
  input:after,
  .mantine-Switch-label {
    color: ${({ theme }) => theme.colors.primary.main};
    font-size: ${({ theme, size = "lg" }) =>
      theme.fonts[sizeToText[size] || "body2"]?.fontSize};
  }
  input:checked:after {
    color: ${({ theme }) => theme.colors.white};
  }
`;
