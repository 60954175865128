import {
  Drawer as MantineDrawer,
  DrawerProps,
  MantineTheme,
} from "@mantine/core";

const styles = (theme: MantineTheme) => ({
  header: {
    padding: "1.3rem 1.5rem",
    borderBottom: "1px solid " + theme.other.colors.border,
    marginBottom: 0,
  },
  title: {
    ...theme.other.fonts.body1,
    color: theme.other.colors.primary.main,
  },
  closeButton: {
    color: theme.other.colors.orange.main,
  },
});

export const Drawer = (props: DrawerProps) => {
  return <MantineDrawer styles={styles} {...props} />;
};
