import { Outlet } from "react-router";
import Appbar from "../Appbar";
import { Footer } from "../../atoms/Footer";
import { RouteContainer, PageOutletContainer } from "./style";
import Sidebar from "../Sidebar";
import { SidebarWrapper } from "../Sidebar/styles";
import { useMediaQuery } from "@mantine/hooks";

export const PageOutlet = () => {
  const largeScreen = useMediaQuery("(min-width: 48rem)");
  return (
    <>
      <Appbar />
      <PageOutletContainer>
        {largeScreen && (
          <SidebarWrapper isOpen={true} position="sticky">
            <Sidebar />
          </SidebarWrapper>
        )}
        <RouteContainer>
          <Outlet />
          <div style={{ marginTop: "1.6rem" }} />
          <Footer />
        </RouteContainer>
      </PageOutletContainer>
    </>
  );
};
