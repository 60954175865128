import { gql } from "@apollo/client";

export const farmAttributes = `
  id
  name
  tag
  farmer {
    name: full_name
    memberships: mutual_group_memberships {
      group: mutual_group {
        id
        name
      }
    }
  }
  tag
  verified: is_verified
  active: is_active
  farmSetuQC: quality_checked
  address {
    village
    taluka
    district
    state
    pincode
    raw: raw_address
    location
  }
`;

export const fieldAttributes = `
  id
  name
  tag
  farm {
    id
    name
    tag
  }
  verified: is_verified
  active: is_active
  farmSetuQC: quality_checked
  farmer {
    name: full_name
    memberships: mutual_group_memberships {
      group: mutual_group {
        id
        name
      }
    }
  }
  area
  areaUnit: area_unit {
    name
  }
  gatNumber: gat_no
  soil {
    id
    name
  }
  area_catered
`;

const plotAttributes = `
  id
  name
  tag
  verified: is_verified
  active: is_active
  farmSetuQC: quality_checked
  field {
    id
    name
    tag
    farm {
      id
      name
      tag
    }
  }
  farmer {
    name: full_name
    memberships: mutual_group_memberships {
      group: mutual_group {
        id
        name
      }
    }
  }

  area
  areaUnit: area_unit {
    name
  }
  area_catered
`;

const cropAttributes = `
  id
  name
  tag
  verified: is_verified
  farmSetuQC: quality_checked
  active: is_active
  sowing_date
  p2p: p2p_distance
  p2pUnit: p2p_distance_unit {
    name
  }
  r2r: r2r_distance
  r2rUnit: r2r_distance_unit {
    name
  }
  crop {
    name
  }
  variety: crop_variety {
    name
  }
  plot {
    id
    name
    tag
    area_catered
    field {
      id
      name
      tag
      farm {
        id
        name
        tag
      }
    }
  }
  farmer {
    name: full_name
    companies {
        company {
          name
        }
      }
    memberships: mutual_group_memberships {
      group: mutual_group {
        id
        name
      }
    }
  }
`;

export const userAttributes = `
  id
  name: full_name
  number
  verified: is_verified
  education
  created_at
  gender
  address: current_address {
    raw: raw_address
    location
  }
`;

export const GET_FARM_GEO_LOCATIONS = gql`
  query getFarmGeoLocations($where: farm_bool_exp) {
    farm(where: $where) {
      ${farmAttributes}
    }
  }
`;

export const GET_FARM_COUNT = gql`
  query getFarmCount($where: farm_bool_exp) {
    farm: farm_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FIELD_GEO_LOCATIONS = gql`
  query getFieldGeoLocations($where: field_bool_exp) {
    field(where: $where) {
      ${fieldAttributes}
    }
  }
`;

export const GET_FIELD_COUNT = gql`
  query getFieldCount($where: field_bool_exp) {
    field: field_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PLOT_GEO_LOCATIONS = gql`
  query getPlotGeoLocations($where: plot_bool_exp) {
    plot(where: $where) {
      ${plotAttributes}
      crops {
        crop {
          name
          color: hex_color_code
        }
        variety: crop_variety{
          name
        }
      }
    }
  }
`;

export const GET_PLOT_COUNT = gql`
  query getPlotCount($where: plot_bool_exp) {
    plot: plot_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PLOT_CROP_GEO_LOCATIONS = gql`
  query getPlotCropGeoLocations($where: plot_crop_bool_exp) {
    plot_crop(where: $where) {
      ${cropAttributes}
    }
  }
`;

export const GET_PLOT_CROP_COUNT = gql`
  query getPlotCropCount($where: plot_crop_bool_exp) {
    plot_crop: plot_crop_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_USER_SIDEBAR = gql`
  query GetUserDetail($id: Int!) {
    user: base_user_by_pk(id: $id) {
      ${userAttributes}
    }
    farmsCount: farm_aggregate(where: { farmer: { id: { _eq: $id } } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FARM_SIDEBAR = gql`
  query farmSidebarDetail($id: Int!) {
    farm: farm_by_pk(id: $id) {
     ${farmAttributes}
    }
    fieldsCount: field_aggregate(where: { farm: { id: { _eq: $id } } }) {
      aggregate {
        count
      }
    }
    plotsCount: plot_aggregate(
      where: { field: { farm: { id: { _eq: $id } } } }
    ) {
      aggregate {
        count
      }
    }
    cropsCount: plot_crop_aggregate(
      where: { plot: { field: { farm: { id: { _eq: $id } } } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FIELD_SIDEBAR = gql`
  query fieldSidebarDetail($id: Int!) {
    field: field_by_pk(id: $id) {
      ${fieldAttributes}
    }
    plotsCount: plot_aggregate(
      where: { field: { id: { _eq: $id } } }
    ) {
      aggregate {
        count
      }
    }
    cropsCount: plot_crop_aggregate(
      where: { plot: { field: { id: { _eq: $id } } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PLOT_SIDEBAR = gql`
  query plotSidebarDetail($id: Int!) {
    plot: plot_by_pk(id: $id) {
      ${plotAttributes}
    }
    cropsCount: plot_crop_aggregate(
      where: { plot: { id: { _eq: $id } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PLOT_CROP_SIDEBAR = gql`
  query plotSidebarDetail($id: Int!) {
    crop: plot_crop_by_pk(id: $id) {
      ${cropAttributes}
    }
  }
`;

export const GET_TEAM_SIDEBAR = gql`
  query getMutualGroup($id: Int!) {
    team: mutual_group_by_pk(id: $id) {
      name
      id
      codename
      verified: is_verified
      farmer: mutual_group_memberships {
        user: base_user {
          full_name
          number
          companies {
            company {
              name
            }
          }
        }
        mutual_group_role {
          codename
        }
      }
      address {
        raw: raw_address
        location
      }
    }
    registration_mutual_group_list(mutual_group_id: $id) {
      farm_count
      plot_count
      field_count
      plot_crop_count
      user_count
    }
  }
`;

export const GET_USER_LISTING = gql`
  query UsersForTeam($where: base_user_bool_exp) {
    user: base_user(where: $where) {
      ${userAttributes}
    }
  }
`;

export const GET_FARM_LISTING = gql`
  query FarmsForUser_Team($where: farm_bool_exp) {
    farm(where: $where) {
      ${farmAttributes}
      fieldsCount: fields_aggregate{
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_FIELD_LISTING = gql`
  query fieldsForFarm_User_Team($where: field_bool_exp) {
    field(where: $where) {
      ${fieldAttributes}
      plotsCount: plots_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_PLOT_LISTING = gql`
  query plotsForField_Farm_User_Team($where: plot_bool_exp) {
    plot(where: $where) {
      ${plotAttributes}
      cropsCount: crops_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_CROP_LISTING = gql`
  query cropsForPlot_Field_Farm_User_Team($where: plot_crop_bool_exp) {
    crop: plot_crop(where: $where) {
      ${cropAttributes}
    }
  }
`;

export const ADD_NEW_LOCATION = gql`
  mutation upsertAddress($address: address_insert_input!) {
    insert_address_one(
      on_conflict: { constraint: address_pkey, update_columns: [location] }
      object: $address
    ) {
      id
      location
    }
  }
`;

export const SET_PLOT_LOCATION = gql`
  mutation upsertPlot(
    $plotId: Int!
    $areaCatered: geometry
    $area: numeric
    $areaUnitId: Int
  ) {
    update_plot_by_pk(
      pk_columns: { id: $plotId }
      _set: {
        area_catered: $areaCatered
        area: $area
        area_unit_id: $areaUnitId
      }
    ) {
      id
      name
    }
  }
`;

export const SET_FIELD_LOCATION = gql`
  mutation upsertField(
    $fieldId: Int!
    $areaCatered: geometry
    $area: numeric
    $areaUnitId: Int
  ) {
    update_field_by_pk(
      pk_columns: { id: $fieldId }
      _set: {
        area_catered: $areaCatered
        area: $area
        area_unit_id: $areaUnitId
      }
    ) {
      id
      name
    }
  }
`;

export const SET_FARM_LOCATION = gql`
  mutation upsertFarm($farmId: Int!, $addressId: Int) {
    update_farm_by_pk(
      pk_columns: { id: $farmId }
      _set: { address_id: $addressId }
    ) {
      id
      name
      address {
        id
        location
      }
    }
  }
`;
