import { DocumentNode } from "graphql";
import { Attachment, TeamGroup } from "../../lib/interfaces/Article";

export enum NotificationActions {
  SET_VALUE,
  SET_VALUES,
  SELECT_VALUE,
  SELECT_FEATURED_IMAGE,
  SELECT_CONTENT,
}

export interface Actions {
  type: NotificationActions;
  payload: any;
}

export interface Region {
  codename: string;
  name: string;
  id: number;
  parentregionid: number;
}

export interface Network {
  codename: string;
  name: string;
}

export interface NotificationState {
  categories: ICategoryData[];
  notificationTypeData: INotificationType[];
  notificationType: INotificationType | null;
  selectedCategory: ICategoryData;
  category: null;

  title: string;
  description: string;
  selectedFeaturedImage: Attachment | null;
  featuredImages: Attachment[];
  regions: Region[];
  teams: TeamGroup[];
  networks: Network[];
  users: INotificationUser[];
  selectedUsers: INotificationUser[];
  visibilityType: "network" | "geographical" | "";
  selectedAllNetwork: {
    networks: Network[];
    teams: TeamGroup[];
    users: INotificationUser[];
  };
  selectedRegions: Region[];
  selectedState: Region[];
  selectedDistrict: Region[];
  selectedTaluka: Region[];
  selectedVillage: Region[];
  selectedNetworks: Network[];
  selectedTeams: TeamGroup[];
  content: string;
  dateTime: null;
  notificationBy: null;
  broadcastDateTime: Date | null;
  broadcastBy: INotificationUser | null;
  recordId: string;
}

export interface ICategoryData {
  codename?: string;
  name: string;
  display: string;
  type: string | null;
  query: DocumentNode | null;
}

export interface INotificationType {
  codename: number | string;
  description?: string;
  name: string;
  featuredImage?: {
    id: number;
    url: string;
  };
}

export interface INotificationUser {
  name: string;
  codename: string;
  networks?: { network: { codename: string } }[];
  team: {
    name: string;
    codename: string;
  };
}

export interface INotificationsDropdownData {
  region: {
    regionGroup: {
      name: string;
    };
    codename: string;
    name: string;
    id: number;
  }[];
  teams: {
    codename: string;
    name: string;
  };
  networks: {
    id: string;
    name: string;
  };
  fileupload: {
    id: number;
    media_type: string;
    name: string;
    url: string;
  };
  users: {
    name: string;
    codename: string;
    groups: {
      group: {
        name: string;
      };
    }[];
    mutual_group_memberships: {
      mutual_group: {
        name: string;
      };
    }[];
  }[];
}

export interface INotificationCategoriesData {
  notification_category: {
    codename: string;
    name: string;
  }[];
}
