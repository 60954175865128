import {
  ApolloCache,
  ApolloQueryResult,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from "@apollo/client";
import { Dispatch, useState } from "react";
import { BsPersonPlusFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { useUser } from "../../../../lib/contexts/usercontext";
import { fullDate } from "../../../../lib/utils/dateTimeFormat";
import { theme } from "../../../../style/theme";
import { Card, CardBody } from "../../../atoms/Card";
import { Divider } from "../../../atoms/Divider";
import { Grid } from "../../../atoms/Grid";
import CalendarIcon from "../../../atoms/Icons/Calendar";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserAddIcon from "../../../atoms/Icons/UserAddIcon";
import UserEditIcon from "../../../atoms/Icons/UserEdit";
import UserIcon from "../../../atoms/Icons/UserIcon";
import UserVerifiedIcon from "../../../atoms/Icons/UserVerified";
import { Checkbox } from "../../../atoms/Input/MantineCheckbox";
import { MantineButton } from "../../../atoms/MantineButton";
import Typography from "../../../atoms/Typography";
import { sidebarDetailSteps } from "../../../templates/TeamsDetailModal/data";
import {
  Actions,
  DetailTypes,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";
import { RestrictedContent } from "../../../molecules/ProtectedRoutes/RestrictedContent";

export interface AuditSectionProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
  type: DetailTypes;
  data?: {
    created_at: string;
    created_by?: {
      full_name?: string;
    };
    updated_at: string;
    updated_by: {
      full_name: string;
    };
    verified_by?: {
      full_name?: string;
    };
    verified_on?: string;
    quality_checked?: boolean;
  };
  handleClickApprove: (id: number) => void;
  handleClickReject: (id: number) => void;
  loading?: boolean;
  documentKinds: any[];
  updateData: (
    options?:
      | MutationFunctionOptions<
          any,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined
  ) => Promise<any>;
  refetch: (
    variables?:
      | Partial<{
          id: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<any>>;
  allowQCPassCheck: boolean;
}

export function AuditSection({
  state,
  dispatch,
  type,
  data,
  handleClickApprove,
  handleClickReject,
  loading,
  documentKinds,
  updateData,
  refetch,
  allowQCPassCheck,
}: AuditSectionProps) {
  const [qualityCheck, setQualityCheck] = useState(data?.quality_checked);
  const user = useUser();

  const updateQCCheck = (value: boolean) => {
    setQualityCheck(value);
    const variables = sidebarDetailSteps[type].updateVariables(state);
    toast
      .promise(
        updateData({ variables: { ...variables, qualityChecked: value } }),
        {
          pending: `QC Check Updating for ${type}`,
          success: `QC Check Updated for ${type}`,
          error: `Couldn't Update QC Check for ${type}`,
        }
      )
      .then(() => {
        refetch();
      })
      .catch(() => {
        setQualityCheck(data?.quality_checked);
      });
  };

  const QCPassCheck = () => {
    return allowQCPassCheck ? (
      <Grid.Col span={3}>
        <Checkbox
          checked={qualityCheck}
          size="md"
          label="FarmSetu QC"
          onChange={(e) => updateQCCheck(e.target.checked)}
        />
      </Grid.Col>
    ) : (
      <></>
    );
  };

  return (
    <section className="flex  flex-col  gap-8" data-cy="audit-section">
      <div className="flex justify-between items-center">
        <div
          className="flex justify-start items-center gap-4"
          data-cy="owner-name-section"
        >
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state[type].detail.owner}</Typography>
        </div>
        <div
          className="flex justify-start items-center gap-4"
          data-cy="team-name-section"
        >
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state[type].detail.teamName}</Typography>
        </div>
      </div>

      <div className="flex flex-col gap-8" data-cy="audit-info">
        <Grid>
          <Grid.Col span={3}>
            <Typography variant="body1">Created -</Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1" className="flex items-center gap-4">
              <UserAddIcon />
              <span>{data?.created_by?.full_name || "-"}</span>
            </Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1" className="flex items-center gap-4">
              <CalendarIcon />
              <span>{data?.created_at ? fullDate(data?.created_at) : "-"}</span>
            </Typography>
          </Grid.Col>
          {QCPassCheck()}
        </Grid>
        <Grid>
          <Grid.Col span={3}>
            <Typography variant="body1">Last Updated -</Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1" className="flex items-center gap-4">
              <UserEditIcon />
              <span>{data?.updated_by?.full_name || "-"}</span>
            </Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1" className="flex items-center gap-4">
              <CalendarIcon />
              <span>{data?.updated_at ? fullDate(data?.updated_at) : "-"}</span>
            </Typography>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={3}>
            <Typography variant="body1">Last Verified -</Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1" className="flex items-center gap-4">
              <UserVerifiedIcon />
              <span>{data?.verified_by?.full_name || "-"}</span>
            </Typography>
          </Grid.Col>
          <Grid.Col span={3}>
            <Typography variant="body1" className="flex items-center gap-4">
              <CalendarIcon />
              <span>
                {data?.verified_on ? fullDate(data?.verified_on) : "-"}
              </span>
            </Typography>
          </Grid.Col>
        </Grid>
      </div>
      <div>
        <Grid>
          {documentKinds[0] && (
            <Grid.Col span={6} data-cy="doc-verification-remark">
              <Typography variant="body1">
                Document Verification Remarks
              </Typography>
              <Card
                border="solid"
                borderWidth={1}
                borderColor={theme.colors.border}
                className="mt-4 min-h-[280px]"
                shadow="none"
              >
                <CardBody className="!p-8 flex flex-col gap-10">
                  {documentKinds?.map((doc, index) => (
                    <section key={index} data-cy="doc-item">
                      <Typography variant="body1">
                        <span className="font-bold">{doc?.name}</span>
                      </Typography>
                      <div>
                        <Typography variant="body2">
                          {doc?.verification_remark || "-"}
                        </Typography>
                      </div>
                    </section>
                  ))}
                </CardBody>
              </Card>
            </Grid.Col>
          )}
          <Grid.Col
            span={documentKinds[0] ? 6 : 12}
            data-cy="user-verification-remark"
          >
            <Typography variant="body1">User Verification Remarks</Typography>
            <Card
              border="solid"
              borderWidth={1}
              borderColor={theme.colors.border}
              className="mt-4 min-h-[280px]"
              shadow="none"
            >
              <CardBody className=" !p-8">
                <Typography variant="body2">
                  {state[type].verification_remark}
                </Typography>
              </CardBody>
            </Card>
          </Grid.Col>
        </Grid>
      </div>
      {!state[type].verified && (
        <RestrictedContent keyName="network_management" permission="edit">
          <Divider size={1} mt={8} mb={8} />
          <div
            className="flex justify-end gap-4 w-full"
            data-cy="action-buttons"
          >
            <MantineButton
              variant="outline"
              onClick={() => handleClickReject(state[type].id)}
              size="md"
            >
              REJECT
            </MantineButton>
            <MantineButton
              variant="filled"
              onClick={() => handleClickApprove(state[type].id)}
              size="md"
            >
              APPROVE
            </MantineButton>
          </div>
        </RestrictedContent>
      )}
    </section>
  );
}
