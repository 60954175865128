import { gql } from "@apollo/client";

export const CARE_LISTING_FILTERS = gql`
  query CareListingFilters {
    brands: manufacturer(
      where: {
        manufacturing_facilities: {
          products: {
            sellable_items: { sellable_item_stocks: { is_live: { _eq: true } } }
          }
        }
      }
      order_by: { name: asc }
    ) {
      value: id
      name
    }
    category(order_by: { order: asc }) {
      value: id
      name
    }
    subcategory(order_by: { order: asc }) {
      value: id
      name
      category: category_id
    }
    crops: product_crop_association(
      where: {
        product: {
          sellable_items: {
            sellable_item_stocks: {
              is_active: { _eq: true }
              is_live: { _eq: true }
            }
          }
        }
      }
      distinct_on: crop_id
    ) {
      crop {
        name
        value: reference_id
      }
    }
  }
`;
