export const removeQuotes = (str: string | Date) => {
  return str.toString().replaceAll('"', "");
};

export const capitalize = (
  str: string,
  spaceBeforeUpperCase = false,
  nestingLevel: number | "all" = "all"
) => {
  const word = spaceBeforeUpperCase
    ? str.replace(/([A-Z])/g, " $1").trim()
    : str;
  const capitalizeSingle = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const capitalizeWrtNestingLevel = (str: string, index: number) => {
    return index + 1 <= nestingLevel ? capitalizeSingle(str) : str;
  };
  return word
    .split(" ")
    .map((letter, index) =>
      nestingLevel !== "all"
        ? capitalizeWrtNestingLevel(letter, index)
        : capitalizeSingle(letter)
    )
    .join(" ");
};
