import Typography from "../../../../../atoms/Typography";
import { ImageViewer } from "../../../../../molecules/ImageViewer";
import { AttachmentSectionProps } from "./type";

function AttachmentSection({
  attachments,
  loading,
  showImageIndex,
  title,
}: AttachmentSectionProps) {
  return (
    <div>
      <Typography variant="body1" className="mb-3">
        <span className="font-bold">{title ?? "Attachments"}</span>
      </Typography>
      {attachments[0] ? (
        <ImageViewer
          images={attachments.map((img) => ({
            ...img,
            src: img.image,
            alt: img.alt,
            index: img.index,
          }))}
          showImageIndex={showImageIndex}
          containerStyle="flex items-center gap-4 flex-wrap"
          width={80}
          height={80}
          imageStyle="w-32 h-32 rounded-md border border-solid border-orange-main"
        />
      ) : (
        <section className="py-4">
          <Typography variant="body2">No attachment present</Typography>
        </section>
      )}
    </div>
  );
}

export default AttachmentSection;
