import {
  Select as MantineSelect,
  MantineTheme,
  SelectProps,
} from "@mantine/core";

const styles = (theme: MantineTheme) => ({
  input: {
    border: "1px solid " + theme.other.colors.border,
    color: theme.other.colors.primary["700"],
    "::placeholder": {
      color: theme.other.colors.primary["700"],
    },
  },
  rightSection: {
    "& svg": {
      color: theme.other.colors.primary.main + "!important",
    },
  },
  item: {
    color: theme.other.colors.primary.main + "!important",
    ":hover": {
      backgroundColor: theme.other.colors.disabled.backgroundColor,
    },
    "&[data-selected]": {
      backgroundColor:
        theme.other.colors.disabled.backgroundColor + "!important",
    },
  },
  label: {
    fontWeight: 700,
    color: theme.other.colors.primary.main,
    marginBottom: theme.other.gaps["4"],
  },
  required: {
    color: theme.other.colors.primary.main,
  },
});

export interface ISelectProps extends Omit<SelectProps, "data"> {
  listData: (string | { [key: string]: any })[];
  values?: string | { [key: string]: any };
  accessor?: { name: string; value: string };
  onSelect: (value: any) => void;
}

export const Select = ({
  listData,
  values,
  accessor,
  onSelect,
  ...props
}: ISelectProps) => {
  return (
    <MantineSelect
      styles={styles}
      data={listData.map((item) =>
        typeof item === "object"
          ? {
              label: item[`${accessor?.name}`],
              value: item[`${accessor?.value}`],
              ...item,
            }
          : item
      )}
      value={typeof values === "object" ? values[`${accessor?.value}`] : values}
      onChange={(item) => {
        onSelect(
          listData.find(
            (listItem: any) =>
              item ===
              (typeof listItem === "object"
                ? listItem[`${accessor?.value}`]
                : listItem)
          )
        );
      }}
      {...props}
    />
  );
};
