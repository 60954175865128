import { Group as MantineGroup, GroupProps, Stack } from "@mantine/core";

type IGroupProps = GroupProps & {
  direction?: React.CSSProperties["flexDirection"];
  justify?: React.CSSProperties["justifyContent"];
};

export const Group = ({
  direction,
  grow,
  noWrap,
  position,
  justify,
  ...props
}: IGroupProps) => {
  return direction === ("column" || "column-reverse") ? (
    <Stack justify={justify} {...props} />
  ) : (
    <MantineGroup grow={grow} noWrap={noWrap} position={position} {...props} />
  );
};
