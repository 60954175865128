import { gql } from "@apollo/client";

export const GET_TREND_METRICS = gql`
  query ArticleTrendMetrics($limit: Int) {
    article_views: trending_metrics_article_views {
      title
      count
      type: article_type
    }
    article_likes: trending_metrics_article_likes {
      title
      count
      type: article_type
    }
    article_favorites: trending_metrics_article_favorites {
      title
      count
      type: article_type
    }
    author_views: trending_metrics_article_author_views {
      name
      count
    }
    author_likes: trending_metrics_article_author_likes {
      name
      count
    }
    author_favorites: trending_metrics_article_author_favorites {
      name
      count
    }
    categoryViews: trending_metrics_article_category_views {
      name
      count
    }
    categoryLikes: trending_metrics_article_category_likes {
      name
      count
    }
    categoryFavorites: trending_metrics_article_category_favorites {
      name
      count
    }
  }
`;

export const GET_ARTICLE_DETAIL_TREND_METRICS = gql`
  query ArticleDetailTrendMetrics($id: String!) {
    trendingMetrics: article_detail_trending_metrics(id: $id) {
      views {
        count
        users
      }
      likes {
        count
        users
      }
      favorites {
        count
        users
      }
    }
  }
`;

export const GET_ARTICLE_USER_METRICS = gql`
  query TrendingArticleUserMetrics {
    usersViews: trending_metrics_article_users_views {
      name
      count
    }
    usersLikes: trending_metrics_article_users_likes {
      name
      count
    }
    usersFavorites: trending_metrics_article_users_favorites {
      name
      count
    }
  }
`;
