export interface IDropDownSelect {
  id: string;
  name: string;
  type: string;
}

export interface IChipSectionList {
  [key: string]: IDropDownSelect[] | string;
}

export const removeFilterItem = (filterData: any, item: IDropDownSelect) => {
  const filterChipsData: IChipSectionList = {
    ...filterData,
  } as IChipSectionList;
  if (typeof filterChipsData[item.type] === "string") {
    filterChipsData[item.type] = "";
  } else {
    filterChipsData[item.type] = (
      filterChipsData[item.type] as IDropDownSelect[]
    ).filter(
      (d: any) => !(d.id ? item.id === d.id : item.id === d.value)
    ) as IDropDownSelect[];
  }
  return filterChipsData;
};

export const joinValuesAndData = (
  data: any[],
  values: any[],
  accessor?: Record<"name" | "value", string>
) => {
  let filtered = data.filter(
    (item) =>
      values.findIndex((val) => {
        if (accessor) return val[accessor.value] === item[accessor.value];
        return val === item;
      }) === -1
  );
  return [...filtered, ...values];
};
