import { Space } from "../../atoms/Space";
import { useEffect } from "react";
import { useUser } from "../../../lib/contexts/usercontext";
import { Input } from "../../atoms/MantineInput";
import { Textarea } from "../../atoms/Textarea";
import SwitchInput from "../../atoms/Switch";
import Typography from "../../atoms/Typography";
import { Group } from "../../atoms/Group";

export const CategoryForm = ({ state, handleChange, type }: any) => {
  const user = useUser();
  useEffect(() => {
    handleChange({ groupId: user.state.me?.mutual_groups?.[0].codename });
  }, [user]);

  return (
    <>
      <Input
        label="Name"
        placeholder="Enter category name"
        value={state.name}
        onChange={(e) => handleChange({ name: e.target.value })}
        required
      />
      <Space h="lg" />
      <Textarea
        label="Description"
        placeholder="Enter category description"
        minRows={10}
        value={state.description}
        onChange={(e) => handleChange({ description: e.target.value })}
      />
      <Space h="lg" />
      <Group className="flex justify-between" mr="md">
        <Group align="center">
          <Typography variant="body2">
            <b>Is this a course category?</b>
          </Typography>
          <SwitchInput
            onLabel="Yes"
            offLabel="No"
            size="lg"
            checked={state.isCourseCategory}
            onChange={(item) => {
              handleChange({ isCourseCategory: item.currentTarget.checked });
            }}
          />
        </Group>
        {type === "edit" ? (
          <Group align="center">
            <Typography variant="body2">
              <b>Is Active?</b>
            </Typography>
            <SwitchInput
              onLabel="Yes"
              offLabel="No"
              size="lg"
              checked={state.isActive}
              onChange={(item) => {
                handleChange({ isActive: item.currentTarget.checked });
              }}
            />
          </Group>
        ) : null}
      </Group>
    </>
  );
};
