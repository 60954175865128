import styled from "styled-components";
import { checkEncodeURI } from "../../../lib/utils/urlQueryFormat";

export const NotificationPreviewContainer = styled.div``;

export const SelectedImageContainer = styled.div<{ imageUrl: string }>`
  height: 20rem;
  border-radius: 1rem;
  overflow: hidden;
  background-image: url(${(props) => checkEncodeURI(props.imageUrl) ? props.imageUrl : encodeURI(props.imageUrl)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  width: 50%;
`;

export const FeatureImage = styled(SelectedImageContainer)`
  text-align: center;
  width: 100%;
  height: 22rem;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 1rem;
`;

export const NotificationContentContainer = styled.div`
  font-size: 1.4rem;
  margin: 2rem 0;
  color: ${({ theme }) => theme.colors.primary.main};
  img,
  p {
    margin: 1rem 0;
    max-width: 100%;
  }
  img {
    display: inline-block;
  }
  a {
    color: ${({ theme }) => theme.colors.blue.default};
    :hover {
      text-decoration: underline;
    }
  }
  blockquote {
    margin-bottom: 1.2rem;
    padding-left: 1.6rem;
    border-left: 4px solid ${({ theme }) => theme.colors.border};
  }
  ul {
    list-style: disc;
    margin: 1rem 0;
    padding-left: 1rem;
  }
  ol {
    list-style: decimal;
    margin: 1rem 0;
    padding-left: 1rem;
  }

  h1 {
    font-size: ${({ theme }) => theme.fonts.h1?.fontSize};
    font-weight: ${({ theme }) => theme.fonts.h1?.fontWeight};
  }
  h2 {
    font-size: ${({ theme }) => theme.fonts.h2?.fontSize};
    font-weight: ${({ theme }) => theme.fonts.h2?.fontWeight};
  }
  h3 {
    font-size: ${({ theme }) => theme.fonts.h3?.fontSize};
    font-weight: ${({ theme }) => theme.fonts.h3?.fontWeight};
  }
  h4 {
    font-size: ${({ theme }) => theme.fonts.h4?.fontSize};
    font-weight: ${({ theme }) => theme.fonts.h4?.fontWeight};
  }

  .ql-align-left {
    text-align: left;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
`;
