import { useEffect, useState } from "react";
import Table from "../../molecules/Table";
import { Pagination } from "../../molecules/Pagination";
import { useOutletContext, useParams } from "react-router";
import { TabsContentMap } from "../../../pages/NotificationsListing/data";
import { NotificationsTableContainer } from "../../../pages/NotificationsListing/styles";
import Typography from "../../atoms/Typography";
import { Select } from "../../atoms/MantineSelect";
import { config } from "../../../config";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import { TableFooter } from "../../molecules/Table/styles";
import { Space } from "../../atoms/Space";
import {
  notificationsDefaultSorting,
  notificationSortingMap,
  datetime_mapping,
} from "./data";
import { SortingRule } from "react-table";
import {
  INotificationData,
  INotificationDetailData,
  INotificationOutletContext,
} from "./type";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_NETWORK_USERS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATION_DETAIL,
  notificationConditions,
} from "./query";
import {
  normalizeNotificationData,
  normalizeNotificationDetailData,
} from "./normalizer";
import { sortingMapper } from "../../../lib/utils/table";
import NotificationPreview from "../../templates/NotificationPreview";
import { Modal } from "../../atoms/Modal";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { useUser } from "../../../lib/contexts/usercontext";

const TabsTableContent = () => {
  let params: any = useParams();
  let type = TabsContentMap[params.type] ? params.type : "broadcasted";
  const { columns } = TabsContentMap[type];
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("notificationsConfig")?.itemsPerPage || config.itemsPerPage
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("notificationsConfig")?.sortBy ||
      notificationsDefaultSorting
  );
  const { state: user } = useUser();
  let organisationId = user.me?.agent?.organisation?.id;

  const {
    currentPage,
    setCurrentPage,
    filterData,
    setResultCount,
    searchText,
    selectedListData,
  } = useOutletContext<INotificationOutletContext>();
  const { data: notificationDetail, loading: notificationDetailLoading } =
    useQuery<INotificationDetailData>(GET_NOTIFICATION_DETAIL, {
      variables: { notificationId },
      skip: !notificationId,
      fetchPolicy: "network-only",
    });

  const getOrderByValue = () => {
    const sortingMapObject: any = sortingMapper(sortBy, notificationSortingMap);
    return {
      ...(sortingMapObject?.broadcast_datetime
        ? { broadcast_datetime: sortingMapObject?.broadcast_datetime }
        : {}),
      ...(sortingMapObject?.title ? { title: sortingMapObject?.title } : {}),
      notification_category: {
        name: sortingMapObject?.name,
      },
    };
  };

  const [
    GetNetworkUsers,
    { data: networkBaseUser, loading: networkBaseUserLoading },
  ] = useLazyQuery(GET_NETWORK_USERS, {
    variables: { orgId: organisationId },
  });

  useEffect(() => {
    if (organisationId) GetNetworkUsers();
  }, [organisationId]);

  /** Get Notification Data here */
  const { data, loading } = useQuery<INotificationData>(GET_NOTIFICATIONS, {
    variables: {
      broadcast_datetime: datetime_mapping[type],
      offset: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
      order: getOrderByValue(),
      where: notificationConditions({
        baseUsers:
          networkBaseUser?.base_user_network?.map(
            (item: any) => item?.base_user?.reference_id
          ) || [],
        broadcast_datetime: datetime_mapping[type],
        fromDate: filterData.fromDate,
        toDate: filterData.toDate,
        category: filterData.category
          ? selectedListData?.category?.listData?.find(
              (cat: any) => cat?.value === filterData.category
            )?.codename
          : filterData.category,
        title: searchText,
      }),
    },
    skip: !networkBaseUser,
    fetchPolicy: "network-only",
  });

  const handleModalDisplay = (e: MouseEvent, value: any) => {
    e.stopPropagation();
    if (value) setNotificationId(value.original?.id);
    else setNotificationId("");
    setDisplayModal((prev) => !prev);
  };

  useEffect(() => {
    setResultCount(
      data?.notification_template_aggregate?.aggregate?.count || 0
    );
  }, [data]);

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("notificationsConfig");
    setLocalStorage("notificationsConfig", {
      ...localConfig,
      itemsPerPage: Number(item),
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("notificationsConfig");
    setLocalStorage("notificationsConfig", { ...localConfig, sortBy: sorting });
    setSortby(sorting);
  };

  return (
    <NotificationsTableContainer data-cy="notifications-table">
      <Table
        tableColumns={columns}
        tableData={data ? normalizeNotificationData(data) : []}
        pageSize={itemsPerPage}
        loading={networkBaseUserLoading || loading}
        fixedHead
        sortByData={sortBy}
        handleSortBy={handleSorting}
        getRowProps={(row: any) => ({
          onClick: (e: MouseEvent) => handleModalDisplay(e, row),
        })}
      />
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w={20} />
        <Select
          listData={config.itemsPerPageList.map((item) => String(item))}
          data-cy="select-entries-per-page"
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && data && (
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              paginationWindow={config.paginationWindow}
              totalPages={Math.ceil(
                data?.notification_template_aggregate.aggregate.count /
                  itemsPerPage
              )}
            />
          )}
        </div>
      </TableFooter>
      <Modal
        isOpen={displayModal}
        handleModal={(e: any) => handleModalDisplay(e, null)}
      >
        {!notificationDetailLoading ? (
          notificationDetail ? (
            <NotificationPreview
              state={normalizeNotificationDetailData(notificationDetail)}
            />
          ) : (
            <></>
          )
        ) : (
          <LogoLoader width={100} margin="15% auto" />
        )}
      </Modal>
    </NotificationsTableContainer>
  );
};

export default TabsTableContent;
