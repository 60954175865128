import { gql } from "@apollo/client";

export const GET_CARE_TREND_METRICS = gql`
  query ProductViewMetrics(
    $cropNutritionId: String!
    $cropProtectionId: String!
    $seedsId: String!
  ) {
    cropNutritionViewProducts: product_view_metrics_by_category(
      category_id: $cropNutritionId
    ) {
      name
      count
    }
    cropProtectionViewProducts: product_view_metrics_by_category(
      category_id: $cropProtectionId
    ) {
      name
      count
    }
    seedsViewProducts: product_view_metrics_by_category(category_id: $seedsId) {
      name
      count
    }
    cropNutritionPurchaseProducts: product_purchase_metrics_by_category(
      category_id: $cropNutritionId
    ) {
      name
      count
    }
    cropProtectionPurchaseProducts: product_purchase_metrics_by_category(
      category_id: $cropProtectionId
    ) {
      name
      count
    }
    seedsPurchaseProducts: product_purchase_metrics_by_category(
      category_id: $seedsId
    ) {
      name
      count
    }
  }
`;

export const CARE_CATEGORIES = gql`
  query CareCategories {
    category(
      where: { name: { _in: ["Seeds", "Crop Protection", "Crop Nutrition"] } }
    ) {
      name
      id
    }
  }
`;

export const GET_PRODUCT_VIEW_BY_USER = gql`
  query GetProductViewByUser {
    productViewByUser: product_view_by_user {
      count
      name
    }
  }
`;
