import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query UsersForTeam($teamId: Int) {
    users: base_user(
      where: { mutual_group_memberships: { mutual_group_id: { _eq: $teamId } } }
    ) {
      id
      name: full_name
    }
  }
`;

export const FARM_DETAIL = gql`
  query farmSidebarDetail($id: Int!) {
    farm: farm_by_pk(id: $id) {
      id
      name
      tag
      farmer {
        name: full_name
        id
        memberships: mutual_group_memberships {
          group: mutual_group {
            id
            name
          }
        }
      }
      created_at
      created_by {
        full_name
      }
      updated_at
      updated_by_id
      verified_by {
        full_name
      }
      verified_on
      verification_remark
      verified: is_verified
      tag
      address {
        id
        name
        address_kind
        village
        taluka
        district
        state
        pincode
        country
        raw: raw_address
        location
        local_area
      }
      quality_checked
    }
  }
`;

export const UPDATE_FARM_DETAIL = gql`
  mutation upsertFarm(
    $id: Int
    $ownerId: Int
    $name: String
    $tag: String
    $addressId: Int
    $qualityChecked: Boolean
  ) {
    farm: insert_farm_one(
      on_conflict: {
        constraint: farm_pkey
        update_columns: [name, farmer_id, tag, address_id, quality_checked]
      }
      object: {
        id: $id
        name: $name
        tag: $tag
        farmer_id: $ownerId
        address_id: $addressId
        quality_checked: $qualityChecked
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpsertAddress($address: address_insert_input!) {
    insert_address_one(
      on_conflict: {
        constraint: address_pkey
        update_columns: [village, taluka, local_area]
      }
      object: $address
    ) {
      id
    }
  }
`;

export const TEAMS_DETAIL = gql`
  query TeamDetail($id: Int!) {
    team: mutual_group_by_pk(id: $id) {
      id
      name
      codename
      photo {
        id
        signed_url
      }
      created_by {
        full_name
        id
      }
      updated_by {
        full_name
        id
      }
      verified_by {
        full_name
        id
      }
      verified: is_verified
      verified_on
      farmer: mutual_group_memberships {
        user: base_user {
          id
          full_name
          number
          companies {
            company {
              name
            }
          }
        }
        mutual_group_role {
          codename
          name
        }
      }
      address {
        id
        name
        address_kind
        village
        taluka
        district
        state
        pincode
        country
        raw: raw_address
        location
        local_area
      }
    }
  }
`;

export const UPDATE_TEAMS = gql`
  mutation upsertTeam($id: Int, $name: String) {
    team: insert_mutual_group_one(
      on_conflict: { constraint: mutual_group_pkey, update_columns: [name] }
      object: { id: $id, name: $name }
    ) {
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation upsertUser(
    $id: Int
    $firstName: String
    $lastName: String
    $username: String
    $number: String
    $secondaryNumber: String
    $dateOfBirth: date
    $gender: String
    $education: String
    $email: String
  ) {
    user: insert_base_user_one(
      on_conflict: {
        constraint: base_user_pkey
        update_columns: [
          first_name
          last_name
          username
          email
          number
          secondary_number
          date_of_birth
          gender
          education
        ]
      }
      object: {
        id: $id
        first_name: $firstName
        last_name: $lastName
        username: $username
        email: $email
        number: $number
        secondary_number: $secondaryNumber
        date_of_birth: $dateOfBirth
        gender: $gender
        education: $education
      }
    ) {
      id
    }
  }
`;

export const UPDATE_TEAM_OWNER = gql`
  mutation updateTeamOwner(
    $mutualGroupId: Int
    $oldOwnerId: Int
    $newOwnerId: Int
  ) {
    update_mutual_group_membership(
      _set: { base_user_id: $newOwnerId }
      where: {
        mutual_group_id: { _eq: $mutualGroupId }
        base_user_id: { _eq: $oldOwnerId }
      }
    ) {
      affected_rows
    }
  }
`;

export const USER_DETAIL = gql`
  query UserDetail($id: Int!) {
    user: base_user_by_pk(id: $id) {
      id
      full_name
      first_name
      last_name
      username
      number
      email
      secondary_number
      date_of_birth
      verified: is_verified
      verification_remark
      education
      created_at
      created_by {
        id
        full_name
      }
      updated_at
      updated_by {
        full_name
      }
      verified_on
      verified_by {
        full_name
      }
      gender
      mutual_group_memberships {
        mutual_group {
          id
          name
        }
      }
      address: current_address {
        id
        name
        address_kind
        village
        taluka
        district
        state
        pincode
        country
        raw: raw_address
        location
        local_area
      }
    }
  }
`;

export const FIELD_DETAIL = gql`
  query fieldDetail($id: Int!) {
    field: field_by_pk(id: $id) {
      id
      name
      tag
      quality_checked
      farm {
        id
        name
        address {
          raw: raw_address
        }
      }
      created_at
      created_by {
        full_name
      }
      updated_at
      updated_by {
        full_name
      }
      verification_remark
      verified: is_verified
      verified_on
      verified_by {
        full_name
      }
      farmer {
        id
        name: full_name
        memberships: mutual_group_memberships {
          group: mutual_group {
            id
            name
          }
        }
      }
      area
      areaUnit: area_unit {
        id
        name
      }
      gatNumber: gat_no
      soil {
        id
        name
      }
      area_catered
    }
  }
`;

export const UPDATE_FIELD = gql`
  mutation upsertField(
    $id: Int
    $name: String
    $ownerId: Int
    $tag: String
    $soilId: Int
    $gatNumber: String
    $areaCatered: geometry
    $area: numeric
    $areaUnitId: Int
    $farmId: Int
    $qualityChecked: Boolean
  ) {
    team: insert_field_one(
      on_conflict: {
        constraint: field_pkey
        update_columns: [
          tag
          name
          gat_no
          soil_id
          farmer_id
          farm_id
          quality_checked
        ]
      }
      object: {
        id: $id
        name: $name
        tag: $tag
        gat_no: $gatNumber
        soil_id: $soilId
        farmer_id: $ownerId
        area_catered: $areaCatered
        area: $area
        area_unit_id: $areaUnitId
        farm_id: $farmId
        quality_checked: $qualityChecked
      }
    ) {
      id
    }
  }
`;

export const VERIFY_FIELD = gql`
  mutation verifyField(
    $id: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_field_by_pk(
      pk_columns: { id: $id }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;

export const PLOT_DETAIL = gql`
  query plotDetail($id: Int!) {
    plot: plot_by_pk(id: $id) {
      id
      name
      tag
      quality_checked
      is_archived
      created_at
      created_by {
        id
        full_name
      }
      updated_at
      updated_by {
        id
        full_name
      }
      verification_remark
      verified: is_verified
      verified_on
      verified_by {
        id
        full_name
      }
      field {
        id
        name
        farm {
          id
          name
          address {
            raw: raw_address
          }
        }
      }
      farmer {
        id
        name: full_name
        memberships: mutual_group_memberships {
          group: mutual_group {
            id
            name
          }
        }
      }
      area
      areaUnit: area_unit {
        name
      }
      crops {
        name
        crop_variety {
          name
        }
        status {
          name
        }
        associations: networks {
          association: network {
            company {
              name
            }
          }
        }
      }
      area_catered
    }
  }
`;

export const UPDATE_PLOT = gql`
  mutation upsertPlot(
    $id: Int
    $name: String
    $ownerId: Int
    $tag: String
    $areaCatered: geometry
    $area: numeric
    $areaUnitId: Int
    $fieldId: Int
    $qualityChecked: Boolean
  ) {
    plot: insert_plot_one(
      on_conflict: {
        constraint: plot_pkey
        update_columns: [tag, name, farmer_id, field_id, quality_checked]
      }
      object: {
        id: $id
        name: $name
        tag: $tag
        farmer_id: $ownerId
        area_catered: $areaCatered
        area: $area
        area_unit_id: $areaUnitId
        field_id: $fieldId
        quality_checked: $qualityChecked
      }
    ) {
      id
    }
  }
`;

export const UPDATE_PLOT_ARCHIVE = gql`
  mutation ($id: Int!, $isArchived: Boolean) {
    update_plot_by_pk(
      pk_columns: { id: $id }
      _set: { is_archived: $isArchived }
    ) {
      id
      is_archived
    }
  }
`;

export const UPDATE_PLOT_CROP_ARCHIVE = gql`
  mutation ($id: Int!, $isArchived: Boolean) {
    update_plot_crop_by_pk(
      pk_columns: { id: $id }
      _set: { is_archived: $isArchived }
    ) {
      id
      is_archived
    }
  }
`;

export const VERIFY_PLOT = gql`
  mutation verifyPlot(
    $id: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_plot_by_pk(
      pk_columns: { id: $id }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;

export const CROP_DETAIL = gql`
  query cropDetail($id: Int!) {
    crop: plot_crop_by_pk(id: $id) {
      id
      name
      tag
      quality_checked
      created_at
      created_by {
        id
        full_name
      }
      updated_at
      updated_by {
        id
        full_name
      }
      verified: is_verified
      verified_on
      verified_by {
        id
        full_name
      }
      status {
        id
        name
      }
      associations: networks {
        id
        association: network {
          company {
            id
            name
          }
        }
      }
      agents {
        id
        agent {
          id
          name
        }
      }
      cropYear: crop_year
      harvestQuantity: harvest_quantity
      verification_remark
      sowing_date
      noOfPlant: plant_count
      growingCycle: growing_cycle
      estimateHarvest: estd_harvest_quantity
      p2p: p2p_distance
      p2pUnit: p2p_distance_unit {
        name
      }
      r2r: r2r_distance
      r2rUnit: r2r_distance_unit {
        name
      }
      is_seed
      is_archived
      crop {
        id
        name
      }
      variety: crop_variety {
        id
        name
      }
      plot {
        id
        name
        area_catered
        area
        areaUnit: area_unit {
          id
          name
        }
        field {
          id
          name
          farm {
            id
            name
            address {
              raw: raw_address
            }
          }
        }
      }
      farmer {
        id
        name: full_name
        companies {
          company {
            name
          }
        }
        memberships: mutual_group_memberships {
          group: mutual_group {
            id
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_CROP = gql`
  mutation upsertCrop(
    $id: Int
    $name: String
    $ownerId: Int
    $tag: String
    $varietyId: Int
    $noOfPlant: Int
    $growingCycle: Int
    $estimateHarvest: numeric
    $harvestQuantity: numeric
    $p2p: numeric
    $r2r: numeric
    $isSeed: Boolean
    $statusId: Int
    $cropYear: Int
    $plotId: Int
    $cropId: Int
    $qualityChecked: Boolean
  ) {
    crop: insert_plot_crop_one(
      on_conflict: {
        constraint: plot_crop_pkey
        update_columns: [
          tag
          name
          crop_variety_id
          plant_count
          growing_cycle
          estd_harvest_quantity
          p2p_distance
          r2r_distance
          is_seed
          status_id
          harvest_quantity
          farmer_id
          crop_year
          plot_id
          crop_id
          status_id
          quality_checked
        ]
      }
      object: {
        id: $id
        name: $name
        tag: $tag
        crop_variety_id: $varietyId
        plant_count: $noOfPlant
        growing_cycle: $growingCycle
        estd_harvest_quantity: $estimateHarvest
        p2p_distance: $p2p
        r2r_distance: $r2r
        is_seed: $isSeed
        harvest_quantity: $harvestQuantity
        status_id: $statusId
        farmer_id: $ownerId
        crop_year: $cropYear
        plot_id: $plotId
        crop_id: $cropId
        quality_checked: $qualityChecked
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ASSOCIATION = ({
  isUpdate = true,
}: {
  isUpdate: boolean;
}) => {
  const mutation = `
  mutation associatePlotCropToOrganisation(
    ${isUpdate ? "$id: Int!" : ""} 
    $networkId: Int
    $plotCropId: Int
  ) {
    insert_plot_crop_network_one(
      object: {   ${
        isUpdate ? "id: $id," : ""
      } network_id: $networkId, plot_crop_id: $plotCropId }
      on_conflict: {
        constraint: plot_crop_network_pkey
        update_columns: [network_id]
      }
    ) {
      id
      network {
        company {
          name
        }
      }
      plot_crop {
        name
      }
    }
  }
  `;
  return gql(mutation);
};

export const UPDATE_AGRONOMIST = ({
  isUpdate = true,
}: {
  isUpdate: boolean;
}) => {
  const mutation = `
    mutation assignAgronomistToPlotCrop(
      ${isUpdate ? "$id: Int!" : ""} 
      $agentId: Int
      $plotCropId: Int
    ) {
      insert_plot_crop_agent_one(object: {
        ${isUpdate ? "id: $id," : ""}
        plot_crop_id: $plotCropId,
        agent_id: $agentId
      }, on_conflict: {
        constraint: plot_crop_agent_pkey
        update_columns: [agent_id]
      }) {
        id
      }
    }
  `;
  return gql(mutation);
};

export const VERIFY_CROP = gql`
  mutation verifyCrop(
    $id: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_plot_crop_by_pk(
      pk_columns: { id: $id }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;

export const VERIFY_AUDIT = gql`
  mutation verifyFarm(
    $id: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_farm_by_pk(
      pk_columns: { id: $id }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;

export const VERIFY_TEAM_AUDIT = gql`
  mutation verifyTeam(
    $id: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_mutual_group_by_pk(
      pk_columns: { id: $id }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;

export const VERIFY_USER_AUDIT = gql`
  mutation verifyUser(
    $id: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_base_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;

export const VERIFY_DOCUMENT = gql`
  mutation verifyDocument(
    $documentId: Int!
    $remarks: String
    $verifiedById: Int
    $verifiedOn: timestamptz
    $isVerified: Boolean
  ) {
    update_document_by_pk(
      pk_columns: { id: $documentId }
      _set: {
        verification_remark: $remarks
        verified_by_id: $verifiedById
        verified_on: $verifiedOn
        is_verified: $isVerified
      }
    ) {
      id
    }
  }
`;
