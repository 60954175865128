import { ReactNode, useLayoutEffect, useMemo } from "react";
import { createPortal } from "react-dom";

interface IPortalProp {
  children: ReactNode;
}

function Portal({ children }: IPortalProp) {
  const element = useMemo(() => document.createElement("div"), []);
  useLayoutEffect(() => {
    element.setAttribute("id", "portal-root");
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, []);
  return createPortal(children, element);
}
export default Portal;
