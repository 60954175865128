import { IconType } from "react-icons";
import { BsPlayCircle } from "react-icons/bs";
import { HiOutlineDocumentText } from "react-icons/hi";
import { IoMicCircleOutline } from "react-icons/io5";
import AdviceIcon from "../../atoms/Icons/AdviceIcon";
import CareIcon from "../../atoms/Icons/CareIcon";
import DraftsIcon from "../../atoms/Icons/DraftsIcon";
import SchoolIcon from "../../atoms/Icons/SchoolIcon";
import WeatherIcon from "../../atoms/Icons/WeatherIcon";

export const iconMap: { [key: string]: IconType } = {
  blog: HiOutlineDocumentText,
  audio: IoMicCircleOutline,
  video: BsPlayCircle,
};

export const iconCategoriesMap: { [key: string]: IconType } = {
  Advise: AdviceIcon,
  School: SchoolIcon,
  Care: CareIcon,
  Weather: WeatherIcon,
  General: DraftsIcon,
}
