import { getDateTimeDistance } from "../../../lib/utils/getDateTimeDistance";
import Typography from "../../atoms/Typography";

interface Props {
  date?: Date | null;
  name?: string;
}

export function NotifierSection({ date, name }: Props) {
  return (
    <div className="flex flex-row gap-4 items-center my-8" data-cy="notification-notifier">
      {date && <Typography variant="small1">{getDateTimeDistance(date, new Date(), true)}</Typography>}
      {date && name && (
        <span className="w-2 h-2 bg-primary-main rounded-full" />
      )}
      {name && <Typography variant="small1">{name}</Typography>}
    </div>
  );
}
