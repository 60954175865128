import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarWrapper = styled.div<{
  isOpen?: boolean;
  position?: string;
}>`
  position: ${({ position }) => position || "fixed"};
  display: flex;
  top: 7rem;
  left: 0;
  transition: 0.2s ease all;
  height: calc(100vh - 7rem);
  right: ${({ isOpen }) => (isOpen ? 0 : undefined)};
  translate: ${({ isOpen }) => (isOpen ? "0rem" : "-20rem")};
`;

export const SidebarContainer = styled.div`
  width: 20rem;
  transition: 0.2s all ease;
  display: flex;
  height: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${(props) => props.theme.elevation.elevation2};
  padding-bottom: 1.6rem;
  padding-left: 0.8rem;
`;

export const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex-shrink: 0;
`;

export const NavList = styled.div`
  display: flex;
  padding-right: 0.8rem;
  flex-direction: column;
  & > div {
    margin-top: 1rem;
  }
`;
