import { gql } from "@apollo/client";

export const GET_SOIL_TYPES = gql`
  query getSoilTypes {
    soil {
      id
      name
    }
  }
`;

export const GET_FARMS = gql`
  query getFarms($teamId: Int) {
    farm(
      order_by: { name: asc }
      where: {
        farmer: {
          mutual_group_memberships: { mutual_group_id: { _eq: $teamId } }
        }
      }
    ) {
      id
      name
      tag
    }
  }
`;

export const GET_FIELDS = gql`
  query getFields($teamId: Int) {
    field(
      order_by: { name: asc }
      where: {
        farm: {
          farmer: {
            mutual_group_memberships: { mutual_group_id: { _eq: $teamId } }
          }
        }
      }
    ) {
      id
      name
      tag
    }
  }
`;

export const GET_PLOTS = gql`
  query getPlots($teamId: Int) {
    plot(
      where: {
        farmer: {
          mutual_group_memberships: { mutual_group_id: { _eq: $teamId } }
        }
      }
    ) {
      id
      name
      tag
    }
  }
`;

export const GET_ASSOCIATIONS = gql`
  query getAllCompanies {
    company(order_by: { name: asc }) {
      id
      name
      networks {
        id
      }
    }
  }
`;

export const GET_CROPS = gql`
  query getCrops {
    crop(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const GET_CROP_VARIETY = gql`
  query getCropVariety($id: Int) {
    cropVariety: crop_variety(where: { crop_id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const GET_STATUS = gql`
  query getStatus {
    status: plot_status {
      id
      name
    }
  }
`;

export const GET_AGRONOMISTS = gql`
  query getAgronomists {
    agent(order_by: { name: asc }) {
      id
      name
    }
  }
`;
