import React from "react";

import { TypographyProps } from "./types";
import { Text } from "./styles";

const validHtmlTextTags = ["h1", "h2", "h3", "h4", "h5", "h6", "p", "span"];

const Typography: React.FunctionComponent<TypographyProps> = ({
  variant,
  className,
  children,
  textColor,
  prefix,
}: TypographyProps) => {
  const innerText = (
    <Text
      textColor={textColor}
      as={validHtmlTextTags.includes(variant) ? variant : "p"}
      type={variant}
      className={className}
    >
      {children}
    </Text>
  );
  return prefix ? (
    <span className={`flex items-center gap-4`}>
      {prefix}
      {innerText}
    </span>
  ) : (
    innerText
  );
};

export default Typography;
