import { useClipboard } from "@mantine/hooks";
import { Dispatch, useContext, useEffect, useRef, useState } from "react";
import { HiCheck, HiX } from "react-icons/hi";
import { config } from "../../../../config";
import { AssetContext } from "../../../../lib/contexts/AssetContext";
import useLocation from "../../../../lib/hooks/useLocation";
import { DEFAULT_LOCATION } from "../../../../lib/utils/constants";
import { getLocalStorage } from "../../../../lib/utils/localStorage";
import { capitalize } from "../../../../lib/utils/stringFormat";
import { theme } from "../../../../style/theme";
import { Divider } from "../../../atoms/Divider";
import { Grid } from "../../../atoms/Grid";
import { Group } from "../../../atoms/Group";
import AreaIcon from "../../../atoms/Icons/AreaIcon";
import FieldIcon from "../../../atoms/Icons/FieldIcon";
import LinkIcon from "../../../atoms/Icons/LinkIcon";
import LocationIcon from "../../../atoms/Icons/LocationIcon";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import PencilIcon from "../../../atoms/Icons/PencilIcon";
import UserIcon from "../../../atoms/Icons/UserIcon";
import { MantineButton } from "../../../atoms/MantineButton";
import { Input } from "../../../atoms/MantineInput";
import Map from "../../../atoms/Map";
import { assetMarker } from "../../../atoms/Map/addToMap";
import { storeEdges } from "../../../atoms/Map/utils";
import Typography from "../../../atoms/Typography";
import { sidebarTypes } from "../../../templates/TeamMapView/data";
import {
  fetchAssets,
  normalizeGeolocation,
} from "../../../templates/TeamMapView/utils";
import {
  Actions,
  DetailTypes,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";

export interface AddressSectionProps {
  data: any;
  state: TeamState;
  dispatch: Dispatch<Actions>;
  type: DetailTypes;
}

export function AddressSection({
  state,
  dispatch,
  type,
  data,
}: AddressSectionProps) {
  const location: any = useLocation();
  const { setAsset, handleAssetCoords, asset } = useContext(AssetContext);
  const clipboard = useClipboard({ timeout: 300 });
  const [loading, setLoading] = useState(true);

  const map = useRef<google.maps.Map>();
  const [assetList, setAssetList] = useState<{
    farm: any[];
    field: any[];
    plot: any[];
  }>();

  function handleChange(condition: Record<string, any>) {
    if (!map.current) return;
    setLoading(true);
    const edges = storeEdges(map.current, 2, "detailAddress_bounds");
    let hidePlots = getLocalStorage("mapConfig")?.hidePlots;
    if (!edges) return setLoading(false);
    let mapZoom = Number(map.current.getZoom());
    Promise.all([
      fetchAssets({ edges, type: "farm" }),
      ...(mapZoom >= config.zoomLimit.field
        ? [fetchAssets({ edges, type: "field" })]
        : []),
      ...(mapZoom >= config.zoomLimit.plot && !hidePlots
        ? [fetchAssets({ edges, type: "plot" })]
        : []),
    ]).then((result) => {
      setAssetList(() => ({
        farm: normalizeGeolocation(result[0]?.data?.farm, "farm"),
        field: normalizeGeolocation(result[1]?.data?.field, "field"),
        plot: normalizeGeolocation(result[2]?.data?.plot, "plot"),
      }));
      setLoading(false);
    });
  }

  useEffect(() => {
    if (type === "field" || type === "plot" || type === "crop") {
      let coords =
        type === "crop"
          ? data?.plot?.area_catered?.coordinates
          : data?.area_catered?.coordinates;
      setAsset(() => ({
        id: state?.[type].id,
        type,
        coords,
        focus: true,
      }));
    }
  }, [data, type]);

  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: (TeamActions as any)[`SET_${type.toUpperCase()}_VALUE`],
      payload: {
        key: "address",
        value: {
          ...state[type].address,
          ...(!state[type].address.updatedFields.includes(name)
            ? { updatedFields: [...state[type].detail.updatedFields, name] }
            : {}),
          [name]: value,
        },
      },
    });
  };
  const isBounds = () => {
    return ["field", "plot", "crop"].includes(type);
  };
  return (
    <section className="flex  flex-col  gap-8" data-cy="address-section">
      <div className="flex justify-between items-center">
        <div
          className="flex justify-start items-center gap-4"
          data-cy="owner-name-section"
        >
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state[type].detail.owner}</Typography>
        </div>
        <div
          className="flex justify-start items-center gap-4"
          data-cy="team-name-section"
        >
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state[type].detail.teamName}</Typography>
        </div>
      </div>
      <div className="flex flex-row items-end gap-6">
        {!["field", "plot", "crop"].includes(type) && (
          <Grid className="mt-2 flex-grow" data-cy="address-input-section">
            <Grid.Col span={4}>
              <Input
                label="House/Street"
                placeholder="Enter house/street"
                type="text"
                value={state[type].address.houseStreet}
                name="houseStreet"
                size="lg"
                onChange={(e) => {
                  dispatchValue("houseStreet", e.target.value);
                }}
                autoComplete="off"
                error={
                  state[type].address.id && !state[type].address.houseStreet
                    ? "House/Street is required"
                    : ""
                }
                disabled={!state[type].address.id}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Input
                label="Village"
                required
                placeholder="Enter village"
                type="text"
                value={state[type].address.village}
                name="name"
                size="lg"
                onChange={(e) => {
                  dispatchValue("village", e.target.value);
                }}
                autoComplete="off"
                error={
                  state[type].address.id && !state[type].address.village
                    ? "Village is required"
                    : ""
                }
                disabled={!state[type].address.id}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Input
                label="Taluka"
                required
                placeholder="Enter taluka"
                type="text"
                value={state[type].address.taluka}
                name="name"
                size="lg"
                onChange={(e) => {
                  dispatchValue("taluka", e.target.value);
                }}
                autoComplete="off"
                error={
                  state[type].address.id && !state[type].address.taluka
                    ? "Taluka is required"
                    : ""
                }
                disabled={!state[type].address.id}
              />
            </Grid.Col>
          </Grid>
        )}
      </div>
      <div className="flex justify-between items-center">
        <Typography variant="body1">
          <span data-cy="raw-address">{state[type].address.raw}</span>
        </Typography>
        {!(type === "team" || type === "user") && (
          <div>
            {asset?.edit ? (
              <Group spacing={8} align="flex-end" grow>
                <MantineButton
                  variant="outline"
                  size="lg"
                  disabled={!Boolean(asset.id)}
                  onClick={() => handleAssetCoords()}
                >
                  <HiCheck color={theme.colors.orange.main} size={18} />
                </MantineButton>
                <MantineButton
                  variant="outline"
                  size="lg"
                  onClick={() => {
                    setAsset((prev: any) => ({
                      ...prev,
                      edit: false,
                      area: undefined,
                      coords: undefined,
                      handlePlotExpand: undefined,
                    }));
                  }}
                >
                  <HiX color={theme.colors.orange.main} size={18} />
                </MantineButton>
              </Group>
            ) : (
              <MantineButton
                variant="outline"
                size="lg"
                leftIcon={
                  isBounds() ? (
                    <FieldIcon
                      width={18}
                      height={18}
                      color={theme.colors.orange.main}
                    />
                  ) : (
                    <LocationIcon
                      width={18}
                      height={18}
                      color={theme.colors.orange.main}
                    />
                  )
                }
                onClick={() => {
                  setAsset((prev: any) => ({
                    ...prev,
                    id: data.id,
                    type,
                    edit: true,
                  }));
                }}
              >
                {isBounds() ? "BOUNDS" : "LOCATION"}
              </MantineButton>
            )}
          </div>
        )}
      </div>
      {isBounds() && (
        <>
          <Divider size={1} mt={4} mb={2} />
          <div
            className="flex justify-between items-center"
            data-cy="address-area-info-section"
          >
            <div className="flex items-center gap-3">
              <Typography variant="body1">{capitalize(type)} area</Typography>
              <AreaIcon
                color={theme.colors.orange.main}
                className="w-12 h-12"
              />
              <Typography variant="body1">
                <span className="font-bold">
                  {(state[type].detail as any).area}{" "}
                  {(state[type].detail as any).areaUnit}
                </span>
              </Typography>
            </div>
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => {
                clipboard.copy(
                  state[type].address.location
                    ? JSON.stringify(state[type].address.location)
                    : "[]"
                );
              }}
            >
              <Typography variant="body1" textColor={theme.colors.orange.main}>
                {clipboard.copied
                  ? `${capitalize(type)} location copied`
                  : `Copy ${type} location`}
              </Typography>
              <LinkIcon />
            </div>
          </div>
        </>
      )}
      <Map
        map={map}
        handleChange={handleChange}
        assetList={assetList}
        name="detailAddress"
        containerStyle="w-full h-[300px] rounded-md"
        center={
          data?.address?.location?.coordinates
            ? {
                lat: data?.address?.location?.coordinates[1],
                lng: data?.address?.location?.coordinates[0],
              }
            : location.status === "error"
            ? DEFAULT_LOCATION
            : {
                lat: location.lat,
                lng: location.lng,
              }
        }
        show={{
          locationMarker: true, // moveToCenter: true,
          moveToSelected: false,
          assets: true,
          zoomLevel: true,
        }}
        icon={
          !(type === "field" || type === "plot" || type === "crop")
            ? {
                locationMarker:
                  assetMarker[type][
                    state[type].verified ? "verified" : "unverified"
                  ],
              }
            : { locationMarker: "" }
        }
        clickHandler={() => {}}
        zoom={16}
        gestureHandling="greedy"
        streetViewControl={false}
        mapTypeId="hybrid"
        mapTypeControlOptions={{
          position: 3,
        }}
        zoomControl={false}
        fullscreenControl={false}
        minZoom={3}
        loading={loading}
      />
    </section>
  );
}
