import styled from "styled-components";
import { IDivider } from "./types";

export const Divider = styled.div<IDivider>`
  flex-grow: 2;
  border: solid 0.5px ${({ theme, color }) => color || theme.colors.border};
  margin: ${({ dir, spacing }) =>
    dir === "verticle" ? `0 ${spacing}rem` : `${spacing}rem 0`};
  background-color: ${({ theme, color }) => color || theme.colors.border};
`;

export const LegendDividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const LegendTitle = styled.div`
  margin-right: 12px;
`;
