import Image from "../Image";
import { MultiThumbnailImagesProps } from "./type";
function MultiThumbnailImages({ imagesData }: MultiThumbnailImagesProps) {
  return (
    <div className="flex flex-row">
      {imagesData?.map((element, index) => (
        <Image
          key={index}
          src={element.image}
          className={`rounded-full w-14 h-14 z-${
            (imagesData.length - index) * 10
          } ${index > 0 ? "-ml-5" : ""} border-solid border border-orange-main`}
          alt={element.alt}
        />
      ))}
    </div>
  );
}
export default MultiThumbnailImages;
