import { gql } from "@apollo/client";

export const CARE_PRODUCT_DETAILS = gql`
  query CareProductDetails(
    $sellable_stock_id: uuid!
    $store_id: String!
    $user_id: String! #reference_id
    $sellable_stock_string_pk: String!
  ) {
    product(
      where: {
        sellable_items: {
          sellable_item_stocks: { id: { _eq: $sellable_stock_id } }
        }
      }
    ) {
      id
      name
      description
      product_attachments(order_by: { order: asc }) {
        order
        photo {
          url
          media_type
          thumbnail_url
        }
      }
      product_favourites(
        where: {
          product: {
            sellable_items: {
              sellable_item_stocks: { id: { _eq: $sellable_stock_id } }
            }
          }
          user_id: { _eq: $user_id }
        }
      ) {
        id
      }
      product_views_aggregate {
        aggregate {
          count
        }
      }
      product_purchased_aggregate(
        where: { sellable_stock: { store_id: { _eq: $store_id } } }
      ) {
        aggregate {
          sum {
            item_quantity
          }
        }
      }
      featured_products: sellable_items(
        where: {
          sellable_item_stocks: {
            is_featured: { _eq: true }
            store_id: { _eq: $store_id }
            is_live: { _eq: true }
          }
        }
      ) {
        product_sku {
          name
          id
          short_name
        }
        sellable_item_stocks {
          seller {
            id
            name
          }
          id
          is_featured
          stock_reference_id
          discounted_rate: discounted_taxed_rate
          unit_rate: taxed_rate
          remaining_stock
          id
          product_favourites(where: { user_id: { _eq: $user_id } }) {
            id
          }
        }
      }
      sellable_items(
        where: {
          sellable_item_stocks: {
            store_id: { _eq: $store_id }
            is_live: { _eq: true }
          }
        }
      ) {
        product_sku {
          name
          id
          short_name
        }
        sellable_item_stocks(
          where: { store_id: { _eq: $store_id }, is_live: { _eq: true } }
        ) {
          seller {
            id
            name
          }
          id
          is_featured
          stock_reference_id
          discounted_rate: discounted_taxed_rate
          unit_rate: taxed_rate
          remaining_stock
          id
        }
      }

      product_certifications {
        issue_date
        product_certification_attachments {
          photo {
            url
          }
        }
      }
      marketing_facility {
        marketer {
          name
        }
      }
      manufacturing_facility {
        manufacturer {
          name
        }
      }
      product_mainsections(order_by: { order: asc }) {
        title: main_section {
          name
        }
        product_mainsection_attachments {
          photo {
            url
            id
            media_type
            thumbnail_url
          }
        }
        display_as
        order
        value
        attributes: product_attributes(order_by: { order: asc }) {
          attribute {
            name
          }
          order
          value
          product_attribute_choices {
            attribute_choice {
              text
            }
          }
        }
        product_sections(order_by: { order: asc }) {
          product_section_attachments {
            photo {
              url
              id
              media_type
              thumbnail_url
            }
          }
          title: section {
            name
          }
          display_as
          order
          value
          product_attributes(order_by: { order: asc }) {
            attribute {
              name
            }
            order
            value
            product_attribute_choices(order_by: { order: asc }) {
              order
              attribute_choice {
                text
              }
            }
          }
          product_subsections(order_by: { order: asc }) {
            title: sub_section {
              name
            }
            display_as
            order
            value
            product_attributes(order_by: { order: asc }) {
              attribute {
                name
              }
              order
              value
              product_attribute_choices {
                attribute_choice {
                  text
                }
              }
            }
          }
        }
      }
    }
    product_composition: product_composition_by_product(
      sellable_stock_id: $sellable_stock_string_pk
    )
    nutrient_recommendation: product_nutrition_recommendation(
      sellable_stock_id: $sellable_stock_string_pk
    )
    protection_recommendation: product_infestation_recommendation(
      sellable_stock_id: $sellable_stock_string_pk
    )
  }
`;
