import { ImageProps } from "./type";
import ImagePlaceholder from "../../../assets/placeholderImage.png";

function Image({ src, ...props }: ImageProps) {
  return (
    <img
      src={src || ImagePlaceholder}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = ImagePlaceholder;
      }}
      {...props}
    />
  );
}

export default Image;
