import styled from "styled-components";

export const SidebarIconText = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  & svg {
    flex-shrink: 0;
    margin-top: 0.25rem;
  }
`;
