import { SVGProps } from "react";

export const AssociationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.40005 4.39922C4.40005 3.18419 5.38502 2.19922 6.60005 2.19922H15.4C16.6151 2.19922 17.6 3.18419 17.6 4.39922V17.5992C18.2076 17.5992 18.7 18.0917 18.7 18.6992C18.7 19.3067 18.2076 19.7992 17.6 19.7992H14.2C13.6478 19.7992 13.2 19.3515 13.2 18.7992V16.4992C13.2 15.8917 12.7076 15.3992 12.1 15.3992H9.90005C9.29254 15.3992 8.80005 15.8917 8.80005 16.4992V18.7992C8.80005 19.3515 8.35233 19.7992 7.80005 19.7992H4.40005C3.79254 19.7992 3.30005 19.3067 3.30005 18.6992C3.30005 18.0917 3.79254 17.5992 4.40005 17.5992V4.39922ZM7.70005 5.49922H9.90005V7.69922H7.70005V5.49922ZM9.90005 9.89922H7.70005V12.0992H9.90005V9.89922ZM12.1 5.49922H14.3V7.69922H12.1V5.49922ZM14.3 9.89922H12.1V12.0992H14.3V9.89922Z"
      fill={props.color || "#005777"}
    />
  </svg>
);
