import { SVGProps } from "react";

const FarmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4797 11.8856C10.4638 11.875 10.4531 11.859 10.4372 11.843C10.1816 11.5448 9.88871 11.268 9.36158 11.268C8.5735 11.268 8.19009 11.875 8.19009 12.892H15.8578C15.8578 11.875 15.4797 11.268 14.6863 11.268C14.0686 11.268 13.7651 11.63 13.5894 11.843L13.5628 11.875V10.0698C13.5628 9.52137 13.7705 9.09543 14.4307 8.75995C14.0899 9.10604 13.9142 9.44686 13.8769 9.84088C15.0804 9.83025 15.5809 8.96758 15.6448 7.97721C14.1166 8.06776 13.3338 8.61085 13.2486 9.62256C12.8546 9.08474 12.1038 8.85043 11.1667 8.85043C11.1932 9.8408 11.6566 10.6928 12.9238 10.7141C12.9771 10.4372 12.828 10.1337 12.3807 9.66512C13.0836 9.89941 13.2433 10.187 13.2433 10.8419V11.9441C13.1954 11.9228 13.1527 11.8909 13.1155 11.8429C12.9291 11.6246 12.6203 11.2679 12.0133 11.2679C11.3956 11.2679 11.0868 11.63 10.9057 11.8429C10.8738 11.8802 10.8419 11.9122 10.8046 11.9282V8.48303C10.8046 7.68431 11.0175 7.32751 11.6672 7.04003C11.2199 7.50863 11.0708 7.81216 11.0175 8.08901C12.3913 8.06769 12.8546 7.22105 12.8812 6.22534C11.4702 6.22534 10.4797 6.75782 10.4797 8.05706V8.56822C10.1123 8.09962 9.42013 7.83875 8.40308 7.78014C8.46696 8.77051 8.9675 9.63318 10.1709 9.64915C10.1284 9.24979 9.95795 8.90897 9.61712 8.56288C10.2774 8.89836 10.4797 9.3243 10.4797 9.87275L10.4797 11.8856Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M11.9962 1.33325C8.32281 1.33325 5.33334 4.32289 5.33334 8.00373C5.33581 11.5309 11.8524 21.5168 11.9962 21.5168C12.1424 21.5168 18.6666 11.5309 18.6666 8.00373C18.6668 4.3228 15.6796 1.33325 11.9962 1.33325ZM11.9962 12.7397C9.38487 12.7397 7.26069 10.6153 7.26069 8.00418C7.26069 5.38285 9.38501 3.26105 11.9962 3.26105C14.6175 3.26105 16.7393 5.38537 16.7393 8.00418C16.7418 10.6155 14.6174 12.7397 11.9962 12.7397Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default FarmIcon;
