import { SwitchProps } from "@mantine/core";
import { SwitchWrapper } from "./style";

export interface ISwitchInput extends SwitchProps {
  label?: string;
}

function SwitchInput({ ...props }: ISwitchInput) {
  return <SwitchWrapper {...props} />;
}

export default SwitchInput;
