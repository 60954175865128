import { Grid } from "../../atoms/Grid";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { NoResults } from "../../atoms/NoResults";
import { ProductCard } from "../../organisms/CareSections/ProductCard";

export function CardSection({
  products,
  loading,
  handleClick,
}: {
  products: any[];
  loading: boolean;
  handleClick?: (value: string) => void;
}) {
  return loading ? (
    <LogoLoader width={100} margin="5% auto" />
  ) : !products.length ? (
    <NoResults width="20%" />
  ) : (
    <Grid>
      {products.map((item: any, i: number) => (
        <Grid.Col sm={6} md={4} lg={3} xl={2} className="flex" key={i}>
          <ProductCard product={item} handleClick={handleClick} />
        </Grid.Col>
      ))}
    </Grid>
  );
}
