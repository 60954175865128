import { Divider, LegendDividerContainer, LegendTitle } from "./styles";
import { LegendDividerProps } from "./types";

function LegendDivider({ title, ...props }: LegendDividerProps) {
  return (
    <LegendDividerContainer>
      <LegendTitle>{title}</LegendTitle>
      <Divider {...props} />
    </LegendDividerContainer>
  );
}

export default LegendDivider;
