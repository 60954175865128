import { Tabs } from "@mantine/core";
import { Fragment } from "react";
import { FaBookmark, FaThumbsUp } from "react-icons/fa";
import { HiEye } from "react-icons/hi";
import { colors } from "../../../style/mantine/colors";
import CartCheckIcon from "../../atoms/Icons/CartCheckIcon";
import EnvelopCheckIcon from "../../atoms/Icons/EnvelopCheckIcon";
import UsersIcon from "../../atoms/Icons/UsersIcon";
import SkeletonLoader from "../../atoms/Loader/SkeletonLoader";
import Typography from "../../atoms/Typography";
import { StyledTabs, TrendContainer } from "./styles";
import { TrendList } from "./TrendList";

type TabNames =
  | "likes"
  | "views"
  | "favorites"
  | "purchase"
  | "notification"
  | "usersSubscribers";

interface IProps {
  label: string;
  tabsNames?: TabNames[];
  defaultValue?: TabNames;
  data: any;
  loading: boolean;
  className?: string;
}

export const TrendMetricsCard = ({
  label,
  data,
  loading,
  className,
  tabsNames = ["views", "likes", "favorites"],
  defaultValue = "views",
}: IProps) => {
  return !loading ? (
    <TrendContainer
      data-cy="trend-metrics-card"
      className={`${className || ""} px-4`}
    >
      <Typography variant="subtitle2">{label}</Typography>
      <Tabs defaultValue={defaultValue} variant="pills">
        <StyledTabs grow>
          {tabsNames.map((tabName) => (
            <Fragment key={tabName}>
              {tabName === "likes" && (
                <Tabs.Tab value="likes" icon={<FaThumbsUp />} />
              )}
              {tabName === "favorites" && (
                <Tabs.Tab value="favorites" icon={<FaBookmark />} />
              )}
              {tabName === "views" && (
                <Tabs.Tab value="views" icon={<HiEye />} />
              )}
              {tabName === "purchase" && (
                <Tabs.Tab value="purchase" icon={<CartCheckIcon />} />
              )}
              {tabName === "notification" && (
                <Tabs.Tab value="notification" icon={<EnvelopCheckIcon />} />
              )}
              {tabName === "usersSubscribers" && (
                <Tabs.Tab
                  value="usersSubscribers"
                  icon={<UsersIcon color={colors.orange.main} />}
                />
              )}
            </Fragment>
          ))}
        </StyledTabs>
        {tabsNames.map((tabName, index) => (
          <span key={index}>
            {tabName === "likes" && (
              <Tabs.Panel value="likes">
                <TrendList data={data?.likes || []} />
              </Tabs.Panel>
            )}
            {tabName === "favorites" && (
              <Tabs.Panel value="favorites">
                <TrendList data={data?.favorites || []} />
              </Tabs.Panel>
            )}
            {tabName === "views" && (
              <Tabs.Panel value="views">
                <TrendList data={data?.views || []} />
              </Tabs.Panel>
            )}
            {tabName === "purchase" && (
              <Tabs.Panel value="purchase">
                <TrendList data={data?.purchase || []} />
              </Tabs.Panel>
            )}
            {tabName === "notification" && (
              <Tabs.Panel value="notification">
                <TrendList data={data?.notification || []} />
              </Tabs.Panel>
            )}
            {tabName === "usersSubscribers" && (
              <Tabs.Panel value="usersSubscribers">
                <TrendList data={data?.usersSubscribers || []} />
              </Tabs.Panel>
            )}
          </span>
        ))}
      </Tabs>
    </TrendContainer>
  ) : (
    <SkeletonLoader height={400} />
  );
};
