import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { capitalize } from "../../../../lib/utils/stringFormat";
import { theme } from "../../../../style/theme";
import { Group } from "../../../atoms/Group";
import Typography from "../../../atoms/Typography";
import { GetEntityIcon } from "./GetEntityIcon";

export function SidebarBottomItem(props: {
  type: string;
  listType: string;
  name: string;
  value: number;
  id: number | string;
}) {
  return typeof props.value === "number" ? (
    <Link
      to={{
        search: `type=${props.type}&id=${props.id}&listType=${props.listType}&name=${props.name}`,
      }}
      style={{ ...(!props.value ? { pointerEvents: "none" } : {}) }}
    >
      <Group position="apart" align="flex-start" noWrap>
        <Group spacing={8} align="center">
          <GetEntityIcon type={props.type} />
          <Typography variant="subtitle1">
            {capitalize(props.type, true)}
          </Typography>
          <span className="bg-primary-700 p-1 rounded-full" />
          <Typography variant="body2">{props.value}</Typography>
        </Group>
        <HiChevronRight
          className="mt-1 cursor-pointer shrink-0"
          size={18}
          color={theme.colors.orange.main}
        />
      </Group>
    </Link>
  ) : null;
}
