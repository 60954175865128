import { SVGProps } from "react";

const UserAddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5999 10.8016C11.5881 10.8016 13.1999 9.18979 13.1999 7.20156C13.1999 5.21334 11.5881 3.60156 9.5999 3.60156C7.61168 3.60156 5.9999 5.21334 5.9999 7.20156C5.9999 9.18979 7.61168 10.8016 9.5999 10.8016Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M9.5999 13.2016C13.5764 13.2016 16.7999 16.4251 16.7999 20.4016H2.3999C2.3999 16.4251 5.62345 13.2016 9.5999 13.2016Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M19.1999 8.40156C19.1999 7.73882 18.6626 7.20156 17.9999 7.20156C17.3372 7.20156 16.7999 7.73882 16.7999 8.40156V9.60156H15.5999C14.9372 9.60156 14.3999 10.1388 14.3999 10.8016C14.3999 11.4643 14.9372 12.0016 15.5999 12.0016H16.7999V13.2016C16.7999 13.8643 17.3372 14.4016 17.9999 14.4016C18.6626 14.4016 19.1999 13.8643 19.1999 13.2016V12.0016H20.3999C21.0626 12.0016 21.5999 11.4643 21.5999 10.8016C21.5999 10.1388 21.0626 9.60156 20.3999 9.60156H19.1999V8.40156Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default UserAddIcon;
