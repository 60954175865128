import { MouseEvent, useEffect, useState } from "react";
import Table from "../../molecules/Table";
import { Pagination } from "../../atoms/Pagination";
import { useQuery } from "@apollo/client";
import { useOutletContext, useParams } from "react-router";
import { TabsContentMap } from "../../../pages/Articles/data";
import {
  IArticleQueryData,
  IArticlesOutletContext,
} from "../../../pages/Articles/types";
import { ArticlesTableContainer } from "../../../pages/Articles/styles";
import Typography from "../../atoms/Typography";
import { Modal } from "../../atoms/Modal";
import { GET_ARTICLE, GET_ARTICLE_LIST } from "./query";
import ArticlePreview from "../../templates/ArticlePreview";
import { articleNormalizer } from "../../templates/ArticlePreview/normalizer";
import { Select } from "../../atoms/MantineSelect";
import { config } from "../../../config";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import { TableFooter } from "../../molecules/Table/styles";
import { ArticleDeleteModal } from "./ArticleDeleteModal";
import { Space } from "../../atoms/Space";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { sortingMapper } from "../../../lib/utils/table";
import { articleDefaultSorting } from "./data";
import { SortingRule } from "react-table";
import { useUser } from "../../../lib/contexts/usercontext";
import { gqlQueryDate } from "../../../lib/utils/dateTimeFormat";
import { RestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";

const TabsTableContent = () => {
  let params: any = useParams();
  let type = TabsContentMap[params.type] ? params.type : "published";
  const { columns, status } = TabsContentMap[type];
  const [displayModal, setDisplayModal] = useState(false);
  const [articleId, setArticleId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("articleConfig")?.itemsPerPage || config.itemsPerPage
  );
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("articleConfig")?.sortBy || articleDefaultSorting
  );
  const { state: user } = useUser();
  const { data: articleData, loading: articleLoading } =
    useQuery<IArticleQueryData>(GET_ARTICLE, {
      variables: { id: articleId },
      skip: !articleId,
      fetchPolicy: "network-only",
    });

  const handleModalDisplay = (e: MouseEvent, value: any) => {
    e.stopPropagation();
    if (value) setArticleId(value.original?.id);
    else setArticleId("");
    setDisplayModal((prev) => !prev);
  };

  const {
    filterData,
    searchText,
    currentPage,
    setCurrentPage,
    setResultCount,
  } = useOutletContext<IArticlesOutletContext>();

  const { loading, data, error, refetch } = useQuery(GET_ARTICLE_LIST, {
    variables: {
      offset: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
      order: [sortingMapper(sortBy) || {}],
      search: searchText,
      status: status || "publish",
      article_type: filterData?.articleType,
      category_ids: filterData?.categories?.map((item: any) => item.id),
      crop_ids: filterData?.crops?.map((item: any) => item.id),
      package_ids: filterData?.subscriptionPackages?.map(
        (item: any) => item.id
      ),
      publish_date_from: filterData?.fromDate
        ? gqlQueryDate(filterData?.fromDate)
        : undefined,
      publish_date_till: filterData?.toDate
        ? gqlQueryDate(filterData?.toDate)
        : undefined,
      region_ids: filterData?.regions?.map((item: any) => item.id),
    },
  });

  useEffect(() => {
    setResultCount(data?.article?.count);
  }, [data]);

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("articleConfig");
    setLocalStorage("articleConfig", {
      ...localConfig,
      itemsPerPage: Number(item),
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("articleConfig");
    setLocalStorage("articleConfig", { ...localConfig, sortBy: sorting });
    setSortby(sorting);
  };

  return (
    <ArticlesTableContainer data-cy="articles-table">
      <Table
        tableColumns={columns}
        tableData={data?.article?.records || []}
        pageSize={itemsPerPage}
        loading={loading}
        getRowProps={(row: any) => ({
          onClick: (e: MouseEvent) => handleModalDisplay(e, row),
        })}
        fixedHead
        sortByData={sortBy}
        handleSortBy={handleSorting}
      />
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w={20} />
        <Select
          data-cy="select-entries-per-page"
          listData={config.itemsPerPageList.map((item) => String(item))}
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && !error && (
            <Pagination
              page={currentPage}
              onChange={setCurrentPage}
              total={Math.ceil(
                (data?.article?.count || itemsPerPage) / itemsPerPage
              )}
              boundaries={0}
              siblings={config.paginationWindow / 2 - 1}
              withEdges
              position="right"
            />
          )}
        </div>
      </TableFooter>

      <Modal
        isOpen={displayModal}
        handleModal={(e) => handleModalDisplay(e, null)}
      >
        {!articleLoading ? (
          <ArticlePreview data={articleNormalizer(articleData?.article)} />
        ) : (
          <LogoLoader width={100} margin="15% auto" />
        )}
      </Modal>
      <RestrictedContent keyName="advice_management" permission="delete">
        <ArticleDeleteModal data={data} refetch={refetch} />
      </RestrictedContent>
    </ArticlesTableContainer>
  );
};

export default TabsTableContent;
