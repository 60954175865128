import {
  Actions,
  NotificationState,
} from "../../../../pages/CreateNotification/type";
import DatePickerInput from "../../../atoms/Input/DatePicker";
import TimePickerInput from "../../../atoms/Input/TimePicker";
import { Input } from "../../../atoms/MantineInput";
import Typography from "../../../atoms/Typography";
import { NotificationCreateInput, TimePickerInputContainer } from "./style";

export interface Props {
  state: NotificationState;
  dispatch: React.Dispatch<Actions>;
  handleChangeValue: (
    key: keyof NotificationState,
    value: string | object | undefined | null
  ) => void;
}
export function BroadcastSection({ state, handleChangeValue }: Props) {
  return (
    <section
      className="flex flex-row gap-6"
      data-cy="notification-broadcast-section"
    >
      <NotificationCreateInput className="w-1/3">
        <Typography variant="h6">
          <span className="font-bold">Broadcast Date*</span>
        </Typography>
        <div>
          <DatePickerInput
            size="lg"
            placeholder="Pick date time"
            value={state.broadcastDateTime}
            onChange={(value: Date) => {
              if(state.broadcastDateTime) {
                value?.setHours(state.broadcastDateTime?.getHours());
                value?.setMinutes(state.broadcastDateTime?.getMinutes());
              } else if(value.getDate() === (new Date()).getDate()) {
                const date = new Date();
                value?.setHours(date.getHours());
                value?.setMinutes(date.getMinutes() + 5);
              }
              handleChangeValue("broadcastDateTime", value);
            }}
            minDate={new Date()}
          />
        </div>
      </NotificationCreateInput>

      <TimePickerInputContainer className="w-1/3">
        <Typography variant="h6">
          <span className="font-bold">Broadcast Time*</span>
        </Typography>
        <div>
          <TimePickerInput
            size="lg"
            placeholder="Pick date time"
            value={state.broadcastDateTime}
            onChange={(value: Date) => {
              const broadcastDate = state.broadcastDateTime;
              broadcastDate?.setHours(value.getHours());
              broadcastDate?.setMinutes(value.getMinutes());
              handleChangeValue("broadcastDateTime", broadcastDate);
            }}
          />
        </div>
      </TimePickerInputContainer>
      <div className="mt-5 w-1/3">
        <Input
          data-test-id="broadcast-by"
          label="Broadcast by"
          type="text"
          value={state.broadcastBy?.name || ""}
          name="broadcastBy"
          size="lg"
          readOnly
        />
      </div>
    </section>
  );
}
