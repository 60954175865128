import {
  MantineTheme,
  Pagination as MantinePagination,
  PaginationProps,
} from "@mantine/core";

const styles = (theme: MantineTheme) => ({
  item: {
    ...theme.other.fonts.body2,
    borderColor: "transparent",
    color: theme.other.colors.primary.main,
    ":hover": {
      borderColor: theme.other.colors.primary.main,
    },
    "&[data-dots]:hover, :disabled:hover": {
      borderColor: "transparent",
    },
    "&[data-active]": {
      background: theme.other.colors.primary.main,
      color: theme.other.colors.white,
    },
  },
});

export const Pagination = (props: PaginationProps) => {
  return <MantinePagination {...props} styles={styles} data-cy="pagination" />;
};
