export const gaps = {
  "4": "4px",
  "8": "8px",
  "12": "12px",
  "16": "16px",
  "24": "24px",
  "32": "32px",
  "40": "40px",
  "48": "48px",
  "56": "56px",
  "60": "60px",
  "72": "72px",
  "96": "96px",
  "112": "112px",
  "120": "120px",
  "144": "144px",
};

export const shadow = {
  elevation1: "0px 4px 6px #0000001A",
  elevation2: "0px 4px 8px #0000000D",
  elevation3: "0px 4px 20px #0000001A",
  elevation4: "0px -10px 20px #00000026",
  elevation5: "10px 10px 20px #00000026",
};
