import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Chip } from "../../atoms/Chip";
import Typography from "../../atoms/Typography";
import { theme } from "../../../style/theme";
import { Space } from "../../atoms/Space";
import { fullDate } from "../../../lib/utils/dateTimeFormat";
import { capitalize } from "../../../lib/utils/stringFormat";
import { ScrollArea } from "../../atoms/ScrollArea";
import { Group } from "../../atoms/Group";
import { BackArrowIcon, FrontArrowIcon } from "./styles";
import dayjs from "dayjs";

export const FilterChips: FC<{
  count?: number;
  chipList: any;
  values: any;
  handleValues: any;
  handleClear?: () => void;
}> = ({ count, chipList, values, handleValues, handleClear }) => {
  const [displayList, setDisplayList] = useState([]);
  const scroll = useRef<HTMLDivElement>(null);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);

  const parseValue = (value: any) => {
    let date = dayjs(value).isValid() && dayjs(value).toISOString();
    if (value instanceof Date || date === value) return fullDate(value);
    return value;
  };

  useEffect(() => {
    let result: any = [];
    Object.entries(chipList).forEach(([key, item]: any) => {
      if (Array.isArray(item)) {
        let accessor = values[key]?.accessor;
        result.push(
          ...item.map((item) => ({
            name: accessor ? item[accessor.name] : item,
            id: accessor ? item[accessor.value] : item,
            type: key,
          }))
        );
      } else {
        result.push({
          name: `${capitalize(key, true)} (${parseValue(item)})`,
          type: key,
        });
      }
    });
    setDisplayList(result);
  }, [chipList]);

  const removeChips = (item: any) => {
    handleValues((prev: any) => {
      if (Array.isArray(prev[item.type])) {
        let accessor = values[item.type]?.accessor;
        let result = prev[item.type].filter(
          (valueItem: any) =>
            (accessor ? valueItem[accessor.value] : valueItem) !== item.id
        );
        return { ...prev, [item.type]: result };
      } else {
        let result = { ...prev };
        delete result[item.type];
        return result;
      }
    });
  };

  const chipsLength = useMemo(
    () =>
      Object.values(chipList).reduce(
        (acc: any, item: any) => acc + (item && item.toString() ? 1 : 0),
        0
      ),
    [chipList]
  );

  useEffect(() => {
    scroll.current &&
      setRightArrow(scroll.current?.scrollWidth > scroll.current?.clientWidth);
  }, [scroll.current?.clientWidth, scroll.current?.scrollWidth]);

  const onScrollPositionChange = (value: any) => {
    setLeftArrow(value.x > 0);
    setRightArrow(
      value.x + Number(scroll.current?.offsetWidth) <
        Number(scroll.current?.scrollWidth) - 2
    );
  };

  const handleScroll = (right = false) => {
    let dir = right ? 1 : -1;
    scroll.current?.scrollTo({
      left:
        scroll.current?.scrollLeft + dir * (scroll.current?.clientWidth / 1.25),
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="flex items-center pl-8 overflow-hidden grow">
        {count !== undefined && (
          <>
            <div className="flex-shrink-0">
              <Typography variant="body1" textColor={theme.colors.orange.main}>
                <span data-cy="total-listing-results">{count || 0}</span>
                {` result${count > 1 ? "s" : ""} found`}
              </Typography>
            </div>
            <Space w={20} />
          </>
        )}
        {leftArrow && (
          <BackArrowIcon onClick={() => handleScroll()} className="mr-2" />
        )}
        <ScrollArea
          type={"never" as any}
          viewportRef={scroll}
          onScrollPositionChange={onScrollPositionChange}
        >
          <Group noWrap>
            {displayList.map((item: any, i: number) => (
              <Chip
                key={i}
                chip={item}
                handleChipDelete={() => removeChips(item)}
              />
            ))}
          </Group>
        </ScrollArea>
        {rightArrow && (
          <FrontArrowIcon onClick={() => handleScroll(true)} className="ml-2" />
        )}
      </div>
      {chipsLength ? (
        <div
          className="cursor-pointer mx-2 relative pl-4"
          onClick={handleClear && handleClear}
        >
          <Typography
            variant="body1"
            textColor={theme.colors.orange.main}
            className="whitespace-nowrap"
          >
            Clear filters
          </Typography>
        </div>
      ) : undefined}
    </>
  );
};
