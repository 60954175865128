import { FaBookmark, FaEye, FaThumbsUp } from "react-icons/fa";
import CoursesIcon from "../../components/atoms/Icons/CoursesIcon";
import { ActivityDataType } from "../../shared/type";

export const totalMetrics: ActivityDataType[] = [
  {
    name: "courses",
    displayName: "All courses",
    icon: CoursesIcon,
    count: null,
  },
  {
    name: "coursesViews",
    displayName: "Views",
    icon: FaEye,
    count: null,
  },
  {
    name: "coursesLikes",
    displayName: "Likes",
    icon: FaThumbsUp,
    count: null,
  },
  {
    name: "coursesFavorites",
    displayName: "Bookmarks",
    icon: FaBookmark,
    count: null,
  },
];
