import { gql } from "@apollo/client";

export const GET_SETUP_CATEGORIES = gql`
  query Categories(
    $limit: Int
    $offset: Int
    $order: [article_category_order_by!]
    $where: article_category_bool_exp
  ) {
    categories: article_category(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
    ) {
      id
      name
      group_id
      description
      is_active
      mutual_group {
        codename
        name
      }
    }
    aggregate: article_category_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_SETUP_PACKAGES = gql`
  query Packages(
    $limit: Int
    $offset: Int
    $order: [JSONScalar]
    $search: String
  ) {
    packages: subscription_package_list(
      limit: $limit
      offset: $offset
      order: $order
      search: $search
    ) {
      count
      records {
        id
        name
        is_active
        package_type
        description
        is_course
        unit_price
        discount_price
        view_count
        like_count
        bookmark_count
        article_count
      }
    }
  }
`;
