import {
  Radio as MantineRadio,
  RadioProps,
  RadioGroupProps,
  MantineTheme,
} from "@mantine/core";

let styles = (theme: MantineTheme) => ({
  label: {
    color: theme.other.colors.primary.main,
    cursor: "pointer",
  },
  radio: {
    cursor: "pointer",
    border: "1px solid " + theme.other.colors.primary.main,
    ":checked": {
      backgroundColor: theme.other.colors.white,
      border: "2px solid " + theme.other.colors.primary.main,
    },
  },
  icon: {
    color: theme.other.colors.primary.main,
    marginLeft: "-1px",
    marginTop: "-1px",
    width: "12px",
    height: "12px",
  },
});

export const RadioGroup = (props: RadioGroupProps) => {
  return <MantineRadio.Group {...props} />;
};

export const Radio = (props: RadioProps) => {
  return <MantineRadio {...props} styles={styles} />;
};
