import styled from "styled-components";

export const TabsContainer = styled.div<{ hideBorder?: boolean }>`
  padding: 0.5rem 0;
  margin-top: 1rem;
  border-top: ${({ theme, hideBorder }) =>
    !hideBorder && `1px solid ${theme.colors.border}`};
`;

export const TabsHead = styled.div`
  display: flex;
  overflow-x: auto;
`;

export const TabsHeadItem = styled.div`
  font-size: 1.4rem;
  padding: 0.8rem 1.5rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary.main};
  cursor: pointer;
  opacity: 0.7;

  &.active {
    font-weight: bold;
    opacity: 1;
    border-bottom-color: ${({ theme }) => theme.colors.primary.main};
  }

  &:hover {
    font-weight: bold;
    border-bottom-color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const TabsContent = styled.div`
  padding: 2rem 1rem;
`;
