import { SVGProps } from "react";

const SendMessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2929 3.29289C11.9024 3.68342 11.9024 4.31658 12.2929 4.70711L14.5858 7L9 7C5.13401 7 2 10.134 2 14V16C2 16.5523 2.44772 17 3 17C3.55229 17 4 16.5523 4 16V14C4 11.2386 6.23858 9 9 9H14.5858L12.2929 11.2929C11.9024 11.6834 11.9024 12.3166 12.2929 12.7071C12.6834 13.0976 13.3166 13.0976 13.7071 12.7071L17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289L13.7071 3.29289C13.3166 2.90237 12.6834 2.90237 12.2929 3.29289Z"
      fill="#F6821F"
    />
  </svg>
);

export default SendMessageIcon;
