import { useState } from "react";
import SimpleImageViewer from "react-simple-image-viewer";
import Image from "../../atoms/Image";
import { ImageProps } from "../../atoms/Image/type";
import SkeletonLoader from "../../atoms/Loader/SkeletonLoader";

interface ImageViewerProps {
  containerStyle?: string;
  imageStyle?: string;
  loading?: boolean;
  images: (ImageProps & { index: number | string })[];
  width?: number;
  height?: number;
  showImageIndex?: boolean;
}

export function ImageViewer({
  containerStyle,
  imageStyle,
  loading,
  images,
  width = 80,
  height = 80,
  showImageIndex,
}: ImageViewerProps) {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const handleSetCurrentImage = (index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <section className={`${containerStyle || ""}`}>
      {loading ? (
        <SkeletonLoader width={width} height={height} className={imageStyle} />
      ) : (
        images.map((image, index) => (
          <span className="relative" key={index}>
            <Image
              {...image}
              className={`${image.className || ""}${imageStyle || ""}`}
              key={index}
              onClick={() => handleSetCurrentImage(index)}
            />
            {showImageIndex && (
              <span className="absolute  py-2 px-4 rounded-md bg-gray-500 bg-opacity-60 bottom-0 right-0 text-white font-bold">
                {image.index}
              </span>
            )}
          </span>
        ))
      )}
      {isViewerOpen && (
        <SimpleImageViewer
          src={images.map((img) => img.src || "")}
          currentIndex={currentImage}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </section>
  );
}
