import {
  Input as MantineInput,
  NumberInput as MantineNumberInput,
  InputProps,
  NumberInputProps,
  MantineTheme,
} from "@mantine/core";

export const wrapperStyles = (theme: MantineTheme) => ({
  label: {
    fontWeight: theme.other.fonts.subtitle2.fontWeight,
    color: theme.other.colors.primary.main,
    marginBottom: theme.other.gaps["4"],
  },
  required: {
    color: theme.other.colors.primary.main,
  },
});

const styles = (theme: MantineTheme) => ({
  rightSection: {
    color: theme.other.colors.primary.main,
  },
  icon: {
    color: theme.other.colors.primary.main,
  },
  input: {
    border: "1px solid " + theme.other.colors.border,
    color: theme.other.colors.primary["700"],
    "::placeholder": {
      color: theme.other.colors.primary["700"],
    },
  },
});

export type IInputProps<T> = T &
  Omit<React.ComponentPropsWithoutRef<"input">, "size"> & {
    label?: string;
    error?: string;
  };

export const Input = ({
  label,
  error,
  size,
  required,
  ...props
}: IInputProps<InputProps>) => {
  return (
    <MantineInput.Wrapper
      label={label}
      styles={wrapperStyles}
      required={required}
      error={error}
      size={size}
    >
      <MantineInput
        styles={styles}
        size={size}
        required={required}
        {...props}
      />
    </MantineInput.Wrapper>
  );
};

export const NumberInput = ({
  label,
  error,
  size,
  required,
  ...props
}: IInputProps<NumberInputProps>) => {
  return (
    <MantineInput.Wrapper
      label={label}
      styles={wrapperStyles}
      required={required}
      error={error}
      size={size}
    >
      <MantineNumberInput
        styles={styles}
        size={size}
        required={required}
        {...props}
      />
    </MantineInput.Wrapper>
  );
};
