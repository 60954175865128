import { IconType } from "react-icons";
import LegendDivider from "../../atoms/LegendDivider";
import Typography from "../../atoms/Typography";
import { LegendTitleContainer } from "../../molecules/LegendTitle/style";
import { TrendContainer } from "../TrendMetricsCard/styles";
import { TrendList } from "../TrendMetricsCard/TrendList";
import { Divider } from "../../atoms/Divider";
interface IActivitiesSectionProps {
  legendTitle: string;
  legendIcon: IconType;
  metrics?: {
    views?: {
      count?: number;
      users?: string[];
    };
    likes?: {
      count?: number;
      users?: string[];
    };
    favorites?: {
      count?: number;
      users?: string[];
    };
  };
}
export const ArticleDetailTrendMetrics = ({
  legendTitle,
  legendIcon,
  metrics,
}: IActivitiesSectionProps) => {
  const LegendIcon = legendIcon;
  const LegendTitle = (
    <LegendTitleContainer>
      <LegendIcon />
      <Typography variant="h6">{legendTitle}</Typography>
    </LegendTitleContainer>
  );

  return (
    <div className="mt-14" data-cy="article-detail-trend-metrics-section">
      <LegendDivider title={LegendTitle} />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-11">
        <TrendingCard
          label={`${metrics?.views?.count || 0} View(s)`}
          trendItemData={metrics?.views?.users?.map((views) => ({
            item: { name: views },
          }))}
        />
        <TrendingCard
          label={`${metrics?.likes?.count || 0} Like(s)`}
          trendItemData={metrics?.likes?.users?.map((likes) => ({
            item: { name: likes },
          }))}
        />
        <TrendingCard
          label={`${metrics?.favorites?.count || 0} Favourite(s)`}
          trendItemData={metrics?.favorites?.users?.map((favorites) => ({
            item: { name: favorites },
          }))}
        />
      </div>
    </div>
  );
};

const TrendingCard = ({
  label,
  trendItemData,
}: {
  label: string;
  trendItemData?: any[];
}) => (
  <TrendContainer
    className="min-h-[300px] !pl-0 shadow-elevation2"
    data-cy="trend-card"
  >
    <Typography variant="subtitle2">
      <span className="px-6">{label}</span>
    </Typography>
    <Divider size={1} mt={16} mb={6} />
    <TrendList data={trendItemData || []} className="px-4" />
  </TrendContainer>
);
