import { SVGProps } from "react";

const FieldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1.33333"
      y="1.33325"
      width="3.31852"
      height="3.31852"
      fill={props.color || "#005777"}
    />
    <rect
      x="19.3479"
      y="1.33325"
      width="3.31852"
      height="3.31852"
      fill={props.color || "#005777"}
    />
    <rect
      x="19.3479"
      y="19.3479"
      width="3.31852"
      height="3.31852"
      fill={props.color || "#005777"}
    />
    <rect
      x="1.33333"
      y="19.3479"
      width="3.31852"
      height="3.31852"
      fill={props.color || "#005777"}
    />
    <rect
      x="2.28125"
      y="3.70337"
      width="1.42222"
      height="16.1185"
      fill={props.color || "#005777"}
    />
    <rect
      x="20.2965"
      y="3.70337"
      width="1.42222"
      height="16.1185"
      fill={props.color || "#005777"}
    />
    <rect
      x="3.70346"
      y="21.7188"
      width="1.42222"
      height="16.1185"
      transform="rotate(-90 3.70346 21.7188)"
      fill={props.color || "#005777"}
    />
    <rect
      x="3.70346"
      y="3.70337"
      width="1.42222"
      height="16.1185"
      transform="rotate(-90 3.70346 3.70337)"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default FieldIcon;
