const educationList = [
  {
    id: "Illiterate",
    name: "Illiterate",
  },
  {
    id: "SSC",
    name: "SSC",
  },
  {
    id: "HSC",
    name: "HSC",
  },
  {
    id: "Graduate",
    name: "Graduate",
  },
  {
    id: "Post-Graduate",
    name: "Post-Graduate",
  },
  {
    id: "Other",
    name: "Other",
  },
];

export default educationList;
