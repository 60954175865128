import { DocumentNode } from "graphql";
import { Dispatch } from "react";
import {
  AddressSectionProps,
  AddressSection,
} from "../../organisms/TeamDetailModalSections/AddressSections/AddressSection";
import {
  AuditSection,
  AuditSectionProps,
} from "../../organisms/TeamDetailModalSections/AuditSection/AuditSection";
import {
  CropDetailForm,
  CropDetailFormProps,
} from "../../organisms/TeamDetailModalSections/DetailForms/CropDetailForm";
import {
  FarmDetailForm,
  FarmDetailFormProps,
} from "../../organisms/TeamDetailModalSections/DetailForms/FarmDetailForm";
import {
  FieldDetailForm,
  FieldDetailFormProps,
} from "../../organisms/TeamDetailModalSections/DetailForms/FieldDetailForm";
import {
  PlotDetailForm,
  PlotDetailFormProps,
} from "../../organisms/TeamDetailModalSections/DetailForms/PlotDetailForm";
import {
  TeamDetailForm,
  TeamDetailFormProps,
} from "../../organisms/TeamDetailModalSections/DetailForms/TeamDetailForm";

import {
  UserDetailForm,
  UserDetailFormProps,
} from "../../organisms/TeamDetailModalSections/DetailForms/UserDetailForm";
import {
  DocSection,
  DocSectionProps,
} from "../../organisms/TeamDetailModalSections/DocSection/DocSection";
import { sidebarTypes } from "../TeamMapView/data";
import {
  FARM_DETAIL,
  TEAMS_DETAIL,
  UPDATE_FARM_DETAIL,
  UPDATE_USER,
  USER_DETAIL,
  VERIFY_AUDIT,
  VERIFY_USER_AUDIT,
  UPDATE_TEAMS,
  VERIFY_TEAM_AUDIT,
  FIELD_DETAIL,
  UPDATE_FIELD,
  VERIFY_FIELD,
  UPDATE_PLOT,
  VERIFY_PLOT,
  PLOT_DETAIL,
  VERIFY_CROP,
  CROP_DETAIL,
  UPDATE_CROP,
} from "./query";
import { initialState } from "./teamDetailReducer";
import { Actions, DetailTypes, TeamActions, TeamState } from "./type";

export const STEPS: { [key: number]: string } = {
  0: "Details",
  1: "Address",
  2: "Documents",
  3: "Audit",
};

export const sidebarDetailQueries: Record<any, DocumentNode> = {
  farm: FARM_DETAIL,
  team: TEAMS_DETAIL,
  user: USER_DETAIL,
  field: FIELD_DETAIL,
  plot: PLOT_DETAIL,
  crop: CROP_DETAIL,
};

export const verifyAuditMutation = {
  farm: VERIFY_AUDIT,
  team: VERIFY_TEAM_AUDIT,
  user: VERIFY_USER_AUDIT,
  field: VERIFY_FIELD,
  plot: VERIFY_PLOT,
  crop: VERIFY_CROP,
};

export const resetUpdatedFields = ({
  dispatch,
  state,
  stepName,
  type,
}: {
  dispatch: Dispatch<Actions>;
  state: TeamState;
  stepName: string;
  type: DetailTypes;
}) => {
  dispatch({
    type: (TeamActions as any)[`SET_${type.toUpperCase()}_VALUE`],
    payload: {
      key: stepName,
      value: {
        ...(state[type] as any)[stepName],
        updatedFields: [],
      },
    },
  });
};

export const getCommonFields = ({
  type,
  updateQuery,
  detailForm,
}: {
  type: DetailTypes;
  updateQuery: DocumentNode;
  detailForm: (props: FarmDetailFormProps) => JSX.Element;
}) => ({
  updateQuery,
  updateVariables: (state: TeamState) => ({
    ...state[type].detail,
    ...(type === "user"
      ? {
          lastName:
            `${state[type].detail.middleName} ${state[type].detail.lastName}`.trim(),
        }
      : {}),
    id: state[type].id,
    addressId: state[type].address.id,
  }),
  resetUpdatedFields: resetUpdatedFields,
  details: {
    component: detailForm,
    showSaveDetail: (state: TeamState) => {
      if (
        type === "user" &&
        state[type].detail.number === state[type].detail.secondaryNumber
      )
        return false;
      return !!(
        state[type].detail.updatedFields[0] &&
        state[type].detail.updatedFields.every(
          (field) => (state[type].detail as any)[field]
        )
      );
    },
  },
  address: {
    addAddressVariables: (state: TeamState) => {
      let address = state[type].address;
      return {
        ...(address.id ? { id: address.id } : {}),
        name: address.name || "",
        address_kind: address.addressKind || "",
        local_area: address.houseStreet || "",
        village: address.village || "",
        pincode: address.pincode || "",
        taluka: address.taluka || "",
        state: address.state || "",
        district: address.district || "",
        location: address.location,
        country: address.country || "",
        raw_address: address.raw || "",
      };
    },
    component: (props: AddressSectionProps) => <AddressSection {...props} />,
    showSaveDetail: (state: TeamState) => {
      return !!(
        state[type].address.updatedFields[0] &&
        state[type].address.updatedFields.every(
          (field) => (state[type].address as any)[field]
        )
      );
    },
    resetUpdatedFields: resetUpdatedFields,
  },
  documents: (props: DocSectionProps) => <DocSection {...props} />,
  audit: (props: AuditSectionProps) => <AuditSection {...props} />,
});

export const sidebarDetailSteps: Record<
  any,
  {
    details: any;
    address: any;
    documents: any;
    audit: any;
    updateQuery: any;
    updateVariables: any;
    resetUpdatedFields: any;
  }
> = {
  farm: getCommonFields({
    type: "farm",
    updateQuery: UPDATE_FARM_DETAIL,
    detailForm: (props: FarmDetailFormProps) => <FarmDetailForm {...props} />,
  }),
  user: getCommonFields({
    type: "user",
    updateQuery: UPDATE_USER,
    detailForm: (props: UserDetailFormProps) => <UserDetailForm {...props} />,
  }),
  team: getCommonFields({
    type: "team",
    updateQuery: UPDATE_TEAMS,
    detailForm: (props: TeamDetailFormProps) => <TeamDetailForm {...props} />,
  }),
  field: getCommonFields({
    type: "field",
    updateQuery: UPDATE_FIELD,
    detailForm: (props: FieldDetailFormProps) => <FieldDetailForm {...props} />,
  }),
  plot: getCommonFields({
    type: "plot",
    updateQuery: UPDATE_PLOT,
    detailForm: (props: PlotDetailFormProps) => <PlotDetailForm {...props} />,
  }),
  crop: getCommonFields({
    type: "crop",
    updateQuery: UPDATE_CROP,
    detailForm: (props: CropDetailFormProps) => <CropDetailForm {...props} />,
  }),
};

export const getAddressValue = (type: DetailTypes, data: any) => {
  switch (type) {
    case "field": {
      return {
        raw: data?.[type]?.farm.address?.raw,
        location: data?.[type]?.area_catered?.coordinates,
      };
    }
    case "plot": {
      return {
        raw: data?.[type]?.field?.farm.address?.raw,
        location: data?.[type]?.area_catered?.coordinates,
      };
    }
    case "crop": {
      return {
        raw: data?.[type]?.plot?.field?.farm.address?.raw,
        location: data?.[type]?.plot?.area_catered?.coordinates,
      };
    }
    default: {
      return {
        id: data?.[type]?.address?.id,
        houseStreet: data?.[type]?.address?.local_area,
        village: data?.[type]?.address?.village,
        taluka: data?.[type]?.address?.taluka,
        name: data?.[type]?.address?.name,
        addressKind: data?.[type]?.address?.address_kind,
        pincode: data?.[type]?.address?.pincode,
        state: data?.[type]?.address?.state,
        district: data?.[type]?.address?.district,
        location: data?.[type]?.address?.location,
        country: data?.[type]?.address?.country,
        raw: data?.[type]?.address?.raw,
      };
    }
  }
};

export const normalizeAndStoreData = (
  dispatch: Dispatch<Actions>,
  data: any,
  type: DetailTypes
) => {
  dispatch({
    type: TeamActions.SET_VALUES,
    payload: [
      {
        key: type,
        value: {
          id: data?.[type]?.id,
          verification_remark: data?.[type]?.verification_remark,
          verified: data?.[type]?.verified,
          detail: {
            ...initialState[type].detail,
            ...(type === "farm"
              ? {
                  tag: data?.farm?.tag,
                  name: data?.farm?.name,
                  owner: data?.farm?.farmer?.name,
                  ownerId: data?.farm?.farmer?.id,
                  teamName:
                    data?.farm?.farmer?.memberships?.[0]?.group?.name || null,
                  teamId:
                    data?.farm?.farmer?.memberships?.[0]?.group?.id || null,
                }
              : {}),
            ...(type === "team"
              ? {
                  name: data?.team?.name,
                  teamName: data?.team?.name,
                  owner: data?.team?.farmer?.find(
                    (farmer: any) =>
                      farmer?.mutual_group_role?.codename === "ROOT"
                  )?.user?.full_name,
                  teamId: data?.team?.id,
                  ownerId: data?.team?.farmer?.find(
                    (farmer: any) =>
                      farmer?.mutual_group_role?.codename === "ROOT"
                  )?.user?.id,
                  signedUrl: data?.team?.photo?.signed_url,
                }
              : {}),
            ...(type === "user"
              ? {
                  firstName: data?.user?.first_name,
                  middleName:
                    data?.user?.last_name.split(" ")?.slice(0, -1)?.[0] || "",
                  lastName:
                    data?.user?.last_name.split(" ")?.slice(-1)?.[0] || "",
                  username: data?.user?.username,
                  email: data?.user?.email,
                  owner: data?.user?.full_name,
                  ownerId: data?.user?.id,
                  teamName:
                    data?.user?.mutual_group_memberships?.[0]?.mutual_group
                      ?.name,
                  teamId:
                    data?.user?.mutual_group_memberships?.[0]?.mutual_group?.id,
                  number: data?.user?.number,
                  secondaryNumber: data?.user?.secondary_number,
                  dateOfBirth: data?.user?.date_of_birth,
                  gender: data?.user?.gender,
                  education: data?.user?.education,
                }
              : {}),
            ...(type === "field"
              ? {
                  tag: data?.field?.tag,
                  name: data?.field?.name,
                  gatNumber: data?.field?.gatNumber,
                  soilName: data?.field?.soil?.name,
                  soilId: data?.field?.soil?.id,
                  farmName: data?.field?.farm?.name,
                  farmId: data?.field?.farm?.id,
                  areaCatered: data?.field?.area_catered,
                  area: data?.field?.area,
                  areaUnit: data?.field?.areaUnit?.name,
                  areaUnitId: data?.field?.areaUnit?.id,
                  owner: data?.field?.farmer?.name,
                  ownerId: data?.field?.farmer?.id,
                  teamName: data?.field?.farmer?.memberships?.[0]?.group?.name,
                  teamId: data?.field?.farmer?.memberships?.[0]?.group?.id,
                }
              : {}),
            ...(type === "plot"
              ? {
                  tag: data?.plot?.tag,
                  name: data?.plot?.name,
                  fieldName: data?.plot?.field?.name,
                  fieldId: data?.plot?.field?.id,
                  areaCatered: data?.plot?.area_catered,
                  isArchived: data?.plot?.is_archived,
                  area: data?.plot?.area,
                  cropName: data?.plot?.crops?.map((e: any) => e?.name) || [],
                  variety:
                    data?.plot?.crops?.map((e: any) => e?.crop_variety?.name) ||
                    "-",
                  association:
                    data?.plot?.crops?.map(
                      (e: any) =>
                        e?.associations?.[0]?.association?.company?.name
                    ) || "-",
                  status:
                    data?.plot?.crops?.map((e: any) => e.status?.name) || "-",
                  areaUnit: data?.plot?.areaUnit?.name,
                  areaUnitId: data?.plot?.areaUnit?.id,
                  owner: data?.plot?.farmer?.name,
                  ownerId: data?.plot?.farmer?.id,
                  teamName: data?.plot?.farmer?.memberships?.[0]?.group?.name,
                  teamId: data?.plot?.farmer?.memberships?.[0]?.group?.id,
                }
              : {}),
            ...(type === "crop"
              ? {
                  tag: data?.crop?.tag,
                  name: data?.crop?.name,
                  areaCatered: data?.crop?.plot?.area_catered,
                  isArchived: data?.crop?.is_archived,
                  associationId:
                    data?.crop?.associations?.[0]?.association?.company?.id,
                  associationLinkId: data?.crop?.associations?.[0]?.id,
                  association:
                    data?.crop?.associations?.[0]?.association?.company?.name,
                  agronomistId: data?.crop?.agents?.[0]?.agent?.id,
                  agronomistLinkId: data?.crop?.agents?.[0]?.id,
                  agronomist: data?.crop?.agents?.[0]?.agent?.name,
                  area: data?.crop?.plot?.area,
                  areaUnit: data?.crop?.plot?.areaUnit?.name,
                  areaUnitId: data?.crop?.plot?.areaUnit?.id,
                  owner: data?.crop?.farmer?.name,
                  ownerId: data?.crop?.farmer?.id,
                  teamName: data?.crop?.farmer?.memberships?.[0]?.group?.name,
                  teamId: data?.crop?.farmer?.memberships?.[0]?.group?.id,
                  updatedFields: [],
                  cropName: data?.crop?.crop?.name,
                  cropId: data?.crop?.crop?.id,
                  cropYear: data?.crop?.cropYear,
                  harvestQuantity: data?.crop?.harvestQuantity,
                  variety: data?.crop?.variety?.name,
                  varietyId: data?.crop?.variety?.id,
                  noOfPlant: data?.crop?.noOfPlant,
                  growingCycle: data?.crop?.growingCycle,
                  estimateHarvest: data?.crop?.estimateHarvest,
                  p2p: data?.crop?.p2p,
                  p2pUnit: data?.crop?.p2pUnit?.name,
                  r2r: data?.crop?.r2r,
                  r2rUnit: data?.crop?.r2rUnit?.name,
                  isSeed: data?.crop?.is_seed,
                  status: data?.crop?.status?.name,
                  statusId: data?.crop?.status?.id,
                  plotName: data?.crop?.plot?.name,
                  plotId: data?.crop?.plot?.id,
                }
              : {}),
          },
          address: {
            ...initialState[type].address,
            ...getAddressValue(type, data),
          },
        },
      },
    ],
  });
};
