import { useEffect } from "react";
import { NotificationState } from "../../../pages/CreateNotification/type";
import { Divider } from "../../atoms/Divider";
import Typography from "../../atoms/Typography";
import { NotificationPreviewChips } from "../../organisms/NotificationPreviewSections/NotificationPreviewChips";
import { NotificationBanner } from "../../organisms/NotificationPreviewSections/NotificationBanner";
import {
  NotificationContentContainer,
  NotificationPreviewContainer,
} from "./styles";
import { NotifierSection } from "../../organisms/NotificationPreviewSections/NotifierSection";

interface Props {
  state: NotificationState;
}

const NotificationPreview = ({ state }: Props) => {
  const {
    title,
    description,
    selectedFeaturedImage,
    selectedRegions,
    selectedAllNetwork,
    content,
    broadcastDateTime,
    broadcastBy,
  } = state;
  const allNetworks = Object.values(selectedAllNetwork).flat();

  useEffect(() => {
    document.title = title + " - SetuFarm";
    return () => {
      document.title = "Notifications - SetuFarm";
    };
  }, []);

  return (
    <NotificationPreviewContainer data-cy="notification-preview-section">
      <NotificationBanner featuredImage={selectedFeaturedImage} />
      <NotifierSection date={broadcastDateTime} name={broadcastBy?.name} />
      <Typography variant="h4" data-cy="notification-title">
        {title}
      </Typography>
      <Typography
        variant="body2"
        className="mt-8"
        data-cy="notification-description"
      >
        {description}
      </Typography>
      <NotificationContentContainer
        data-cy="notification-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {selectedRegions.length + allNetworks.length ? (
        <Divider size={1} mt={16} mb={24} />
      ) : null}
      <NotificationPreviewChips
        chips={{
          "region(s)": selectedRegions.map((item: any) => ({
            name: item.name,
            id: item.codename,
          })),
          "network(s)":
            selectedAllNetwork.networks?.map((item) => ({
              name: item.name,
              id: item.codename,
            })) || [],
          "team(s)":
            selectedAllNetwork.teams?.map((item) => ({
              name: item.name,
              id: item.codename,
            })) || [],
          "user(s)":
            selectedAllNetwork.users?.map((item) => ({
              name: item.name,
              id: item.codename,
            })) || [],
        }}
      />
    </NotificationPreviewContainer>
  );
};

export default NotificationPreview;
