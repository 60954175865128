import { joinValuesAndData } from "../../lib/utils/filters";

export const normalizeNetworkTeams = (
  data: any,
  values?: any[],
  key: string = ""
) => {
  if (!data) return values || [];
  data = data.map((team: any) => {
    return { ...team.team, network: team?.network?.id };
  });
  data = joinValuesAndData(data, values || [], { name: key, value: key });
  data.sort((a: any, b: any) => a.name.localeCompare(b.name));
  return data;
};
