import { useLazyQuery } from "@apollo/client";
import { Dispatch, useEffect } from "react";
import { theme } from "../../../../style/theme";
import { Card, CardBody } from "../../../atoms/Card";
import { Divider } from "../../../atoms/Divider";
import { Grid } from "../../../atoms/Grid";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserIcon from "../../../atoms/Icons/UserIcon";
import Image from "../../../atoms/Image";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import { GET_USERS } from "../../../templates/TeamsDetailModal/query";
import {
  Actions,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";

export interface TeamDetailFormProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
}

export function TeamDetailForm({ state, dispatch }: TeamDetailFormProps) {
  const [getUsers, { data: usersData }] = useLazyQuery<{
    users: { id: number; name: string }[];
  }>(GET_USERS);

  useEffect(() => {
    if (state.team.detail.teamId) {
      getUsers({ variables: { teamId: state.team.detail.teamId } });
    }
  }, [state.team.detail.teamId]);

  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: TeamActions.SET_TEAM_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.team.detail,
          ...(!state.team.detail.updatedFields.includes(name)
            ? { updatedFields: [...state.team.detail.updatedFields, name] }
            : {}),
          [name]: value,
        },
      },
    });
  };

  return (
    <section className="flex  flex-col  gap-8" data-cy="detail-form">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.team.detail.owner}</Typography>
        </div>
        <div className="flex justify-start items-center gap-4">
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.team.detail.teamName}</Typography>
        </div>
      </div>
      <Grid className="mt-2" data-cy="detail-items">
        <Grid.Col span={6}>
          <Input
            label="Team name"
            data-cy="team-name"
            required
            placeholder="Enter team name"
            type="text"
            value={state.team.detail.name}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("name", e.target.value);
            }}
            autoComplete="off"
            error={!state.team.detail.name ? "Team name is required" : ""}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Team owner name"
            size="lg"
            data-cy="team-owner-name"
            listData={usersData?.users || []}
            placeholder="Select owner"
            values={{ id: state.team.detail.ownerId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("ownerId", item?.id);
            }}
            clearable
            required
            error={!state.team.detail.ownerId ? "Owner is required" : ""}
            disabled
          />
        </Grid.Col>
      </Grid>
      <Divider size={1} mt={8} mb={8} />
      <Typography variant="h6">
        <span data-cy="team-consent">Team Consent</span>
      </Typography>
      <Card border="solid" borderWidth={1} borderColor={theme.colors.border}>
        <CardBody>
          <Image
            data-cy="team-consent-image"
            src={state.team.detail.signedUrl || ""}
            alt={"sign"}
            className="w-full h-80  object-contain"
          />
        </CardBody>
      </Card>
    </section>
  );
}
