import { SyntheticEvent, useEffect, useState } from "react";
import { theme } from "../../../style/theme";
import Typography from "../Typography";

export const VideoPlayer = ({
  url,
  className,
  onError,
}: {
  url: string;
  className?: string;
  onError?: any;
}) => {
  const [error, setError] = useState<
    SyntheticEvent<HTMLVideoElement, Event> | undefined
  >();
  useEffect(() => {
    handleError(undefined);
  }, [url]);

  const handleError = (e: any) => {
    setError(e);
    onError && onError(e);
  };

  return !error ? (
    <video
      className={className}
      src={url}
      onError={(e) => handleError(e)}
      controls
    >
      Your browser does not support the <b>video</b> element.
    </video>
  ) : url ? (
    <Typography
      variant="body2"
      className="pt-6"
      textColor={theme.colors.negative.default}
    >
      Invalid url! Please try again.
    </Typography>
  ) : null;
};
