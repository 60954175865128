import React from "react";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
import { UserProvider } from "./lib/contexts/usercontext";
import GlobalStyles from "./style/globalstyles";
import { theme } from "./style/theme";
import { client } from "./lib/utils/apiconfig";
import { MantineProvider } from "@mantine/core";
import { mantineTheme } from "./style/mantine/mantineTheme";
import AppRoutes from "./routes";

const App: React.FunctionComponent = () => {
  return (
    <>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={mantineTheme}
          >
            <GlobalStyles />
            <UserProvider>
              <AppRoutes />
            </UserProvider>
            <ToastContainer />
          </MantineProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
