import {
  createStyles,
  Stepper as MantineStepper,
  StepperProps,
} from "@mantine/core";

const useStyles = createStyles<any, { flex: boolean } >((theme, _params, getRef) => ({
  separator: {
    borderColor: theme.other.colors.cyan.main,
  },
  separatorActive: {
    backgroundColor: theme.other.colors.cyan.main,
  },
  stepIcon: {
    "&[data-completed]": {
      backgroundColor: theme.other.colors.cyan.main,
      borderColor: theme.other.colors.cyan.main,
    },
    "&[data-progress]": {
      color: theme.other.colors.primary.main,
      borderColor: theme.other.colors.primary.main,
    },
    color: theme.other.colors.cyan.main,
  },
  stepLabel: {
    color: theme.other.colors.primary.main,
  },
  stepDescription: {
    color: theme.other.colors.primary[700],
  },
  ..._params.flex ? {
      content: {
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
      }
  } : {}
}));

export function Stepper({flex = false, ...props}: StepperProps & {flex? : boolean}) {
  const { classes } = useStyles({ flex });

  return <MantineStepper classNames={classes} {...props} />;
}

Stepper.Step = MantineStepper.Step;
Stepper.Completed = MantineStepper.Completed;
