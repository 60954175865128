import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { HiChevronDown } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router";
import { SubMenu } from "../../molecules/Sidebar/navroutesconfig";
import { Title, SubMenuList, NavListItem } from "./styles";
import { PermissionKey } from "../../../lib/contexts/usercontext";
import { useRestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";

interface Props {
  keyName: PermissionKey;
  title: string;
  Icon: JSX.Element;
  route: string;
  subMenu?: SubMenu[];
  feature?: boolean;
  expandedItems?: string[];
  setExpandedItems: Dispatch<SetStateAction<string[] | undefined>>;
  handleClose?: (value: boolean) => void;
}

const NavItem: React.FC<Props> = ({
  keyName,
  Icon,
  title,
  route,
  subMenu,
  feature,
  expandedItems = [],
  setExpandedItems,
  handleClose,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isItemSelected = pathname === route;
  const isItemExpanded = expandedItems?.some((item) => item === title);
  const [expanded, setExpanded] = useState(isItemExpanded);

  const {
    getCollapseProps,
    getToggleProps,
    setExpanded: setItemExpanded,
    isExpanded,
  } = useCollapse({
    defaultExpanded: expanded,
    onExpandStart() {
      setExpanded(true);
    },
    onCollapseEnd() {
      setExpanded(false);
    },
  });

  useEffect(() => {
    if (expanded) {
      setExpandedItems([...(expandedItems || []), title]);
    } else {
      setExpandedItems(
        expandedItems ? expandedItems?.filter((item) => !(item === title)) : []
      );
    }
  }, [expanded]);

  if (feature && process.env.REACT_APP_ENV === "prod") return null;

  if (!useRestrictedContent({ keyName, permission: "view" })) return null;

  return (
    <>
      <NavListItem
        isSelected={
          isItemSelected ||
          !!(
            !isExpanded &&
            subMenu?.some((item) => item.selectby.includes(pathname))
          )
        }
        onClick={() => {
          if (!isItemSelected) {
            navigate(route);
            handleClose?.(false);
          }
        }}
        className="!p-0"
      >
        <section
          className="flex flex-row p-[0.8rem] !pr-0 items-center gap-3 w-full"
          onClick={() => setItemExpanded(true)}
        >
          <span className="  w-10  h-10">{Icon}</span>
          <Title variant="body2">{title}</Title>
        </section>
        {subMenu && (
          <div
            className="p-[0.8rem] !pl-0 z-10"
            onClick={(e) => {
              e.stopPropagation();
              setItemExpanded(!isExpanded);
            }}
          >
            <HiChevronDown
              className={`icon-thumbnails-small text-primary-main ${
                isExpanded ? "rotate-180" : ""
              } transform transition-all`}
            />
          </div>
        )}
      </NavListItem>
      {subMenu && (
        <SubMenuList {...getCollapseProps()}>
          {expanded &&
            subMenu?.map((item) => {
              let isSelected = item.selectby.includes(pathname);
              if (item.feature && process.env.REACT_APP_ENV === "prod")
                return null;
              if (
                item.permission &&
                !useRestrictedContent({ keyName, permission: item.permission })
              )
                return null;
              return (
                <li
                  key={item.title}
                  onClick={() => {
                    if (!isSelected) {
                      navigate(item.route);
                      handleClose?.(false);
                    }
                  }}
                >
                  <NavListItem isSelected={isSelected}>
                    <span className=" ml-12">
                      <Title variant="body2">{item.title}</Title>
                    </span>
                  </NavListItem>
                </li>
              );
            })}
        </SubMenuList>
      )}
    </>
  );
};

export default NavItem;
