import { gql } from "@apollo/client";

export const GET_ARTICLE_FILTERS = gql`
  query FilterValues($orgIdString: [String]) {
    categories: article_categories(distinct_on: category_id) {
      category {
        id
        name
      }
    }
    regions: region(order_by: { name: asc }) {
      id: codename
      name
    }
    crops: crop(order_by: { name: asc }) {
      id: codename
      name
    }
    subscriptionPackages: subscription_package_list(
      offset: 0
      limit: 100
      is_course: false
      organisation_ids: $orgIdString
    ) {
      records {
        id
        name
      }
    }
  }
`;

export const GET_ARTICLE_LIST = gql`
  query ArticleList(
    $limit: Int!
    $offset: Int!
    $order: [JSONScalar]
    $status: String!
    $search: String
    $article_type: String
    $category_ids: [String]
    $crop_ids: [String]
    $package_ids: [String]
    $publish_date_from: Date
    $publish_date_till: Date
    $region_ids: [String]
  ) {
    article: article_list_v2(
      limit: $limit
      offset: $offset
      order: $order
      status: $status
      search: $search
      article_type: $article_type
      category_ids: $category_ids
      crop_ids: $crop_ids
      package_ids: $package_ids
      publish_date_from: $publish_date_from
      publish_date_till: $publish_date_till
      region_ids: $region_ids
    ) {
      count
      records {
        id
        title
        description
        article_type
        publish_date
        duration
        views: view_count
        likes: like_count
        favorites: bookmark_count
        creator_id
        categories
        authors
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  query GetArticle($id: String!) {
    article: article_details(id: $id) {
      is_active
      id
      publish_date
      title
      updated_at
      status
      feature_date_from
      feature_date_till
      expiry_date
      duration
      is_series
      description
      created_at
      content
      article_type
      subscriptions: package_list {
        id
        name
      }
      regions: region_list {
        id
        name
        codename: reference_id
      }
      feature_image {
        id
        name
        url
        media_type
      }
      crops: crop_list {
        name
        codename: reference_id
      }
      crop_varieties: variety_list {
        codename: reference_id
        name
      }
      categories: category_list {
        id
        name
      }
      authors: author_list {
        id
        reference_id
        full_name: name
      }
      attachments: attachments {
        id
        name
        url
        media_type
      }
      user_groups: team_list {
        codename: reference_id
        name
        id
      }
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation deleteArticle($id: uuid!) {
    update_article_by_pk(pk_columns: { id: $id }, _set: { is_active: false }) {
      id
    }
  }
`;
