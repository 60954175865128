import { useMutation, useQuery } from "@apollo/client";
import { Group } from "../../atoms/Group";
import { Space } from "../../atoms/Space";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { MantineButton } from "../../atoms/MantineButton";
import { Select } from "../../atoms/MantineSelect";
import { Modal } from "../../atoms/Modal";
import Typography from "../../atoms/Typography";
import Table from "../../molecules/Table";
import { coursesArticlesColumns } from "./data";
import { COURSE_DETAILS, LINK_COURSE_ARTICLE } from "./query";
import { RestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";
import { Cell } from "react-table";
import { NumberInput } from "../../atoms/MantineInput";

interface INewCoursesArticle {
  prev: any;
  article: any;
  seriesId: string | undefined;
}

const initialData = {
  prev: null,
  article: null,
  seriesId: "",
};

export const CoursesDetailModal = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [newArticle, setNewArticle] = useState<INewCoursesArticle>(initialData);

  const { data, loading, refetch } = useQuery(COURSE_DETAILS, {
    variables: { id: params?.id },
    skip: !params?.id,
    fetchPolicy: "network-only",
  });

  const [LinkCourseArticle] = useMutation(LINK_COURSE_ARTICLE);

  useEffect(() => {
    document.title = data?.course?.name || "Courses Articles - SetuFarm";
    setNewArticle((prev) => ({
      ...prev,
      prev: data?.course?.articles?.slice(-1)[0],
      seriesId: params.id,
    }));
    return () => {
      document.title = "School Courses - SetuFarm";
    };
  }, [loading]);

  function refetchUpdate() {
    refetch().then((dt) => {
      setNewArticle(() => ({
        article: null,
        prev: dt?.data?.course?.articles?.slice(-1)[0],
        seriesId: params.id,
      }));
    });
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    LinkCourseArticle({
      variables: {
        order:
          newArticle.prev?.order != undefined ? newArticle.prev?.order + 1 : 0,
        seriesId: newArticle.seriesId,
        articleId: newArticle.article.id,
      },
    })
      .then(({ data }) => {
        if (newArticle.prev) {
          LinkCourseArticle({
            variables: {
              id: newArticle.prev?.series_article_id,
              articleId: newArticle.prev?.article_id,
              seriesId: data?.upsert_article_series.article_series.series_id,
              nextId: data?.upsert_article_series.article_series.id,
            },
          })
            .then(({ data }) => {
              setNewArticle((prev) => ({ ...prev, article: null }));
              refetchUpdate();
              toast.success("Added article to series");
            })
            .catch(() => toast.error("Couldn't add article"));
        } else {
          setNewArticle((prev) => ({ ...prev, article: null }));
          refetchUpdate();
          toast.success("Added article to series");
        }
      })
      .catch(() => toast.error("Couldn't add article"));
  };

  const orderColumn = {
    Header: "Order",
    accessor: "order",
    flex: 1,
    Cell: (cell: Cell) => {
      const [newOrder, setNewOrder] = useState<any>();
      const [updateCourseArticle] = useMutation(LINK_COURSE_ARTICLE);

      function handleUpdate() {
        updateCourseArticle({
          variables: {
            order: newOrder,
            id: (cell.row.original as any).series_article_id,
            articleId: (cell.row.original as any).article_id,
            seriesId: params.id,
          },
        }).then(() => refetchUpdate());
      }

      return (
        <>
          {newOrder !== undefined ? (
            <NumberInput
              value={newOrder}
              autoFocus={true}
              onChange={setNewOrder}
              onBlur={() => setNewOrder(undefined)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                  handleUpdate();
                }
              }}
            />
          ) : (
            <div onClick={() => setNewOrder(cell.value)}>
              <Typography variant="body2">{cell.value}</Typography>
            </div>
          )}
        </>
      );
    },
  };

  return (
    <Modal
      isOpen={true}
      handleModal={(e) => navigate("/school/courses")}
      modalTitle={data?.course?.name || "Courses Articles"}
      width={"min(150rem, 95vw)"}
      height="auto"
    >
      {!loading ? (
        data ? (
          <div>
            <Group position="apart" align="baseline" direction="column">
              <Group spacing={8} data-cy="subscription-info">
                <Typography variant="body2">
                  Subscription Package: <b>{data?.course?.package?.name}</b>
                </Typography>
                <div className="bg-primary-700 p-1 rounded-full"></div>
                <Typography variant="body2">
                  Subscribers: <b>{data?.course?.subscription_count || 0}</b>
                </Typography>
              </Group>
              <RestrictedContent keyName="school_management" permission="edit">
                <form
                  onSubmit={handleSubmit}
                  className="w-full"
                  data-cy="add-article"
                >
                  <Group align="flex-end">
                    <Select
                      placeholder="Select an article"
                      listData={data?.course?.package_articles || []}
                      accessor={{ value: "id", name: "title" }}
                      values={newArticle.article || ""}
                      onSelect={(item) =>
                        setNewArticle((prev) => ({ ...prev, article: item }))
                      }
                      searchable
                      clearable
                      className="flex-1"
                    />
                    <MantineButton
                      disabled={!newArticle.article?.id}
                      type="submit"
                    >
                      Add
                    </MantineButton>
                  </Group>
                </form>
              </RestrictedContent>
            </Group>
            <Space h="lg" />
            <span data-cy="course-articles-table">
              <Table
                tableColumns={[orderColumn, ...coursesArticlesColumns]}
                tableData={data ? data?.course?.articles : []}
                loading={loading}
                getRowProps={(row: any) => {}}
                pageSize={data?.course?.articles?.length}
                manualSorting={false}
              />
            </span>
          </div>
        ) : null
      ) : (
        <LogoLoader width={75} margin="10% auto" />
      )}
    </Modal>
  );
};
