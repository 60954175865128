export async function gqlFetchRequest(
  query: string | undefined,
  variables: any
) {
  if (!query) return { error: true };
  try {
    const response = await fetch(`${process.env.REACT_APP_GRAPHQL_URI}`, {
      method: "POST",
      body: JSON.stringify({
        query,
        variables,
      }),
      headers: {
        "content-type": "application/json",
        "X-Hasura-Role": "agent_agronomist",
        "x-hasura-agent-id": `${localStorage.getItem("agentId")}`,
        Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
      },
    });
    return await response.json();
  } catch (error) {
    return { error };
  }
}
