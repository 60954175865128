import { SidebarContainer, NavList } from "./styles";
import { NavItems, supplyChainModules } from "./navroutesconfig";
import NavItem from "../../atoms/NavItems";
import { SupplyChainModule } from "./SupplyChainModule";
import { Group } from "../../atoms/Group";
import { config } from "../../../config";
import { IEnv } from "../../../lib/interfaces/IEnv";
import { colors } from "../../../style/mantine/colors";
import { ScrollArea } from "../../atoms/ScrollArea";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import {
  ALLOW_QC_CHECK_DOMAIN,
  SIDEBAR_EXPANDED_ITEMS,
} from "../../../lib/utils/constants";
import { useEffect, useState } from "react";
import { useUser } from "../../../lib/contexts/usercontext";

const Sidebar = ({
  handleClose,
}: {
  handleClose?: (value: boolean) => void;
}) => {
  const sidebarExpandedItems = getLocalStorage(SIDEBAR_EXPANDED_ITEMS) as
    | string[]
    | undefined;
  const { state: user } = useUser();
  const [expandedItemList, setExpandedItemList] =
    useState(sidebarExpandedItems);

  useEffect(() => {
    setLocalStorage(SIDEBAR_EXPANDED_ITEMS, expandedItemList);
  }, [expandedItemList]);

  return (
    <SidebarContainer>
      <ScrollArea className="flex-grow">
        <NavList>
          {NavItems.map((item) => (
            <NavItem
              key={item.title}
              keyName={item.keyName}
              title={item.title}
              Icon={<item.Icon color={colors.primary.main} />}
              route={item.route}
              feature={item.feature}
              subMenu={item.subMenu}
              expandedItems={expandedItemList}
              setExpandedItems={setExpandedItemList}
              handleClose={handleClose}
            />
          ))}
        </NavList>
      </ScrollArea>
      {/* {!user.me?.email.includes(ALLOW_QC_CHECK_DOMAIN) ? null : (
        <section>
          <Group
            direction="column"
            spacing={20}
            className="mt-8 px-4"
            align={"stretch"}
          >
            {supplyChainModules[config.env as IEnv].map((item, i) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                key={i}
                style={{ display: "block" }}
              >
                <SupplyChainModule image={item.image} text={item.title} />
              </a>
            ))}
          </Group>
        </section>
      )} */}
    </SidebarContainer>
  );
};

export default Sidebar;
