import React from "react";
import { useState } from "react";
import Typography from "../../atoms/Typography";
import UploadButton from "../../atoms/UploadFile";
import {
  FeaturedImageContainer,
  SelectedImageContainer,
  SelectItem,
} from "./styles";
import { Attachment } from "../../../lib/interfaces/Article";
import { Select } from "../../atoms/MantineSelect";
import { Space } from "../../atoms/Space";
import {
  Actions,
  NotificationState,
  NotificationActions,
} from "../../../pages/CreateNotification/type";

interface Props {
  state: NotificationState;
  dispatch: (value: Actions) => void;
  onFeaturedImageUploaded: (response: any) => void;
}

const MediaSection: React.FunctionComponent<Props> = ({
  state,
  dispatch,
  onFeaturedImageUploaded,
}) => {
  const [selectedFeature, setSelectedFeature] = useState("");

  const handleDropdownFeatureImage = (item: Attachment) => {
    if (item) setSelectedFeature("dropdown");
    else setSelectedFeature("");

    dispatch({
      type: NotificationActions.SELECT_FEATURED_IMAGE,
      payload: { data: item },
    });
  };

  const handleFeatureImageUpload = (response: any) => {
    if (response.status === 200) {
      setSelectedFeature("upload");
      onFeaturedImageUploaded(response);
    } else {
      setSelectedFeature("");
      dispatch({
        type: NotificationActions.SELECT_VALUE,
        payload: {
          prop: "selectedFeaturedImage",
          data: undefined,
        },
      });
    }
  };

  return (
    <div className="flex gap-6" data-cy="notification-media-section">
      <div className="w-1/3">
        <Typography variant="h5">Featured image</Typography>
        {!state.notificationType?.featuredImage?.url && (
          <div className="mt-3">
            <Select
              data-test-id="feature-image"
              limit={100}
              values={state.selectedFeaturedImage || ""}
              listData={state.featuredImages}
              accessor={{ name: "name", value: "id" }}
              placeholder="Select existing images"
              onSelect={handleDropdownFeatureImage}
              disabled={selectedFeature === "upload"}
              size="lg"
              searchable
              clearable
              itemComponent={SelectItem}
              className="w-full"
            />
          </div>
        )}
      </div>
      {!state.notificationType?.featuredImage?.url && (
        <div className="mt-[34px]">
          <UploadButton
            accept={["image/*"]}
            onfileUploadedCallback={handleFeatureImageUpload}
            placeholder=""
            disabled={selectedFeature === "dropdown"}
          />
        </div>
      )}

      {(state.notificationType?.featuredImage?.url ||
        state.selectedFeaturedImage?.url) && (
        <SelectedImageContainer
          imageUrl={
            state.notificationType?.featuredImage?.url ||
            state.selectedFeaturedImage?.url ||
            ""
          }
        />
      )}
    </div>
  );
};

export default MediaSection;
