import { HiChevronRight, HiHome } from "react-icons/hi";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.primary.main};
  display: flex;
  align-items: center;
  padding: 0.25rem;
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
`;

export const BreadCrumbItem = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const HomeIcon = styled(HiHome)`
  width: 2.8rem;
  height: 2.8rem;
  color: ${({ theme }) => theme.colors.orange.main};
`;

export const ArrowIcon = styled(HiChevronRight)`
  width: 2.4rem;
  height: 2.4rem;
  fill: ${({ theme }) => theme.colors.primary.main};
`;
