import { useState } from "react";
import { Group } from "../../atoms/Group";
import { Modal } from "../../atoms/Modal";
import { Select } from "../../atoms/MantineSelect";
import { useSearchParams } from "react-router-dom";
import { MantineButton } from "../../atoms/MantineButton";
import { useUser } from "../../../lib/contexts/usercontext";
import axios from "axios";
import { toast } from "react-toastify";

export function InviteUser() {
  const inviteTemplates = [
    { label: "English", value: "63ef2e58d6fc0503525d2392" },
    { label: "Hindi", value: "63ef317fd6fc055e664e6212" },
    { label: "Marathi", value: "63ef3256d6fc05701037a712" },
  ];
  const [template, setTemplate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteUser = searchParams.get("inviteUser");
  const [loading, setLoading] = useState(false);
  const { state: user } = useUser();

  async function sendInvite() {
    setLoading(true);
    return await axios({
      url: `${process.env.REACT_APP_KITE_URL}/services/account/invite-user/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
      },
      data: {
        base_user_id: Number(inviteUser),
        template_id: template,
        organisation_name: user.me?.agent.organisation.name,
        app_name: "SetuFarm",
      },
    })
      .then(() => {
        hideModal();
        toast.success("Invite sent successfully.");
      })
      .catch(() => toast.error("Failed to send invite. Please try again."))
      .finally(() => setLoading(false));
  }

  function hideModal() {
    searchParams.delete("inviteUser");
    setSearchParams(searchParams);
  }

  return (
    <Modal
      isOpen={Boolean(inviteUser)}
      handleModal={hideModal}
      modalTitle={`Invite User`}
      height="auto"
      margin="auto"
      width={"min(60rem, 95vw)"}
    >
      <Group mb={16} className="flex-wrap items-end" spacing={16}>
        <Select
          label={"Choose a template"}
          size={"md"}
          value={template}
          listData={inviteTemplates}
          accessor={{ name: "label", value: "value" }}
          placeholder={"Select a template"}
          className="flex-1"
          onSelect={(value) => setTemplate(value?.value)}
        />
        <MantineButton
          size="md"
          loading={loading}
          disabled={!template || !inviteUser}
          onClick={sendInvite}
        >
          Send Invite
        </MantineButton>
      </Group>
    </Modal>
  );
}
