import React from "react";

import { Line, SectionContainer, SectionLabel } from "./styles";

interface Props {
  value: string;
}

const SectionDivider: React.FunctionComponent<Props> = ({ value }) => {
  return (
    <SectionContainer>
      <Line />
      <SectionLabel variant="small2">{value}</SectionLabel>
      <Line />
    </SectionContainer>
  );
};

export default SectionDivider;
