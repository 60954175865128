import { gql } from "@apollo/client";

const farmContentTypeId = 38;
const fieldContentTypeId = 39;
const plotContentTypeId = 40;
const cropContentTypeId = 43;

export const FARM_ASSETS_METRICS = gql`
  query getFarmMetrics {
    # Farm Metrics
    totalFarms: farm_aggregate(
      where: {is_active: {_eq: true}}
      ) {
      aggregate {
        count
      }
    }

    unverifiedFarms: farm_aggregate(where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] }) {
      aggregate {
        count
      }
    }
    farmDocuments: document_aggregate(where: { content_type_id: { _eq: ${farmContentTypeId} } }) {
      aggregate {
        count
      }
    }

    # Field Metrics
    totalFields: field_aggregate(
      where: {is_active: {_eq: true}}
      ) {
      aggregate {
        count
      }
    }

    unverifiedFields: field_aggregate(where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] }) {
      aggregate {
        count
      }
    }

    totalFieldArea: field_aggregate{
      aggregate {
        sum {
          area
        }
      }
    }

    unverifiedFieldArea: field_aggregate(
      where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] }
    ) {
      aggregate {
        sum {
          area
        }
      }
    }

    fieldDocuments: document_aggregate(
      where: { content_type_id: { _eq: ${fieldContentTypeId} } }
    ) {
      aggregate {
        count
      }
    }

    # Plot Metrics
    totalPlots: plot_aggregate(
      where: {
        _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
      }
      ){
      aggregate {
        count
      }
    }

    unverifiedPlots: plot_aggregate(where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] 
      _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
    }) {
      aggregate {
        count
      }
    }

    totalPlotArea: plot_aggregate(
      where: {
        _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
      }
      ) {
      aggregate {
        sum {
          area
        }
      }
    }

    unverifiedPlotArea: plot_aggregate(where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] 
      _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
    }) {
      aggregate {
        sum {
          area
        }
      }
    }

    plotDocuments: document_aggregate(where: { content_type_id: { _eq: ${plotContentTypeId} } }) {
      aggregate {
        count
      }
    }

    # Plot Crop Metrics
    totalCrops: plot_crop_aggregate(
      where: {
      _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
    }) {
      aggregate {
        count
      }
    }
    unverifiedCrops: plot_crop_aggregate(
      where: { _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] 
      _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
    } ) {
      aggregate {
        count
      }
    }
    totalCropArea: plot_aggregate(
      where: {
      _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
    }) {
      aggregate {
        sum {
          area
        }
      }
    }
    unverifiedCropArea: plot_aggregate(where: { 
      _or: [
        {is_verified: {_is_null: true}}, 
        {is_verified: {_eq: false}}
      ] 
      _and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]
    }) {
      aggregate {
        sum {
          area
        }
      }
    }
    uniqueCrops: plot_crop_aggregate(
      where: {_and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]}
      distinct_on: crop_id) {
      aggregate {
        count
      }
    }
    uniqueVarieties: plot_crop_aggregate(
      where: {_and: [{is_active: {_eq: true}}, {is_archived: {_eq: false}}]}
      distinct_on: crop_variety_id) {
      aggregate {
        count
      }
    }
    cropDocuments: document_aggregate(
      where: { content_type_id: { _eq: ${cropContentTypeId} } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
