import { SVGProps } from "react";

const ContentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.400024 2.39922C0.400024 1.18419 1.385 0.199219 2.60002 0.199219H7.64439C8.22787 0.199219 8.78745 0.431004 9.20002 0.843584L12.9557 4.59922C13.3682 5.0118 13.6 5.57138 13.6 6.15485V15.5992C13.6 16.8142 12.6151 17.7992 11.4 17.7992H2.60002C1.385 17.7992 0.400024 16.8142 0.400024 15.5992V2.39922ZM2.60002 8.99922C2.60002 8.3917 3.09251 7.89922 3.70002 7.89922H10.3C10.9075 7.89922 11.4 8.3917 11.4 8.99922C11.4 9.60673 10.9075 10.0992 10.3 10.0992H3.70002C3.09251 10.0992 2.60002 9.60673 2.60002 8.99922ZM3.70002 12.2992C3.09251 12.2992 2.60002 12.7917 2.60002 13.3992C2.60002 14.0067 3.09251 14.4992 3.70002 14.4992H10.3C10.9075 14.4992 11.4 14.0067 11.4 13.3992C11.4 12.7917 10.9075 12.2992 10.3 12.2992H3.70002Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default ContentIcon;
