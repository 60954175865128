import { Group, Space } from "@mantine/core";
import { useEffect } from "react";
import { useUser } from "../../../lib/contexts/usercontext";
import { Checkbox } from "../../atoms/Input/MantineCheckbox";
import { Input } from "../../atoms/MantineInput";
import { Textarea } from "../../atoms/Textarea";
import { Select } from "../../atoms/MantineSelect";
import { packageType } from "../../templates/AdvisorySetup/data";

export const PackageForm = ({ state, handleChange, type }: any) => {
  const user = useUser();
  useEffect(() => {
    handleChange({ orgId: user.state.me?.agent.organisation.id });
  }, [user]);

  return (
    <>
      <Input
        label="Name"
        placeholder="Enter package name"
        value={state.name}
        onChange={(e) => handleChange({ name: e.target.value })}
        required
      />
      <Space h="lg" />
      <Textarea
        label="Description"
        placeholder="Enter package description"
        minRows={10}
        value={state.description}
        onChange={(e) => handleChange({ description: e.target.value })}
      />
      <Space h="lg" />
      <Select
        label="Package Type"
        listData={packageType}
        placeholder="Select package type"
        values={{ id: state.packageType }}
        accessor={{ name: "name", value: "id" }}
        onSelect={(item) => handleChange({ packageType: item.id })}
        required
      />
      <Space h="lg" />
      <Input
        label="Unit Price"
        type="number"
        placeholder="Enter unit price"
        value={state.unitPrice}
        onChange={(e) => handleChange({ unitPrice: e.target.value })}
        required
      />
      <Space h="lg" />
      <Input
        label="Discount Price"
        type="number"
        placeholder="Enter discount price"
        value={state.discountPrice}
        onChange={(e) => handleChange({ discountPrice: e.target.value })}
        required
      />
      <Space h="lg" />
      <Group>
        <Checkbox
          label="Is Course"
          checked={state.isCourse}
          onChange={(e) => handleChange({ isCourse: e.target.checked })}
        />
        <Checkbox
          label="Active"
          checked={state.isActive}
          onChange={(e) => handleChange({ isActive: e.target.checked })}
        />
      </Group>
    </>
  );
};
