import { useMutation, useQuery } from "@apollo/client";
import { MouseEventHandler, useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { MantineButton } from "../../components/atoms/MantineButton";
import NotificationCreationForm from "../../components/templates/NotificationCreationForm";
import {
  CREATE_NOTIFICATION,
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_DROPDOWNS_DATA_QUERY,
} from "./query";
import { createNotificationBreadcrumbs } from "./data";
import { useNavigate } from "react-router";
import PageTitle from "../../components/molecules/PageTitle";
import {
  notificationReducer,
  initialState,
  getAllRegions,
  getAllNetworks,
} from "./notificationReducer";
import {
  ICategoryData,
  INotificationCategoriesData,
  INotificationsDropdownData,
  NotificationActions,
  NotificationState,
} from "./type";
import { UPLOAD_FILE } from "../../lib/queries/uploadFile";
import { useUser } from "../../lib/contexts/usercontext";
import { Modal } from "../../components/atoms/Modal";
import { Space } from "../../components/atoms/Space";
import NotificationPreview from "../../components/templates/NotificationPreview";

const CreateNotification = () => {
  const [UploadFile] = useMutation(UPLOAD_FILE);
  const [state, dispatch] = useReducer(notificationReducer, initialState);
  const [displayModal, setDisplayModal] = useState(false);

  const navigate = useNavigate();
  const { state: user } = useUser();

  useEffect(() => {
    dispatch({
      type: NotificationActions.SET_VALUE,
      payload: {
        key: "broadcastBy",
        value: {
          codename: user?.me?.reference_id,
          name: `${user?.me?.first_name || ""} ${user?.me?.last_name || ""}`,
        },
      },
    });
  }, [user]);

  const {
    loading: notificationsDropdownDataLoading,
    data: notificationsDropdownData,
  } = useQuery<INotificationsDropdownData>(NOTIFICATION_DROPDOWNS_DATA_QUERY, {
    variables: {
      orgId: user.me?.agent.organisation.id,
    },
    skip: !user.me?.agent.organisation.id,
    fetchPolicy: "network-only",
  });

  const [CreateNotification] = useMutation(CREATE_NOTIFICATION);

  useEffect(() => {
    if (!notificationsDropdownDataLoading && notificationsDropdownData) {
      dispatch({
        type: NotificationActions.SET_VALUES,
        payload: [
          {
            key: "networks",
            value: notificationsDropdownData.networks,
          },
          {
            key: "featuredImages",
            value: notificationsDropdownData.fileupload,
          },
        ],
      });
    }
  }, [notificationsDropdownData, notificationsDropdownDataLoading]);

  const {
    loading: notificationCategoriesLoading,
    data: notificationCategoriesData,
  } = useQuery<INotificationCategoriesData>(NOTIFICATION_CATEGORIES);

  useEffect(() => {
    if (!notificationCategoriesLoading && notificationCategoriesData) {
      dispatch({
        type: NotificationActions.SET_VALUES,
        payload: [
          {
            key: "categories",
            value: state.categories.map((item: ICategoryData) => ({
              ...item,
              codename: notificationCategoriesData.notification_category.find(
                (cat) => cat.name === item.display
              )?.codename,
            })),
          },
          {
            key: "selectedCategory",
            value: {
              ...state.selectedCategory,
              ...{
                codename: notificationCategoriesData.notification_category.find(
                  (cat) => cat.name === state.selectedCategory.display
                )?.codename,
              },
            },
          },
        ],
      });
    }
  }, [notificationCategoriesData, notificationCategoriesLoading]);

  useEffect(() => {
    document.title = "Create Notification - SetuFarm";
  }, []);

  const onFeaturedImageUploaded = (response: any) => {
    UploadFile({
      variables: {
        url: response.data.url,
        mediaType: response.data.type,
        name: response.data.name,
        duration: 0,
      },
    }).then(({ data }) => {
      dispatch({
        type: NotificationActions.SELECT_VALUE,
        payload: {
          prop: "selectedFeaturedImage",
          data: {
            id: data.insert_fileupload_one.id,
            url: new URL(response.data.url || response.data.preview_url).href,
            media_type: response.data.type,
            name: response.data.name,
          },
        },
      });
    });
  };

  const hasRequiredData = () => {
    let { networks, ...restNetwork } = getAllNetworks(state);
    let checkVisibility =
      state.visibilityType === "geographical"
        ? getAllRegions(state).length
        : state.visibilityType === "network"
        ? Object.values(restNetwork).flat().length
        : true;

    return (
      state.title &&
      state.description &&
      state.selectedNetworks.length &&
      checkVisibility &&
      state.broadcastDateTime
    );
  };

  const onBroadcastNotification = () => {
    /** Get Date of after 5 minutes. */
    const date = new Date();
    date.setMinutes(date.getMinutes() + 5);

    let broadcastDateTime =
      (state as NotificationState).broadcastDateTime || date;
    if (broadcastDateTime?.getTime() <= new Date().getTime()) {
      broadcastDateTime = date;
    }
    const allRegions = getAllRegions(state);
    const allNetwork = getAllNetworks(state);
    const data = {
      title: state.title,
      description: state.description,
      categoryId: state.selectedCategory.codename,
      featureImage:
        state.notificationType?.featuredImage?.url ||
        state.selectedFeaturedImage?.url,
      body: state.content,
      broadcastDateTime,
      broadcasterId: user.me?.reference_id,
      networks: allNetwork.networks.map((network) => network.codename) || [],
      ...(state.visibilityType === "geographical"
        ? { regions: allRegions?.map((region: any) => region.codename) || [] }
        : state.visibilityType === "network"
        ? {
            teams: allNetwork.teams.map((team: any) => team.codename) || [],
            users: allNetwork.users.map((user: any) => user.codename) || [],
          }
        : {}),
      recordId: state.recordId,
    };
    toast
      .promise(CreateNotification({ variables: data }), {
        pending: "Broadcast Creating",
        success: "Broadcast Created",
        error: "Couldn't Create Broadcast",
      })
      .then(() => {
        navigate("/notifications/listing");
      });
  };

  const handleModalDisplay: MouseEventHandler = (e) => {
    e.stopPropagation();
    setDisplayModal((prev) => !prev);
  };

  return (
    <>
      <PageTitle
        breadCrumbsList={createNotificationBreadcrumbs}
        fixed
        rightSection={
          <>
            <MantineButton
              variant="outline"
              data-test-id="notification-broadcast-preview-button"
              onClick={handleModalDisplay}
              disabled={!hasRequiredData()}
              uppercase
            >
              Preview
            </MantineButton>
            <Space w={20} />
            <MantineButton
              data-test-id="notification-broadcast"
              onClick={onBroadcastNotification}
              disabled={!hasRequiredData()}
              uppercase
            >
              Broadcast
            </MantineButton>
          </>
        }
      />

      <NotificationCreationForm
        state={state}
        dispatch={dispatch}
        onFeaturedImageUploaded={onFeaturedImageUploaded}
      />
      <Modal
        isOpen={Boolean(hasRequiredData() && displayModal)}
        handleModal={handleModalDisplay}
      >
        <NotificationPreview
          state={{
            ...state,
            selectedRegions: getAllRegions(state),
            selectedAllNetwork: getAllNetworks(state),
          }}
        />
      </Modal>
    </>
  );
};

export default CreateNotification;
