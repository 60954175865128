import { gql } from "@apollo/client";

export const SCHOOL_METRICS_QUERY = gql`
  query getSchoolMetrics {
    courses: total_series_count {
      total
    }
    coursesLikes: total_series_likes {
      total
    }
    coursesFavorites: total_series_favorites {
      total
    }
    coursesViews: total_series_views {
      total
    }
  }
`;
