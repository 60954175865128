import styled from "styled-components";

export const LegendTitleContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    color: ${(props) => props.theme.colors.primary.main};
  }
`;
