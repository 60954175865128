import { SVGProps } from "react";

const WeatherIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6911 3.90099C16.4332 3.80226 16.3042 3.51316 16.4029 3.25527L16.7604 2.32137C16.8592 2.06348 17.1483 1.93445 17.4062 2.03318C17.664 2.13191 17.7931 2.42101 17.6943 2.6789L17.3368 3.6128C17.2381 3.87069 16.949 3.99972 16.6911 3.90099ZM11.6536 6.26302C11.4297 6.03017 11.4351 5.65652 11.6938 5.46305C12.489 4.86833 13.4559 4.53588 14.4586 4.52154C15.6574 4.50439 16.8179 4.94314 17.7055 5.74905C18.5931 6.55495 19.1415 7.66787 19.2398 8.8627C19.322 9.86219 19.0842 10.8566 18.5688 11.7053C18.4011 11.9814 18.0297 12.0228 17.7764 11.8223C17.5231 11.6219 17.4851 11.2557 17.6414 10.973C17.9789 10.3626 18.1318 9.6623 18.0739 8.95863C18 8.05932 17.5872 7.22168 16.9191 6.61511C16.2511 6.00853 15.3776 5.67831 14.4754 5.69121C13.7694 5.70131 13.087 5.9209 12.5119 6.31559C12.2456 6.49838 11.8775 6.49586 11.6536 6.26302ZM19.8665 11.4741C19.9954 11.2299 20.2979 11.1364 20.5421 11.2653L21.4264 11.7322C21.6706 11.8611 21.7641 12.1636 21.6352 12.4078C21.5063 12.652 21.2038 12.7454 20.9596 12.6165L20.0752 12.1497C19.831 12.0208 19.7376 11.7183 19.8665 11.4741ZM20.038 6.02703C19.7788 6.12231 19.6459 6.40966 19.7412 6.66885C19.8365 6.92803 20.1239 7.0609 20.383 6.96562L21.3216 6.62059C21.5808 6.52531 21.7137 6.23796 21.6184 5.97877C21.5231 5.71959 21.2358 5.58672 20.9766 5.682L20.038 6.02703ZM10.7169 4.45289C10.8583 4.69008 11.1652 4.76773 11.4024 4.62633C11.6396 4.48492 11.7172 4.17801 11.5758 3.94082L11.0637 3.08188C10.9223 2.84469 10.6154 2.76704 10.3782 2.90845C10.141 3.04985 10.0634 3.35677 10.2048 3.59396L10.7169 4.45289ZM14.4811 13.1178C14.2257 9.76154 11.4216 7.11777 8 7.11777C4.41015 7.11777 1.5 10.0279 1.5 13.6178C1.5 17.2076 4.41015 20.1178 8 20.1178C8.00168 20.1178 8.00336 20.1178 8.00503 20.1178H16C17.933 20.1178 19.5 18.5508 19.5 16.6178C19.5 14.6848 17.933 13.1178 16 13.1178H14.4811Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default WeatherIcon;
