import { SVGProps } from "react";

export const R2RDistanceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.65748 9.95272C8.64359 9.94342 8.63436 9.92945 8.62047 9.91547C8.39823 9.65456 8.14362 9.41232 7.6853 9.41232C7.0001 9.41232 6.66675 9.9435 6.66675 10.8333H13.3334C13.3334 9.94343 13.0047 9.41232 12.3149 9.41232C11.7778 9.41232 11.5139 9.72912 11.3612 9.91547L11.338 9.94342V8.36393C11.338 7.88403 11.5186 7.51133 12.0927 7.21778C11.7964 7.52062 11.6436 7.81884 11.6112 8.1636C12.6575 8.1543 13.0927 7.39946 13.1482 6.53288C11.8196 6.61212 11.139 7.08733 11.0649 7.97257C10.7223 7.50198 10.0696 7.29696 9.25472 7.29696C9.27784 8.16353 9.68068 8.90902 10.7825 8.92767C10.8288 8.68541 10.6991 8.41982 10.3103 8.00981C10.9214 8.21482 11.0602 8.46643 11.0602 9.03948V10.0039C11.0186 9.98529 10.9815 9.95734 10.9492 9.91541C10.7871 9.72439 10.5186 9.41226 9.9908 9.41226C9.45377 9.41226 9.18526 9.72906 9.02787 9.91541C9.0001 9.94804 8.97233 9.97599 8.9399 9.98997V6.97548C8.9399 6.2766 9.12507 5.9644 9.68989 5.71286C9.301 6.12288 9.17139 6.38847 9.12507 6.63071C10.3195 6.61206 10.7223 5.87125 10.7454 5C9.51861 5 8.65748 5.46592 8.65748 6.60276V7.05002C8.33804 6.64 7.7362 6.41173 6.85193 6.36045C6.90747 7.22702 7.34267 7.98186 8.38898 7.99584C8.35197 7.6464 8.20381 7.34818 7.90748 7.04535C8.48154 7.33889 8.65746 7.7116 8.65746 8.19149L8.65748 9.95272Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M6.27323 18.3333L4.16675 16.6667M4.16675 16.6667L6.27323 15M4.16675 16.6667H15.0001M12.8936 18.3333L15.0001 16.6667M15.0001 16.6667L12.8936 15"
      stroke={props.color || "#005777"}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 2.08464V14.168H5.83333V1.66797H2.5V2.08464Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M14.1667 2.08464V14.168H17.5001V1.66797H14.1667V2.08464Z"
      fill={props.color || "#005777"}
    />
  </svg>
);
