import { useLazyQuery, useQuery } from "@apollo/client";
import { Dispatch, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "../../../../lib/contexts/usercontext";
import { getOrganizationPreferences } from "../../../../lib/utils/teamDetailApi";
import { theme } from "../../../../style/theme";
import { Divider } from "../../../atoms/Divider";
import { Grid } from "../../../atoms/Grid";
import AreaIcon from "../../../atoms/Icons/AreaIcon";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserIcon from "../../../atoms/Icons/UserIcon";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import { GET_USERS } from "../../../templates/TeamsDetailModal/query";
import {
  Actions,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";
import { GET_FARMS, GET_SOIL_TYPES } from "./query";

export interface FieldDetailFormProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
}

export function FieldDetailForm({ state, dispatch }: FieldDetailFormProps) {
  const user = useUser();
  const [fieldSuffix, setFieldSuffix] = useState("");

  const [getUsers, { data: usersData }] = useLazyQuery<{
    users: { id: number; name: string }[];
  }>(GET_USERS);

  const { data: soilTypes } = useQuery(GET_SOIL_TYPES);
  const [getFarms, { data: farms }] = useLazyQuery(GET_FARMS);

  useEffect(() => {
    if (state.field.detail.teamId) {
      getUsers({ variables: { teamId: state.field.detail.teamId } });
      getFarms({ variables: { teamId: state.field.detail.teamId } })
    }
  }, [state.field.detail.teamId]);

  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: TeamActions.SET_FIELD_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.field.detail,
          ...(!state.field.detail.updatedFields.includes(name)
            ? { updatedFields: [...state.field.detail.updatedFields, name] }
            : {}),
          [name]: value,
        },
      },
    });
  };

  const fetchData = async (userId: string) => {
    try {
      const organizationPreferences = await getOrganizationPreferences({
        userId,
      });

      setFieldSuffix(organizationPreferences.data.field_suffix);
    } catch (error) {
      toast.error("Failed to fetch preferences");
    }
  };

  useEffect(() => {
    const userId = user?.state?.me?.id;
    if (userId) {
      fetchData(userId);
    }
    return () => {
      setFieldSuffix("");
    }
  }, [user]);

  return (
    <section className="flex  flex-col  gap-8" data-cy="detail-form">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.field.detail.owner}</Typography>
        </div>
        <div className="flex justify-start items-center gap-4">
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.field.detail.teamName}</Typography>
        </div>
      </div>
      <Grid className="mt-2">
        <Grid.Col lg={2} md={4} sm={6} xs={12}>
          <Input
            label="Field tag"
            data-cy="field-tag"
            required
            prefix=""
            icon={
              <div className=" font-bold text-primary-main">
                {fieldSuffix} -
              </div>
            }
            placeholder="Enter tag"
            type="text"
            maxLength={8}
            value={state.field.detail.tag.replace(`${fieldSuffix}-`, "")}
            name="description"
            size="lg"
            onChange={(e) => {
              dispatchValue("tag", `${fieldSuffix}-${e.target.value}`);
            }}
            autoComplete="off"
            error={!state.field.detail.tag ? "Tag is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={5} md={4} sm={6} xs={12}>
          <Input
            label="Field name"
            data-cy="field-name"
            required
            placeholder="Enter field name"
            type="text"
            value={state.field.detail.name}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("name", e.target.value);
            }}
            autoComplete="off"
            error={!state.field.detail.name ? "Name is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={5} md={4} sm={6} xs={12}>
          <Select
            label="Field owner"
            data-cy="field-owner"
            size="lg"
            listData={usersData?.users || []}
            placeholder="Select owner"
            values={{ id: state.field.detail.ownerId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("ownerId", item?.id);
            }}
            clearable
            required
            error={!state.field.detail.ownerId ? "Owner is required" : ""}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Input
            label="GAT number"
            data-cy="gat-number"
            required
            placeholder="Enter GAT number"
            type="text"
            value={state.field.detail.gatNumber || ""}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("gatNumber", e.target.value);
            }}
            autoComplete="off"
            error={
              !state.field.detail.gatNumber ? "GAT number is required" : ""
            }
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Soil type"
            data-cy="soil-type"
            size="lg"
            listData={soilTypes?.soil || []}
            placeholder="Select soil type"
            values={{ id: state.field.detail.soilId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("soilId", item?.id);
            }}
            clearable
            required
            error={!state.field.detail.soilId ? "Soil type is required" : ""}
          />
        </Grid.Col>
      </Grid>
      <Divider size={1} mt={8} mb={8} />
      <Typography variant="h6"><span data-cy="additional-detail">Additional details</span></Typography>
      <Grid>
        <Grid.Col span={6} data-cy="area-section">
          <Typography variant="body1">Field area</Typography>
          <div className="flex items-center gap-4 mt-2">
            <AreaIcon  color={theme.colors.orange.main}/>
            <Typography variant="body1">
              <span className="font-bold">
                {state.field.detail.area} {state.field.detail.areaUnit}
              </span>
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Farm"
            data-cy="farm"
            size="lg"
            searchable
            listData={farms?.farm?.map((item: {id: number; tag: string; name: string}) => ({...item, name: `${item.tag} ${item.name}`})) || []}
            placeholder="Select farm"
            values={{ id: state.field.detail.farmId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("farmId", item?.id);
            }}
            clearable
            required
            error={!state.field.detail.farmId ? "Farm is required" : ""}
          />
        </Grid.Col>
      </Grid>
    </section>
  );
}
