import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Typography from "../../atoms/Typography";
import {
  ArrowIcon,
  BreadCrumbContainer,
  BreadCrumbItem,
  Container,
  HomeIcon,
} from "./styles";
import { IBreadCrumbProps } from "./types";

const BreadCrumbs: React.FC<{ breadCrumbsList: IBreadCrumbProps }> = ({
  breadCrumbsList,
}) => {
  const { pathname } = useLocation();
  return (
    <Container>
      {pathname.split("/").map((item, i, arr) => (
        <BreadCrumbContainer key={i}>
          {item === "" ? (
            <Link to="/">
              <HomeIcon />
            </Link>
          ) : (
            <BreadCrumbItem to={arr.slice(0, i + 1).join("/")}>
              <Typography variant="h6">
                {breadCrumbsList[item] || item}
              </Typography>
            </BreadCrumbItem>
          )}
          {i < arr.length - 1 && <ArrowIcon />}
        </BreadCrumbContainer>
      ))}
    </Container>
  );
};

export default BreadCrumbs;
