import { Modal } from "../../atoms/Modal";
import { Image } from "@mantine/core";
import DeleteIllustration from "../../../assets/delete_illustration.png";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { DELETE_ARTICLE } from "./query";
import { MantineButton } from "../../atoms/MantineButton";

interface IDeleteModalProps {
  data: any;
  refetch: any;
}

export const ArticleDeleteModal = ({ data, refetch }: IDeleteModalProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [DeleteArticle] = useMutation(DELETE_ARTICLE);
  const handleDelete = () => {
    toast
      .promise(
        DeleteArticle({
          variables: { id: searchParams.get("deleteId") },
        }),
        {
          pending: "Deleting Article",
          success: "Article Deleted",
          error: "Couldn't Delete Article",
        }
      )
      .then(() => {
        refetch();
        navigate(-1);
      });
  };

  return (
    <Modal
      width={"min(60rem, 95vw)"}
      height={"min(45rem, 95vh)"}
      isOpen={data?.article?.records?.find(
        (item: any) => item.id === searchParams.get("deleteId")
      )}
      handleModal={(e) => navigate(-1)}
      modalTitle="Are you sure, you want to delete this article?"
    >
      <div className="h-full flex flex-col justify-around">
        <Image src={DeleteIllustration} className="mx-auto !w-1/2" />
        <div className="flex justify-around items-stretch gap-16 mt-24 px-20">
          <MantineButton
            variant="outline"
            fullWidth
            size="md"
            onClick={() => navigate(-1)}
          >
            NO
          </MantineButton>
          <MantineButton size="md" onClick={handleDelete} fullWidth>
            YES
          </MantineButton>
        </div>
      </div>
    </Modal>
  );
};
