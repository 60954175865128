import {
  SegmentedControl as MantineSegmentedControl,
  MantineTheme,
  SegmentedControlProps,
} from "@mantine/core";

const styles = (theme: MantineTheme) => ({
  label: { color: theme.other.colors.primary.main + " !important" },
});

export const SegmentedControl = (props: SegmentedControlProps) => {
  return <MantineSegmentedControl styles={styles} {...props} />;
};
