import loader from "../../../../assets/loaders/logoLoader.gif";
import Image from "../../Image";
import { ImageProps } from "../../Image/type";

interface ILogoLoader extends ImageProps {
  margin?: string;
}

export const LogoLoader = ({ margin, ...props }: ILogoLoader) => {
  return <Image src={loader} style={{ margin: margin || "auto" }} {...props} />;
};
