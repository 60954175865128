import { useQuery } from "@apollo/client";
import { Space } from "@mantine/core";
import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { SortingRule } from "react-table";
import { config } from "../../../config";
import { useUser } from "../../../lib/contexts/usercontext";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import { sortingMapper } from "../../../lib/utils/table";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { Select } from "../../atoms/MantineSelect";
import { Modal } from "../../atoms/Modal";
import { Pagination } from "../../atoms/Pagination";
import Typography from "../../atoms/Typography";
import Table from "../../molecules/Table";
import { TableFooter } from "../../molecules/Table/styles";
import { CardSection } from "./CardSection";
import { CareProductPreview } from "../CareProductPreview";
import { CARE_PRODUCT_DETAILS } from "../CareProductPreview/query";
import {
  careListingColumns,
  careListingDefaultSorting,
  careListingSortingMap,
} from "./data";
import { normalizeProductListing } from "./normalize";
import { careListingCondition, CARE_LISTING } from "./query";

export function CareListingTemplate({
  listType,
  search,
  currentPage,
  setCurrentPage,
  setCareListCount,
  filterData,
}: {
  listType: string;
  search: string;
  filterData: any;
  currentPage: number;
  setCurrentPage: any;
  setCareListCount: Dispatch<SetStateAction<number>>;
}) {
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("careConfig")?.itemsPerPage || config.itemsPerPage
  );
  const [displayModal, setDisplayModal] = useState(false);
  const [stockId, setStockId] = useState("");
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("careConfig")?.sortBy || careListingDefaultSorting
  );

  const storeId = process.env.REACT_APP_STORE_ID;
  const { loading, data, error } = useQuery(CARE_LISTING, {
    variables: {
      offset: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
      order: sortingMapper(sortBy, careListingSortingMap),
      where: careListingCondition(filterData, search, storeId),
    },
  });

  const { state: user } = useUser();
  const { data: detailsData, loading: detailsLoading } = useQuery(
    CARE_PRODUCT_DETAILS,
    {
      variables: {
        sellable_stock_id: stockId,
        store_id: storeId,
        user_id: user.me?.reference_id,
        sellable_stock_string_pk: stockId,
      },
      skip: !user.me?.reference_id || !stockId,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    setCareListCount(data?.sellable_item_stock_aggregate?.aggregate?.count);
  }, [data]);

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("careConfig");
    setLocalStorage("careConfig", {
      ...localConfig,
      itemsPerPage: Number(item),
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("careConfig");
    setLocalStorage("careConfig", { ...localConfig, sortBy: sorting });
    setSortby(sorting);
  };

  const handleModalDisplay = (value: any, e?: MouseEvent) => {
    e?.stopPropagation();
    if (value) setStockId(value.original?.id);
    else setStockId("");
    setDisplayModal((prev) => !prev);
  };

  return (
    <div className="flex flex-col relative" data-cy="farm-care-table">
      {listType === "list" ? (
        <Table
          tableColumns={careListingColumns}
          tableData={data?.sellable_item_stock || []}
          pageSize={itemsPerPage}
          loading={loading}
          sortByData={sortBy}
          handleSortBy={handleSorting}
          fixedHead
          getRowProps={(row: any) => ({
            onClick: (e: MouseEvent) => handleModalDisplay(row, e),
          })}
        />
      ) : (
        <CardSection
          loading={loading}
          handleClick={(id) => handleModalDisplay({ original: { id } })}
          products={normalizeProductListing(data?.sellable_item_stock)}
        ></CardSection>
      )}
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w="lg" />
        <Select
          data-cy="select-entries-per-page"
          listData={config.itemsPerPageList.map((item) => String(item))}
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && !error && (
            <Pagination
              page={currentPage}
              onChange={setCurrentPage}
              total={Math.ceil(
                (data?.sellable_item_stock_aggregate?.aggregate?.count ||
                  itemsPerPage) / itemsPerPage
              )}
              boundaries={0}
              siblings={config.paginationWindow / 2 - 1}
              withEdges
              position="right"
            />
          )}
        </div>
      </TableFooter>
      <Modal
        modalTitle={"Product Details"}
        isOpen={displayModal}
        handleModal={(e) => handleModalDisplay(null, e)}
        width={"40rem"}
      >
        {!detailsLoading ? (
          <CareProductPreview data={detailsData} />
        ) : (
          <LogoLoader width={100} margin="15% auto" />
        )}
      </Modal>
    </div>
  );
}
