import FarmIcon from "../../../atoms/Icons/FarmIcon";
import FieldIcon from "../../../atoms/Icons/FieldIcon";
import { FarmIco, MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import PlotIcon from "../../../atoms/Icons/PlotIcon";
import UserIcon from "../../../atoms/Icons/UserIcon";

export function GetEntityIcon({
  type,
  size,
  color,
}: {
  type: string;
  size?: number;
  color?: string;
}) {
  const props = {
    ...(size && { width: size, height: size }),
    ...(color && { color }),
  };
  switch (type) {
    case "team":
      return <MultipleUsersIcon {...props} />;
    case "user":
      return <UserIcon {...props} />;
    case "farm":
      return <FarmIcon {...props} />;
    case "field":
      return <FieldIcon {...props} />;
    case "plot":
      return <PlotIcon {...props} />;
    case "crop":
      return <FarmIco {...props} />;
    default:
      return null;
  }
}
