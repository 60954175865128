import { Textarea } from "@mantine/core";
import { useState } from "react";
import { MantineButton } from "../../atoms/MantineButton";

export interface RemarkSectionProps {
  onSubmit: (remark: string) => void;
  onCancel: () => void;
  placeholder?: string;
}
export function RemarkSection({
  onSubmit,
  onCancel,
  placeholder = "Type remarks here..",
}: RemarkSectionProps) {
  const [remarks, setRemarks] = useState("");

  return (
    <section className=" bg-white rounded-md border border-border border-solid px-5 pb-5 mt-5" data-cy="remark-section">
      <Textarea
        autosize
        onChange={(event) => setRemarks(event.target.value)}
        value={remarks}
        minRows={3}
        variant="unstyled"
        className="border-none"
        placeholder={placeholder}
      />

      <div className="w-full flex items-center justify-end gap-4">
        <div>
          <MantineButton onClick={onCancel} variant="outline" data-cy="remark-cancel">
            CANCEL
          </MantineButton>
        </div>
        <div>
          <MantineButton disabled={!remarks} onClick={() => onSubmit(remarks)} data-cy="remark-send">
            SEND
          </MantineButton>
        </div>
      </div>
    </section>
  );
}
