import React, { ReactNode, useEffect, useRef, useState } from "react";
import { BsUpload, BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import { useUser } from "../../../lib/contexts/usercontext";
import useWindowSize from "../../../lib/hooks/useviewportsize";
import { uploadFile } from "../../../lib/utils/uploadapi";
import { deviceSize } from "../../../style/devices";
import { Loader } from "../Loader/Loader";
import Typography from "../Typography";
import { UploadBtn, ButtonContainer } from "./styles";

interface Props {
  accept: string[];
  onfileUploadedCallback: (response: any) => void;
  label?: ReactNode;
  className?: string;
  placeholder: string;
  disabled?: boolean;
  clearFile?: boolean;
  uploadPath?: string;
  method?: "post" | "put";
  formData?: Record<string, any>;
}

const UploadButton: React.FunctionComponent<Props> = ({
  accept,
  onfileUploadedCallback,
  label,
  className,
  placeholder,
  disabled,
  clearFile,
  uploadPath,
  method,
  formData,
}) => {
  const [file, setFile] = useState<File>();
  const fileRef = React.useRef<HTMLInputElement>(null);
  const { state: user } = useUser();
  const size = useWindowSize();
  const isValidType = () => accept.some((item) => file?.type.match(item));
  const [loading, setLoading] = useState(false);
  const abortController = useRef<any>(null);

  useEffect(() => {
    if (file && !isValidType()) {
      handleCancel();
      toast.error("Invalid file type");
    } else if (file && isValidType() && !disabled) {
      setLoading(true);
      abortController.current = new AbortController();

      const data = new FormData();
      data.append("file", file, file.name);
      data.append("path", `dev/${user.me?.reference_id}/articles/`);
      data.append("name", file.name);
      data.append("access", "public-read");

      Object.entries(formData || {}).forEach(([key, value]) => {
        data.append(key, value);
      });

      uploadFile({
        token: user.token,
        signal: abortController.current?.signal,
        uploadPath,
        method: method || "put",
        formData: data,
      })
        .then((res: any) => {
          res.data.type = file?.type.split("/")[0];
          let filename = file.name.split(".");
          let extension = filename.pop();
          res.data.name = `${filename.join(".")}_${Date.now()}.${extension}`;
          setLoading(false);
          onfileUploadedCallback(res);
        })
        .catch(() => {
          toast.info("Couldn't Upload File");
        });
    }
  }, [file, user, disabled]);

  useEffect(() => {
    if (clearFile) handleCancel();
  }, [clearFile]);

  const openFileSelector = () => {
    if (fileRef.current) fileRef.current.click();
  };

  const onfileUploaded = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    if (target.files) {
      const files = target.files;
      const currentFile = Array.prototype.slice.call(files);
      if (currentFile[0]) setFile(currentFile[0]);
    }
  };

  const handleCancel = (e: any = null) => {
    if (e) e.stopPropagation();
    abortController.current?.abort();
    if (fileRef.current) fileRef.current.value = "";
    setFile(undefined);
    setLoading(false);
    onfileUploadedCallback({ status: null });
  };

  return (
    <ButtonContainer className={className}>
      {label && (
        <Typography
          variant={size.width < deviceSize.mobile ? "subtitle1" : "h5"}
          className="mb-3"
        >
          {label}
        </Typography>
      )}

      <UploadBtn onClick={openFileSelector} disabled={disabled}>
        {file?.name || placeholder ? (
          <Typography
            variant="body1"
            textColor="inherit"
            className="max-w-xs truncate"
          >
            {(isValidType() && file?.name) || placeholder}
          </Typography>
        ) : null}
        {loading ? (
          <Loader mr={10} />
        ) : isValidType() ? (
          <BsX className="cancel_upload" onClick={handleCancel} />
        ) : null}
        <BsUpload />
      </UploadBtn>
      <input
        type="file"
        onChange={onfileUploaded}
        accept={accept.join(", ")}
        ref={fileRef}
        style={{ display: "none" }}
        disabled={disabled}
      />
    </ButtonContainer>
  );
};

export default UploadButton;
