import { gql } from "@apollo/client";

export const GET_CATEGORY = gql`
  query GetCategory($id: uuid!) {
    category: article_category_by_pk(id: $id) {
      id
      name
      isCourseCategory: is_course_category
      isActive: is_active
      description
      mutual_group {
        name
        codename
      }
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory(
    $name: String!
    $description: String
    $groupId: String
    $isCourseCategory: Boolean!
  ) {
    insert_article_category_one(
      object: {
        name: $name
        description: $description
        group_id: $groupId
        is_course_category: $isCourseCategory
      }
    ) {
      name
      id
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation CategoryUpdate(
    $id: uuid!
    $name: String!
    $description: String
    $groupId: String
    $isActive: Boolean
    $isCourseCategory: Boolean!
  ) {
    update_article_category_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        group_id: $groupId
        is_active: $isActive
        is_course_category: $isCourseCategory
      }
    ) {
      id
      name
    }
  }
`;

export const GET_PACKAGE = gql`
  query GetPackage($id: String!) {
    package: subscription_package_detail(id: $id) {
      id
      name
      description
      isActive: is_active
      isCourse: is_course
      unitPrice: unit_price
      discountPrice: discount_price
      packageType: package_type
      orgId: owner_organisation_id
    }
  }
`;

export const CREATE_PACKAGE = gql`
  mutation CreatePackage(
    $name: String!
    $description: String
    $isPublic: Boolean
  ) {
    insert_article_subscription_package_one(
      object: { name: $name, description: $description, is_public: $isPublic }
    ) {
      id
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation UpdatePackage(
    $id: uuid!
    $name: String!
    $description: String
    $isPublic: Boolean
    $isActive: Boolean
  ) {
    update_article_subscription_package_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        description: $description
        is_public: $isPublic
        is_active: $isActive
      }
    ) {
      id
    }
  }
`;

export const UPSERT_PACKAGE = gql`
  mutation UpsertPackage(
    $id: String
    $name: String!
    $description: String
    $isActive: Boolean
    $orgId: String
    $packageType: String!
    $unitPrice: Int
    $discountPrice: Int
    $isCourse: Boolean
  ) {
    upsert_subscription_package(
      id: $id
      name: $name
      description: $description
      is_active: $isActive
      organisation_id: $orgId
      package_type: $packageType
      unit_price: $unitPrice
      discount_price: $discountPrice
      is_course: $isCourse
    ) {
      package {
        id
      }
    }
  }
`;

export const GET_ARTICLES = gql`
  query SeriesNewArticle($avoidList: [uuid!], $packageId: uuid) {
    article(
      order_by: { title: asc }
      where: {
        id: { _nin: $avoidList }
        subscriptions: { package_id: { _eq: $packageId } }
      }
    ) {
      id
      title
    }
  }
`;

export const GET_SUBSCRIBERS = gql`
  query getSubscriberCount($teams: [String!]) {
    users: base_user_aggregate(
      where: {
        mutual_group_memberships: {
          mutual_group: { codename: { _in: $teams } }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_ORGANISATIONS = gql`
  query getOrganisations {
    organisation {
      id
      name
    }
  }
`;
