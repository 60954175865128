import {
  IoChevronBackCircleOutline,
  IoChevronForwardCircleOutline,
} from "react-icons/io5";
import styled from "styled-components";

export const FrontArrowIcon = styled(IoChevronForwardCircleOutline)`
  width: 36px;
  height: 36px;
  flex: 0 0 36px;
  cursor: pointer;
  align-self: center;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const BackArrowIcon = styled(IoChevronBackCircleOutline)`
  width: 36px;
  height: 36px;
  flex: 0 0 36px;
  cursor: pointer;
  align-self: center;
  color: ${({ theme }) => theme.colors.primary.main};
`;
