import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

export const ErrorBoundary: React.FC = ({ children }) => {
  return (
    // @ts-ignore
    <SentryErrorBoundary
      fallback={
        <div className="text-center">
          <h1 className="my-6 text-4xl">An error has occurred!</h1>
          <a className="text-primary-main text-lg" href="/">
            Go to home page
          </a>
        </div>
      }
    >
      {children}
    </SentryErrorBoundary>
  );
};
