import { useContext, useEffect } from "react";
import useCollapse from "react-collapsed";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { AssetContext } from "../../../../lib/contexts/AssetContext";
import { Divider } from "../../../atoms/Divider";
import { sidebarTypes } from "../../../templates/TeamMapView/data";
import { ListCardHeader } from "./ListCardHeader";
import { SidebarDetail } from "./SidebarDetail";

export const ListCardContainer = styled.div<{ success?: boolean }>`
  padding: 1.4rem;
  margin-bottom: 1rem;
  &:first-child {
    margin-top: 1rem;
  }
  border-radius: 4px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: ${({ theme, success }) =>
    theme.colors[success ? "positive" : "negative"].default};
  border: 1px solid
    ${({ theme, success }) =>
      theme.colors[success ? "positive" : "negative"].default};
`;

export function ListCard({
  isActive,
  onSelect,
  item,
  type,
}: {
  isActive?: boolean;
  onSelect?: () => void;
  item: any;
  type: sidebarTypes;
  idType?: sidebarTypes;
}) {
  const { setAsset } = useContext(AssetContext);
  const [params, setParams] = useSearchParams();

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    isExpanded: isActive,
  });

  useEffect(() => {
    if (isExpanded) {
      if (params.get("expandedId")) {
        params.set("expandedId", item.id);
      } else {
        params.append("expandedId", item.id);
      }
      setParams(params);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (params.get("expandedId") === item.id.toString()) {
      let coords =
        type === "farm"
          ? item.address?.location?.coordinates
          : type === "crop"
          ? item.plot.area_catered?.coordinates
          : item.area_catered?.coordinates;

      setAsset(() => ({
        id: item.id,
        type,
        coords,
        focus: true,
      }));
    }
  }, [params.get("expandedId"), item]);

  return (
    <ListCardContainer success={item.verified}>
      <div
        {...getToggleProps({ onClick: onSelect })}
        className="w-full"
        data-cy="list-card-header"
      >
        <ListCardHeader
          listType={type}
          name={type === "crop" ? item.crop?.name : item.name}
          variety={item.variety?.name}
          verified={item.verified}
          tag={item.tag}
          isExpanded={isExpanded}
          area={
            item.area &&
            item.areaUnit &&
            `${Number(item.area).toFixed(2)} ${item.areaUnit.name}`
          }
        />
      </div>
      <div
        className="!flex flex-col gap-4 w-full"
        {...getCollapseProps()}
        data-cy="list-card-body"
      >
        <Divider size={1} className="!mt-3" />
        <SidebarDetail
          data={{ ...item, [type]: item }}
          type={type}
          isListing={true}
        />
      </div>
    </ListCardContainer>
  );
}
