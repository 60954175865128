import { SVGProps } from "react";

const CoursesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.8344 16.6158C19.8934 16.5605 19.9273 16.4793 19.9273 16.3936V6.74918C20.1836 6.76952 20.4418 6.79699 20.701 6.83728V17.7699C17.1095 17.19 14.1067 17.8964 12.7224 18.3308C13.7145 17.5161 16.0744 16.0422 19.6221 16.6815C19.6968 16.6954 19.7759 16.6718 19.8347 16.616L19.8344 16.6158ZM19.401 5.11914V16.0554C15.623 15.482 13.2095 17.1433 12.2631 17.9783V7.22284C12.7086 6.76483 15.1938 4.45466 19.401 5.11919V5.11914ZM4.59876 5.11914C8.80524 4.45586 11.2913 6.7652 11.7368 7.22278V17.9772C10.9225 17.26 9.02888 15.9377 6.12269 15.9377C5.64249 15.9377 5.13404 15.9739 4.59876 16.0549V5.11914ZM3.29879 6.83739C3.55798 6.7969 3.81608 6.76964 4.07251 6.74929V16.3938C4.07251 16.4792 4.10647 16.5606 4.16539 16.6159C4.22413 16.6713 4.30251 16.6953 4.37795 16.6817C7.92453 16.042 10.2852 17.5163 11.2777 18.3312C9.89291 17.8972 6.88988 17.1909 3.29903 17.7702V6.83759L3.29879 6.83739ZM2.00009 8.0181C2.25983 7.97761 2.51719 7.95035 2.77254 7.93V18.1104C2.77254 18.1959 2.8065 18.2773 2.86542 18.3326C2.92434 18.388 3.00272 18.4118 3.07798 18.3983C6.97549 17.6956 10.2794 18.6055 11.4509 18.9998L2 19L2.00009 8.0181ZM21.9996 18.9991H12.5492C13.7207 18.605 17.0251 17.6955 20.9221 18.3977C20.9966 18.4111 21.0757 18.3875 21.1347 18.332C21.1936 18.2766 21.2276 18.1954 21.2276 18.1098V7.92985C21.4825 7.9502 21.7402 7.97746 22 8.01795L21.9996 18.9991Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default CoursesIcon;
