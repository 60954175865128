import styled from "styled-components";
import tw from "twin.macro";
import { Divider } from "../LegendDivider/styles";
import { Radius, StyledCardProps } from "./types";

const getRadius = (radius: Radius) => {
  switch (radius) {
    case "pill":
      return 6;
    case "rounded":
      return 100;
    case "square":
      return 0;
    default:
      return 6;
  }
};

/**
 * @function Card
 * Card Generic Component Can be extendible.
 */
export const Card = styled.div<StyledCardProps>(
  ({
    minWidth,
    width,
    height,
    radius,
    theme,
    border,
    borderColor,
    borderWidth,
    shadow = "elevation2",
  }) => {
    return `
      min-width: ${
        minWidth ? (minWidth === "full" ? "100%" : `${minWidth}px`) : "auto"
      };
      overflow: hidden;
      width: ${width ? (width === "full" ? "100%" : `${width}px`) : "auto"};
      height: ${height ? `${height}px` : "auto"};
      background: ${theme.colors.white};
      ${shadow !== "none" && `box-shadow: ${theme.elevation[shadow]}`};
      border-radius: ${`${getRadius(radius || "pill")}px`};
      border: ${
        border && borderColor && borderWidth
          ? `${border} ${borderWidth}px ${borderColor}`
          : "none"
      }
  `;
  }
);
export const CardHeader = tw.div`
   p-4 flex justify-between
`;
export const CardBody = tw.div`
  overflow-auto
  p-4
`;
export const CardDivider = tw(Divider)``;
