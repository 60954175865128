import { Attachment } from "../../../lib/interfaces/Article";
import { FeatureImage } from "../../templates/ArticlePreview/styles";
import { VideoPreview } from "../ArticleCreationSections/MediaPreview/VideoPreview";
import { ArticleMediaContainer } from "./styles";

interface IArticleImageProps {
  articleType: string;
  attachments: Attachment | null;
  featuredImage: Attachment;
}

export const ArticleBanner = ({
  articleType,
  attachments,
  featuredImage,
}: IArticleImageProps) => {
  return (
    <div data-cy="article-banner">
      {articleType === "video" && attachments?.media_type === "video" && (
        <ArticleMediaContainer>
          <VideoPreview attachment={attachments} height="40rem" width="60%" />
        </ArticleMediaContainer>
      )}
      {articleType === "audio" && attachments?.media_type === "audio" && (
        <ArticleMediaContainer>
          <audio src={attachments.url} controls />
        </ArticleMediaContainer>
      )}
      {articleType === "blog" && featuredImage?.url && (
        <FeatureImage imageUrl={featuredImage.url} />
      )}
    </div>
  );
};
