import VerifiedFarmIcon from "../../../assets/mapIcons/mapFarmIcon.png";
import VerifiedTeamIcon from "../../../assets/mapIcons/mapTeamIcon.png";
import UnverifiedFarmIcon from "../../../assets/mapIcons/mapFarmIconUnverified.png";
import UnverifiedTeamIcon from "../../../assets/mapIcons/mapTeamIconUnverified.png";
import VerifiedUserIcon from "../../../assets/mapIcons/mapUserIcon.png";
import UnverifiedUserIcon from "../../../assets/mapIcons/mapUserIconUnverified.png";

export const assetsColor = {
  field: { stroke: "#1DC6FC", fill: "transparent", unverified: "#740000" },
  plot: { stroke: "#6EFF3A", fill: "#6EFF3A", unverified: "#FD41FF" },
  farm: { stroke: "", fill: "", unverified: "" },
};

export const assetMarker = {
  farm: {
    verified: VerifiedFarmIcon,
    unverified: UnverifiedFarmIcon,
  },
  user: {
    verified: VerifiedUserIcon,
    unverified: UnverifiedUserIcon,
  },
  team: {
    verified: VerifiedTeamIcon,
    unverified: UnverifiedTeamIcon,
  },
};

export function createMapDiv(
  id?: string,
  text?: string,
  title?: string,
  handler?: (elem: HTMLButtonElement, ev: MouseEvent) => any
) {
  const centerControlDiv = document.createElement("div");
  const controlButton = document.createElement("button");

  if (id) controlButton.id = id;
  if (text) controlButton.innerHTML = text;
  if (title) controlButton.title = title;

  if (handler)
    controlButton.addEventListener("click", (e) => handler(controlButton, e));

  centerControlDiv.appendChild(controlButton);
  return centerControlDiv;
}

export function removeAssets(
  assetList: (google.maps.Marker | google.maps.Polygon)[]
) {
  assetList.forEach((item) => {
    google.maps.event.clearInstanceListeners(item);
    item.setMap(null);
  });
}

export function displayAssetLabel(
  map: google.maps.Map,
  point: google.maps.Point | null,
  assetLabel: HTMLDivElement,
  type: string
) {
  const { offsetHeight, offsetWidth } = map.getDiv();
  let popupTopOffset = type === "farm" ? 200 : 20;
  let posX = Number(point?.x) - assetLabel.clientWidth / 2; // Half width offset to make popup center
  let posY = Number(point?.y) - assetLabel.clientHeight - popupTopOffset;
  assetLabel.style.left = posX + offsetWidth / 2 + "px";
  assetLabel.style.top = posY + offsetHeight / 2 + "px";
  assetLabel.style.display = "block";
}
