import { HiChevronDown, HiX } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { capitalize } from "../../../../lib/utils/stringFormat";
import { theme } from "../../../../style/theme";
import { Group } from "../../../atoms/Group";
import Typography from "../../../atoms/Typography";
import { sidebarTypes } from "../../../templates/TeamMapView/data";
import { GetEntityIcon } from "./GetEntityIcon";
import { SidebarHeadLink } from "./SidebarHeadItem";
import { SidebarIconText } from "./styles";

export function SidebarListHead({ data }: { data: any }) {
  const [params, setParams] = useSearchParams();
  const type = params.get("type") as sidebarTypes;
  const listType = params.get("listType") as sidebarTypes;

  return (
    <Group
      position="apart"
      align="flex-start"
      py={12}
      noWrap
      data-cy="listing-header"
    >
      <Group spacing={8} direction="column">
        <SidebarIconText>
          <GetEntityIcon type={type} size={24} />
          <Typography variant="h5">{capitalize(type)}</Typography>
        </SidebarIconText>
        <SidebarHeadLink
          data={{
            id: params.get("id"),
            name: params.get("name"),
            tag: params.get("tag"),
          }}
          type={listType}
        />
      </Group>
      {params.get("id") ? (
        <HiX
          style={{ flex: "0 0 18px" }}
          className="mt-1 cursor-pointer shrink-0"
          data-cy="asset-sidebar-close"
          size={18}
          color={theme.colors.orange.main}
          onClick={() => setParams({})}
        />
      ) : (
        <HiChevronDown
          style={{ flex: "0 0 18px" }}
          className="mt-1 cursor-pointer shrink-0"
          size={18}
          color={theme.colors.orange.main}
          onClick={() => setParams({ id: data[type].id })}
        />
      )}
    </Group>
  );
}
