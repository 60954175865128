import { useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "../../../lib/contexts/usercontext";
import { sendReportToEmail } from "../../../lib/utils/downloadReports";
import { capitalize } from "../../../lib/utils/stringFormat";
import { Group } from "../../atoms/Group";
import DatePickerInput from "../../atoms/Input/DatePicker";
import { MantineButton } from "../../atoms/MantineButton";
import { Input } from "../../atoms/MantineInput";
import { Modal } from "../../atoms/Modal";

export function DownloadReportModal({
  type,
  open,
  setOpen,
}: {
  type: "user" | "team";
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { state: user } = useUser();
  const [reportProps, setReportProps] = useState({
    email: user.me?.email,
    from: new Date(),
    till: new Date(),
  });
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      isOpen={open}
      handleModal={() => setOpen(false)}
      modalTitle={`Download ${capitalize(type)}s Report`}
      height="auto"
      margin="auto"
      width={"min(60rem, 95vw)"}
    >
      <Input
        label="Email"
        value={reportProps.email}
        onChange={(e) =>
          setReportProps((prev) => ({ ...prev, email: e.target.value }))
        }
      />
      <Group mt={16} className="flex-wrap items-end">
        <DatePickerInput
          placeholder="From Date"
          label="From Date"
          className="flex-1"
          value={reportProps.from}
          onChange={(from) =>
            from && setReportProps((prev) => ({ ...prev, from }))
          }
          clearable={false}
          maxDate={reportProps.till}
          dropdownPosition="top-start"
        />
        <DatePickerInput
          placeholder="To Date"
          label="To Date"
          className="flex-1"
          value={reportProps.till}
          onChange={(till) =>
            till && setReportProps((prev) => ({ ...prev, till }))
          }
          clearable={false}
          maxDate={new Date()}
          minDate={reportProps.from}
          disabled={!reportProps.from}
          dropdownPosition="top-start"
        />
        <MantineButton
          disabled={!reportProps.from || !reportProps.till}
          onClick={() => {
            setLoading(true);
            reportProps.from &&
              reportProps.till &&
              toast
                .promise(sendReportToEmail({ type, ...reportProps }), {
                  success: "Report was sent successfully!",
                  error: "Error occurred. Please try again.",
                })
                .then(() => setOpen(false))
                .finally(() => setLoading(false));
          }}
          loading={loading}
        >
          Download
        </MantineButton>
      </Group>
    </Modal>
  );
}
