import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { config } from "../../config";
dayjs.extend(advancedFormat);

/**
 *
 * @param date
 * @returns Format like "13th Apr, 2022 05:00"
 */
export function fullDateTime(date: string | Date) {
  return dayjs(date).format(config.dateFormats.fullDateTime);
}

/**
 *
 * @param date
 * @returns Format like "13th Apr, 2022"
 */
export function fullDate(date: string | Date) {
  return dayjs(date).format(config.dateFormats.fullDate);
}

/**
 *
 * @param date
 * @returns Format like "05:00 AM"
 */
export function time12Hours(date: string | Date) {
  return dayjs(date).format(config.dateFormats.time12Hours);
}

export function gqlQueryDate(date: string | Date | null) {
  return date ? dayjs(date).format(config.dateFormats.gqlQueryDate) : null;
}

/**
 *
 * @param date
 * @returns Format like "April 12, 2022"
 */
export function getMantineDateFormat(date: string | Date) {
  return dayjs(date).format(config.dateFormats.mantineDate);
}
