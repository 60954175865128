import { SVGProps } from "react";

const UniqueCropIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.55387 17.6851C7.51823 17.6607 7.49453 17.624 7.45888 17.5872C6.88847 16.9014 6.23496 16.2646 5.05862 16.2646C3.29993 16.2646 2.44434 17.6609 2.44434 20H19.5554C19.5554 17.6607 18.7118 16.2646 16.9412 16.2646C15.5628 16.2646 14.8854 17.0974 14.4933 17.5872L14.434 17.6607V13.5087C14.434 12.2472 14.8975 11.2675 16.3709 10.4959C15.6103 11.2919 15.2183 12.0758 15.1351 12.9821C17.8206 12.9577 18.9376 10.9734 19.0802 8.69548C15.6699 8.90377 13.9231 10.1529 13.7329 12.48C12.8537 11.2429 11.1782 10.704 9.0868 10.704C9.14614 12.9819 10.1801 14.9416 13.008 14.9906C13.1269 14.3538 12.7942 13.6556 11.796 12.5779C13.3646 13.1167 13.721 13.7782 13.721 15.2845V17.8198C13.614 17.7708 13.5189 17.6973 13.4358 17.5871C13.0199 17.0849 12.3308 16.2644 10.9761 16.2644C9.59768 16.2644 8.90852 17.0972 8.50456 17.5871C8.43327 17.6729 8.36199 17.7463 8.27877 17.7831V9.85892C8.27877 8.02179 8.75404 7.20112 10.2037 6.53989C9.20558 7.61771 8.87291 8.31586 8.75404 8.95265C11.8197 8.90361 12.8537 6.95626 12.913 4.66602C9.76411 4.66602 7.55388 5.89077 7.55388 8.87916V10.0549C6.73397 8.97705 5.18926 8.37701 2.91962 8.2422C3.06219 10.5202 4.17919 12.5044 6.86473 12.5411C6.76974 11.6226 6.38946 10.8386 5.62888 10.0426C7.1023 10.8142 7.55384 11.7939 7.55384 13.0554L7.55387 17.6851Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M17 2C16.8648 3.10377 16.8201 3.80459 16.5559 4.25705C16.124 4.81937 15.3754 4.87294 14 5.00001C15.353 5.16373 16.0846 5.19919 16.5177 5.71435C16.8414 6.17036 16.8961 6.87587 17 8C17.1764 6.54202 17.2041 5.80574 17.8433 5.38681C18.2979 5.16726 18.9761 5.11933 20 5.00005C18.7305 4.84453 17.9939 4.80875 17.5553 4.4209C17.1846 3.97885 17.145 3.2448 17 2.00003L17 2Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M6.50001 2C6.4324 2.55189 6.41005 2.90229 6.27794 3.12852C6.06198 3.40969 5.68769 3.43647 5 3.50001C5.6765 3.58187 6.04228 3.59959 6.25887 3.85717C6.42069 4.08518 6.44806 4.43793 6.5 5C6.58821 4.27101 6.60204 3.90287 6.92167 3.69341C7.14895 3.58363 7.48807 3.55967 8 3.50003C7.36524 3.42226 6.99693 3.40438 6.77767 3.21045C6.59228 2.98943 6.57251 2.6224 6.5 2.00002L6.50001 2Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default UniqueCropIcon;
