import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { toast } from "react-toastify";
import { MantineButton } from "../../atoms/MantineButton";
import { Modal } from "../../atoms/Modal";
import { ISetupModalData } from "./data";
import { Group } from "../../atoms/Group";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { GET_ALL_ORGANISATIONS } from "./query";
import { Select } from "../../atoms/MantineSelect";
import { Loader } from "../../atoms/Loader/Loader";
import { GET_SETUP_PACKAGES } from "../../templates/AdvisorySetup/query";

export const OrgAccessForm = () => {
  const { setupType, refetch } = useOutletContext<{
    setupType: keyof ISetupModalData;
    refetch: any;
  }>();
  const navigate = useNavigate();
  const [state, setState] = useState({
    organisation_id: "",
    subscription_package: "",
  });
  const params = useParams();

  const { data: orgList, loading: orgListLoading } = useQuery(
    GET_ALL_ORGANISATIONS
  );

  const { data: packageList, loading: packageListLoading } =
    useQuery(GET_SETUP_PACKAGES);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast
      .promise(
        axios({
          url: `${
            process.env.REACT_APP_ONFARM_REST_API_URL
          }/articles/org-access-package/${params.id ? `${params.id}/` : ""}`,
          method: params.id ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
          },
          data: state,
        }),
        {
          pending: (params.id ? "Editing" : "Creating") + " access",
          success: (params.id ? "Edited" : "Created") + " access",
          error: "Couldn't " + (params.id ? "edit" : "create") + " access",
        }
      )
      .then(() => {
        refetch();
        navigate("/advisory/setup/" + setupType);
      });
  };

  const disableSubmit = () => {
    return !(state.organisation_id && state.subscription_package);
  };

  function handleChange(value: Partial<typeof state>) {
    setState((prev: any) => ({ ...prev, ...value }));
  }

  useEffect(() => {
    if (params.id) {
      axios({
        url: `${process.env.REACT_APP_ONFARM_REST_API_URL}/articles/org-access-package/${params.id}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
        },
      }).then(({ data }) => {
        if (!data.data) {
          navigate("/advisory/setup/" + setupType);
        } else {
          const orgAccess = data.data as typeof state;
          setState(() => ({
            organisation_id: orgAccess.organisation_id,
            subscription_package: orgAccess.subscription_package,
          }));
        }
      });
    }
  }, [params.id]);

  return (
    <Modal
      isOpen={true}
      handleModal={(e) => navigate("/advisory/setup/" + setupType)}
      modalTitle={`${params.id ? "Edit" : "Create"} access`}
      width={"min(60rem, 95vw)"}
      height="auto"
    >
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <>
          <Group spacing="lg">
            <Select
              label="Organization"
              listData={orgList?.organisation || []}
              placeholder="Select an organization"
              values={{ id: state.organisation_id }}
              accessor={{ name: "name", value: "id" }}
              className="flex-1"
              onSelect={(item) => handleChange({ organisation_id: item.id })}
              rightSection={orgListLoading ? <Loader size="sm" /> : <></>}
              searchable
              required
            />
            <Select
              label="Packages"
              listData={
                packageList?.packages?.records?.map((item: any) => ({
                  id: item.id,
                  name: item.name,
                })) || []
              }
              rightSection={packageListLoading ? <Loader size="sm" /> : <></>}
              placeholder="Select a package"
              values={{ id: state.subscription_package }}
              className="flex-1"
              accessor={{ name: "name", value: "id" }}
              onSelect={(item) =>
                handleChange({ subscription_package: item.id })
              }
              searchable
              required
            />
          </Group>
        </>
        <MantineButton
          className="mt-8 self-end"
          type="submit"
          disabled={disableSubmit()}
        >
          {params.id ? "Edit" : "Create"}
        </MantineButton>
      </form>
    </Modal>
  );
};
