import { gql } from "@apollo/client";

export const UPDATE_BULK_QC = (
  type: "farm" | "field" | "plot" | "plot_crop"
) => {
  return gql`
    mutation setActiveOrInactive(
      $isActiveTrue: [Int!]
      $isActiveFalse: [Int!]
      $FSQCTrue: [Int!]
      $FSQCFalse: [Int!]
    ) {
      isActiveTrue: update_${type}_many(
        updates: {
          where: { id: { _in: $isActiveTrue } }
          _set: { is_active: true }
        }
      ) {
        affected_rows
      }
      isActiveFalse: update_${type}_many(
        updates: {
          where: { id: { _in: $isActiveFalse } }
          _set: { is_active: false }
        }
      ) {
        affected_rows
      }
      FSQCTrue: update_${type}_many(
        updates: {
          where: { id: { _in: $FSQCTrue } }
          _set: { quality_checked: true }
        }
      ) {
        affected_rows
      }
      FSQCFalse: update_${type}_many(
        updates: {
          where: { id: { _in: $FSQCFalse } }
          _set: { quality_checked: false }
        }
      ) {
        affected_rows
      }
    }
  `;
};
