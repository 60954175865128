import { useEffect, useState } from "react";
import Tabs from "../../components/organisms/Tabs";
import { notificationsListBreadcrumbs, TabsData } from "./data";
import { Outlet, useNavigate } from "react-router";
import PageTitle from "../../components/molecules/PageTitle";
import { MantineButton } from "../../components/atoms/MantineButton";
import { useSearchParams } from "react-router-dom";
import { Group } from "../../components/atoms/Group";
import { ExpandingInput } from "../../components/molecules/FilterDrawer/ExpandingInput";
import { Input } from "../../components/atoms/MantineInput";
import { HiSearch } from "react-icons/hi";
import FilterDrawer from "../../components/molecules/FilterDrawer";
import { filterData, initialData, initialValues } from "./filterData";
import {
  ICategoryData,
  INotificationCategoriesData,
} from "../CreateNotification/type";
import { NOTIFICATION_CATEGORIES } from "../CreateNotification/query";
import { useQuery } from "@apollo/client";
import { categoryData } from "../CreateNotification/data";
import { useDebouncedValue } from "@mantine/hooks";
import { config } from "../../config";
import { RestrictedContent } from "../../components/molecules/ProtectedRoutes/RestrictedContent";

const NotificationsListing = () => {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedList, setSelectedList] = useState<any>({});
  const [selectedListData, setSelectedListData] = useState(initialData);
  const [resultCount, setResultCount] = useState(0);
  const [delayedSearchText] = useDebouncedValue(
    searchText,
    config.debounceWait
  );
  const {
    loading: notificationCategoriesLoading,
    data: notificationCategoriesData,
  } = useQuery<INotificationCategoriesData>(NOTIFICATION_CATEGORIES);

  useEffect(() => {
    handleQueryParams({ search: delayedSearchText });
  }, [delayedSearchText]);

  useEffect(() => {
    if (notificationCategoriesData) {
      setSelectedListData((prev: any) => ({
        ...prev,
        category: {
          ...prev.category,
          listData: categoryData.map((item: ICategoryData) => ({
            ...item,
            codename: notificationCategoriesData.notification_category.find(
              (cat) => cat.name === item.display
            )?.codename,
            value: item.display,
            name: item.display,
          })),
        },
      }));
    }
  }, [notificationCategoriesData]);

  const handleQueryParams = ({
    page = currentPage,
    search = searchText,
  }: {
    page?: number;
    search?: string;
  }) => {
    if (page > 1) searchParams.set("page", page.toString());
    else searchParams.delete("page");
    if (search) searchParams.set("search", search);
    else searchParams.delete("search");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setSearchText(searchParams.get("search") || "");
    setCurrentPage(Number(searchParams.get("page")) || 1);
    document.title = "Notifications - SetuFarm";
  }, [searchParams]);

  return (
    <>
      <PageTitle
        breadCrumbsList={notificationsListBreadcrumbs}
        fixed
        rightSection={
          <RestrictedContent keyName="message_management" permission="create">
            <MantineButton
              className="h-16 px-14"
              onClick={() => navigate("/notifications/create")}
            >
              CREATE
            </MantineButton>
          </RestrictedContent>
        }
      />
      <Group spacing={0} noWrap>
        <ExpandingInput>
          <Input
            data-cy="notification-search-input"
            value={searchText || ""}
            onChange={(e) => setSearchText(e.target.value)}
            rightSection={<HiSearch />}
            className="transition ease-in-out"
            placeholder="Search notifications here..."
            size="md"
          />
        </ExpandingInput>
        <FilterDrawer
          filterData={filterData}
          initialValues={initialValues}
          handleValues={setSelectedList}
          initialData={selectedListData}
          count={resultCount}
        />
      </Group>
      <Tabs tabs={TabsData}>
        <Outlet
          context={{
            filterData: selectedList,
            selectedListData,
            currentPage,
            setResultCount: setResultCount,
            searchText: delayedSearchText,
            setSelectedListData,
            setCurrentPage: (page: number) => handleQueryParams({ page }),
          }}
        />
      </Tabs>
    </>
  );
};

export default NotificationsListing;
