import {
  createStyles,
  Title as MantineTitle,
  TitleOrder,
  TitleProps,
} from "@mantine/core";
const useStyles = createStyles((theme, params: { order?: TitleOrder }) => ({
  root: {
    ...theme.other.fonts[`h${params.order || 1}`],
    fontFamily: "inherit",
    color: theme.other.colors.primary.main,
  },
}));

export const Title = (props: TitleProps) => {
  const { classes } = useStyles({ order: props.order });
  return <MantineTitle className={classes.root} {...props} />;
};
