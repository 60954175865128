import { useQuery } from "@apollo/client";
import { Space } from "../../atoms/Space";
import { useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router";
import { config } from "../../../config";
import { useUser } from "../../../lib/contexts/usercontext";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import { Select } from "../../atoms/MantineSelect";
import Typography from "../../atoms/Typography";
import { Pagination } from "../../atoms/Pagination";
import Table from "../../molecules/Table";
import { TableFooter } from "../../molecules/Table/styles";
import { ISetupData, setupData, setupOrder, setupSortingMap } from "./data";
import { SetupTableContainer } from "./styles";
import { sortingMapper } from "../../../lib/utils/table";
import { SortingRule } from "react-table";

const SetupTabsTableContent = () => {
  let params: any = useParams();
  let setupType: keyof ISetupData =
    params.type in setupData ? params.type : "categories";
  const { search, currentPage, setCurrentPage } = useOutletContext<any>();
  const user = useUser();
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage("setupConfig")?.[setupType]?.itemsPerPage ||
      config.itemsPerPage
  );
  const [sortBy, setSortby] = useState<SortingRule<{}>[]>(
    getLocalStorage("setupConfig")?.[setupType]?.sortBy || setupOrder[setupType]
  );
  const sortingMap = sortingMapper(sortBy, setupSortingMap);

  const { loading, data, error, refetch } = useQuery(
    setupData[setupType].query,
    {
      variables: {
        offset: (currentPage - 1) * itemsPerPage,
        limit: itemsPerPage,
        order: sortingMap ? [sortingMap] : [],
        ...(setupData[setupType]?.where
          ? {
              where: {
                name: { _ilike: `%${search}%` },
                ...setupData[setupType]?.where?.({
                  groupIds: user.state.me?.mutual_groups.map(
                    (item) => item.codename
                  ),
                }),
              },
            }
          : { search }),
      },
    }
  );

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage("setupConfig");
    setLocalStorage("setupConfig", {
      ...localConfig,
      [setupType]: {
        ...localConfig?.[setupType],
        itemsPerPage: Number(item),
      },
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage("setupConfig");
    setLocalStorage("setupConfig", {
      ...localConfig,
      [setupType]: { ...localConfig?.[setupType], sortBy: sorting },
    });
    setSortby(sorting);
  };

  return (
    <SetupTableContainer>
      <Table
        tableColumns={setupData[setupType].columns}
        tableData={!error && data ? setupData[setupType].mapper(data) : []}
        pageSize={itemsPerPage}
        loading={loading}
        getRowProps={(row: any) => ({
          onClick: (e: MouseEvent) =>
            setupData[setupType].rowClick && navigate(`${row.original.id}`),
        })}
        fixedHead
        sortByData={sortBy}
        handleSortBy={handleSorting}
      />
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w={20} />
        <Select
          listData={config.itemsPerPageList.map((item) => String(item))}
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && !error && (
            <Pagination
              page={currentPage}
              onChange={setCurrentPage}
              total={Math.ceil(
                (data?.aggregate?.aggregate?.count || itemsPerPage) /
                  itemsPerPage
              )}
              boundaries={0}
              siblings={config.paginationWindow / 2 - 1}
              withEdges
              position="right"
            />
          )}
        </div>
      </TableFooter>
      <Outlet context={{ setupType, refetch }} />
    </SetupTableContainer>
  );
};
export default SetupTabsTableContent;
