import { useQuery } from "@apollo/client";
import { IconType } from "react-icons";
import LegendDivider from "../../atoms/LegendDivider";
import Typography from "../../atoms/Typography";
import { LegendTitleContainer } from "../../molecules/LegendTitle/style";
import { TrendMetricsCard } from "../TrendMetricsCard";
import { GET_NOTIFICATION_TREND_METRICS } from "./query";
import { INotificationTrendingMetrics } from "./type";
interface IActivitiesSectionProps {
  legendTitle: string;
  legendIcon: IconType;
}
export const NotificationTrendMetrics = ({
  legendTitle,
  legendIcon,
}: IActivitiesSectionProps) => {
  const { data, loading } = useQuery<INotificationTrendingMetrics>(
    GET_NOTIFICATION_TREND_METRICS
  );
  const LegendIcon = legendIcon;
  const LegendTitle = (
    <LegendTitleContainer>
      <LegendIcon />
      <Typography variant="h6">{legendTitle}</Typography>
    </LegendTitleContainer>
  );

  return (
    <div className="mt-14" data-cy="notification-trend-metrics-section">
      <LegendDivider title={LegendTitle} />
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full gap-11">
        <TrendMetricsCard
          label="Notification"
          loading={loading}
          tabsNames={["views", "notification"]}
          defaultValue="views"
          data={{
            notification: data?.notificationMetrics
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e, type: e.category },
                count: e.count,
                type: e.category,
              })),
            views: data?.notificationMetricsSeen
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e, type: e.category },
                count: e.count,
              })),
          }}
        />
        <TrendMetricsCard
          label="Region"
          loading={loading}
          tabsNames={["views", "notification"]}
          defaultValue="views"
          data={{
            notification: data?.notificationRegionMetrics
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
            views: data?.notificationRegionMetricsSeen
              .filter((e) => e.count > 0)
              .map((e) => ({
                item: { ...e },
                count: e.count,
              })),
          }}
        />
      </div>
    </div>
  );
};
