import { useState } from "react";
import { useLayer } from "react-laag";
import LogoImage from "../../../assets/dummyuser.png";
import {
  AppbarContainer,
  ActionsContainer,
  UserContainer,
  UserDetailsContainer,
  UserImage,
  AppBarDropDownItem,
  UserDropdown,
} from "./styles";
import Typography from "../../atoms/Typography";
import { removeUser, useUser } from "../../../lib/contexts/usercontext";
import { HiLogout } from "react-icons/hi";
import { LogoContainer, SidebarWrapper } from "../Sidebar/styles";
import Logo from "../../../assets/logo.svg";
import LogoMin from "../../../assets/logomin.svg";
import Sidebar from "../Sidebar";
import { MdMenu } from "react-icons/md";
import { theme } from "../../../style/theme";
import { useMediaQuery } from "@mantine/hooks";

const Appbar: React.FunctionComponent = () => {
  const { state: user, dispatch } = useUser();
  const photo = user.me?.photo?.url ? user.me?.photo?.url : LogoImage;
  const [open, setOpen] = useState(false);
  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: open,
    placement: "bottom-end",
    triggerOffset: 20,
    onOutsideClick: () => setOpen((prev) => !prev),
  });
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const largeScreen = useMediaQuery("(min-width: 48rem)");

  return (
    <AppbarContainer>
      {!largeScreen && (
        <>
          <MdMenu
            size={30}
            onClick={() => setSidebarOpen((p) => !p)}
            color={theme.colors.primary.main}
            cursor={"pointer"}
            style={{ flexShrink: 0 }}
          />
          <SidebarWrapper isOpen={sidebarOpen}>
            <Sidebar handleClose={setSidebarOpen} />
            <div
              style={{
                flex: 1,
                backgroundColor: theme.colors.background + "50",
              }}
              onClick={() => setSidebarOpen((p) => !p)}
            />
          </SidebarWrapper>
        </>
      )}
      <LogoContainer to="/" title="Home Page">
        <img src={largeScreen ? Logo : LogoMin} alt="logo" />
      </LogoContainer>
      <ActionsContainer></ActionsContainer>
      <UserContainer>
        <UserDetailsContainer>
          <Typography variant="subtitle1">
            {(user.me?.first_name ?? "") + " " + (user.me?.last_name ?? "")}
          </Typography>
        </UserDetailsContainer>
        <UserImage
          src={photo}
          alt="user-image"
          {...triggerProps}
          onClick={() => setOpen((prev) => !prev)}
        />
        {open &&
          renderLayer(
            <UserDropdown {...layerProps} width={150}>
              <AppBarDropDownItem onClick={() => removeUser(dispatch)}>
                <HiLogout size={20} />
                <span>Logout</span>
              </AppBarDropDownItem>
            </UserDropdown>
          )}
      </UserContainer>
    </AppbarContainer>
  );
};

export default Appbar;
