import { MultiSelect } from "../../components/atoms/MultiSelect";

export const filterData = [
  {
    name: "crops",
    component: MultiSelect,
    props: {
      size: "md",
      label: "Crops",
      listData: [],
      searchable: true,
      clearable: true,
      placeholder: "Select crops",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onSelect: (value: any) => func("crops", value),
    }),
  },
  {
    name: "brands",
    component: MultiSelect,
    props: {
      size: "md",
      label: "Brands",
      listData: [],
      searchable: true,
      clearable: true,
      placeholder: "Select brands",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onSelect: (value: any) => func("brands", value),
    }),
  },
  {
    name: "category",
    component: MultiSelect,
    props: {
      label: "Category",
      size: "md",
      listData: [],
      searchable: true,
      clearable: true,
      placeholder: "Select categories",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onSelect: (value: any) => func("category", value),
    }),
  },
  {
    name: "subCategory",
    component: MultiSelect,
    props: {
      size: "md",
      label: "Sub category",
      listData: [],
      searchable: true,
      clearable: true,
      placeholder: "Select sub categories",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onSelect: (value: any) => func("subCategory", value),
    }),
  },
];

export const initialValues = {
  crops: {
    valueprop: "values",
    accessor: {
      name: "name",
      value: "value",
    },
    values: [],
  },
  brands: {
    valueprop: "values",
    accessor: {
      name: "name",
      value: "value",
    },
    values: [],
  },
  category: {
    valueprop: "values",
    accessor: {
      name: "name",
      value: "value",
    },
    dependency: {
      dependent: ["subCategory"],
      mapper: (data: any, value: any) =>
        data.filter((item: any) =>
          value.find((category: any) => item.category === category.value)
        ),
    },
    values: [],
  },
  subCategory: {
    valueprop: "values",
    accessor: {
      name: "name",
      value: "value",
    },
    values: [],
  },
};

export const initialData = {
  localStorageKey: "careFilter",
  crops: {
    dataprop: "listData",
    listData: [],
  },
  brands: {
    dataprop: "listData",
    listData: [],
  },
  category: {
    dataprop: "listData",
    listData: [],
    dependency: {
      dependent: ["subCategory"],
      mapper: (data: any, value: any) =>
        data.filter((item: any) =>
          value.find((category: any) => item.category === category.value)
        ),
    },
  },
  subCategory: {
    dataprop: "listData",
    listData: [],
  },
};
