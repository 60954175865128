import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
*,*::after,*::before{
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
}
html{
 font-size:62.5%;
}
body {
  font-family: 'Open Sans', sans-serif; 
}
button, input, optgroup, select, textarea {
  font-family: 'Open Sans', sans-serif !important; 
  line-height: 1.3 !important;
}

#moveToSelected,[id^=zoomButton],#polygonArea,[id^=toggleAssets],#bulkFSQC {
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  color: rgb(25, 25, 25);
  cursor: pointer;
  font-family: Roboto, Arial, sans-serif;
  font-size: 18px;
  margin-top: 10px;
  margin-right: 10px;
  padding: 0 8px;
  height: 38px;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

#polygonArea{
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: 500;
}

#moveToCenter{
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-2x.png);
	background-size: 320px 32px;
	background-position: 2px 2px;
	background-repeat: no-repeat;

  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
  padding: 18px;
}
.gmnoprint[role=menubar] {
  margin-top: 10px !important;
  & > div > button{
    padding: 10px 14px !important;
  }
}
`;

export default GlobalStyles;
