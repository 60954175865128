import { useOutletContext, useSearchParams } from "react-router-dom";
import Map from "../../atoms/Map";
import useLocation from "../../../lib/hooks/useLocation";
import { DEFAULT_LOCATION } from "../../../lib/utils/constants";
import { useEffect, useRef, useState } from "react";
import { sidebarListingQueries, sidebarQueries, sidebarTypes } from "./data";
import { getLocalStorage } from "../../../lib/utils/localStorage";
import { LogoLoader } from "../../atoms/Loader/LogoLoader";
import { TeamsDetailModal } from "../TeamsDetailModal";
import { DetailTypes } from "../TeamsDetailModal/type";
import { AssetProvider } from "../../../lib/contexts/AssetContext";
import { fetchAssets, normalizeGeolocation } from "./utils";
import { config } from "../../../config";
import { storeEdges } from "../../atoms/Map/utils";
import { normalizeTeamManageFilter } from "./normalize";
import { MapSidebar } from "./MapSidebar";

const TeamMapView = () => {
  const location: any = useLocation();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const map = useRef<google.maps.Map>();
  const { filterData, setAssetCount } = useOutletContext<{
    filterData: Record<string, any>;
    setAssetCount: any;
  }>();
  const [assetList, setAssetList] = useState<{
    farm: any[];
    field: any[];
    plot: any[];
  }>();

  function handleChange(condition: Record<string, any>) {
    if (!map.current) return;
    setLoading(true);
    const edges = storeEdges(map.current, 1);
    let hidePlots = getLocalStorage("mapConfig")?.hidePlots;
    if (!edges) return;
    let mapZoom = Number(map.current.getZoom());
    let filters = normalizeTeamManageFilter(getLocalStorage("teamFilter"));
    let ignoreBounds = Boolean(filters.farmer);

    Promise.all([
      fetchAssets({ edges, type: "farm", filters, ignoreBounds }),
      fetchAssets({
        edges,
        type: "field",
        fetchCount: mapZoom < config.zoomLimit.field,
        filters,
        ignoreBounds,
      }),
      fetchAssets({
        edges,
        type: "plot",
        filters,
        fetchCount: mapZoom < config.zoomLimit.plot || hidePlots,
        ignoreBounds,
      }),
    ]).then((result) => {
      if (condition.ignoreBounds) {
        const bounds = new google.maps.LatLngBounds();
        result?.[0]?.data?.farm?.map((item: any) => {
          let coords = item?.address?.location?.coordinates;
          bounds.extend(new google.maps.LatLng(coords[1], coords[0]));
        });
        map.current?.fitBounds(bounds);
      }
      setAssetList(() => ({
        farm: normalizeGeolocation(result?.[0]?.data?.farm, "farm"),
        field: normalizeGeolocation(result?.[1]?.data?.field, "field"),
        plot: normalizeGeolocation(result?.[2]?.data?.plot, "plot"),
      }));
      let field = result?.[1]?.data?.field;
      let plot = result?.[2]?.data?.plot;
      setAssetCount({
        farms: result?.[0]?.data?.farm?.length || 0,
        fields: field?.aggregate?.count || field?.length || 0,
        plots: plot?.aggregate?.count || plot?.length || 0,
      });
      setLoading(false);
    });
  }

  useEffect(() => {
    const ignoreBounds =
      filterData?.teams?.length ||
      filterData?.talukas?.length ||
      filterData?.villages?.length;
    handleChange({ ignoreBounds });
  }, [filterData]);
  return (
    <AssetProvider>
      {location.status !== "loading" ? (
        <div className="relative flex mt-4 h-[calc(100vh-305px)]">
          {params.get("id") &&
          (sidebarQueries[params.get("type") as sidebarTypes] ||
            sidebarListingQueries[params.get("type") as sidebarTypes]) ? (
            <MapSidebar />
          ) : null}
          <Map
            map={map}
            handleChange={(condition) =>
              handleChange({ ...condition, ignoreBounds: false })
            }
            assetList={assetList}
            name="teamsMap"
            containerStyle="w-full h-[calc(100vh-290px)] rounded-md"
            center={
              location.status === "error"
                ? DEFAULT_LOCATION
                : {
                    lat: location.lat,
                    lng: location.lng,
                  }
            }
            show={{
              locationMarker: true,
              moveToCenter: true,
              moveToSelected: true,
              assets: true,
              assetToggle: true,
              zoomLevel: false,
              bulkFSQC: true,
            }}
            clickHandler={() => {}}
            zoom={16}
            gestureHandling="greedy"
            streetViewControl={false}
            mapTypeId="satellite"
            mapTypeControlOptions={{ position: 3 }}
            fullscreenControl={false}
            minZoom={3}
            loading={loading}
          />
          <TeamsDetailModal
            detail={params.get("detail")}
            detailType={params.get("detailType") as DetailTypes | null}
          />
        </div>
      ) : (
        <LogoLoader width={100} margin="10% auto" />
      )}
    </AssetProvider>
  );
};
export default TeamMapView;
