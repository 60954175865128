import { NotificationState } from "../../../../pages/CreateNotification/type";
import { RadioGroup, Radio } from "../../../atoms/Input/Radio";

export interface Props {
  state: NotificationState;
  handleChangeValue: (
    key: keyof NotificationState,
    value: string | object | undefined
  ) => void;
}

export function CategorySection({ state, handleChangeValue }: Props) {
  return (
    <section data-cy="notification-category-section">
      <RadioGroup
        size="md"
        value={state.selectedCategory.name || ""}
        onChange={(name) =>
          handleChangeValue(
            "selectedCategory",
            state.categories.find((category) => category.name === name)
          )
        }
        spacing={30}
        mt={5}
      >
        {state.categories.map((category, index) => (
          <Radio key={index} value={category.name} label={category.display} />
        ))}
      </RadioGroup>
    </section>
  );
}
