import styled, { css } from "styled-components";
import { ActivityCardContainerStyleProps } from "./type";

export const ActivityCardContainer = styled.div<ActivityCardContainerStyleProps>`
  display: flex;
  justify-content: space-between;
  height: 100%;
  ${({ alignCenter }) => alignCenter && "text-align: center"};

  border-top: ${({ borderTop }) =>
    borderTop
      ? `${borderTop.width}px ${borderTop.style} ${borderTop.color}`
      : "none"};

  ${({ variant }) =>
    variant === "small"
      ? css`
          padding: 2.4rem;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.8rem;
          & > * {
            flex: 1;
          }
        `
      : css`
          flex-direction: column;
          padding: 2.4rem;
        `}
`;

export const ActivityCardHeader = styled.div`
  display: flex;
  height: 66px;
  justify-content: flex-start;
  align-items: center;
`;

export const ActivityCardIconWrapper = styled.span`
  background-color: #ebf4f8;
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-right: 12px;
  & > * {
    width: 24px;
    height: 24px;
    color: ${(props) => props.theme.colors.primary.main};
  }
`;

export const StatusBlock = styled.div<{ success?: boolean; padding?: string }>`
  padding: ${({padding}) => padding || "1.4rem"};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  flex-wrap: wrap;
  span {
    font-size: 1.6rem;
  }
  b {
    font-size: 2rem;
  }

  background-color: ${({ theme, success }) =>
    theme.colors[success ? "positive" : "negative"].light}15;
  color: ${({ theme, success }) =>
    theme.colors[success ? "positive" : "negative"].default};
  border: 1px solid
    ${({ theme, success }) =>
      theme.colors[success ? "positive" : "negative"].default};
`;
