import { Link } from "react-router-dom";
import { theme } from "../../../../style/theme";
import { Group } from "../../../atoms/Group";
import Typography from "../../../atoms/Typography";
import { GetEntityIcon } from "./GetEntityIcon";
import { SidebarIconText } from "./styles";

export function SidebarHeadLink({
  data,
  type,
  size = 20,
}: {
  data: any;
  type: string;
  size?: number;
}) {
  return (
    <SidebarIconText>
      <GetEntityIcon type={type} size={size} color={theme.colors.blue.dark} />
      <Typography variant="body1" textColor={theme.colors.blue.dark}>
        <Link to={{ search: `type=${type}&id=${data?.id || "#"}` }}>
          {data?.tag || ""} {data?.name || "-"}
        </Link>
      </Typography>
    </SidebarIconText>
  );
}
