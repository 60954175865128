import { Group } from "../../atoms/Group";
import { forwardRef } from "react";
import styled from "styled-components";
import Image from "../../atoms/Image";
import Typography from "../../atoms/Typography";

export const Container = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const MediaPreviewContainer = styled.div<{
  width?: string;
  height?: string;
}>`
  border-radius: 1rem;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  overflow: hidden;
`;

export const FeaturedImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 2rem;
`;

export const SelectedImageContainer = styled.div<{ imageUrl: string }>`
  height: 20rem;
  border-radius: 1rem;
  overflow: hidden;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  width: 50%;
  flex: 1;
`;

export const SelectItem = forwardRef<HTMLDivElement, any>(
  ({ label, url, ...others }: any, ref) => (
    <div ref={ref} {...others}>
      <Group className="h-20 py-2" noWrap>
        <Image src={url} className="w-20 max-h-full" />
        <Typography
          variant="body2"
          className="text-ellipsis overflow-hidden max-h-16"
        >
          {label}
        </Typography>
      </Group>
    </div>
  )
);
