import { gql } from "@apollo/client";

interface INotificationConditionProps {
  baseUsers: any[];
  broadcast_datetime: any;
  fromDate?: Date;
  toDate?: Date;
  category?: string;
  title?: string;
}
export const notificationConditions = ({
  baseUsers,
  broadcast_datetime,
  fromDate,
  toDate,
  category,
  title,
}: INotificationConditionProps) => {
  let titleSearch = title ? { _ilike: `%${title}%` } : null;

  return {
    _and: [
      ...(fromDate ? [{ broadcast_datetime: { _gte: fromDate } }] : []),
      ...(toDate ? [{ broadcast_datetime: { _lte: toDate } }] : []),
      {
        broadcast_datetime: {
          ...broadcast_datetime,
        },
      },
    ],
    ...(baseUsers?.length && { broadcaster_id: { _in: baseUsers } }),
    ...(category && { category_id: { _eq: category } }),
    ...(titleSearch && { title: titleSearch }),
  };
};

export const GET_NETWORK_USERS = gql`
  query getUsersFromMyNetwork($orgId: Int) {
    base_user_network(
      where: { network: { company: { organisation_id: { _eq: $orgId } } } }
      distinct_on: base_user_id
    ) {
      base_user {
        reference_id
        full_name
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotifications(
    $order: [notification_template_order_by!]
    $limit: Int
    $offset: Int
    $where: notification_template_bool_exp
  ) {
    notification_template(
      limit: $limit
      offset: $offset
      order_by: $order
      where: $where
    ) {
      id
      title
      description
      shared_broadcaster {
        reference_id
        name
      }
      broadcaster_id
      broadcast_datetime
      feature_image {
        url
      }
      feature_image_url
      notification_category {
        id
        name
      }
      delivered_to: notifications_aggregate {
        aggregate {
          count
        }
      }
      viewed: notifications_aggregate(where: { is_seen: { _eq: true } }) {
        aggregate {
          count
        }
      }
    }
    notification_template_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_NOTIFICATION_DETAIL = gql`
  query getNotificationTemplateDetails($notificationId: uuid!) {
    notification_template_by_pk(id: $notificationId) {
      title
      description
      selected_category: category_id
      notification_category {
        id
        name
      }
      feature_image_id
      feature_image_url
      feature_image {
        id
        url
      }
      body
      broadcast_datetime
      broadcaster: broadcaster_id
      shared_broadcaster {
        name
        reference_id
      }
      regions {
        region {
          regionGroup: region_group {
            name
          }
          name
          id
        }
      }
      networks {
        network {
          codename
          name
        }
      }
      teams {
        team {
          name
          id
        }
      }
      users {
        id: user_id
        user: shared_user {
          name
        }
      }
    }
  }
`;
