import { FC, MouseEvent, useEffect, useState } from "react";
import Table from "../../molecules/Table";
import { Pagination } from "../../atoms/Pagination";
import { useQuery } from "@apollo/client";
import { useOutletContext } from "react-router";
import { TabsContentMap } from "../../../pages/TeamUserTable/data";
import { TeamUserTableContainer } from "../../../pages/TeamUserTable/styles";
import Typography from "../../atoms/Typography";
import { Select } from "../../atoms/MantineSelect";
import { config } from "../../../config";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../lib/utils/localStorage";
import { TableFooter } from "../../molecules/Table/styles";
import { Space } from "../../atoms/Space";
import { teamUserData } from "./data";
import { SortingRule } from "react-table";
import { ITeamUserOutletContext } from "../../../pages/TeamUserTable/types";
import { useUser } from "../../../lib/contexts/usercontext";
import { ALLOW_QC_CHECK_DOMAIN } from "../../../lib/utils/constants";
import { TeamsDetailModal } from "../../templates/TeamsDetailModal";
import { useSearchParams } from "react-router-dom";
import { capitalize } from "../../../lib/utils/stringFormat";
import { manageCondition } from "./query";
import { useRestrictedContent } from "../../molecules/ProtectedRoutes/RestrictedContent";

const TabsTableContent: FC<{ type: "team" | "user" }> = ({ type }) => {
  const { columns } = TabsContentMap[type];
  const [itemsPerPage, setItemsPerPage] = useState(
    getLocalStorage(`${type}ManageTeamConfig`)?.itemsPerPage ||
      config.itemsPerPage
  );
  const { state: user } = useUser();
  const [params, setParams] = useSearchParams();

  const {
    filterData,
    searchText,
    currentPage,
    setCurrentPage,
    setResultCount,
  } = useOutletContext<ITeamUserOutletContext>();

  const { loading, data, error, refetch } = useQuery(teamUserData[type].query, {
    variables: {
      offset: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
      query: searchText,
      ...manageCondition(filterData),
    },
  });

  const isSectionAllowed = useRestrictedContent({
    keyName: "network_management",
    permission: "edit",
  });

  useEffect(() => {
    setResultCount(data?.[type]?.total);
  }, [data]);

  const handleLimit = (item: number) => {
    let localConfig = getLocalStorage(`${type}sManageConfig`);
    setLocalStorage(`${type}sManageConfig`, {
      ...localConfig,
      itemsPerPage: Number(item),
    });
    setItemsPerPage(Number(item));
  };

  const handleSorting = (sorting: SortingRule<{}>[]) => {
    let localConfig = getLocalStorage(`${type}sManageConfig`);
    setLocalStorage(`${type}sManageConfig`, {
      ...localConfig,
      sortBy: sorting,
    });
  };

  useEffect(() => {
    document.title = `Manage ${capitalize(type)} - SetuFarm`;
  }, [type]);

  return (
    <TeamUserTableContainer data-cy="team-user-table">
      <Table
        tableColumns={
          user.me?.email.includes(ALLOW_QC_CHECK_DOMAIN)
            ? columns
            : columns.filter((item) => item.accessor !== "region")
        }
        tableData={!error && data ? data?.[type]?.records : []}
        pageSize={itemsPerPage}
        loading={loading}
        fixedHead
        sortByData={[]}
        getRowProps={
          isSectionAllowed
            ? (row: any) => ({
                onClick: (e: MouseEvent) => {
                  let id =
                    type === "team" ? row.original[type].id : row.original.id;
                  params.set("detail", id);
                  params.set("detailType", type);
                  setParams(params);
                },
              })
            : undefined
        }
        handleSortBy={handleSorting}
      />
      <TableFooter>
        <Typography variant="body2">Entries per page</Typography>
        <Space w={20} />
        <Select
          data-cy="select-entries-per-page"
          listData={config.itemsPerPageList.map((item) => String(item))}
          values={String(itemsPerPage)}
          onSelect={(item) => {
            handleLimit(item);
          }}
          className="w-40"
        />
        <div className="ml-auto">
          {!loading && !error && (
            <Pagination
              page={currentPage}
              onChange={setCurrentPage}
              total={Math.ceil(
                (data?.[type]?.total || itemsPerPage) / itemsPerPage
              )}
              boundaries={0}
              siblings={config.paginationWindow / 2 - 1}
              withEdges
              position="right"
            />
          )}
        </div>
      </TableFooter>
      <TeamsDetailModal
        detail={params.get("detail")}
        detailType={params.get("detailType") as "team" | "user"}
      />
    </TeamUserTableContainer>
  );
};

export default TabsTableContent;
