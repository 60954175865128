import { Group } from "../../atoms/Group";
import { capitalize } from "../../../lib/utils/stringFormat";
import { Chip } from "../../atoms/Chip";
import { Space } from "../../atoms/Space";
import Typography from "../../atoms/Typography";

export const ArticlePreviewChips = ({ chips }: any) => {
  return (
    <div>
      {Object.entries(chips).map(([key, value]: any) => {
        return value.length ? (
          <div key={key} data-cy="article-chip-item">
            <Typography variant="h6">{capitalize(key)}</Typography>
            <Space h={12} />
            <Group spacing={12}>
              {value.map(
                (item: any, i: number) =>
                  item && <Chip fill key={i} chip={item} size="small" />
              )}
            </Group>
            <Space h={24} />
          </div>
        ) : null;
      })}
    </div>
  );
};
