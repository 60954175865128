export const concatParams = (
  values: { [key: string]: any },
  accessor = "value"
) => {
  let queryString = "";
  for (let key in values) {
    if (values[key]) {
      if (Array.isArray(values[key])) {
        if (values[key][0]) {
          queryString += `${queryString ? "&" : "?"}${key}=${values[key].map(
            (e: any) => e[accessor] || e
          )}`;
        }
      } else if (typeof values[key] === "object") {
        queryString += `${queryString ? "&" : "?"}${key}=${
          values[key][accessor]
        }`;
      } else {
        queryString += `${queryString ? "&" : "?"}${key}=${values[key]}`;
      }
    }
  }
  return queryString;
};

export const checkEncodeURI = (str: string) => /\%/i.test(str);
