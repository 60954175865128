import { gqlFetchRequest } from "../../../lib/utils/gqlFetchRequest";
import {
  GET_FARM_COUNT,
  GET_FARM_GEO_LOCATIONS,
  GET_FIELD_COUNT,
  GET_FIELD_GEO_LOCATIONS,
  GET_PLOT_COUNT,
  GET_PLOT_CROP_COUNT,
  GET_PLOT_CROP_GEO_LOCATIONS,
  GET_PLOT_GEO_LOCATIONS,
} from "./query";

export function normalizeGeolocation(data: any, type: string): any[] {
  if (!data || !Array.isArray(data)) return [];
  return data.map((item: any) => ({
    id: item.id,
    name: item.name,
    tag: item.tag,
    type,
    coordinates:
      item.area_catered?.coordinates?.[0].slice(0, -1) ||
      item.address?.location?.coordinates,
    verified: item.verified,
    ...(type === "plot" && {
      crops: item.crops.map((item: any) => ({
        ...item.crop,
        variety: item?.variety?.name || "",
      })),
    }),
    farmer: {
      name: item.farmer?.name,
      team: item.farmer?.memberships?.[0]?.group?.name,
    },
    farm: {
      name: item.farm?.name || item.field?.farm?.name || "",
      tag: item.farm?.tag || item.field?.farm?.tag || "",
    },
    field: {
      name: item.field?.name || "",
      tag: item.field?.tag || "",
    },
    area: {
      value: item.area,
      unit: item.areaUnit?.name,
    },
  }));
}

export async function fetchAssets({
  edges,
  type,
  filters,
  fetchCount,
  ignoreBounds,
}: {
  edges: any;
  type: "farm" | "field" | "plot" | "plot_crop";
  filters?: Record<string, any>;
  fetchCount?: boolean;
  ignoreBounds?: boolean;
}) {
  if (!edges) return;
  let _st_intersects = {
    type: "Polygon",
    coordinates: [
      [
        [edges.rightMax, edges.topMax],
        [edges.rightMax, edges.bottomMax],
        [edges.leftMax, edges.bottomMax],
        [edges.leftMax, edges.topMax],
        [edges.rightMax, edges.topMax],
      ],
    ],
  };

  const query = {
    farm: fetchCount ? GET_FARM_COUNT : GET_FARM_GEO_LOCATIONS,
    field: fetchCount ? GET_FIELD_COUNT : GET_FIELD_GEO_LOCATIONS,
    plot: fetchCount ? GET_PLOT_COUNT : GET_PLOT_GEO_LOCATIONS,
    plot_crop: fetchCount ? GET_PLOT_CROP_COUNT : GET_PLOT_CROP_GEO_LOCATIONS,
  };

  const where = {
    ...(!ignoreBounds
      ? type === "farm"
        ? { address: { location: { _st_intersects } } }
        : type === "plot_crop"
        ? { plot: { area_catered: { _st_intersects } } }
        : { area_catered: { _st_intersects } }
      : {}),
    ...filters,
  };

  return gqlFetchRequest(query[type].loc?.source.body, { where });
}

export async function getAssetById(
  id: string | null,
  type: "farm" | "field" | "plot" | null
) {
  if (!id || !type) return { error: true };
  const query = {
    farm: GET_FARM_GEO_LOCATIONS,
    field: GET_FIELD_GEO_LOCATIONS,
    plot: GET_PLOT_GEO_LOCATIONS,
  };
  const where = {
    id: {
      _eq: id,
    },
  };

  return gqlFetchRequest(query[type]?.loc?.source.body, { where });
}
