import { Cell, SortingRule } from "react-table";
import MultiThumbnailImages from "../../atoms/MultiThumbnailImages";

export const careListingColumns: any = [
  {
    Header: "Media",
    accessor: "sellable_item.product.featured_image.url",
    flex: 0.75,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return (
        <MultiThumbnailImages imagesData={[{ image: cell.value, alt: "" }]} />
      );
    },
  },
  {
    Header: "Product Name",
    accessor: "sellable_item.product.name",
    flex: 1.5,
  },
  {
    Header: "Manufacturer",
    accessor: "sellable_item.product.manufacturing_facility.manufacturer.name",
    flex: 1.25,
    disableSortBy: true,
  },
  {
    Header: "Packaging Type (Featured)",
    accessor: "sellable_item.packaging.name",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Original Price (Featured)",
    accessor: "unit_rate",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return cell.value && Number(cell.value).toFixed(2);
    },
  },
  {
    Header: "Discounted Price (Featured)",
    accessor: "discounted_rate",
    flex: 1.25,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return cell.value && Number(cell.value).toFixed(2);
    },
  },
  {
    Header: "Views",
    accessor: "views.aggregate.count",
    flex: 0.75,
    disableSortBy: true,
  },
  {
    Header: "Purchases",
    accessor: "purchases.aggregate.sum.item_quantity",
    flex: 0.75,
    disableSortBy: true,
  },
];

export const careListingSortingMap: any = {
  "sellable_item.product.name": (value: boolean) => ({
    sellable_item: { product: { name: value } },
  }),
};

export const careListingDefaultSorting: SortingRule<{}>[] = [
  {
    id: "sellable_item.product.name",
    desc: false,
  },
];
