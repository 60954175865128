import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});
const tradeHttpLink = createHttpLink({
  uri: process.env.REACT_APP_SETUTRADE_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  //  if login use anonymous token
  if (_.operationName === "Login")
    return {
      headers: {
        ...headers,
        "content-type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_KITE_ANONYMOUS_TOKEN}`,
      },
    };

  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("farmsetu_token");
  const agentId = localStorage.getItem("agentId");
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
      "X-Hasura-Role": "agent_agronomist",
      "X-Hasura-Language": "en",
      "x-hasura-agent-id": agentId,
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === "setuTrade",
    authLink.concat(tradeHttpLink),
    authLink.concat(httpLink)
  ),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});
