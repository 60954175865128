export const numberFormatter = <T extends string | number = "string">(
  num: number,
  precision: number,
  output: "number" | "string" = "string"
): T => {
  const result = parseFloat(num.toFixed(precision));
  return (output === "string" ? result.toLocaleString() : result) as T;
};

export const validateNumber = (
  num: number | string | null,
  precision = 2
): string | number => {
  return num != null
    ? typeof num === "number"
      ? numberFormatter(num, precision)
      : num
    : "-";
};

export function nFormatter(num: number) {
  return Intl.NumberFormat("en-IN", {
    notation: "compact",
    compactDisplay: "short",
    maximumFractionDigits: 2,
  })
    .format(num)
    .replaceAll("T", "K");
}

export function percentage(parts: number, whole: number, precision = 2) {
  return numberFormatter((parts / whole) * 100, precision);
}
