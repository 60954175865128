import { SVGProps } from "react";

const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9962 2.66797C6.84735 2.66797 2.66663 6.8483 2.66663 12.0051C2.66663 17.1539 6.84696 21.3346 11.9962 21.3346C17.153 21.3346 21.3333 17.1543 21.3333 12.0051C21.3333 6.8483 17.153 2.66797 11.9962 2.66797V2.66797ZM10.6656 5.33686H13.3345L12.8858 16.0047H11.1066L10.6656 5.33686ZM11.9962 16.8943C12.4921 16.8943 12.8858 17.2879 12.8858 17.776C12.8858 18.272 12.4922 18.6656 11.9962 18.6656C11.5081 18.6656 11.1066 18.272 11.1066 17.776C11.1066 17.2879 11.5081 16.8943 11.9962 16.8943Z"
      fill={props.color || "#EAD30A"}
    />
  </svg>
);

export default WarningIcon;
