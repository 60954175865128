import { Cell } from "react-table";
import { fullDateTime } from "../../lib/utils/dateTimeFormat";
import { HiCheck, HiPencil, HiX } from "react-icons/hi";
import { useNavigate } from "react-router";
import { useUser } from "../../lib/contexts/usercontext";
import { Actions } from "../../components/molecules/Table/styles";
import { theme } from "../../style/theme";
import { GET_SETUP_SERIES } from "./query";
import { ISetupType } from "./type";
import { RestrictedContent } from "../../components/molecules/ProtectedRoutes/RestrictedContent";

const actionsColumn = ({ userCheck }: { userCheck: boolean }) => ({
  Header: "Actions",
  accessor: "",
  flex: 1,
  disableSortBy: true,
  Cell: (cell: Cell<any>) => {
    const navigate = useNavigate();
    const user = useUser();
    const canEdit = userCheck
      ? cell.row.original.group_id ===
        user.state.me?.mutual_groups?.[0]?.codename
      : true;

    return canEdit ? (
      <RestrictedContent keyName="school_management" permission="edit">
        <Actions data-cy="course-row-actions">
          <HiPencil
            onClick={(e) => {
              e.stopPropagation();
              navigate("edit/" + cell.row.original.id);
            }}
          />
        </Actions>
      </RestrictedContent>
    ) : null;
  },
});

export const coursesSortingMap: any = {
  mutual_group: "group_id",
  article_subscription_package: { value: "name" },
};

const booleanColumn = (header: string, accessor: string) => ({
  Header: header,
  accessor: accessor,
  flex: 0.5,
  disableSortBy: true,
  Cell: (cell: Cell) => {
    return (
      <div className="ml-4">
        {cell.value ? (
          <HiCheck color={theme.colors.positive.default} />
        ) : (
          <HiX color={theme.colors.negative.default} />
        )}
      </div>
    );
  },
});

const seriesColumns = [
  {
    Header: "Name",
    accessor: "name",
    flex: 2,
  },
  {
    Header: "Package",
    accessor: "subscription_package",
    flex: 2,
    Cell: (cell: Cell) => {
      return <>{cell.value.name || ""}</>;
    },
  },
  {
    Header: "Order",
    accessor: "order",
    flex: 1,
  },
  {
    Header: "Created at",
    accessor: "created_at",
    flex: 2,
    Cell: (cell: Cell) => {
      return fullDateTime(cell.value);
    },
  },
  {
    Header: "Articles",
    accessor: "articles",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Views",
    accessor: "views",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Likes",
    accessor: "likes",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Bookmarks",
    accessor: "favorites",
    flex: 1,
    disableSortBy: true,
  },
  booleanColumn("Active", "is_active"),
  actionsColumn({ userCheck: false }),
];

export const courseOrder = [
  {
    id: "name",
    desc: false,
  },
  {
    id: "order",
    desc: false,
  },
];

export const coursesData: ISetupType = {
  columns: seriesColumns,
  query: GET_SETUP_SERIES,
  mapper: (data) => data,
  where: (data) => ({}),
  rowClick: true,
};

export const schoolCoursesBreadcrumbs = {
  courses: "Courses",
  school: "School",
};
