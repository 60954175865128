import { isYoutubeUrl } from "../../../../lib/utils/mediaUrlChecks";
import { VideoPlayer } from "../../../atoms/VideoPlayer";
import { YouTubeEmbed } from "../../../atoms/YouTubeEmbed";
import { MediaPreviewContainer } from "../styles";

export const VideoPreview = ({
  attachment,
  width,
  height,
  onError,
}: {
  attachment: { url: string };
  width?: string;
  height?: string;
  onError?: any;
}) => {
  return (
    <MediaPreviewContainer width={width} height={height}>
      {isYoutubeUrl(attachment?.url) ? (
        <YouTubeEmbed url={attachment?.url} className="w-full h-full" />
      ) : attachment?.url ? (
        <VideoPlayer
          url={attachment?.url}
          className="w-full h-full"
          onError={onError}
        />
      ) : null}
    </MediaPreviewContainer>
  );
};
