import { gql } from "@apollo/client";

export const GET_MANAGE_FILTERS = gql`
  query ManageFilters($orgId: Int) {
    networks: network(
      where: { company: { organisation_id: { _eq: $orgId } } }
    ) {
      id
      name
    }
  }
`;

export const GET_NETWORK_TEAMS = gql`
  query NetworkTeams($limit: Int, $offset: Int, $search: String) {
    teams: mutual_group_network(
      limit: $limit
      offset: $offset
      where: { mutual_group: { name: { _ilike: $search } } }
    ) {
      team: mutual_group {
        id
        name
      }
      network {
        id
      }
    }
  }
`;
