import { ITabsTableContent } from "./types";
import { Cell } from "react-table";
import { HiShieldCheck } from "react-icons/hi";
import { theme } from "../../style/theme";
import { validateNumber } from "../../lib/utils/numberFormat";
import { fullDateTime } from "../../lib/utils/dateTimeFormat";
import { FaEnvelope } from "react-icons/fa";
import { Actions } from "../../components/molecules/Table/styles";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "@mantine/core";

export const Columns = [
  {
    Header: "Team Name",
    accessor: "teamName",
    flex: 2,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as { team: { name: string } };
      return <>{record.team.name}</>;
    },
  },
  {
    Header: "Region",
    accessor: "region",
    flex: 1.5,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as {
        team: { region: { name: string } };
      };
      return <>{record?.team?.region?.name}</>;
    },
  },
  {
    Header: "Address",
    accessor: "address",
    flex: 1.5,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as {
        team: { address: { village: string } };
      };
      return <>{record?.team?.address?.village}</>;
    },
  },
  {
    Header: "Main Farmer",
    accessor: "user",
    flex: 1.5,
    disableSortBy: true,
  },
  {
    Header: "No of Members",
    accessor: "userCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of Farms",
    accessor: "farmCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of Fields",
    accessor: "fieldCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of Plots",
    accessor: "plotCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of PlotCrops",
    accessor: "plotCropCount",
    flex: 1,
    disableSortBy: true,
  },
];

export const UserColumns = [
  {
    Header: "",
    accessor: "isVerified",
    flex: 0.5,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as {
        isVerified: boolean;
        fullName: string;
      };
      return record.isVerified ? (
        <HiShieldCheck
          color={theme.colors.positive.default}
          size={24}
          className="m-auto"
        />
      ) : (
        <></>
      );
    },
  },
  {
    Header: "Farmer",
    accessor: "fullName",
    flex: 2,
    disableSortBy: true,
  },
  {
    Header: "Contact",
    accessor: "contact",
    flex: 1.5,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as {
        contact: { primaryNumber: string; secondaryNumber: string };
      };

      return (
        <div>
          <div>{record?.contact?.primaryNumber}</div>
          <div>{record?.contact?.secondaryNumber}</div>
        </div>
      );
    },
  },
  {
    Header: "Address",
    accessor: "address",
    flex: 1.5,
    disableSortBy: true,
  },
  {
    Header: "Team Name",
    accessor: "teamName",
    flex: 1.5,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as { team: { name: string }[] };

      return <span>{record?.team?.[0]?.name}</span>;
    },
  },
  {
    Header: "Region",
    accessor: "region",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as { team: { region: string }[] };

      return <span>{record?.team?.[0]?.region}</span>;
    },
  },
  {
    Header: "No of Farms",
    accessor: "farmCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of Fields",
    accessor: "fieldCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of Plots",
    accessor: "plotCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "No of PlotCrops",
    accessor: "plotCropCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Field Acreage",
    accessor: "fieldAcreage",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as { fieldAcreage: number };
      return <span>{validateNumber(record.fieldAcreage, 2)}</span>;
    },
  },
  {
    Header: "Plot Acreage",
    accessor: "plotAcreage",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const record = cell.row.original as { plotAcreage: number };
      return <span>{validateNumber(record.plotAcreage, 2)}</span>;
    },
  },
  {
    Header: "No of Docs",
    accessor: "docsCount",
    flex: 1,
    disableSortBy: true,
  },
  {
    Header: "Last active on mobile",
    accessor: "last_active_on_mobile",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      let dateTime = (cell.row.original as any).last_active_on_mobile;
      return dateTime ? fullDateTime(dateTime) : "";
    },
  },
  {
    Header: "Last active on web",
    accessor: "last_active_on_web",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      let dateTime = (cell.row.original as any).last_active_on_web;
      return dateTime ? fullDateTime(dateTime) : "";
    },
  },
  {
    Header: "Actions",
    accessor: "id",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      const [searchParams, setSearchParams] = useSearchParams();
      const rowData = cell.row.original as any;

      return (
        <>
          {!rowData.last_active_on_mobile && (
            <Tooltip
              label="Invite User"
              color={theme.colors.primary.main}
              position="left-start"
            >
              <Actions>
                <FaEnvelope
                  onClick={(e) => {
                    e.stopPropagation();
                    searchParams.set("inviteUser", rowData?.id);
                    setSearchParams(searchParams);
                  }}
                />
              </Actions>
            </Tooltip>
          )}
        </>
      );
    },
  },
];

export const TabsData = [
  {
    id: "team",
    head: "Teams",
  },
  {
    id: "user",
    head: "Users",
  },
];

export const teamsUserTableBreadcrumbs = {
  teams: "Teams",
  network: "Network",
  users: "Users",
};

export const TabsContentMap: ITabsTableContent = {
  team: {
    columns: Columns,
  },
  user: {
    columns: UserColumns,
  },
};
