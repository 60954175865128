import { Tuple } from "@mantine/core";

export type SystemColors =
  | "primary"
  | "orange"
  | "cyan"
  | "warning"
  | "positive"
  | "negative"
  | "blue"
  | "shadow"
  | "background"
  | "border"
  | "white"
  | "disabled";

export interface base10Colors {
  ["100"]: string;
  ["200"]: string;
  ["300"]: string;
  ["400"]: string;
  ["500"]: string;
  ["600"]: string;
  ["700"]: string;
  ["800"]: string;
  ["900"]: string;
  main: string;
}

export interface base7Colors {
  ["100"]: string;
  ["200"]: string;
  ["300"]: string;
  ["400"]: string;
  ["500"]: string;
  ["600"]: string;
  main: string;
}

export interface base3Colors {
  default: string;
  light: string;
  dark: string;
  dark2?: string;
}

export const colors = {
  primary: {
    main: "#005777",
    100: "#0057771A",
    200: "#00577733",
    300: "#0057774D",
    400: "#00577766",
    500: "#00577780",
    600: "#00577799",
    700: "#005777B2",
    800: "#005777CC",
    900: "#005777E5",
  },
  orange: {
    main: "#F6821F",
    100: "#F6821F0D",
    200: "#F6821F1A",
    300: "#F6821F26",
    400: "#F6821F66",
    500: "#F6821F99",
    600: "#F6821FCC",
  },
  cyan: {
    main: "#008D97",
    100: "#008D971A",
    200: "#008D9733",
    300: "#008D9766",
    400: "#008D9780",
    500: "#008D9799",
    600: "#008D97CC",
  },
  warning: {
    default: "#FEE81E",
    light: "#FFEC42",
    dark: "#EAD30A",
  },
  positive: {
    default: "#2AC769",
    light: "#40DD7F",
    dark: "#1AB759",
  },
  negative: {
    default: "#FF1515",
    dark: "#BA0000",
    light: "#FF4A4A",
  },
  blue: {
    default: "#3CB5FF",
    dark: "#0093ED",
    light: "#5AC1FF",
  },
  shadow: {
    default: "#0000001A",
    dark: "#00000026",
    light: "#0000000D",
  },
  background: "#FAFAFA",
  border: "#DBE2EA",
  white: "#FFFFFF",
  disabled: {
    color: "#00577766",
    backgroundColor: "#EBF4F8",
  },
};

export const primaryList: Tuple<string, 10> = [
  "#005777",
  "#0057771A",
  "#00577733",
  "#0057774D",
  "#00577766",
  "#00577780",
  "#00577799",
  "#005777B2",
  "#005777CC",
  "#005777E5",
];
