import { gql } from "@apollo/client";

export const GET_COURSE = gql`
  query GetSeries($id: String!) {
    series: series_details(id: $id) {
      id
      name
      isActive: is_active
      isPublic: is_public
      order
      courseSummary: course_summary
      package {
        id
        name
      }
      categories: category_list {
        id
        name
      }
      authors: author_list {
        seriesAuthorId: id
        title
        reference_id
        name
      }
      crops: crop_list {
        id
        name
        crop_id: reference_id
      }
      featuredImage: featured_image {
        id
        name
        url
        media_type
      }
      featured_video {
        id
        url
        name
        media_type
      }
      currency
      areLecturesLocked: are_lectures_locked
      noOfLecturesUnlocked: no_of_lectures_unlocked
      unitPrice: unit_price
      discountPrice: discounted_price
      taxedUnitPrice: taxed_unit_price
      taxedDiscountPrice: taxed_discounted_price
    }
  }
`;

export const GET_SERIES_CREATE_DATA = gql`
  query GetSeriesCreateData {
    packages: article_subscription_package(
      where: { is_active: { _eq: true } }
    ) {
      id
      name
    }
    categories: article_category(
      where: { is_course_category: { _eq: true }, is_active: { _eq: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    authors: advice_authors(
      where: { is_school_mentor: { _eq: true }, is_active: { _eq: true } }
      order_by: { shared_author: { name: asc } }
    ) {
      seriesAuthorId: id
      title
      shared_author {
        sharedAuthorId: id
        authorId: reference_id
        name
      }
    }
    crops: shared_entity(
      where: { group_name: { _eq: "kite.crop" } }
      order_by: { name: asc }
    ) {
      shared_crop_id: id
      crop_id: reference_id
      name
    }
    featuredImages: fileupload(
      where: { media_type: { _eq: "image" } }
      order_by: { name: asc }
    ) {
      id
      name
      url
      media_type
    }
  }
`;

export const UPSERT_COURSE = gql`
  mutation UpsertCourse(
    $id: String
    $name: String!
    $packageId: String!
    $order: Int
    $featuredImageId: Int
    $categories: [String]!
    $authors: [String]!
    $crops: [String]
    $courseSummary: String
    $taxedDiscountPrice: Float
    $discountPrice: Float
    $taxedUnitPrice: Float
    $unitPrice: Float
    $isPublic: Boolean
    $areLecturesLocked: Boolean
    $noOfLecturesUnlocked: Int
    $orgId: Int
  ) {
    upsert_series(
      id: $id
      name: $name
      package_id: $packageId
      order: $order
      featured_image_id: $featuredImageId
      category_ids: $categories
      author_ids: $authors
      crop_ids: $crops
      course_summary: $courseSummary
      discount_price: $discountPrice
      taxed_discounted_price: $taxedDiscountPrice
      unit_price: $unitPrice
      taxed_unit_price: $taxedUnitPrice
      organisation_id: $orgId
      is_public: $isPublic
      are_lectures_locked: $areLecturesLocked
      no_of_lectures_unlocked: $noOfLecturesUnlocked
    ) {
      series {
        id
        name
      }
    }
  }
`;

export const LINK_COURSE_ARTICLE = gql`
  mutation UpsertLectureInCourse(
    $id: String
    $articleId: String!
    $seriesId: String!
    $order: Int
    $nextId: String
  ) {
    upsert_article_series(
      id: $id
      article_id: $articleId
      series_id: $seriesId
      order: $order
      next_lecture_id: $nextId
    ) {
      article_series {
        id
        article_id
        next_id
        series_id
      }
    }
  }
`;

export const COURSE_DETAILS = gql`
  query CourseDetails($id: String!) {
    course: series_lecture_list(id: $id) {
      id
      name
      subscription_count
      package {
        id
        name
      }
      articles {
        series_article_id
        order
        article_id
        title
        views
        likes
        favorites
        next_id
        next_title
        assessment_id
        assessment_name
      }
      package_articles {
        id
        title
      }
    }
  }
`;
