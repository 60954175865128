import { useEffect, useState } from "react";
import { Group } from "../../atoms/Group";
import { MantineButton as Button } from "../../atoms/MantineButton";
import { Drawer } from "../../atoms/Drawer";
import { ComponentList } from "../../atoms/ComponentList";
import FilterIcon from "../../atoms/Icons/FilterIcon";
import { FilterChips } from "./FilterChips";
import { SimpleGrid } from "../../atoms/SimpleGrid";
import { useLocalStorage } from "@mantine/hooks";
import dayjs from "dayjs";

interface IProps {
  filterData: any;
  initialValues: any;
  initialData: any;
  handleValues: React.Dispatch<React.SetStateAction<any>>;
  count?: number;
}

export default function FilterDrawer({
  filterData,
  initialValues,
  initialData,
  handleValues,
  count,
}: IProps) {
  const [values, setValues] = useState(initialValues);
  const [data, setData] = useState(initialData);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getFilterValues = (values: Record<string, any>) => {
    let result: any = {};
    Object.entries(values).forEach(([key, item]: any) => {
      if (item[item.valueprop]) result[key] = item[item.valueprop];
    });
    return result;
  };

  const [localStorageFilters, setLocalStorageFilters] = useLocalStorage({
    key: initialData.localStorageKey,
    defaultValue: {},
  });

  const dependentDropdowns = (
    dependency: any,
    prevState: any,
    propName: string,
    value: any
  ) => {
    let result: any = {};
    dependency.dependent.forEach((name: string) => {
      let valueProp = prevState[name][propName];
      let list = prevState[name].original || prevState[name][valueProp];
      let initialVal = initialValues[name][valueProp];
      let val = prevState[name].original || initialVal || [];
      result[name] = {
        ...prevState[name],
        [valueProp]:
          value && value.toString() ? dependency.mapper(list, value) : val,
      };
    });
    return result;
  };

  function handleValue(type: string, value: any, search?: boolean) {
    if (!search) {
      setValues((prev: any) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [prev[type].valueprop]: value,
        },
        ...(prev[type]?.dependency &&
          dependentDropdowns(prev[type]?.dependency, prev, "valueprop", value)),
      }));
      setData((prev: any) => ({
        ...prev,
        ...(prev[type]?.dependency &&
          dependentDropdowns(prev[type]?.dependency, prev, "dataprop", value)),
      }));
    } else {
      setData((prev: any) => ({
        ...prev,
        [type]: {
          ...prev[type],
          [prev[type].dataprop]: value,
        },
      }));
    }
  }

  const resetValues = () =>
    setLocalStorageFilters(() => getFilterValues(initialValues));

  const applyValues = () => {
    setLocalStorageFilters(() => getFilterValues(values));
    setIsOpen((open) => !open);
  };

  const parseValue = (value: any) => {
    let date = dayjs(value).isValid() && dayjs(value).toISOString();
    if (date && date === value) return new Date(value);
    return value;
  };

  const setFromLocalStorage = (localFilter: any) => {
    if (!Object.keys(localFilter).length) return initialValues;
    let result: any = { ...values };
    Object.entries(values).forEach(([key, item]: any) => {
      let value = parseValue(localFilter[key] || initialValues[item.valueprop]);
      result[key] = { ...item, [item.valueprop]: value };
      if (item.dependency) handleValue(key, value);
    });
    return result;
  };

  useEffect(() => {
    let result = setFromLocalStorage(localStorageFilters);
    setValues(() => result);
    handleValues(localStorageFilters);
  }, [localStorageFilters]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  return (
    <>
      <Button
        data-cy="filter-drawer-button"
        variant="outline"
        className="px-3 h-16 bg-white border-border"
        onClick={() => setIsOpen((open) => !open)}
      >
        <FilterIcon className=" w-10 h-10" />
      </Button>
      <FilterChips
        count={count}
        values={values}
        chipList={localStorageFilters}
        handleValues={setLocalStorageFilters}
        handleClear={resetValues}
      />
      <Drawer
        position="right"
        opened={isOpen}
        onClose={() => setIsOpen((open) => !open)}
        title="Set Filters"
        size={360}
      >
        <Group direction="column" grow style={{ height: "calc(100vh - 50px)" }}>
          <div className="flex-1 px-6 py-6 overflow-y-auto">
            <SimpleGrid
              spacing={30}
              cols={2}
              alignItems="end"
              data-cy="filter-items"
            >
              <ComponentList
                components={filterData}
                data={data}
                values={values}
                handler={handleValue}
              />
            </SimpleGrid>
          </div>
          <Group position="apart" p={10} style={{ flex: "none" }}>
            <Button size="md" onClick={resetValues} variant="outline">
              CLEAR
            </Button>
            <Button size="md" onClick={applyValues}>
              APPLY
            </Button>
          </Group>
        </Group>
      </Drawer>
    </>
  );
}
