import { gql } from "@apollo/client";

export const GET_SCHOOL_TREND_METRICS = gql`
  query SeriesQueries {
    categoryLikes: trending_metrics_series_category_likes {
      name
      count
    }
    categoryViews: trending_metrics_series_category_views {
      name
      count
    }
    categoryFavorites: trending_metrics_series_category_favorites {
      name
      count
    }
    authorLikes: trending_metrics_series_author_likes {
      name
      count
    }
    authorViews: trending_metrics_series_author_views {
      name
      count
    }
    authorFavorites: trending_metrics_series_author_favorites {
      name
      count
    }
    courseLikes: trending_metrics_series_likes {
      name
      count
    }
    courseViews: trending_metrics_series_views {
      name
      count
    }
    courseFavorites: trending_metrics_series_favorites {
      name
      count
    }
  }
`;

export const GET_SCHOOL_USER_METRICS = gql`
  query SchoolUserMetrics {
    usersLikes: trending_metrics_series_user_likes {
      name
      count
    }
    usersFavorites: trending_metrics_series_user_favorites {
      name
      count
    }
    usersViews: trending_metrics_series_user_views {
      name
      count
    }
  }
`;
