import { useContext, useEffect, useRef, useState } from "react";
import { assetsColor, createMapDiv, removeAssets } from "./addToMap";
import { MapWrapperProps, MapPopupData } from "./type";
import { getAssetById } from "../../templates/TeamMapView/utils";
import mapFarmIcon from "../../../assets/mapIcons/mapFarmIcon.png";
import mapFarmIconUnverified from "../../../assets/mapIcons/mapFarmIconUnverified.png";

import { MapOverlay } from "./styles";
import { setLocalStorage } from "../../../lib/utils/localStorage";
import { LogoLoader } from "../Loader/LogoLoader";
import { useSearchParams } from "react-router-dom";

import currentLocationIcon from "../../../assets/mapIcons/currentLocation.png";
import { AssetContext } from "../../../lib/contexts/AssetContext";
import { config } from "../../../config";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { MapPopup } from "./MapPopup";
import { Modal } from "../Modal";
import { BulkAudit } from "../../organisms/TeamsMapSections/BulkAudit";
import { storeEdges } from "./utils";
import { makeMarker } from "./makeMarker";
import { makePolygon } from "./makePolygon";
import {
  RestrictedContent,
  useRestrictedContent,
} from "../../molecules/ProtectedRoutes/RestrictedContent";
import { MapTools } from "./MapTools";

export default function MapWrapper({
  map = { current: undefined },
  name,
  handleChange,
  assetList,
  children,
  polygon,
  containerStyle,
  show,
  icon,
  clickHandler,
  loading,
  ...mapOptions
}: MapWrapperProps) {
  const ref = useRef<HTMLDivElement>(null);
  const assetLabel = useRef<HTMLDivElement>(null);
  const [popupData, setPopupData] = useState<MapPopupData | null>(null);
  const [params, setParams] = useSearchParams();
  const { asset, setAsset } = useContext(AssetContext);
  const assetId = `${
    params.get("detail") || params.get("expandedId") || params.get("id")
  }`;
  const assetType = `${params.get("detailType") || params.get("type")}`;

  const [bulkAudit, setBulkAudit] = useState(false);
  const isEditAllowed = useRestrictedContent({
    keyName: "farm_management",
    permission: "edit",
  });

  useEffect(() => {
    if (!ref.current) return;
    map.current = new google.maps.Map(ref.current, mapOptions);

    if (show?.moveToCenter) {
      map.current?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        createMapDiv("moveToCenter", "", "Move to current location", () => {
          if (mapOptions.center) map.current?.setCenter(mapOptions.center);
        })
      );
    }

    if (show?.locationMarker) {
      new google.maps.Marker({
        position: mapOptions.center,
        map: map.current,
        icon: icon?.locationMarker || currentLocationIcon,
      });
    }

    return () => {
      map.current && google.maps.event.clearInstanceListeners(map.current);
    };
  }, []);

  // Handle Map zoom_changed and idle events based
  useEffect(() => {
    if (!map.current) return;
    let zoomButton: HTMLDivElement;
    if (show?.zoomLevel && !document.getElementById(`zoomButton_${name}`)) {
      const zoom = map.current?.getZoom();
      zoomButton = createMapDiv(`zoomButton_${name}`, `${zoom}`, "Zoom level");
      map.current?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
        zoomButton
      );
    }

    if (asset?.edit) {
      google.maps.event.clearListeners(map.current, "idle");
      map.current.addListener("zoom_changed", () => {
        let zoomText = zoomButton?.querySelector("button");
        if (zoomText) zoomText.innerHTML = `${map.current?.getZoom()}`;
      });
    } else {
      let idleTimeout: ReturnType<typeof setTimeout>;
      map.current?.addListener("idle", function () {
        if (assetLabel.current) assetLabel.current.style.display = "none";
        if (idleTimeout) clearTimeout(idleTimeout);
        idleTimeout = setTimeout(
          () => handleChange && handleChange({}),
          config.debounceWait
        );
      });

      let zoomTimeout: ReturnType<typeof setTimeout>;
      map.current?.addListener("zoom_changed", function () {
        let zoomText = zoomButton?.querySelector("button");
        if (zoomText) zoomText.innerHTML = `${map.current?.getZoom()}`;

        if (zoomTimeout) clearTimeout(zoomTimeout);
        zoomTimeout = setTimeout(
          () => name && setLocalStorage(`${name}_bounds`, {}),
          config.debounceWait
        );
      });
      name && setLocalStorage(`${name}_bounds`, {});
      google.maps.event.trigger(map.current, "idle");
    }
    return () => {
      const rightBottom =
        map.current?.controls[google.maps.ControlPosition.RIGHT_BOTTOM];
      if (rightBottom && rightBottom.getLength() > 1) rightBottom.removeAt(1);
    };
  }, [asset?.edit]);

  function moveToSelected() {
    if (assetType === "null" || assetId === "null") return;
    getAssetById(assetId, assetType as any).then((result) => {
      setAsset(() => ({}));
      let item = result?.data?.[assetType]?.[0];
      if (!item) return;
      if (assetType === "farm") {
        let coords = item.address.location.coordinates;
        map.current?.setCenter({ lat: coords[1], lng: coords[0] });
        map.current?.setZoom(17);
      }
      if (["field", "plot"].includes(assetType)) {
        const bounds = new google.maps.LatLngBounds();
        item.area_catered.coordinates[0].map(([lng, lat]: any) => {
          bounds.extend(new google.maps.LatLng(lat, lng));
        });
        map.current?.fitBounds(bounds);
      }
    });
  }

  useEffect(() => {
    moveToSelected();
  }, [assetType, assetId]);

  // Rendering and editing markers
  useEffect(() => {
    if (!map.current) return;
    let markerCluster = new MarkerClusterer({ map: map.current });
    let mapOverlay = new google.maps.OverlayView();
    mapOverlay.draw = function () {};
    mapOverlay.setMap(map.current);

    let list = assetList?.farm?.map((item) => {
      return makeMarker({
        label: item?.label || null,
        map: map.current,
        position: { lat: item.coordinates[1], lng: item.coordinates[0] },
        icon: item.verified ? mapFarmIcon : mapFarmIconUnverified,
        data: item,
        setAsset: setAsset,
        clickable: true,
        draggable:
          asset?.edit && asset.id === item.id && asset.type === item.type,
        onClick: item.disableClick
          ? undefined
          : (data) => {
              setParams({ id: data.id, type: data.type });
            },
        overlay: mapOverlay.getProjection(),
        assetLabel: assetLabel.current,
        setPopupData,
        zIndex: 30,
      });
    });
    if (list) markerCluster.addMarkers(list);

    return () => {
      map.current && google.maps.event.clearListeners(map.current, "click");
      markerCluster.clearMarkers();
      markerCluster.setMap(null);
    };
  }, [assetList?.farm, asset?.edit]);

  // Rendering and editing field and plot polygons
  useEffect(() => {
    if (!map.current) return;
    let fieldList: Record<string, google.maps.Polygon> = {};
    let plotList: Record<string, google.maps.Polygon> = {};

    let mapOverlay = new google.maps.OverlayView();
    mapOverlay.draw = function () {};
    mapOverlay.setMap(map.current);

    assetList?.field.concat(assetList?.plot).map((item) => {
      makePolygon({
        map: map.current,
        data: item,
        editable:
          asset?.edit && asset.id === item.id && assetType === item.type,
        setAsset: setAsset,
        clickable: true,
        container:
          item.type === "plot" && asset?.containerId
            ? fieldList[asset?.containerId]
            : undefined,
        polygonList: item.type === "field" ? fieldList : plotList,
        paths:
          asset?.id === item.id &&
          ["field", "plot"].includes(`${asset?.type}`) &&
          asset?.coords &&
          asset.edit
            ? asset?.coords?.[0]
                ?.slice(1)
                .map(([lng, lat]: any[]) => ({ lat, lng }))
            : item.coordinates.map(([lng, lat]: any[]) => ({ lat, lng })),
        strokeWeight: item.type === "field" ? 5 : 1,
        strokeColor:
          assetsColor[item.type as keyof typeof assetsColor][
            item.verified ? "stroke" : "unverified"
          ],
        fillOpacity: 0.6,
        fillColor:
          item.crops?.[0]?.color ||
          assetsColor[item.type as keyof typeof assetsColor].fill,
        zIndex: item.type === "field" ? 10 : 20,
        onClick: (data) => {
          setParams({ type: data.type, id: data.id });
        },
        expand:
          asset?.handlePlotExpand &&
          asset.id === item.id &&
          assetType === "plot",
        overlay: mapOverlay.getProjection(),
        assetLabel: assetLabel.current,
        setPopupData,
      });
    });

    return () => {
      const topCenter =
        map.current?.controls[google.maps.ControlPosition.TOP_CENTER];
      if (topCenter && topCenter.getLength() > 0) topCenter.removeAt(0);

      removeAssets(Object.values(fieldList));
      removeAssets(Object.values(plotList));
    };
  }, [assetList?.field, assetList?.plot, asset?.edit, asset?.handlePlotExpand]);

  return (
    <div className="relative flex-1">
      <div ref={ref} id="map" className={`${containerStyle || ""}`} />
      <MapPopup data={popupData} ref={assetLabel} />
      <MapTools
        map={map.current}
        show={
          show && {
            assetToggle: show?.assetToggle,
            moveToSelected: assetType !== "null" && assetId !== "null",
            bulkFSQC: show?.bulkFSQC && Boolean(isEditAllowed),
          }
        }
        moveToSelected={moveToSelected}
        openBulkAudit={() => setBulkAudit(true)}
      />
      <RestrictedContent keyName="farm_management" permission="edit">
        {map.current ? (
          <Modal
            modalTitle="Bulk Audit"
            width="max(60%, 100rem)"
            height="auto"
            isOpen={bulkAudit}
            handleModal={() => setBulkAudit(false)}
          >
            <BulkAudit edges={storeEdges(map.current, 1)} />
          </Modal>
        ) : (
          <></>
        )}
      </RestrictedContent>
      {loading ? (
        <MapOverlay>
          <LogoLoader width={100} />
        </MapOverlay>
      ) : null}
    </div>
  );
}
