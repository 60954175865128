import { Attachment } from "../../../lib/interfaces/Article";
import { FeatureImage } from "../../templates/NotificationPreview/styles";

interface INotificationImageProps {
  featuredImage: Attachment | null;
}

export const NotificationBanner = ({ featuredImage }: INotificationImageProps) => {
  return (
    <div data-cy="notification-preview-banner">
      <FeatureImage imageUrl={featuredImage?.url || ""} />
    </div>
  );
};
