import { useLazyQuery, useQuery } from "@apollo/client";
import { Dispatch, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUser } from "../../../../lib/contexts/usercontext";
import { getOrganizationPreferences } from "../../../../lib/utils/teamDetailApi";
import { theme } from "../../../../style/theme";
import { Divider } from "../../../atoms/Divider";
import { Grid } from "../../../atoms/Grid";
import AreaIcon from "../../../atoms/Icons/AreaIcon";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserIcon from "../../../atoms/Icons/UserIcon";
import { Radio, RadioGroup } from "../../../atoms/Input/Radio";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import { GET_USERS } from "../../../templates/TeamsDetailModal/query";
import {
  Actions,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";
import {
  GET_AGRONOMISTS,
  GET_ASSOCIATIONS,
  GET_CROPS,
  GET_CROP_VARIETY,
  GET_PLOTS,
  GET_STATUS,
} from "./query";

export interface CropDetailFormProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
}

export function CropDetailForm({ state, dispatch }: CropDetailFormProps) {
  const user = useUser();
  const [cropSuffix, setCropSuffix] = useState("");

  const [getUsers, { data: usersData }] = useLazyQuery<{
    users: { id: number; name: string }[];
  }>(GET_USERS);

  const [getPlots, { data: plotsData }] = useLazyQuery<{
    plot: { id: number; name: string; tag: string }[];
  }>(GET_PLOTS);

  const { data: crops } = useQuery(GET_CROPS);
  const { data: allStatus } = useQuery(GET_STATUS);

  const { data: associations } = useQuery(GET_ASSOCIATIONS);
  const { data: agronomists } = useQuery(GET_AGRONOMISTS);

  const [getCropVariety, { data: cropVariety }] =
    useLazyQuery(GET_CROP_VARIETY);
  useEffect(() => {
    if (state.crop.detail.teamId) {
      getUsers({ variables: { teamId: state.crop.detail.teamId } });
      getPlots({ variables: { teamId: state.crop.detail.teamId } });
    }
  }, [state.crop.detail.teamId]);

  useEffect(() => {
    if (state.crop.detail.cropId) {
      getCropVariety({ variables: { id: state.crop.detail.cropId } });
    }
  }, [state.crop.detail.cropId]);

  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: TeamActions.SET_CROP_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.crop.detail,
          ...(!state.crop.detail.updatedFields.includes(name)
            ? { updatedFields: [...state.crop.detail.updatedFields, name] }
            : {}),
          [name]: value,
        },
      },
    });
  };

  const dispatchMultiple = ({
    items,
  }: {
    items: { name: string; value: string }[];
  }) => {
    const fieldsToPutOnUpdateFields = items
      .filter((e) => !state.crop.detail.updatedFields.includes(e.name))
      .map((e) => e.name);
    const values = items.reduce(
      (acc, cv) => ({ ...acc, [cv.name]: cv.value }),
      {}
    );
    dispatch({
      type: TeamActions.SET_CROP_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.crop.detail,
          ...(fieldsToPutOnUpdateFields[0]
            ? {
                updatedFields: [
                  ...state.crop.detail.updatedFields,
                  ...fieldsToPutOnUpdateFields,
                ],
              }
            : {}),
          ...values,
        },
      },
    });
  };

  const fetchData = async (userId: string) => {
    try {
      const organizationPreferences = await getOrganizationPreferences({
        userId,
      });

      setCropSuffix(organizationPreferences.data.plot_crop_suffix);
    } catch (error) {
      toast.error("Failed to fetch preferences");
    }
  };

  useEffect(() => {
    const userId = user?.state?.me?.id;
    if (userId) {
      fetchData(userId);
    }
    return () => {
      setCropSuffix("");
    };
  }, [user]);

  return (
    <section className="flex  flex-col  gap-8" data-cy="detail-form">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.crop.detail.owner}</Typography>
        </div>
        <div className="flex justify-start items-center gap-4">
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.crop.detail.teamName}</Typography>
        </div>
      </div>
      <Grid className="mt-2" gutter="xl">
        <Grid.Col lg={2} md={4} sm={6} xs={12}>
          <Input
            label="Crop tag"
            data-cy="crop-tag"
            required
            prefix=""
            icon={
              <div className=" font-bold text-primary-main">{cropSuffix} -</div>
            }
            placeholder="Enter tag"
            type="text"
            maxLength={8}
            value={state.crop.detail.tag.replace(`${cropSuffix}-`, "")}
            name="tag"
            size="lg"
            onChange={(e) => {
              dispatchValue("tag", `${cropSuffix}-${e.target.value}`);
            }}
            autoComplete="off"
            error={!state.crop.detail.tag ? "Tag is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={5} md={4} sm={6} xs={12}>
          <Input
            label="Plot crop name"
            data-cy="plot-crop-name"
            required
            placeholder="Enter plot crop name"
            type="text"
            value={state.crop.detail.name}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("name", e.target.value);
            }}
            autoComplete="off"
            error={!state.crop.detail.name ? "Plot crop name is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={5} md={4} sm={6} xs={12}>
          <Select
            label="Plot owner"
            data-cy="plot-owner"
            size="lg"
            listData={usersData?.users || []}
            placeholder="Select owner"
            values={{ id: state.crop.detail.ownerId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("ownerId", item?.id);
            }}
            clearable
            required
            error={!state.crop.detail.ownerId ? "Owner is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={6} xs={12}>
          <Select
            label="Crop name"
            data-cy="crop-name"
            size="lg"
            listData={crops?.crop || []}
            placeholder="Select crop name"
            values={{ id: state.crop.detail.cropId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("cropId", item?.id);
            }}
            clearable
            required
            error={!state.crop.detail.cropId ? "Crop is required" : ""}
          />
        </Grid.Col>
        <Grid.Col lg={6} xs={12}>
          <Select
            label="Crop variety"
            data-cy="crop-variety"
            size="lg"
            listData={cropVariety?.cropVariety || []}
            placeholder="Select crop variety"
            values={{ id: state.crop.detail.varietyId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("varietyId", item?.id);
            }}
            clearable
            required
            error={!state.crop.detail.cropId ? "Crop variety is required" : ""}
          />
        </Grid.Col>

        <Grid.Col lg={4} xs={12}>
          <Input
            label="No. of plants"
            data-cy="no-of-plants"
            placeholder="Enter no of plants"
            type="number"
            value={state.crop.detail.noOfPlant || 0}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("noOfPlant", e.target.value);
            }}
            autoComplete="off"
          />
        </Grid.Col>

        <Grid.Col lg={4} xs={12}>
          <Input
            label="Growing cycle (in days)"
            data-cy="growing-cycle"
            placeholder="Enter growing cycle"
            type="number"
            value={state.crop.detail.growingCycle || 0}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("growingCycle", e.target.value);
            }}
            autoComplete="off"
          />
        </Grid.Col>

        <Grid.Col lg={4} xs={12}>
          <Input
            label="Estimate harvest (in ton)"
            data-cy="estimate-harvest"
            placeholder="Enter estimate harvest"
            type="number"
            value={state.crop.detail.estimateHarvest || 0}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("estimateHarvest", e.target.value);
            }}
            autoComplete="off"
          />
        </Grid.Col>

        <Grid.Col lg={6} xs={12}>
          <Input
            label="PXP distance (in feet)"
            data-cy="p2p-distance"
            required
            placeholder="Enter PXP distance"
            type="number"
            value={state.crop.detail.p2p || 0}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("p2p", e.target.value);
            }}
            autoComplete="off"
            error={!state.crop.detail.p2p ? "PXP distance is required" : ""}
          />
        </Grid.Col>

        <Grid.Col lg={6} xs={12}>
          <Input
            label="RXR distance (in feet)"
            data-cy="r2r-distance"
            required
            placeholder="Enter RXR distance"
            type="number"
            value={state.crop.detail.r2r || 0}
            name="name"
            size="lg"
            onChange={(e) => {
              dispatchValue("r2r", e.target.value);
            }}
            autoComplete="off"
            error={!state.crop.detail.r2r ? "RXR distance is required" : ""}
          />
        </Grid.Col>

        <Grid.Col lg={4} xs={12}>
          <Typography variant="h6">
            <b data-cy="is-seed">Is seed?*</b>
          </Typography>
          <RadioGroup
            data-cy="is-seed-radio"
            size="md"
            value={state.crop.detail.isSeed ? "yes" : "no"}
            onChange={(value) =>
              dispatchValue("isSeed", value === "yes" ? true : false)
            }
            spacing={30}
            mt={8}
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </RadioGroup>
        </Grid.Col>

        <Grid.Col lg={4} xs={12}>
          <Typography variant="h6">
            <b data-cy="close-crop">Close crop?*</b>
          </Typography>
          <RadioGroup
            size="md"
            data-cy="close-crop-radio"
            value={state.crop.detail.isArchived ? "yes" : "no"}
            onChange={(value) =>
              dispatchValue("isArchived", value === "yes" ? true : false)
            }
            spacing={30}
            mt={8}
          >
            <Radio value="yes" label="Yes" />
            <Radio value="no" label="No" />
          </RadioGroup>
        </Grid.Col>

        <Grid.Col lg={4} xs={12}>
          <Select
            label="Crop status"
            data-cy="crop-status"
            size="lg"
            listData={allStatus?.status || []}
            placeholder="Select crop status"
            values={{ id: state.crop.detail.statusId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("statusId", item?.id);
            }}
            clearable
            required
            error={!state.crop.detail.statusId ? "Crop status is required" : ""}
          />
        </Grid.Col>
      </Grid>
      <Divider size={1} mt={8} mb={8} />
      <Typography variant="h6"><span data-cy="additional-details">Additional details</span></Typography>

      <Grid>
        <Grid.Col span={6} data-cy="area-section">
          <Typography variant="body1">Plot area</Typography>
          <div className="flex items-center gap-4 mt-2">
            <AreaIcon color={theme.colors.orange.main} />
            <Typography variant="body1">
              <span className="font-bold">
                {state.crop.detail.area} {state.crop.detail.areaUnit}
              </span>
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            label="Plot"
            data-cy="plot"
            size="lg"
            searchable
            listData={
              plotsData?.plot?.map(
                (item: { id: number; tag: string; name: string }) => ({
                  ...item,
                  name: `${item.tag} ${item.name}`,
                })
              ) || []
            }
            placeholder="Select plot"
            values={{ id: state.crop.detail.plotId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("plotId", item?.id);
            }}
            clearable
            required
            error={!state.crop.detail.plotId ? "Plot is required" : ""}
          />
        </Grid.Col>
      </Grid>

      <Divider size={1} mt={8} mb={8} />
      <Typography variant="h6"><span data-cy="association">Association</span></Typography>
      <Grid>
        <Grid.Col lg={6} sm={12}>
          <Select
            label="Agronomist"
            data-cy="agronomist"
            size="lg"
            searchable
            listData={agronomists?.agent || []}
            placeholder="Select agronomist"
            values={{ id: state.crop.detail.agronomistId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("agronomistId", item?.id);
            }}
            clearable
          />
        </Grid.Col>
        <Grid.Col lg={6} sm={12}>
          <Select
            label="Association"
            data-cy="association-select"
            size="lg"
            searchable
            listData={
              associations?.company.filter(
                (e: { networks: {}[] }) => e.networks[0]
              ) || []
            }
            placeholder="Select association"
            values={{ id: state.crop.detail.associationId }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchMultiple({
                items: [
                  { name: "associationId", value: item?.id },
                  { name: "networkId", value: item?.networks?.[0]?.id },
                ],
              });
            }}
            clearable
          />
        </Grid.Col>
      </Grid>
    </section>
  );
}
