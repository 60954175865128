import { DocumentNode } from "graphql";
import { HiOutlineEye, HiOutlineHeart, HiOutlineThumbUp } from "react-icons/hi";
import { Cell } from "react-table";
import { theme } from "../../../style/theme";
import Typography from "../../atoms/Typography";
import { CategoryForm } from "./CategoryForm";
import { PackageForm } from "./PackageForm";
import {
  CREATE_CATEGORY,
  GET_CATEGORY,
  GET_PACKAGE,
  UPDATE_CATEGORY,
  UPSERT_PACKAGE,
} from "./query";

interface ISetupModalType {
  name: string;
  Form: any;
  state: any;
  create: DocumentNode;
  read: DocumentNode;
  update: DocumentNode;
  mapper: (data: any) => any;
  required: string[];
  validityCheck: (data: any, value: any) => any;
}

export interface ISetupModalData {
  categories: ISetupModalType;
  packages: ISetupModalType;
}

export const setupModalData: ISetupModalData = {
  categories: {
    name: "category",
    create: CREATE_CATEGORY,
    read: GET_CATEGORY,
    mapper: ({ category: { mutual_group, ...rest } }) => ({
      ...rest,
      groupId: mutual_group?.[0]?.codename,
    }),
    required: ["name"],
    validityCheck: (data, value) => value.includes(data.groupId),
    update: UPDATE_CATEGORY,
    state: {
      name: "",
      description: "",
      groupId: "",
      isCourseCategory: false,
      isActive: true,
    },
    Form: CategoryForm,
  },
  packages: {
    name: "package",
    create: UPSERT_PACKAGE,
    read: GET_PACKAGE,
    update: UPSERT_PACKAGE,
    mapper: (data) => ({
      ...data.package,
      unitPrice: String(data.package.unitPrice),
      discountPrice: String(data.package.discountPrice),
    }),
    required: ["name", "unitPrice", "discountPrice", "packageType"],
    validityCheck: (data, value) => value.includes(data.orgId),
    state: {
      name: "",
      description: "",
      isActive: true,
      isCourse: false,
      unitPrice: "0",
      discountPrice: "0",
    },
    Form: PackageForm,
  },
};

export const seriesArticlesColumns: any[] = [
  {
    Header: "Order",
    accessor: "order",
    flex: 1,
  },
  {
    Header: "Title",
    accessor: "article",
    flex: 2,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return <>{cell.value?.title || ""}</>;
    },
  },
  {
    Header: "Next Article",
    accessor: "next",
    flex: 2,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return <>{cell.value?.article?.title || ""}</>;
    },
  },
  {
    Header: "Assessment",
    accessor: "assessment",
    flex: 2,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return (
        <>
          {cell.value?.name || (
            <Typography variant="body2" textColor={theme.colors.primary["500"]}>
              No Assessment
            </Typography>
          )}
        </>
      );
    },
  },
  {
    Header: (
      <>
        <HiOutlineEye size={18} />
        <span className="ml-2">Views</span>
      </>
    ),
    accessor: "article.views",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return <>{cell.value.aggregate.count}</>;
    },
  },
  {
    Header: (
      <>
        <HiOutlineThumbUp size={18} />
        <span className="ml-2">Likes</span>
      </>
    ),
    accessor: "article.likes",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return <>{cell.value.aggregate.count}</>;
    },
  },
  {
    Header: (
      <>
        <HiOutlineHeart size={18} />
        <span className="ml-2">Bookmarks</span>
      </>
    ),
    accessor: "article.favorites",
    flex: 1,
    disableSortBy: true,
    Cell: (cell: Cell) => {
      return <>{cell.value.aggregate.count}</>;
    },
  },
];
