import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Divider } from "../../atoms/Divider";
import { MultipleUsersIcon } from "../../atoms/Icons/NavIcons";
import Typography from "../../atoms/Typography";
import { ArticleDetailTrendMetrics } from "../../organisms/AdvisorySections/ArticleDetailTrendMetrics";
import { GET_ARTICLE_DETAIL_TREND_METRICS } from "../../organisms/AdvisorySections/query";
import { ArticleBanner } from "../../organisms/ArticlePreviewSections/ArticleBanner";
import { ArticlePreviewChips } from "../../organisms/ArticlePreviewSections/ArticlePreviewChips";
import { TopListSection } from "../../organisms/ArticlePreviewSections/TopListSection";
import {
  ArticleContentContainer,
  ArticlePreviewContainer,
  AttachmentLink,
} from "./styles";
import { IArticlePreview } from "./types";

interface IArticlePreviewProps {
  data: IArticlePreview | undefined;
}

const ArticlePreview = ({ data }: any) => {
  if (!data) return null;
  const {
    article_type,
    attachment,
    authors,
    categories,
    content,
    created_at,
    crops,
    crop_varieties,
    description,
    duration,
    expiry_date,
    feature_date_from,
    feature_date_till,
    feature_image,
    id,
    is_active,
    views,
    likes,
    favorites,
    publish_date,
    regions,
    status,
    subscriptions,
    title,
    updated_at,
    user_groups,
  } = data;

  const { data: metrics } = useQuery(GET_ARTICLE_DETAIL_TREND_METRICS, {
    variables: { id: id },
    skip: !id,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    document.title = title + " - SetuFarm";
    return () => {
      document.title = "Articles - SetuFarm";
    };
  }, []);

  return (
    <ArticlePreviewContainer>
      <ArticleBanner
        articleType={article_type}
        attachments={attachment}
        featuredImage={feature_image}
      />
      <TopListSection
        publishDate={publish_date}
        authors={authors}
        categories={categories}
        subscription={subscriptions}
        metrics={metrics?.trendingMetrics}
      />
      <Divider size={1} mt={16} mb={24} />
      <Typography variant="h4">
        <span data-cy="title">{title}</span>
      </Typography>
      <ArticleContentContainer
        data-cy="content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <AttachmentLink>
        {article_type === "blog" && attachment?.media_type && (
          <>
            <b>File uploaded instead of content part:</b>
            <a href={attachment?.url} target="_blank">
              {attachment?.name}
            </a>
          </>
        )}
      </AttachmentLink>
      {regions.length +
      crop_varieties.length +
      user_groups.length +
      crops.length ? (
        <Divider size={1} mt={16} mb={24} />
      ) : null}
      <ArticlePreviewChips
        chips={{
          regions: regions.map((item: any) => ({
            name: item.name,
            id: item.codename,
          })),
          crops,
          varieties: crop_varieties,
          teams: user_groups,
        }}
      />
      <ArticleDetailTrendMetrics
        legendTitle="User Engagement"
        legendIcon={MultipleUsersIcon}
        metrics={metrics?.trendingMetrics}
      />
    </ArticlePreviewContainer>
  );
};

export default ArticlePreview;
