import styled from "styled-components";
import { theme } from "../../../style/theme";

export const ChipContainer = styled.div<{
  bgFill?: boolean;
  size?: "small" | "medium";
}>`
  font-size: ${({ size }) =>
    size === "medium"
      ? theme.fonts.subtitle2?.fontSize
      : theme.fonts.small1?.fontSize};
  padding: ${({ size }) =>
    size === "medium" ? "0.6rem 1rem" : "0.4rem 0.8rem"};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 1.5rem;
  color: ${({ theme, bgFill }) =>
    bgFill ? theme.colors.white : theme.colors.primary.main};
  background-color: ${({ theme, bgFill }) =>
    bgFill ? theme.colors.primary.main : theme.colors.white};

  & > svg {
    margin-left: 2.5rem;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
`;
