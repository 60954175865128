export interface TeamState {
  farm: {
    id: number;
    verification_remark: string;
    verified: boolean;
    detail: {
      tag: string;
      name: string;
      owner: string;
      ownerId: string | null;
      teamName?: string | null;
      teamId?: number | null;
      updatedFields: string[];
    };
    address: {
      id?: number;
      houseStreet: string;
      village: string;
      taluka: string;
      location: {
        coordinates: number[];
        type: string;
      };
      name: string;
      addressKind: string;
      pincode: string;
      state: string;
      district: string;
      country: string;
      raw: string;
      updatedFields: string[];
    };
  };
  team: {
    id: number;
    verification_remark: string;
    verified: boolean;
    detail: {
      name: string;
      owner: string;
      ownerId: string | null;
      teamName?: string | null;
      teamId?: number | null;
      signedUrl?: string | null;
      updatedFields: string[];
    };
    address: {
      id?: number;
      houseStreet: string;
      village: string;
      taluka: string;
      location: {
        coordinates: number[];
        type: string;
      };
      name: string;
      addressKind: string;
      pincode: string;
      state: string;
      district: string;
      country: string;
      raw: string;
      updatedFields: string[];
    };
  };
  user: {
    id: number;
    verification_remark: string;
    verified: boolean;
    detail: {
      firstName: string;
      middleName: string;
      lastName: string;
      username: string;
      email: string;
      owner: string;
      ownerId: string | null;
      teamName?: string | null;
      teamId?: number | null;
      number?: string | null;
      secondaryNumber?: string | null;
      dateOfBirth?: Date | null;
      gender?: string | null;
      education?: string | null;
      updatedFields: string[];
    };
    address: {
      id?: number;
      houseStreet: string;
      village: string;
      taluka: string;
      location: {
        coordinates: number[];
        type: string;
      };
      name: string;
      addressKind: string;
      pincode: string;
      state: string;
      district: string;
      country: string;
      raw: string;
      updatedFields: string[];
    };
  };
  field: {
    id: number;
    verification_remark: string;
    verified: boolean;
    detail: {
      tag: string;
      name: string;
      gatNumber: string;
      soilName: string | null,
      soilId: number | null,
      farmName: string | null,
      farmId: number | null,
      owner: string;
      areaCatered: any;
      area:  number | null,
      areaUnit: string | null,
      areaUnitId: number | null,
      ownerId: string | null;
      teamName?: string | null;
      teamId?: number | null;
      updatedFields: string[];
    };
    address: {
      id?: number;
      houseStreet: string;
      village: string;
      taluka: string;
      location: {
        coordinates: number[];
        type: string;
      };
      name: string;
      addressKind: string;
      pincode: string;
      state: string;
      district: string;
      country: string;
      raw: string;
      updatedFields: string[];
    };
  };
  plot: {
    id: number;
    verification_remark: string;
    verified: boolean;
    detail: {
      tag: string;
      name: string;
      fieldName: string | null,
      fieldId: number | null,
      owner: string;
      areaCatered: any;
      isArchived: boolean | null;
      cropName: string[];
      variety: string[];
      association: string[];
      status: string[];
      area:  number | null,
      areaUnit: string | null,
      areaUnitId: number | null,
      ownerId: string | null;
      teamName?: string | null;
      teamId?: number | null;
      updatedFields: string[];
    };
    address: {
      id?: number;
      houseStreet: string;
      village: string;
      taluka: string;
      location: {
        coordinates: number[];
        type: string;
      };
      name: string;
      addressKind: string;
      pincode: string;
      state: string;
      district: string;
      country: string;
      raw: string;
      updatedFields: string[];
    };
  };
  crop: {
    id: number;
    verification_remark: string;
    verified: boolean;
    detail: {
      tag: string;
      name: string;
      associationId: number | null;
      associationLinkId: number | null;
      association: string;
      agronomistId: number | null;
      agronomistLinkId: number | null;
      agronomist: string;
      networkId: number | null;
      variety: string;
      varietyId: number |  null;
      noOfPlant: number | null;
      growingCycle: number | null;
      estimateHarvest: number | null;
      p2p: number | null;
      p2pUnit: string;
      r2r: number | null;
      r2rUnit: string;
      isSeed: boolean | null;
      isArchived: boolean | null;
      status: string;
      statusId: number | null;
      plotName: string;
      plotId: number | null;
      areaCatered: any;
      area:  number | null,
      areaUnit: string | null,
      areaUnitId: number | null,
      cropName: string;
      cropId: number  | null;
      cropYear: string | null;
      harvestQuantity: number | null;
      owner: string;
      ownerId: string | null;
      teamName?: string | null;
      teamId?: number | null;
      updatedFields: string[];
    };
    address: {
      id?: number;
      houseStreet: string;
      village: string;
      taluka: string;
      location: {
        coordinates: number[];
        type: string;
      };
      name: string;
      addressKind: string;
      pincode: string;
      state: string;
      district: string;
      country: string;
      raw: string;
      updatedFields: string[];
    };
  };
}

export enum TeamActions {
  SET_VALUE,
  SET_VALUES,
  SET_FARM_VALUE,
  SET_USER_VALUE,
  SET_TEAM_VALUE,
  SET_FIELD_VALUE,
  SET_PLOT_VALUE,
  SET_CROP_VALUE,
}

export interface Actions {
  type: TeamActions;
  payload: any;
}

export type DetailTypes = "farm" | "team" | "user" | "field" | "plot" | "crop";
