export async function sendReportToEmail({
  type,
  from,
  till,
  email,
}: {
  type: "user" | "team";
  from: Date;
  till: Date;
  email?: string;
}) {
  const groups = "Farmer";
  let fromDate = from.toLocaleDateString("fr-CA");
  let tillDate = till.toLocaleDateString("fr-CA");
  let appendEmail = email ? `&email=${email}` : "";
  let url = `${process.env.REACT_APP_KITE_URL}/services/reports/registrations/${type}?from=${fromDate}&till=${tillDate}&groups=${groups}${appendEmail}`;
  const response = await fetch(url, {
    headers: {
      authorization: `Bearer ${localStorage.getItem("farmsetu_token")}`,
    },
  });
  if (!response.ok) {
    throw new Error("Couldn't send report");
  } else return await response.json();
}
