import { SVGProps } from "react";

const UserVerifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 10C13.7486 10 15.5714 8.20914 15.5714 6C15.5714 3.79086 13.7486 2 11.5 2C9.25141 2 7.42857 3.79086 7.42857 6C7.42857 8.20914 9.25141 10 11.5 10Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M2 22C2 16.8453 6.25329 12.6667 11.5 12.6667C16.7467 12.6667 21 16.8453 21 22H2Z"
      fill={props.color || "#005777"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8536 8.14645C22.0488 8.34171 22.0488 8.65829 21.8536 8.85355L17.8536 12.8536C17.6583 13.0488 17.3417 13.0488 17.1464 12.8536L15.1464 10.8536C14.9512 10.6583 14.9512 10.3417 15.1464 10.1464C15.3417 9.95118 15.6583 9.95118 15.8536 10.1464L17.5 11.7929L21.1464 8.14645C21.3417 7.95118 21.6583 7.95118 21.8536 8.14645Z"
      fill={props.color || "#005777"}
      stroke={props.color || "#005777"}
      stroke-width="0.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UserVerifiedIcon;
