import dayjs from "dayjs";
import { Dispatch } from "react";
import educationList from "../../../../lib/data/educationList";
import { theme } from "../../../../style/theme";
import { Grid } from "../../../atoms/Grid";
import { MultipleUsersIcon } from "../../../atoms/Icons/NavIcons";
import UserIcon from "../../../atoms/Icons/UserIcon";
import DatePickerInput from "../../../atoms/Input/DatePicker";
import { Radio, RadioGroup } from "../../../atoms/Input/Radio";
import { Input } from "../../../atoms/MantineInput";
import { Select } from "../../../atoms/MantineSelect";
import Typography from "../../../atoms/Typography";
import {
  Actions,
  TeamActions,
  TeamState,
} from "../../../templates/TeamsDetailModal/type";
import { gqlQueryDate } from "../../../../lib/utils/dateTimeFormat";

export interface UserDetailFormProps {
  state: TeamState;
  dispatch: Dispatch<Actions>;
}

export function UserDetailForm({ state, dispatch }: UserDetailFormProps) {
  const dispatchValue = (name: string, value: any) => {
    dispatch({
      type: TeamActions.SET_USER_VALUE,
      payload: {
        key: "detail",
        value: {
          ...state.user.detail,
          ...(!state.user.detail.updatedFields.includes(name)
            ? {
                updatedFields: [
                  ...state.user.detail.updatedFields,
                  name === "middleName" ? "lastName" : name,
                ],
              }
            : {}),
          [name]: value,
        },
      },
    });
  };

  return (
    <section className="flex flex-col gap-8" data-cy="detail-form">
      <div className="flex justify-between items-center">
        <div className="flex justify-start items-center gap-4">
          <UserIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.user.detail.owner}</Typography>
        </div>
        <div className="flex justify-start items-center gap-4">
          <MultipleUsersIcon color={theme.colors.orange.main} />
          <Typography variant="h6">{state.user.detail.teamName}</Typography>
        </div>
      </div>
      <Grid className="mt-2">
        <Grid.Col span={4}>
          <Input
            label="First name"
            data-cy="first-name"
            required
            placeholder="Enter first name"
            type="text"
            value={state.user.detail.firstName}
            name="firstName"
            size="lg"
            onChange={(e) => {
              dispatchValue("firstName", e.target.value);
            }}
            autoComplete="off"
            error={!state.user.detail.firstName ? "First name is required" : ""}
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <Input
            label="Middle name"
            data-cy="middle-name"
            placeholder="Enter middle name"
            type="text"
            value={state.user.detail.middleName}
            name="middleName"
            size="lg"
            onChange={(e) => {
              dispatchValue("middleName", e.target.value);
            }}
            autoComplete="off"
          />
        </Grid.Col>

        <Grid.Col span={4}>
          <Input
            label="Last name"
            data-cy="last-name"
            required
            placeholder="Enter last name"
            type="text"
            value={state.user.detail.lastName}
            name="lastName"
            size="lg"
            onChange={(e) => {
              dispatchValue("lastName", e.target.value);
            }}
            autoComplete="off"
            error={!state.user.detail.lastName ? "Last name is required" : ""}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <Input
            label="Primary contact"
            data-cy="primary-contact"
            required
            placeholder="Enter primary contact"
            type="number"
            value={state.user.detail.number || ""}
            name="number"
            size="lg"
            onChange={(e) => {
              if (e.target.value.length > 20) return;
              dispatchValue("number", e.target.value);
            }}
            autoComplete="off"
            error={
              !state.user.detail.number ? "Primary contact is required" : ""
            }
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <Input
            label="Alternate contact"
            data-cy="alternate-contact"
            placeholder="Enter alternate contact"
            type="number"
            value={state.user.detail.secondaryNumber || ""}
            name="alternate-contact"
            size="lg"
            onChange={(e) => {
              if (e.target.value.length > 20) return;
              dispatchValue("secondaryNumber", e.target.value);
            }}
            autoComplete="off"
            error={
              state.user.detail.number === state.user.detail.secondaryNumber
                ? "Primary and Alternate numbers cannot be the same."
                : ""
            }
          />
        </Grid.Col>

        <Grid.Col span={6} lg={4}>
          <DatePickerInput
            size="lg"
            name=""
            label="Date of birth"
            data-cy="dob"
            required
            placeholder="Pick date"
            value={dayjs(state.user.detail.dateOfBirth).toDate()}
            maxDate={new Date()}
            onChange={(value: Date) => {
              dispatchValue("dateOfBirth", gqlQueryDate(value));
            }}
          />
        </Grid.Col>

        <Grid.Col span={6} lg={4} data-cy="gender">
          <Typography variant="h6">
            <b>Gender*</b>
          </Typography>
          <RadioGroup
            size="md"
            value={state.user.detail.gender?.toLocaleLowerCase() || "other"}
            onChange={(value) => dispatchValue("gender", value)}
            spacing={30}
            mt={8}
          >
            <Radio value="male" label="Male" />
            <Radio value="female" label="Female" />
            <Radio value="other" label="Other" />
          </RadioGroup>
        </Grid.Col>

        <Grid.Col span={6} lg={4}>
          <Select
            label="Education"
            data-cy="education"
            size="lg"
            listData={educationList}
            placeholder="Select education"
            values={{ id: state.user.detail.education || "" }}
            accessor={{ name: "name", value: "id" }}
            onSelect={(item) => {
              dispatchValue("education", item?.id);
            }}
            clearable
            required
            error={!state.user.detail.education ? "Education is required" : ""}
          />
        </Grid.Col>
      </Grid>
    </section>
  );
}
