import { SVGProps } from "react";

const CareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9536 15.1229C10.9839 16.5384 11.4857 18.8872 11.8582 19.856C12.0301 19.1327 13.0746 17.4198 14.5956 16.9655C16.8165 16.3021 17.3077 16.291 18.6895 15.1945C20.0712 14.0979 20.902 11.0555 20.9902 10.7831C21.0785 10.5107 21.1818 9.74094 21.0088 8.8662C20.8704 8.16641 20.8095 7.45664 20.7963 7.18923C20.8771 6.53838 20.8143 6.15711 20.7728 6.04784C20.1954 5.85839 19.9965 6.7307 19.9692 7.19054L19.8935 9.4618C20.1085 9.56104 20.4812 9.89858 20.066 10.609C19.7337 11.1774 19.1728 12.1194 18.9339 12.5194C18.2765 13.1934 17.9856 13.3133 17.8972 13.3704C17.8265 13.4161 17.7426 13.4249 17.7095 13.4236C18.3027 12.9724 18.6112 12.6144 18.7631 12.3916C18.915 12.1688 19.0793 11.8926 19.3187 11.4545C19.5163 11.0928 20.0696 10.3225 20.0157 9.96112C20.0044 9.88486 19.9457 9.76594 19.8666 9.73998C19.4125 9.59103 19.138 9.80233 18.5488 10.3983C17.9596 10.9943 17.7179 11.3886 17.5287 11.5561C17.3395 11.7236 16.838 12.3197 16.333 12.4984C15.828 12.6772 15.4828 12.7074 15.0631 12.8424C14.6434 12.9774 13.1656 13.3536 11.9536 15.1229Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M19.7669 8.38703L19.7825 9.4541C19.2729 9.44763 18.9631 9.69233 18.8719 9.81548L18.9068 8.9085C18.9224 7.50151 19.0436 6.96819 19.1022 6.87741C19.1768 6.68184 19.382 6.29759 19.6061 6.32519C19.8862 6.35969 19.8034 6.6626 19.7622 7.38093C19.7291 7.9556 19.7515 8.29111 19.7669 8.38703Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M18.7082 8.90073L18.7246 9.91065C18.2934 10.2571 17.9273 10.7015 17.8361 10.8246L17.8847 9.53466C17.9002 8.12767 18.0656 7.5291 18.1242 7.43832C18.1988 7.24275 18.404 6.8585 18.6281 6.8861C18.9082 6.9206 18.7595 7.16679 18.7182 7.88513C18.6851 8.45979 18.6928 8.80481 18.7082 8.90073Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M17.666 10.6397L17.6671 11.0131C17.2757 11.4587 16.9134 11.8326 16.8222 11.9557L16.9047 11.4375C17.0212 10.5573 16.9569 10.7802 16.9378 10.2142C16.9141 9.50995 17.0879 8.97323 17.1268 8.91293C17.2014 8.71735 17.3736 8.30053 17.5977 8.32813C17.8778 8.36263 17.7186 8.57609 17.6773 9.29442C17.6443 9.86909 17.6507 10.5438 17.666 10.6397Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M10.0457 15.1229C11.0154 16.5384 10.5135 18.8872 10.141 19.856C9.96912 19.1327 8.92466 17.4198 7.40367 16.9655C5.18274 16.3021 4.69159 16.291 3.30981 15.1945C1.92803 14.0979 1.09726 11.0555 1.00904 10.7831C0.92081 10.5107 0.817487 9.74094 0.990485 8.8662C1.12888 8.16641 1.1898 7.45664 1.20296 7.18923C1.12219 6.53838 1.18495 6.15711 1.22643 6.04784C1.80388 5.85839 2.00281 6.7307 2.0301 7.19054L2.10577 9.4618C1.89075 9.56104 1.51802 9.89858 1.93332 10.609C2.26555 11.1774 2.82642 12.1194 3.06533 12.5194C3.72275 13.1934 4.01362 13.3133 4.10203 13.3704C4.17275 13.4161 4.25665 13.4249 4.28976 13.4236C3.69655 12.9724 3.38804 12.6144 3.23614 12.3916C3.08425 12.1688 2.91999 11.8926 2.6806 11.4545C2.48292 11.0928 1.92966 10.3225 1.98352 9.96112C1.99489 9.88486 2.05357 9.76594 2.13269 9.73998C2.58673 9.59103 2.86123 9.80233 3.45044 10.3983C4.03964 10.9943 4.28139 11.3886 4.47058 11.5561C4.65976 11.7236 5.16129 12.3197 5.66626 12.4984C6.17124 12.6772 6.51651 12.7074 6.93617 12.8424C7.35583 12.9774 8.83366 13.3536 10.0457 15.1229Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M2.23238 8.38703L2.21673 9.4541C2.72637 9.44763 3.03617 9.69233 3.12737 9.81548L3.09244 8.9085C3.07688 7.50151 2.95571 6.96819 2.89708 6.87741C2.8225 6.68184 2.6173 6.29759 2.39319 6.32519C2.11305 6.35969 2.19582 6.6626 2.23711 7.38093C2.27014 7.9556 2.24772 8.29111 2.23238 8.38703Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M3.29109 8.90073L3.27471 9.91065C3.70584 10.2571 4.07196 10.7015 4.16315 10.8246L4.11459 9.53466C4.09902 8.12767 3.93371 7.5291 3.87508 7.43832C3.8005 7.24275 3.5953 6.8585 3.37119 6.8861C3.09105 6.9206 3.23979 7.16679 3.28109 7.88513C3.31412 8.45979 3.30643 8.80481 3.29109 8.90073Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M4.33324 10.6397L4.33215 11.0131C4.72362 11.4587 5.08592 11.8326 5.17711 11.9557L5.09453 11.4375C4.97811 10.5573 5.04232 10.7802 5.06142 10.2142C5.08519 9.50995 4.91137 8.97323 4.87242 8.91293C4.79784 8.71735 4.62569 8.30053 4.40158 8.32813C4.12144 8.36263 4.28067 8.57609 4.32197 9.29442C4.355 9.86909 4.34858 10.5438 4.33324 10.6397Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M17.9425 0.580739C14.6369 1.28988 10.7655 4.53016 10.8051 9.02024C10.814 10.1001 11.4183 12.5423 11.5236 12.5304C11.6072 9.20582 12.9134 6.18971 15.0628 4.16777C13.3278 6.62939 12.7368 9.38823 12.1953 12.5303C14.3853 12.3803 16.123 10.5555 16.5502 7.82941C16.715 6.7743 16.7684 5.68982 16.9047 4.62662C16.9994 3.89918 17.1059 3.16634 17.2857 2.46904C17.4547 1.81165 17.7234 1.19666 17.9425 0.580739Z"
      fill={props.color || "#005777"}
    />
    <path
      d="M2.53951 5.29255C5.84581 4.55867 10.9578 5.8551 10.3141 11.1701C9.62358 8.97568 8.5231 7.9203 6.83571 7.20889C8.28283 8.4571 9.22842 9.73001 9.72336 11.9388C8.56192 12.7265 6.52269 11.3838 5.76165 9.76396C5.22462 8.62089 5.22462 7.20889 4.68759 6.57013C4.31306 6.12464 3.61353 5.29259 2.53951 5.29255Z"
      fill={props.color || "#005777"}
    />
  </svg>
);

export default CareIcon;
