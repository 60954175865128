import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000070;
  z-index: 1000;
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const ModalBox = styled.div<{
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ padding }) => padding || "2rem 0"};
  ${({ margin }) => `margin: ${margin}`};
  border-radius: 1rem;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || "95vw"};
  height: ${({ height }) => height || "95vh"};
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 1rem 2rem;
`;

export const ModalHead = styled.div<{ borderBottom?: boolean }>`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ borderBottom, theme }) =>
      borderBottom ? `solid 1px ${theme.colors.border};` : "none"}
    & svg {
    cursor: pointer;
  }
`;
