import { MultiSelect } from "../../components/atoms/MultiSelect";
import { normalizeNetworkTeams } from "./normalize";
import { GET_NETWORK_TEAMS } from "./query";

export const filterData = [
  {
    name: "network",
    component: MultiSelect,
    props: {
      label: "Networks",
      size: "md",
      listData: [],
      searchable: true,
      clearable: true,
      placeholder: "Select networks",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onSelect: (value: any) => func("network", value),
    }),
  },
  {
    name: "teams",
    component: MultiSelect,
    props: {
      label: "Teams",
      size: "md",
      listData: [],
      query: GET_NETWORK_TEAMS,
      searchable: true,
      clearable: true,
      placeholder: "Select teams",
      className: "grid-col-span-2",
    },
    handler: (func: any) => ({
      onSelect: (value: any) => func("teams", value),
      fetchedData: (data: { teams: any[] }, values: any[], key?: string) =>
        func("teams", normalizeNetworkTeams(data.teams, values, key), true),
    }),
  },
];

export const initialValues = {
  teams: {
    valueprop: "values",
    accessor: {
      name: "name",
      value: "id",
    },
    values: [],
  },
  network: {
    valueprop: "values",
    accessor: {
      name: "name",
      value: "id",
    },
    values: [],
  },
};

export const teamsInitialData = {
  localStorageKey: "teamsManageFilter",
  teams: {
    dataprop: "listData",
    listData: [],
  },
  network: {
    dataprop: "listData",
    listData: [],
  },
};

export const usersInitialData = {
  localStorageKey: "usersManageFilter",
  teams: {
    dataprop: "listData",
    listData: [],
  },
  network: {
    dataprop: "listData",
    listData: [],
  },
};
