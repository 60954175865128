import { useEffect, useState } from "react";

interface ILocation {
  lat: number;
  lng: number;
  status: "loading" | "success" | "error";
}

export default function useLocation() {
  const [location, setLocation] = useState<ILocation>({
    lat: 0,
    lng: 0,
    status: "loading",
  });

  function handleLocation(status: PermissionStatus) {
    if (status.state !== "denied") {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(() => ({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          status: "success",
        }));
      });
      if (status.state === "prompt")
        setLocation(() => ({ status: "error", lat: 0, lng: 0 }));
    } else {
      setLocation(() => ({ status: "error", lat: 0, lng: 0 }));
    }
  }

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.permissions.query({ name: "geolocation" }).then((status) => {
        handleLocation(status);
        status.onchange = () => handleLocation(status);
      });
    } else {
      setLocation(() => ({ status: "error", lat: 0, lng: 0 }));
    }
  }, []);

  return location;
}
