import { gql } from "@apollo/client";

export const TODAYS_BASE_RATES = gql`
  query getTodaysBaseRates {
    todaysBaseRates: trade_otc_todays_commodity_variety_base_rates_v2
  }
`;

export const CREATE_BASE_RATES = gql`
  mutation createBaseRates($rates: [BaseRateRangeValueInputType]!) {
    set_commodity_variety_base_rates_v2(rates: $rates) {
      ids
    }
  }
`;

export const TRADE_BASE_RATES = gql`
  query getTradeBaseRates(
    $search: String
    $startDate: Date
    $endDate: Date
    $limit: Int
    $offset: Int
  ) {
    baseRates: trade_otc_base_rates_v2(
      search: $search
      start_date: $startDate
      end_date: $endDate
      limit: $limit
      offset: $offset
    )
  }
`;
