import { FC } from "react";
import { HiX } from "react-icons/hi";
import { ChipContainer } from "./styles";
import { IChip } from "./types";

export const Chip: FC<{
  chip: IChip;
  handleChipDelete?: Function;
  fill?: boolean;
  size?: "small" | "medium";
}> = ({ chip, fill, size, handleChipDelete }) => {
  return (
    <ChipContainer bgFill={fill} size={size || "medium"}>
      <span>{chip.name}</span>
      {handleChipDelete && (
        <HiX className="!ml-2" onClick={() => handleChipDelete(chip)} />
      )}
    </ChipContainer>
  );
};
