import SkeletonLoader from "../../atoms/Loader/SkeletonLoader";
import Typography from "../../atoms/Typography";
import { UserEngagementContainer } from "./styles";
import { TrendList } from "../TrendMetricsCard/TrendList";
import { Divider } from "../../atoms/Divider";
import { numberFormatter } from "../../../lib/utils/numberFormat";

type TabNames = "likes" | "views" | "favorites" | "purchase";

interface IProps {
  label: string;
  count?: number;
  data: any;
  loading: boolean;
  className?: string;
}

export const UserEngagementCard = ({
  label,
  count,
  data,
  loading,
  className,
}: IProps) => {
  return !loading ? (
    <UserEngagementContainer
      data-cy="user-engagement-card"
      className={`${className || ""}`}
    >
      <div className="px-4 pb-6 border-b border-solid border-border flex items-center justify-between">
        <Typography variant="subtitle2">{label}</Typography>
        {count && (count !== undefined || count !== null) && (
          <Typography variant="subtitle2">
            {numberFormatter(count, 2)}
          </Typography>
        )}
      </div>
      <TrendList data={data || []} />
    </UserEngagementContainer>
  ) : (
    <SkeletonLoader height={400} />
  );
};
