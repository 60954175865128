import { Group } from "../../atoms/Group";
import Typography from "../../atoms/Typography";

export function ProductTable({
  data,
}: {
  data: { name: string; value: string }[];
}) {
  return (
    <>
      {data.map((item, i) => (
        <Group key={i} className="border border-border border-solid">
          <div className="bg-gray-100 p-2 flex-1">
            <Typography variant="body1">{item.name}</Typography>
          </div>
          <div className="p-2 flex-1">
            <Typography variant="body1">{item.value}</Typography>
          </div>
        </Group>
      ))}
    </>
  );
}
